import React from 'react'
import { Col, Form, Input, Row, Switch } from 'antd';
import { INPUT_FILTERS, CHECKBOX_FILTERS } from '../constants';
import FiltersModalFormFieldsMultiSelect from './FormFieldsMultiSelect';
import FiltersModalFormField from './FormField';

const FiltersModalForm = (props) => {
  const {
    form,
    fields,
    ...modalFormProps
  } = props;

  return (
    <Form
      {...modalFormProps}
      form={form}
      layout="vertical"
      autoComplete="off"
    >
      {INPUT_FILTERS.map(item => (
        <Form.Item
          key={item.value}
          name={item.value}
          label={item.label}
          tooltip={item?.tooltip}
        >
          <Input.Group compact>
            <FiltersModalFormFieldsMultiSelect
              value={item.value}
              fields={fields}
              style={{ width: '30%' }}
            />

            <FiltersModalFormField
              type={item.type}
              value={item.value}
            />
          </Input.Group>
        </Form.Item>
      ))}

      <Row>
        {CHECKBOX_FILTERS.map(item => (
          <Col
            key={item.value}
            span={12}
          >
            <Form.Item
              name={item.value}
              label={item.label}
              tooltip={item?.tooltip}
            >
              <Input.Group
                compact
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FiltersModalFormFieldsMultiSelect
                  value={item.value}
                  fields={fields}
                  style={{
                    width: '70%',
                    marginRight: '10px',
                  }}
                />

                <Form.Item
                  name={[item.value, 'value']}
                  valuePropName="checked"
                  noStyle
                >
                  <Switch style={{ borderRadius: '100px' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Form>
  );
}

export default FiltersModalForm;
