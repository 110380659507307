import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import StoreSetupFooter from './Footer';

const MerchantSetupBody = props => {
  const [form] = Form.useForm();
  const { setCurrentStep, stores: { currentStep }} = props;

  const maxWidth = useMemo(() => currentStep === 0 ? '100%' :'700px', [currentStep]);

  const incrementCurrentStep = () => {
    setTimeout(() => {
      setCurrentStep(currentStep + 1);
    }, 100);
  }

  const StepComponentWithForm = () => {
    return React.Children.map(props.children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          form,
          incrementCurrentStep,
        });
      }

      return child;
    });
  }

  return (
    <div style={{ maxWidth }}>
      <Form.Provider>
        <div className="p-top-15 p-btm-15">
          <StepComponentWithForm />
        </div>

        <StoreSetupFooter form={form} />
      </Form.Provider>
    </div>
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setCurrentStep(val) {
    dispatch({
      type: 'SET_CURRENT_STEP',
      payload: val
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSetupBody);
