import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Select } from 'antd';
import { FORM_LAYOUT } from '../../shared/constants';
import { requiredField, urlField } from '../../../../../utils/validations';
import ImageUploadInput from '../../../../Global/ImageUploadInput';
import request from '../../../../../core/request';
import POS_TYPES from '../../../../../shared/constants/pos-types';

const AccountInformation = props => {
  const {
    form,
    setAccountInformation,
    incrementCurrentStep,
    accountInformation,
  } = props;

  const [packages, setPackages] = useState([]);

  const initialValues = useMemo(() => (accountInformation), [accountInformation]);
  const packageBillingCode = Form.useWatch('packageBillingCode', form);


  const handleSubmit = (fields) => {
    const brandImage = form.getFieldValue('brandImage');

    setAccountInformation({
      ...accountInformation,
      ...fields,
      brandImage,
    });

    incrementCurrentStep();
  }

  const handleChangeBrandImage = ({ fileUrl, fileList }) => {
    setAccountInformation({
      ...accountInformation,
      brandImage: fileUrl,
    })

    return fileList;
  }

  useEffect(() => {
    request.basic
      .get('/packages/getPackages')
      .then(({ data }) => {
        const packages = data.map(item => {
          const massMessagingFeature = item.features.find(item => item.code === 'mass_messaging');

          return {
            value: item.code,
            label: item.name,
            mass_messaging: massMessagingFeature.value,
          }
        });

        setPackages(packages)});
  }, []);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (packageBillingCode && packages.length) {
      const selectedPackage = packages.find(item => item.value === packageBillingCode);

      if (selectedPackage) {
        form.setFieldValue('massMessageLimit', selectedPackage.mass_messaging);
      }
    }
  }, [form, packageBillingCode, packages]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      autoComplete="off"
      {...FORM_LAYOUT}
    >
      <Form.Item
        name="fileList"
        label="Brand Image"
        rules={[requiredField('Brand Image')]}
        getValueFromEvent={handleChangeBrandImage}
      >
        <ImageUploadInput folder="logos" />
      </Form.Item>

      <Form.Item
        name="accountName"
        label="Account name"
        rules={[requiredField('Account name')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="websiteUrl"
        label="Website URL"
        rules={[
          requiredField('Website URL'),
          urlField(),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="packageBillingCode"
        label="Package Billing Code"
        rules={[requiredField('Package Billing Code')]}
      >
        <Select style={{ width: '100%' }}>
          { packages.map(item => (
            <Select.Option
              key={item.value}
              value={item.value}
            >
              { item.label }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="licenseSeats"
        label="License seats"
        rules={[requiredField('License seats')]}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="massMessageLimit"
        label="Mass message limit"
        rules={[requiredField('Mass message limit')]}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="posType"
        label="POS type"
      >
        <Select
          placeholder="Select POS type or leave empty"
          style={{ width: '100%' }}
          allowClear
        >
          { POS_TYPES.map(posType => (
            <Select.Option
              key={posType.value}
              value={posType.value}
            >
              { posType.label }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="salesforceBillingAccountId"
        label="Merchant Salesforce ID"
        rules={[requiredField('Merchant Salesforce ID')]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}

const mapStateToProps = state => state.stores;

const mapDispatchToProps = dispatch => ({
  setAccountInformation(val) {
    dispatch({
      type: 'SET_ACCOUNT_INFORMATION',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
