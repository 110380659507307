import React from "react";
import { Button, Drawer, Skeleton, Spin } from "antd";

const DeleteClients = (props) => {
  return (
    <Drawer
      title={"List of Clients to Delete"}
      placement="right"
      open={props.open}
      onClose={() => props.setOpen(false)}
      destroyOnClose={true}
      loading={`${props.loading}`}
      width={"400px"}
    >
      {props.loading ? (
        <div>
          <Spin />
          <Skeleton />
        </div>
      ) : (
        <div>
          {Object.values(props.mergingClients).map(cli => {
            return (
              <div key={cli.id} className="removeFromList">
                <span style={{marginRight: '15px'}}>{cli.name}: {cli.id}</span>
                <Button
                  type="danger"
                  onClick={() => {
                    let delObj = props.mergingClients;
                    delete delObj[`${cli.id}`];
                    props.setMergingClients({ ...delObj });
                  }}>
                  Remove From List
                </Button>
              </div>)
          })}
        </div>
        // <DataAssignmentListItems
        //   fullClientDetails={props.fullClientDetails}
        //   setTempClientDetails={props.setTempClientDetails}
        //   tempClientDetails={props.tempClientDetails}
        //   primaryClient={props.primaryClient}
        // />
      )}
    </Drawer>
  );
};
export default DeleteClients;
