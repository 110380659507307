import React from 'react'
import { Modal } from 'antd'

const SearchAssociatesModal = ({ open, setOpen, onSuccess, merchantName }) => {
  return (
    <Modal
      title="Search associates"
      open={open}
      onOk={onSuccess}
      onCancel={() => setOpen(false)}
    >
      Search all <strong>{merchantName || ''}</strong> associates?
    </Modal>
  );
}

export default SearchAssociatesModal;
