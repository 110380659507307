import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Col, Row, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import StoreLocation from "./StoreLocation";
import { DEFAULT_STORE_LOCATION } from "../../../../../reducers/stores";
import { FORM_LAYOUT } from "../../shared/constants";
import { useHistory } from "react-router";
import request from "../../../../../core/request";

const ActionButton = (props) => {
  return (
    <Row>
      <Col xs={{ span: 24, offset: 24 }} sm={{ span: 16, offset: 8 }} md={{ span: 18, offset: 6 }}>
        <Button {...props} type="link" style={{ padding: "4px 0" }}>
          {props.text}
        </Button>
      </Col>
    </Row>
  );
};

const StoreLocations = (props) => {
  const history = useHistory();

  const {
    form,
    setBandwidthInformation,
    setTwilioInformation,
    setStoreLocations,
    setStoreLoading,
    stores,
    stores: {
      bandwidthInformation,
      twilioInformation,
      accountInformation,
      ownerInformation,
      storeLocations,
    },
  } = props;

  const [bandwidthPeerId, setBandwidthPeerId] = useState(bandwidthInformation.peerId);
  const [bandwidthPeerName, setBandwidthPeerName] = useState(bandwidthInformation.peerName);
  const [twilioFriendlyName, setTwilioFriendlyName] = useState(twilioInformation.friendlyName);
  const [twilioAuthToken, setTwilioAuthToken] = useState(twilioInformation.authToken);
  const [twilioSid, setTwilioSid] = useState(twilioInformation.sid);

  const initialValues = useMemo(() => {
    let initialValues = {};

    Object.keys(storeLocations).forEach((key) => {
      const {
        searchStatus,
        paymentStatus,
        locationName,
        street1,
        street2,
        city,
        state,
        zipCode,
        country,
        reviewLink,
        salesforceAccountId,
        phoneNumber,
        phoneNumberCountryCode,
        provider,
        providerAreaCode,
        providerAreaCodeCountryCode,
        providerNumber,
        timezone,
        lat,
        lng,
      } = storeLocations[key];

      initialValues = {
        ...initialValues,
        [`${key}-searchStatus`]: searchStatus,
        [`${key}-paymentStatus`]: paymentStatus,
        [`${key}-locationName`]: locationName,
        [`${key}-street1`]: street1,
        [`${key}-street2`]: street2,
        [`${key}-city`]: city,
        [`${key}-state`]: state,
        [`${key}-zipCode`]: zipCode,
        [`${key}-country`]: country,
        [`${key}-reviewLink`]: reviewLink,
        [`${key}-salesforceAccountId`]: salesforceAccountId,
        [`${key}-phoneNumber`]: phoneNumber,
        [`${key}-phoneNumberCountryCode`]: phoneNumberCountryCode,
        [`${key}-provider`]: provider,
        [`${key}-providerAreaCode`]: providerAreaCode,
        [`${key}-providerAreaCodeCountryCode`]: providerAreaCodeCountryCode,
        [`${key}-providerNumber`]: providerNumber,
        [`${key}-timezone`]: timezone,
        [`${key}-lat`]: lat,
        [`${key}-lng`]: lng,
      };
    });

    return initialValues;
  }, [storeLocations]);

  const getFormFieldsByKey = (key) => {
    const fields = form.getFieldsValue([
      `${key}-searchStatus`,
      `${key}-paymentStatus`,
      `${key}-locationName`,
      `${key}-street1`,
      `${key}-street2`,
      `${key}-city`,
      `${key}-state`,
      `${key}-zipCode`,
      `${key}-country`,
      `${key}-reviewLink`,
      `${key}-salesforceAccountId`,
      `${key}-phoneNumber`,
      `${key}-phoneNumberCountryCode`,
      `${key}-provider`,
      `${key}-providerAreaCode`,
      `${key}-providerAreaCodeCountryCode`,
      `${key}-providerNumber`,
      `${key}-timezone`,
      `${key}-lat`,
      `${key}-lng`,
    ]);

    return {
      searchStatus: fields[`${key}-searchStatus`],
      paymentStatus: fields[`${key}-paymentStatus`],
      locationName: fields[`${key}-locationName`],
      street1: fields[`${key}-street1`],
      street2: fields[`${key}-street2`],
      city: fields[`${key}-city`],
      state: fields[`${key}-state`],
      zipCode: fields[`${key}-zipCode`],
      country: fields[`${key}-country`],
      reviewLink: fields[`${key}-reviewLink`],
      salesforceAccountId: fields[`${key}-salesforceAccountId`],
      phoneNumber: fields[`${key}-phoneNumber`],
      phoneNumberCountryCode: fields[`${key}-phoneNumberCountryCode`],
      provider: fields[`${key}-provider`],
      providerAreaCode: fields[`${key}-providerAreaCode`],
      providerAreaCodeCountryCode: fields[`${key}-providerAreaCodeCountryCode`],
      providerNumber: fields[`${key}-providerNumber`],
      timezone: fields[`${key}-timezone`],
      lat: fields[`${key}-lat`],
      lng: fields[`${key}-lng`],
    };
  };

  const getReducerSchemaFromForm = () => {
    const reducerSchema = {};

    Object.keys(storeLocations).forEach((key) => {
      reducerSchema[key] = getFormFieldsByKey(key);
    });

    return reducerSchema;
  };

  const handleAddLocation = () => {
    const reducerSchema = getReducerSchemaFromForm();

    const currentKeys = Object.keys(reducerSchema).map((key) => parseInt(key));
    const nextKey = Math.max.apply(Math, currentKeys) + 1;

    reducerSchema[nextKey] = { ...DEFAULT_STORE_LOCATION };

    setBandwidthInformation({
      peerName: bandwidthPeerName,
      peerId: bandwidthPeerId,
    });

    setTwilioInformation({
      friendlyName: twilioFriendlyName,
      authToken: twilioAuthToken,
      sid: twilioSid,
    });

    setStoreLocations(reducerSchema);
  };

  const handleRemoveLocation = (key) => {
    const reducerSchema = getReducerSchemaFromForm();

    delete reducerSchema[key];

    setBandwidthInformation({
      peerName: bandwidthPeerName,
      peerId: bandwidthPeerId,
    });

    setTwilioInformation({
      friendlyName: twilioFriendlyName,
      authToken: twilioAuthToken,
      sid: twilioSid,
    });

    setStoreLocations(reducerSchema);
  };

  const handleShowRemoveLocationConfirm = (key, index) => {
    Modal.confirm({
      title: `Are you sure you want to delete store location ${index + 1}`,
      content: "This will permanently delete this store location.",
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      onOk() {
        handleRemoveLocation(key);
      },
    });
  };

  const handleFinish = () => {
    const reducerSchema = getReducerSchemaFromForm();

    const newBandwidthInformation = {
      peerName: bandwidthPeerName,
      peerId: bandwidthPeerId,
    };

    const newTwilioInformation = {
      friendlyName: twilioFriendlyName,
      authToken: twilioAuthToken,
      sid: twilioSid,
    };

    setBandwidthInformation(newBandwidthInformation);

    setTwilioInformation(newTwilioInformation);

    setStoreLocations(reducerSchema);

    handleSubmit(reducerSchema, newTwilioInformation, newBandwidthInformation);
  };

  const handleSubmit = async (storeLocations, newTwilioInformation, newBandwidthInformation) => {
    setStoreLoading(true);

    let supplierId = accountInformation?.accountName
      .replace(/[^a-zA-Z0-9]/g, '')
      .toLowerCase();

    try {
      const storesRequestBody = Object.values(storeLocations).map((e) => ({
        name: e.locationName,
        street1: e.street1,
        street2: e.street2,
        city: e.city,
        state: e.state,
        postalCode: e.zipCode,
        isoCountryCode: e.country,
        phone: e.phoneNumber,
        provider: e.provider,
        twilioNumber: e.providerNumber,
        reviewLink: e.reviewLink,
        salesforceAccountId:
          Object.keys(storeLocations).length > 1
            ? e.salesforceAccountId
            : accountInformation.salesforceBillingAccountId,
        timezone: e.timezone,
        lat: e.lat,
        lon: e.lng,
      }));

      const requestBody = {
        env: process.env.REACT_APP_API_ENV,
        function: "createStore",
        template: stores.storeType,
        boutique: "false",
        name: accountInformation.accountName,
        supplierId,
        brandImage: accountInformation.brandImage,
        websiteUrl: accountInformation.websiteUrl,
        kioskBackground: "#3F3F3F",
        kioskPrimary: "#000000",
        kioskSecondary: "#000000",
        kioskLogoRound: "false",
        licenseCount: accountInformation.licenseSeats,
        csm: "admin",
        packageBillingCode: accountInformation.packageBillingCode,
        posType: accountInformation.posType,
        massMessageLimit: accountInformation.massMessageLimit,
        twilioAccountSid: newTwilioInformation.sid,
        twilioAuthToken: newTwilioInformation.authToken,
        salesforceBillingAccountId: accountInformation.salesforceBillingAccountId,
        zohoCustomerId: accountInformation.zohoCustomerId,
        managerInfo: {
          firstName: ownerInformation.firstName,
          lastName: ownerInformation.lastName,
          email: ownerInformation.email,
          phone: ownerInformation.phoneNumber,
          pin: "1234",
        },
        stores: storesRequestBody,
      };

      await request.twilio.post(`/twiliomigration`, requestBody);

      message.success("Store created successfully");

      history.push("/");
    } catch (error) {
      message.error("Error creating store");
    } finally {
      setStoreLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      name="storeLocations"
      form={form}
      onFinish={handleFinish}
      initialValues={initialValues}
      autoComplete="off"
      {...FORM_LAYOUT}
    >
      {Object.keys(storeLocations).map((key, index) => (
        <div key={key}>
          {Object.keys(storeLocations).length > 1 && <h3>Location {index + 1}</h3>}

          <StoreLocation
            index={key}
            form={form}
            locationsIndexes={Object.keys(storeLocations)}
            bandwidthPeerId={bandwidthPeerId}
            bandwidthPeerName={bandwidthPeerName}
            setBandwidthPeerId={setBandwidthPeerId}
            setBandwidthPeerName={setBandwidthPeerName}
            twilioFriendlyName={twilioFriendlyName}
            twilioAuthToken={twilioAuthToken}
            twilioSid={twilioSid}
            setTwilioFriendlyName={setTwilioFriendlyName}
            setTwilioAuthToken={setTwilioAuthToken}
            setTwilioSid={setTwilioSid}
          />

          {Object.keys(storeLocations).length > 1 && (
            <ActionButton
              text="Delete location"
              onClick={() => handleShowRemoveLocationConfirm(key, index)}
              danger
            />
          )}

          <ActionButton text="Add store location" onClick={handleAddLocation} />
        </div>
      ))}
    </Form>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setStoreLoading(val) {
    dispatch({
      type: "SET_STORE_LOADING",
      payload: val,
    });
  },
  setBandwidthInformation(val) {
    dispatch({
      type: "SET_BANDWIDTH_INFORMATION",
      payload: val,
    });
  },
  setTwilioInformation(val) {
    dispatch({
      type: "SET_TWILIO_INFORMATION",
      payload: val,
    });
  },
  setStoreLocations(val) {
    dispatch({
      type: "SET_STORE_LOCATIONS",
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocations);
