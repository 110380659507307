const NAMESPACE = 'uploadAssociateAssignments';

const DEFAULT_VALUES = {
  merchant: undefined,
  currentStep: 0,
  fileList: null,
  includeHeaders: true,
  deleteOldAssociatesAssigned: false,
  associateAssignments: [],
  associateAssignmentsHeaders: {},
  success: 0,
  notFound: 0,
}

const INITIAL_STATE = { ...DEFAULT_VALUES }

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case `${NAMESPACE}/SET_DEFAULT_VALUES`:
      return { ...state, ...DEFAULT_VALUES }
    case `${NAMESPACE}/SET_CURRENT_STEP`:
      return { ...state, currentStep: action.payload };
    case `${NAMESPACE}/SET_MERCHANT`:
      return { ...state, merchant: action.payload };
    case `${NAMESPACE}/SET_FILE_LIST`:
      return { ...state, fileList: action.payload };
    case `${NAMESPACE}/SET_INCLUDE_HEADERS`:
      return { ...state, includeHeaders: action.payload };
      case `${NAMESPACE}/SET_DELETE_OLD_ASSOCIATES_ASSIGNED`:
        return { ...state, deleteOldAssociatesAssigned: action.payload };
    case `${NAMESPACE}/SET_ASSOCIATE_ASSIGNMENTS`:
      return { ...state, associateAssignments: action.payload };
    case `${NAMESPACE}/SET_ASSOCIATE_ASSIGNMENTS_HEADERS`:
      return { ...state, associateAssignmentsHeaders: action.payload };
    case `${NAMESPACE}/SET_SUCCESS`:
      return { ...state, success: action.payload };
    case `${NAMESPACE}/SET_NOT_FOUND`:
      return { ...state, notFound: action.payload };
    default:
      return state;
  }
}
