import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import PhoneInput2 from "../PhoneInput2";
import request from "../../../core/request";

const DEFAULT_COUNTRY = "US";
const GENERIC_ERROR = "An error has occurred, please try again.";

const ProviderBuyNumberInput = (props) => {
  const {
    value,
    countryValue,
    onChange,
    onCountryChange,
    onSuccess,
    onError,
    disabled,
    accountAlias,
    authToken,
    sid,
    provider,
    ...rest
  } = props;

  const [countryCode, setCountryCode] = useState(countryValue || DEFAULT_COUNTRY);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleBuy = async () => {
    setLoading(true);

    try {
      const requestData = {
        function: "createNumber",
        friendlyName: accountAlias,
        phoneNumber,
        provider,
      };

      if (provider === "twilio") {
        requestData.sid = sid;
        requestData.authToken = authToken;
      }

      await request.twilio.post("/twiliomigration", requestData);

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError(GENERIC_ERROR);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCountryCode(countryValue || DEFAULT_COUNTRY);
  }, [countryValue]);

  useEffect(() => {
    if (onCountryChange) {
      onCountryChange(countryCode);
    }
  }, [onCountryChange, countryCode]);

  useEffect(() => {
    if (onChange && phoneNumber !== value) {
      onChange(phoneNumber || "");
    }
  }, [onChange, phoneNumber, value]);

  const disableButton = useMemo(
    () =>
      disabled ||
      loading ||
      !accountAlias ||
      !countryCode ||
      !phoneNumber ||
      (provider === "twilio" && (!authToken || !sid)),
    [disabled, loading, authToken, sid, accountAlias, countryCode, phoneNumber, provider]
  );

  return (
    <PhoneInput2
      {...rest}
      type="number"
      value={value}
      onChange={setPhoneNumber}
      countryValue={countryCode}
      onCountryChange={setCountryCode}
      disabled={disabled || loading}
      style={{ flex: 1 }}
      readOnly
    >
      <Button type="primary" loading={loading} onClick={handleBuy} disabled={disableButton}>
        Buy
      </Button>
    </PhoneInput2>
  );
};

export default ProviderBuyNumberInput;
