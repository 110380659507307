import React, { useCallback, useEffect, useMemo, useState } from 'react';
import usePaginatedRequest from '../../../hooks/usePaginatedRequest';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import request from '../../../core/request';
import { Button } from 'antd';
import DuplicatedClientsHeader from './components/Header';
import { MergeCellsOutlined } from '@ant-design/icons';
import DuplicatedClientsInnerTable from './components/InnerTable';
import MergeModal from './components/MergeModal';
import Table from '../../Global/Table';

const DuplicatedClients = () => {
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [selectedDuplicatedBy, setSelectedDuplicatedBy] = useState('name');
  const [selectedClients, setSelectedClients] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [includeArchived, setIncludeArchived] = useState(true);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);

  const handleOpenMergeModal = useCallback((clients) => {
    setSelectedClients(clients);
    setShowMergeModal(true);
  }, []);

  const handleCloseMergeModal = useCallback(() => {
    setShowMergeModal(false);

    setTimeout(() => {
      setSelectedClients([]);
    }, 500);
  }, []);

  const requestMethod = useCallback(({ params }) => {
    return request.basic.get('/clients/getDuplicatedClients', {
      params: {
        ...params,
        merchantUuid: selectedMerchant?.value,
        duplicatedBy: selectedDuplicatedBy,
      },
    });
  }, [selectedMerchant, selectedDuplicatedBy]);

  const defaultFilters = useMemo(() => includeArchived ? {} : {
    archive_date: {
      $null: true,
    }
  }, [includeArchived]);

  const paginatedRequest = usePaginatedRequest({
    defaultFilters,
    requestMethod,
    automaticRequest: false,
  });

  const {
    loading,
    page,
    total,
    pageSize,
    results,
    setResults,
    getResults,
    setFilters,
  } = paginatedRequest;

  const columns = useMemo(() => ([
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Matches',
      dataIndex: 'matches',
      ellipsis: true,
    },
    {
      key: 'options',
      title: 'Options',
      fixed: 'right',
      ellipsis: true,
      render: (_, item) => (
        <Button
          type="primary"
          icon={<MergeCellsOutlined />}
          onClick={() => handleOpenMergeModal(item.clients, item.merchant_uuid)}
        >
          Merge
        </Button>
      ),
    },
  ]), [handleOpenMergeModal]);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
    showSizeChanger: true,
  }), [page, pageSize, total]);

  const handleSearchClients = () => {
    getResults();
    setFirstSearch(true);
  }

  const handleClearMerchant = () => {
    setResults([]);
    setFirstSearch(false);
  }

  useEffect(() => {
    setFilters(defaultFilters);
  }, [setFilters, defaultFilters]);

  useEffect(() => {
    setExpandedRows([]);
  }, [results]);

  return (
    <PrimaryLayout header="Duplicated clients">
      <DuplicatedClientsHeader
        loading={loading}
        selectedMerchant={selectedMerchant}
        setSelectedMerchant={setSelectedMerchant}
        includeArchived={includeArchived}
        setIncludeArchived={setIncludeArchived}
        searchClients={handleSearchClients}
        onClearMerchant={handleClearMerchant}
        selectedDuplicatedBy={selectedDuplicatedBy}
        setSelectedDuplicatedBy={setSelectedDuplicatedBy}
      />

      <Table
        {...paginatedRequest}
        tableRowKey="uuid"
        tableColumns={columns}
        pagination={pagination}
        disabled={!firstSearch}
        exportUri={`/clients/exportDuplicatedClients?merchantUuid=${selectedMerchant?.value}`}
        tableProps={{
          expandable: {
            expandedRowKeys: expandedRows,
            onExpandedRowsChange: setExpandedRows,
            expandedRowRender: item => (
              <DuplicatedClientsInnerTable
                clients={item.clients}
                selectedMerchant={selectedMerchant?.value}
                expanded={expandedRows.includes(item.uuid)}
                onDelete={getResults}
              />
            ),
          }
        }}
      />

      {
        Boolean(selectedMerchant) &&
        Boolean(selectedClients.length) &&
        <MergeModal
          showModal={showMergeModal}
          selectedMerchant={selectedMerchant?.value}
          onCancel={handleCloseMergeModal}
          selCliList={selectedClients}
          searchClients={getResults}
        />
      }
    </PrimaryLayout>
  );
}

export default DuplicatedClients;
