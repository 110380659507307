import React from 'react'
import { Form, Input, message, Modal, Select } from 'antd'
import { useState } from 'react'
import request from '../../../../core/request';
import { requiredField } from '../../../../utils/validations';

const UPDATE_SUCCESS_MESSAGE = 'Store updated successfully.';

const PROVIDERS = [
  { value: 'twilio', label: 'Twilio' },
  { value: 'bandwidth', label: 'Bandwidth' },
];

const EditStoreModal = ({ open, onCancel, onSuccess, store }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleChangeSmsNumber = (e) => {
    const value = e.target.value;
    const fixedValue = value.replace(/[^0-9+]/g, '').replace(/\+/g, (match, offset) => (offset === 0 ? match : ''));

    form.setFieldsValue({ sms_number: fixedValue });
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = {
        uuid: store.uuid,
        pos_ref_num: form.getFieldValue('pos_ref_num'),
        sms_number: form.getFieldValue('sms_number'),
        provider: form.getFieldValue('provider'),
        store_id: store.id,
      };

      await request.basic.put(`/stores/updateStore`, data);

      message.success(UPDATE_SUCCESS_MESSAGE);

      if (onSuccess) onSuccess()
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
      onCancel();
    }
  }

  return (
    <Modal
      title="Update store"
      open={open}
      okText="Update"
      onOk={handleSubmit}
      confirmLoading={loading}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="pos_ref_num"
          initialValue={store?.pos_ref_num}
          label="POS ref number"
        >
          <Input placeholder="Enter the new POS ref number" />
        </Form.Item>

        <Form.Item
          name="sms_number"
          initialValue={store?.sms_number}
          label="Store SMS Number"
        >
          <Input placeholder="Enter the store SMS Number" onChange={handleChangeSmsNumber} />
        </Form.Item>

        <Form.Item
          name="provider"
          initialValue={store?.provider}
          label="Provider"
          rules={[requiredField('Provider')]}
        >
          <Select
            placeholder="Enter provider"
            style={{ width: '100%' }}
            allowClear
          >
            { PROVIDERS.map(item => (
              <Select.Option
                key={item.value}
                value={item.value}
              >
                { item.label }
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditStoreModal
