import React from 'react';
import { Alert, Button, Space } from 'antd';

const PageRowsSelectAlert = (props) => {
  const {
    loading,
    pageRowsLength,
    totalRowsLength,
    onClickSelect,
    onClickUnselect
  } = props;

  return (
    <Alert
      type="info"
      message={`${pageRowsLength} rows selected`}
      description={`All page rows have been selected. Would you like to select all ${totalRowsLength} total rows?`}
      style={{ marginBottom: '20px' }}
      action={
        <Space>
          <Button
            type="primary"
            loading={loading}
            onClick={onClickSelect}
          >
            Select all rows
          </Button>

          <Button
            type="primary"
            loading={loading}
            onClick={onClickUnselect}
          >
            Unselect all
          </Button>
        </Space>
      }
      showIcon
    />
  );
}

export default PageRowsSelectAlert;
