import React, { useCallback } from 'react'
import { useHistory } from 'react-router';
import { Button, Modal } from 'antd';

const ViewMerchantsModal = ({ open, onCancel, merchantName, merchantUuid }) => {
  const history = useHistory();

  const handleClickViewAssociates = useCallback(() => {
      history.push(`/associates?search=${merchantUuid}`);
    }, [history, merchantUuid],
  );


  const handleClickViewDashboardMerchants = useCallback(() => {
      history.push(`/accounts?search=${merchantUuid}`);
    }, [history, merchantUuid],
  );

  return (
    <Modal
      title="View merchants"
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Button
        size="large"
        onClick={handleClickViewAssociates}
        style={{ marginBottom: '16px' }}
        block
      >
        View all {merchantName || ''} associates
      </Button>

      <Button
        size="large"
        onClick={handleClickViewDashboardMerchants}
        block
      >
        View all {merchantName || ''} dashboard logins
      </Button>
    </Modal>
  );
}

export default ViewMerchantsModal;
