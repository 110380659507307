import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Radio, Space } from 'antd';
import { requiredField } from '../../../../../utils/validations';
import { FORM_LAYOUT } from '../../shared/constants';

const STORE_TYPES = [
  { value: 'jewelry', label: 'Jewelry' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'standard', label: 'Standard' },
];

const StoreType = (props) => {
  const {
    form,
    setStoreType,
    incrementCurrentStep,
    stores: { storeType },
  } = props;

  const handleFinish = (fields) => {
    setStoreType(fields.storeType);
    incrementCurrentStep();
  }

  const initialValues = useMemo(() => ({ storeType }), [storeType]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      autoComplete="off"
      onFinish={handleFinish}
      {...FORM_LAYOUT}
    >
      <Form.Item
        name="storeType"
        rules={[requiredField('Store type')]}
      >
        <Radio.Group>
          <Space direction="vertical">
            { STORE_TYPES.map(item => (
              <Radio
                key={item.value}
                value={item.value}
              >
                {item.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setStoreType(val) {
    dispatch({
      type: 'SET_STORE_TYPE',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreType);
