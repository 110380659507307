import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { requiredField, emailField } from '../../../../../utils/validations';
import PhoneInput from '../../../../Global/PhoneInput';
import { FORM_LAYOUT } from '../../shared/constants';

const OwnerInformation = (props) => {
  const {
    form,
    setOwnerInformation,
    incrementCurrentStep,
    ownerInformation,
  } = props;

  const initialValues = useMemo(() => (ownerInformation), [ownerInformation]);

  const handleSubmit = (fields) => {
    setOwnerInformation(fields);
    incrementCurrentStep();
  }

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      name="ownerInformation"
      form={form}
      onFinish={handleSubmit}
      initialValues={ownerInformation}
      autoComplete="off"
      {...FORM_LAYOUT}
    >
      <Form.Item
        name="firstName"
        label="First name"
        rules={[requiredField('First name')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last name"
        rules={[requiredField('Last name')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          requiredField('Email'),
          emailField(),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phoneNumber"
        label="Phone number"
      >
        <PhoneInput defaultCountry="US" />
      </Form.Item>
    </Form>
  );
}

const mapStateToProps = state => state.stores;

const mapDispatchToProps = dispatch => ({
  setOwnerInformation(val) {
    dispatch({
      type: 'SET_OWNER_INFORMATION',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerInformation);
