import React from "react";

const SmileyFace = props => {
  const height = props.height || 48;
  const width = props.width || 48;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#75D6A1" />
      <path
        d="M30.8862 19.6471C32.4098 19.6471 33.6407 18.3853 33.6407 16.8235C33.6407 15.2618 32.4098 14 30.8862 14C29.3626 14 28.1317 15.2618 28.1317 16.8235C28.1317 18.3853 29.3626 19.6471 30.8862 19.6471ZM17.1138 19.6471C18.6374 19.6471 19.8683 18.3853 19.8683 16.8235C19.8683 15.2618 18.6374 14 17.1138 14C15.5902 14 14.3593 15.2618 14.3593 16.8235C14.3593 18.3853 15.5902 19.6471 17.1138 19.6471ZM35.6205 25.2941H12.3795C11.5532 25.2941 10.9076 26.0353 11.0109 26.8824C11.7684 33.1471 16.9847 38 23.3114 38H24.6886C31.0067 38 36.223 33.1471 36.9891 26.8824C37.0924 26.0353 36.4468 25.2941 35.6205 25.2941ZM24.6886 35.1765H23.3114C18.9903 35.1765 15.2631 32.2382 14.0666 28.1176H33.9334C32.7369 32.2382 29.0097 35.1765 24.6886 35.1765Z"
        fill="black"
      />
    </svg>
  );
};

export default SmileyFace;
