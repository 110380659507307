import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../../Global/Table';
import request from '../../../../../core/request';
import usePaginatedRequest from '../../../../../hooks/usePaginatedRequest';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import useGoogleMapsService from '../../../../../hooks/useGoogleMapsService';

const REQUEST_METHOD = ({ params = {} }) => {
  return request.basic.get(`/zohoCustomers/getZohoCustomers`, { params })
}

const getAddressQuery = (address) => {
  const {
    street = '',
    city = '',
    state = '',
    zip = '',
    country = '',
  } = address;

  return `${street}, ${city} ${state} ${zip} ${country}`;
}

const AccountsOnHold = (props) => {
  const {
    setStoreLoading,
    setCurrentStep,
    setStoreType,
    setAccountInformation,
    setOwnerInformation,
    setStoreLocations,
    stores: { currentStep },
  } = props;

  const {
    loaded,
    loadLibraries,
    getGeocodeInformation,
    getLocationTimezone,
  } = useGoogleMapsService({
    libraries: ['places'],
  });

  const paginatedRequest = usePaginatedRequest({
    requestMethod: REQUEST_METHOD,
  });

  const incrementCurrentStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep, setCurrentStep]);

  const handleSelectRow = useCallback(async (item) => {
    setStoreLoading(true);

    setStoreType(item.industry);

    setAccountInformation({
      accountName: item.company_name,
      websiteUrl: item.website_url,
      packageBillingCode: item.product_code,
      salesforceBillingAccountId: item.cf_salesforce_id,
      zohoCustomerId: item.customer_id,
    });

    setOwnerInformation({
      firstName: item.first_name,
      lastName: item.last_name,
      email: item.email,
    });

    try {
      const addressQuery = getAddressQuery(item.billing_address);
      const geocodeInformation = await getGeocodeInformation(addressQuery);

      if (!Array.isArray(geocodeInformation?.results) || !geocodeInformation.results.length) {
        return;
      }

      const firstGeocodeResult = geocodeInformation.results[0];
      const location = firstGeocodeResult.geometry.location;
      const countryItem = firstGeocodeResult.address_components.find(item => item.types.includes('country'));
      const countryCode = countryItem?.short_name;

      const locationTimezone = await getLocationTimezone(location.lat, location.lng);

      setStoreLocations({
        0: {
          searchStatus: '',
          paymentStatus: '',
          locationName: '',
          street1: item.billing_address.street,
          street2: item.billing_address.street2,
          city: item.billing_address.city,
          state: item.billing_address.state,
          zipCode: item.billing_address.zip,
          country: countryCode,
          reviewLink: 'https://search.google.com/local/writereview?placeid=',
          salesforceAccountId: item.cf_salesforce_id,
          phoneNumber: '',
          phoneNumberCountryCode: 'US',
          provider: 'twilio',
          providerAreaCode: '',
          providerAreaCodeCountryCode: 'US',
          providerNumber: '',
          timezone: locationTimezone,
          lat: location.lat,
          lng: location.lng,
        }
      });

      incrementCurrentStep();
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setStoreLoading(false);
    }
  }, [
    getGeocodeInformation,
    getLocationTimezone,
    setAccountInformation,
    setOwnerInformation,
    setStoreLoading,
    setStoreLocations,
    setStoreType,
    incrementCurrentStep,
  ]);

  const TABLE_COLUMNS = useMemo(() => ([
    {
      title: 'Retailer Name',
      dataIndex: 'company_name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Salesforce ID',
      dataIndex: 'cf_salesforce_id',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Admin Name',
      dataIndex: 'admin_name',
      sorter: true,
      ellipsis: true,
    },
    {
      key: 'options',
      title: 'Options',
      fixed: 'right',
      ellipsis: true,
      render: (_, item) => (
        <Button
          type="primary"
          onClick={() => handleSelectRow(item)}
        >
          Continue Setup
        </Button>
      ),
    },
  ]), [handleSelectRow]);

  useEffect(() => {
    if (!loaded) loadLibraries();
  }, [loaded, loadLibraries]);

  return (
    <Table
        {...paginatedRequest}
        tableRowKey="id"
        tableColumns={TABLE_COLUMNS}
        showAdvancedFilters={false}
        headerChildren={
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={incrementCurrentStep}
          >
            Create From Scratch
          </Button>
        }
      />
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setStoreLoading(val) {
    dispatch({
      type: 'SET_STORE_LOADING',
      payload: val
    });
  },
  setCurrentStep(val) {
    dispatch({
      type: 'SET_CURRENT_STEP',
      payload: val,
    });
  },
  setDefaultValues(val) {
    dispatch({
      type: 'SET_DEFAULT_VALUES',
      payload: val,
    });
  },
  setStoreType(val) {
    dispatch({
      type: 'SET_STORE_TYPE',
      payload: val,
    });
  },
  setAccountInformation(val) {
    dispatch({
      type: 'SET_ACCOUNT_INFORMATION',
      payload: val,
    });
  },
  setOwnerInformation(val) {
    dispatch({
      type: 'SET_OWNER_INFORMATION',
      payload: val,
    });
  },
  setStoreLocations(val) {
    dispatch({
      type: 'SET_STORE_LOCATIONS',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsOnHold);
