import React from 'react'
import { Button, Popconfirm } from 'antd';

const DASHBOARD_URLS = {
  local: 'http://localhost:3000',
  stage: 'https://dashboard.clientbook.com',
  test: 'https://devdash.clientbook.com',
  production: 'https://dashboard.clientbook.com',
  development: 'https://devdash.clientbook.com',
}

const DASHBOARD_URL = DASHBOARD_URLS[process.env.REACT_APP_API_ENV] || DASHBOARD_URLS.local;

const handleRedirect = (email, password) => {
  if (email && password) {
    const queryString = new URLSearchParams({
      a: btoa(email),
      c: btoa(password),
    }).toString()

    window.open(`${DASHBOARD_URL}/login/console-login?${queryString}`, '_blank');
  }
}

const QuickLogin = (item) => {
  return (
    <Popconfirm
      title={`Log in as ${item.first_name} of ${item.merchant_name}`}
      okText={"Yes"}
      cancelText={"No"}
      placement="leftBottom"
      onConfirm={() => handleRedirect(item.email, item.password)}
    >
      <Button type="primary">
        Quick Login
      </Button>
    </Popconfirm>
  );
}

export default QuickLogin;
