import React, { useMemo } from 'react'
import { Form, Input, message, Modal, Select } from 'antd'
import { useState } from 'react'
import request from '../../../../../../core/request';
import { requiredField } from '../../../../../../utils/validations';
import { useParams } from 'react-router';

const CREATE_SUCCESS_MESSAGE = 'Sales action config created successfully.';

const SALE_ACTION_CONFIG_TYPES = [
  { value: 'MESSAGE',  label: 'Auto-Message' },
  { value: 'REMINDER',  label: 'Auto-Reminder' },
];

const CreateSalesActionConfigModal = ({ open, onCancel, onSuccess }) => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const title = Form.useWatch('title', form);
  const type = Form.useWatch('type', form);
  const description = Form.useWatch('description', form);

  const disabled = useMemo(() => !title || !type || !description, [title, type, description]);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = {
        title,
        type,
        description,
        merchantUuid: uuid,
      };

      await request.basic.post('/merchants/createSalesActionConfig', data);

      message.success(CREATE_SUCCESS_MESSAGE);

      if (onSuccess) onSuccess()
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
      onCancel();
    }
  }

  return (
    <Modal
      title="Create sales action config"
      open={open}
      okText="Create"
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      okButtonProps={{ disabled }}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[requiredField('Title')]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[requiredField('Type')]}
        >
          <Select
            placeholder="Enter type"
            style={{ width: '100%' }}
            allowClear
          >
            { SALE_ACTION_CONFIG_TYPES.map(item => (
              <Select.Option
                key={item.value}
                value={item.value}
              >
                { item.label }
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[requiredField('Description')]}
        >
          <Input placeholder="Enter description" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateSalesActionConfigModal;
