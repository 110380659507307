import React, { useCallback } from 'react';
import { message } from 'antd';
import AllRowsSelectAlert from './components/AllRowsSelectAlert';
import PageRowsSelectAlert from './components/PageRowsSelectAlert';

const RowsSelectAlert = (props) => {
  const {
    loading,
    tableRowKey,
    setLoading,
    total,
    pageSize,
    page,
    search,
    filters,
    sortField,
    sortOrder,
    results,
    requestMethod,
    selectedRowKeys,
    setSelectedRowKeys,
  } = props;

  const getPaginatedRequestParams = useCallback(() => {
    const params = {
      pageSize: total,
    };

    if (Number(page) && page > 1) {
      params.page = page;
    }

    if (search) {
      params.search = search;
    }

    if (filters && typeof filters === 'object' && Object.keys(filters).length) {
      params.filters = filters;
    }

    if (sortField) {
      params.sortField = sortField;
    }

    if (sortOrder) {
      params.sortOrder = sortOrder;
    }

    return params;
  }, [filters, page, search, sortField, sortOrder, total]);

  const handleSelectPage = useCallback(() => {
    setSelectedRowKeys(results.slice(0, pageSize).map(item => item[tableRowKey]));
  }, [pageSize, results, setSelectedRowKeys, tableRowKey]);

  const handleSelectAll = useCallback(async () => {
    setLoading(true);

    try {
      const params = getPaginatedRequestParams();
      const response = await requestMethod({ params })
      const results = response.data.results.map(item => item[tableRowKey]);

      setSelectedRowKeys(results);

      message.success('All rows selected');
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
    }
  }, [getPaginatedRequestParams, requestMethod, setLoading, setSelectedRowKeys, tableRowKey]);

  if (!selectedRowKeys.length || selectedRowKeys.length < pageSize) {
    return null;
  }

  if (selectedRowKeys.length === pageSize) {
    return (
      <PageRowsSelectAlert
        loading={loading}
        pageRowsLength={selectedRowKeys.length}
        totalRowsLength={total}
        onClickSelect={handleSelectAll}
        onClickUnselect={() => setSelectedRowKeys([])}
      />
    );
  }

  return (
    <AllRowsSelectAlert
      loading={loading}
      rowsLength={selectedRowKeys.length}
      onClickSelect={handleSelectPage}
      onClickUnselect={() => setSelectedRowKeys([])}
    />
  );
}

export default RowsSelectAlert;
