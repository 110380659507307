import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import MergeAssignment from './MergeAssignment';
import { message, notification } from 'antd';
import request from '../../../../../core/request';

const defaultTempDetails = {
  salesTransactions: [],
  salesOpps: [],
  relatives: [],
  lifeEvents: [],
  tags: [],
  collections: [],
  associates: [],
  history: [],
  address: [],
  preferences: [],
  chatMessages: {},
  client: {},
}

const defaultPrimaryClient = {
  salesTransactions: [],
  salesOpps: [],
  relatives: [],
  lifeEvents: [],
  tags: [],
  collections: [],
  associates: [],
  history: [],
  address: [],
  preferences: [],
  chatMessages: [],
  client: {},
}

const MergeModal = props => {
  const windowWidth = window.innerWidth || 1000;
  const [modalLoading, setModalLoading] = useState(false);
  const [tempClientDetails, setTempClientDetails] = useState(defaultTempDetails)
  const [clients, setClients] = useState([]);
  const [cliAttrCnts, setCliAttrCnts] = useState({});
  const [primaryClient, setPrimaryClient] = useState(defaultPrimaryClient);
  const [mergingClients, setMergingClients] = useState({});
  const [showStep, setShowStep] = useState(0);

  useEffect(() => {
    const getClients = async () => {
      setModalLoading(true);

      const clientsResponse = await request.basic.get(`/clients/getClients`, {
        params: {
          merchantUuid: props.selectedMerchant,
          pageSize: 100,
          filters: JSON.stringify({
            id: {
              $in: props.selCliList,
            },
          }),
        }
      });

      setClients(clientsResponse.data.results);

      const clientsInfoResponse = await request.basic.post(`/clients/getClientGroupInfo`, {
        clientList: props.selCliList,
      });

      setCliAttrCnts(clientsInfoResponse.data);
      setModalLoading(false);
    }

    getClients();
  }, [props.selCliList, props.selectedMerchant]);

  const cancelHandler = () => {
    props.onCancel();
    setMergingClients({});
    if(showStep === 0) {
      props.onCancel();
    } else if(showStep === 1) {
      setShowStep(0);
    } else {
      setShowStep(1);
    }
  }

    const saveHandler = () => {
        setModalLoading(true);
        request.basic.post(`/clients/saveAndMergeClients`, {
            deleteClients: Object.keys(mergingClients), // actually deleting clients
            primaryClient,
            newValues: tempClientDetails

        })
        .then(resp => {
            setModalLoading(false);
            cancelHandler();
            notification['success']({
                message: "Merge Successful!"
            })
            props.searchClients()
        })
        .catch(err => {
            setModalLoading(false);
            message.error('Error Merging and Deleting Clients');
            if(err && err.hasOwnProperty('response') && err.response.hasOwnProperty('data')) {
                notification['error']({
                    message: 'MERGE ERROR',
                    description: JSON.stringify(err.response.data),
                  });
            } else {
                notification['error']({
                    message: 'MERGE ERROR',
                    description: JSON.stringify(err),
                  });
            }
        });
    }

    const resetToDefault = () => {
        setTempClientDetails(defaultTempDetails);
        setPrimaryClient(defaultPrimaryClient);
    }

    return (
        <Modal
            width={windowWidth*.9}
            title="Merge Wizard"
            open={props.showModal}
            onOk={saveHandler}
            okText={"SAVE"}
            okButtonProps={{
                disabled: modalLoading
            }}
            onCancel={cancelHandler}
            cancelText={"CANCEL"}
            bodyStyle={{
                height: '85vh',
                top: '20px !important'
            }}
            maskStyle={{
                top: '20px !important'
            }}
            destroyOnClose={true}
            afterClose={resetToDefault}
        >
          <MergeAssignment
              selCliList={clients}
              setLoading={setModalLoading}
              loading={modalLoading}
              cliAttrCnts={cliAttrCnts}
              primaryClient={primaryClient}
              setPrimaryClient={setPrimaryClient}
              mergingClients={mergingClients}
              setMergingClients={setMergingClients}
              tempClientDetails={tempClientDetails}
              setTempClientDetails={setTempClientDetails}
              resetToDefault={resetToDefault}
            />
        </Modal>
    )
}
export default MergeModal
