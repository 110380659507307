import React, { useCallback, useMemo } from 'react';
import { Button, Space, Tag, Tooltip } from 'antd';
import usePaginatedRequest from '../../../hooks/usePaginatedRequest';
import { useState } from 'react';
import ViewMerchantsModal from './components/ViewMerchantsModal';
import { CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined, UserOutlined, MobileOutlined } from '@ant-design/icons';
import DeleteMerchantModal from './components/DeleteMerchantModal';
import { Link, useHistory } from 'react-router-dom';
import request from '../../../core/request';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import Table from '../../Global/Table';
import CopyToClipboardColumn from '../../Global/Table/components/CopyToClipboardColumn';
import MobileNumberValidatorModal from './components/MobileNumberValidatorModal';

const DEFAULT_SELECTED_MERCHANT = { uuid: '', name: '' };

const POS_TYPE_FILTER = [
  { value: 'BIG', text: 'BIG' },
  { value: 'DCIT', text: 'DCIT' },
  { value: 'EDGE', text: 'EDGE' },
  { value: 'LIGHTSPEED', text: 'Lightspeed' },
  { value: 'LOGICMATE', label: 'Logicmate' },
  { value: 'RAIN', text: 'Rain' },
  { value: 'SHOPIFY', text: 'Shopify' },
  { value: 'SHOPKEEPER', text: 'Shopkeeper' },
];

const PACKAGE_TYPE_FILTER = [
  { value: 'Legacy', text: 'Legacy' },
  { value: 'Basic', text: 'Basic' },
  { value: 'Signature', text: 'Signature' },
  { value: 'Elite', text: 'Elite' },
  { value: 'Premier', text: 'Premier' },
];

const REQUEST_METHOD = ({ params = {} }) => {
  return request.basic.get(`/merchants/getMerchants`, { params })
}

const Merchants = () => {
  const [showValidatorModal, setShowValidatorModal] = useState(false);
  const [showViewMerchantsModal, setShowViewMerchantsModal] = useState(false);
  const [showDeleteMerchantsModal, setShowDeleteMerchantsModal] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(DEFAULT_SELECTED_MERCHANT);

  const history = useHistory();

  const paginatedRequest = usePaginatedRequest({
    requestMethod: REQUEST_METHOD,
  });

  const handleSearchByMerchant = () => {
    history.push(`/associates?search=${selectedMerchant.name}`);
  }

  const handleOpenMobileNumberValidation = (merchant) => {
    setSelectedMerchant(merchant);
    setShowValidatorModal(true);
  }

  const handleCloseMobileNumberVlidationModal = () => {
    setShowValidatorModal(false);
    setTimeout(() => {
      setSelectedMerchant(null);
    }, 500);
  }


  const handleOpenViewMerchantsModal = (merchant) => {
    setSelectedMerchant(merchant);
    setShowViewMerchantsModal(true);
  }

  const handleCloseViewMerchantsModal = () => {
    setShowViewMerchantsModal(false);

    setTimeout(() => {
      setSelectedMerchant(null);
    }, 500);
  }

  const handleOpenDeleteMerchantsModal = useCallback((merchant) => {
    setSelectedMerchant(merchant);
    setShowDeleteMerchantsModal(true);
  }, []);

  const handleCloseDeleteMerchantsModal = () => {
    setShowDeleteMerchantsModal(false);

    setTimeout(() => {
      setSelectedMerchant(null);
    }, 500);
  }

  const tableColumns = useMemo(() => ([
    {
      title: 'Merchant Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'License Key',
      dataIndex: 'license_key',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Salesforce Billing Account ID',
      dataIndex: 'salesforce_billing_account_id',
      ellipsis: true,
    },
    {
      title: 'Password',
      dataIndex: 'password',
      ellipsis: true,
      render: (_, item) => (
        <CopyToClipboardColumn
          value={item.password}
          label={item.password}
        />
      ),
    },
    {
      title: 'POS Type',
      dataIndex: 'pos_type',
      sorter: true,
      ellipsis: true,
      filters: POS_TYPE_FILTER,
      render: value => (value ? <Tag color="blue">{value}</Tag> : null),
    },
    {
      title: 'Package Name',
      dataIndex: 'package_name',
      sorter: true,
      ellipsis: true,
      filters: PACKAGE_TYPE_FILTER,
      render: value => (value ? <Tag color="blue">{value}</Tag> : null),
    },
    {
      title: 'Clients Sync',
      dataIndex: 'sync_clients',
      sorter: true,
      ellipsis: true,
      render: value => (Number(value) ? <CheckOutlined /> : null),
    },
    {
      title: 'Products Sync',
      dataIndex: 'sync_products',
      sorter: true,
      ellipsis: true,
      render: value => (Number(value) ? <CheckOutlined /> : null),
    },
    {
      title: 'Message Limit',
      dataIndex: 'mass_messaging',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Merchant UUID',
      dataIndex: 'uuid',
      ellipsis: true,
      render: (_, item) => (
        <CopyToClipboardColumn
          value={item.uuid}
          label={item.uuid}
        />
      ),
    },
    {
      key: 'options',
      title: 'Options',
      fixed: 'right',
      ellipsis: true,
      render: (_, item) => (
        <Space>
          <Tooltip title="View Options">
            <Button
              icon={<EyeOutlined />}
              type="primary"
              ghost
              onClick={() => handleOpenViewMerchantsModal(item)}
            />
          </Tooltip>

          <Tooltip title="Mobile Number Validator">
            <Button
              icon={<MobileOutlined />}
              type="primary"
              ghost
              onClick={() => handleOpenMobileNumberValidation(item)}
            />
          </Tooltip>

          <Tooltip title="Merchant clients">
            <Link to={`/merchants/${item.uuid}/clients`}>
              <Button
                type="primary"
                icon={<UserOutlined />}
                ghost
              />
            </Link>
          </Tooltip>

          <Tooltip title="Edit merchant">
            <Link to={`/merchants/${item.uuid}/edit`}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                ghost
              />
            </Link>
          </Tooltip>

          <Tooltip title="Delete merchant">
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => handleOpenDeleteMerchantsModal(item)}
              ghost
            />
          </Tooltip>
        </Space>
      )
    },
  ]), [handleOpenDeleteMerchantsModal]);

  return (
    <PrimaryLayout header="Merchants">
      <Table
        {...paginatedRequest}
        tableRowKey="uuid"
        tableColumns={tableColumns}
      />

      {selectedMerchant &&
        <MobileNumberValidatorModal
          open={showValidatorModal}
          closeModal={handleCloseMobileNumberVlidationModal}
          // onSuccess={handleCloseMobileNumberVlidationModal}
          selectedMerchant={selectedMerchant}
        />
      }

      {
        selectedMerchant &&
        <ViewMerchantsModal
          open={showViewMerchantsModal}
          onCancel={handleCloseViewMerchantsModal}
          onSuccess={handleSearchByMerchant}
          merchantUuid={selectedMerchant.uuid}
          merchantName={selectedMerchant.name}
        />
      }

      {
        selectedMerchant &&
        <DeleteMerchantModal
          open={showDeleteMerchantsModal}
          onCancel={handleCloseDeleteMerchantsModal}
          onSuccess={() => {
            paginatedRequest.getResults(true);
            handleCloseDeleteMerchantsModal();
          }}
          merchant={selectedMerchant}
        />
      }
    </PrimaryLayout>
  );
}

export default Merchants;
