// import { dispatcher } from "../core/actions";

const initialState = {
    allRatings: [],
    merchantRatings: [],
    associateRatings: []
};


export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case "SET_RATING_STATE":
      return { ...state2, ...action.payload };
    default:
      break;
  }
  return state2;
}
