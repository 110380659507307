import React from 'react';
import { Button, Select, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const OPTIONS = [
  { value: 0, label: 'Current Month' },
  { value: 1, label: 'Past 2 Months' },
  { value: 2, label: 'Past 3 Months' },
];

const ReportDetailsHeader = (props) => {
  const {
    report,
    loading,
    rangeType,
    setRangeType,
    onSubmit,
  } = props;

  const history = useHistory();

  return (
    <div style={{
      display: 'flex',
      gap: '20px',
      paddingRight: '50px',
    }}>
      <Button
        type="text"
        icon={<LeftOutlined />}
        style={{ marginTop: '2px' }}
        onClick={() => history.push('/reports')}
      />

      <div style={{ width: '100%' }}>
        <Typography.Title level={3}>{report.name}</Typography.Title>

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}>
            <Typography.Text>{report.description}</Typography.Text>

            {report.showRangeSelector &&
            <Select
              defaultValue={0}
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
              value={rangeType}
              options={OPTIONS}
              disabled={loading}
              onChange={setRangeType}
            />}
          </div>

          <Button
            type="primary"
            loading={loading}
            onClick={onSubmit}
          >
            Run Report
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReportDetailsHeader;
