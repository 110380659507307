import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Form, Select, Space } from 'antd';
import { connect } from 'react-redux';
import { requiredField } from '../../../../utils/validations';
import { ASSOCIATE_HEADERS, CLIENT_HEADERS, HEADERS } from '../shared/constants';

const UploadAssociateAssignmentsEditStep = (props) => {
  const {
    currentStep,
    setCurrentStep,
    associateAssignments,
    associateAssignmentsHeaders,
    setAssociateAssignmentsHeaders,
  } = props;

  const [headersMap, setHeadersMap] = useState({});

  const associateHeaderSelected = useMemo(() => (
    ASSOCIATE_HEADERS.some(item => Object.values(headersMap).includes(item.value))
  ), [headersMap]);

  const clientHeaderSelected = useMemo(() => (
    CLIENT_HEADERS.some(item => Object.values(headersMap).includes(item.value))
  ), [headersMap]);

  const disableRules = useMemo(() => (
    associateHeaderSelected && clientHeaderSelected
  ), [associateHeaderSelected, clientHeaderSelected]);

  const getDisabledStatus = (key) => {
    return Object.values(headersMap).some(item => item === key);
  }

  const handleSelect = (header, value) => {
    setHeadersMap(prev => ({ ...prev, [header]: value  }));
  }

  const handleClear = (header) => {
    setHeadersMap(prev => ({ ...prev, [header]: null  }));
  }

  const handleFinish = (fields) => {
    const newAssociateAssignmentsHeaders = Object
      .entries(fields)
      .reduce((acc, curr) => curr[1] ? { ...acc, [curr[0]]: curr[1] } : acc, {});

    setAssociateAssignmentsHeaders(newAssociateAssignmentsHeaders);
    setCurrentStep(currentStep + 1);
  }

  useEffect(() => {
    const newHeadersMap = Object
      .keys(associateAssignments[0])
      .reduce((acc, curr) => ({ ...acc, [curr]: null }), {});

    setHeadersMap({
      ...newHeadersMap,
    });
  }, [associateAssignments, associateAssignmentsHeaders]);

  return (
    <div style={{ maxWidth: '650px' }}>
      <Alert
        type="info"
        message="Match the columns of your CSV file with the available options"
        description="Select at least one header from your CSV file to identify an associate and a client."
        style={{ marginBottom: '20px' }}
        showIcon
      />

      <Form
        initialValues={associateAssignmentsHeaders}
        onFinish={handleFinish}
        labelCol={{
          sm: { span: 24 },
          md: { span: 4 },
        }}
        wrapperCol={{
          sm: { span: 24 },
          md: { span: 20 },
        }}
      >
        {Object
          .keys(headersMap)
          .map(header => (
            <Form.Item
              key={header}
              name={header}
              label={header}
              rules={disableRules ? undefined : [requiredField(header)]}
            >
              <Select
                onSelect={(value) => handleSelect(header, value)}
                onClear={() => handleClear(header)}
                name={header}
                allowClear
              >
                {HEADERS.map(item => (
                  <Select.Option
                    key={item.value}
                    value={item.value}
                    disabled={getDisabledStatus(item.value)}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ))}

        <Form.Item>
          <Space>
            <Button onClick={() => setCurrentStep(currentStep - 1)}>
              Back
            </Button>

            <Button
              type="primary"
              htmlType="submit"
            >
              Next
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

const mapStateToProps = state => state.uploadAssociateAssignments;

const mapDispatchToProps = dispatch => ({
  setCurrentStep(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_CURRENT_STEP',
      payload
    });
  },
  setAssociateAssignmentsHeaders(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_ASSOCIATE_ASSIGNMENTS_HEADERS',
      payload,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadAssociateAssignmentsEditStep);

