import React from 'react'
import request from '../../../core/request';
import AsyncPaginatedSelect from '../AsyncPaginatedSelect';

const REQUEST_METHOD = ({ params = {} }) => {
  return request.basic.get(`/merchants/getMerchants`, { params });
}

const MerchantSelectInput = props => {
  return (
    <AsyncPaginatedSelect
      {...props}
      placeholder="Select a Merchant"
      requestMethod={REQUEST_METHOD}
    />
  );
}

export default MerchantSelectInput;
