import React, { useEffect, useRef } from 'react';
import Chart from "chart.js";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

let theChart;

const MerchantGraph = (props) => {
    const chartRef = useRef(null);
    const {merchantName, selectedTimeFrame, allRatings, associateRatings} = props;

    Chart.pluginService.register({
        beforeDraw: function(chart) {
          if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || "Arial";
            var txt = centerConfig.text;
            var color = centerConfig.color || "#000";
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated =
              (sidePadding / 100) * (chart.innerRadius * 2);
            //Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2;

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);

            //Set font settings to draw it correctly.
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
      });
      const buildChart = () => {
        if(theChart) {
            theChart.clear();
            theChart.destroy();
          }
          const timeObj = getTimeObj();

        const associateData = Object.values(timeObj).map(rating => {
            let baseR = (rating.asctRating/rating.asctTotal) * 100;
            if (baseR > 100) {
                baseR = 100;
            }
            return Math.floor(baseR) || 0;
        });
        const merchantData = Object.values(timeObj).map(rating => {
            let baseR = (rating.allRating/rating.allTotal) * 100;
            if (baseR > 100) {
                baseR = 100;
            }
            return Math.floor(baseR) || 0;
        });
      const ctx = chartRef.current.getContext("2d");
      let step = 20;
      theChart = new Chart(ctx, {
        // ...props.configObj
            data: {
        datasets: [ {
            type: 'line',
            label: 'Overall Rating',
            data: merchantData,
            fill: false,
            borderColor: 'rgb(54, 162, 235)'
        },{
            type: 'bar',
            label: `${merchantName} Rating`,
            data: associateData,
            borderColor: '#75D6A1',
            backgroundColor: []
        },],
        labels: Object.keys(timeObj).map(time => moment(time, 'YYYY-MM-DD').format("MMM DD, YYYY"))
    },
    options: {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          yAxes: [
            {
              ticks: {
                // beginAtZero: true,
                suggestedMax: 100,
                suggestedMin: -100,
                stepSize: step
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              offset: true,
              gridLines: {
                display: false
              }
            }
          ]
        }
      }
      });
      var bars = [];
      if(theChart.hasOwnProperty('config') && theChart.config.hasOwnProperty('data') && theChart.config.data.hasOwnProperty('datasets')){
          bars = theChart.config.data.datasets[1];
      }
      if(bars && bars.data.length > 0) {
          bars.data.forEach((bar, i) => {
              if(bar > 0) {
                bars.backgroundColor[i] = "#75D6A1";
              } else if (bar < 0) {
                bars.backgroundColor[i] = "#FF7C7C";
              } else {
                bars.backgroundColor[i] = "#FFCA00";
              }
          })
        theChart.update();
      }

    };

      useEffect(() => {
          buildChart();
      }, [props.configObj]);

      const getTimeObj = () => {
          const today = moment(new Date()).startOf("day");
          const datesArr = allRatings.map(rating => moment(rating.date, 'YYYYMMDDHHmmss')).filter(date => moment(date).isValid());
          const minDate = moment.min(datesArr);
        const splitTF = selectedTimeFrame.split(" ");
        let daySpan = 0;
        if(Number(splitTF[0])) {
            daySpan=Number(splitTF[0])
        } else {
            daySpan = today.diff(minDate, 'days') || 30
        }
        const firstDate = moment(new Date()).subtract(daySpan, 'days');
        const firstQuarter = moment(new Date()).subtract(Math.floor(daySpan*.75), 'days');
        const halfDate = moment(new Date()).subtract(Math.floor(daySpan*.5), 'days');
        const thirdQuarter = moment(new Date()).subtract(Math.floor(daySpan*.25), 'days');
        const lastDate = moment(new Date()).endOf('day');
        const daysList = [firstDate.format("YYYY-MM-DD"), firstQuarter.format("YYYY-MM-DD"), halfDate.format("YYYY-MM-DD"), thirdQuarter.format("YYYY-MM-DD"), lastDate.format("YYYY-MM-DD")];

        let returnObj = {};
        daysList.forEach(dProp => returnObj[dProp] = {
            asctTotal: 0,
            allTotal: 0,
            asctRating: 0,
            allRating: 0
        });
        listHandler(associateRatings, "asct");
        listHandler(allRatings, "all");
        function listHandler(list, where) {
            list.forEach(rating => {
                const pRD = moment(rating.date, 'YYYYMMDDHHmmss');
                const firstDiff = dateDiff(pRD, firstDate);
                const firstQDiff = dateDiff(pRD, firstQuarter);
                const halfDiff = dateDiff(pRD, halfDate);
                const thirdQDiff = dateDiff(pRD, thirdQuarter);
                const lastDiff = dateDiff(pRD, lastDate);

                if(firstDiff < firstQDiff) {
                    const objProp = firstDate.format("YYYY-MM-DD");
                    returnObj[objProp][`${where}Total`] += 1;
                    returnObj[objProp][`${where}Rating`] += rating.answer || 0;
                } else {
                    if(firstQDiff < halfDiff) {
                        const objProp = firstQuarter.format("YYYY-MM-DD");
                        returnObj[objProp][`${where}Total`] += 1;
                        returnObj[objProp][`${where}Rating`] += rating.answer || 0;
                    } else {
                        if(halfDiff < thirdQDiff) {
                            const objProp = halfDate.format("YYYY-MM-DD");
                            returnObj[objProp][`${where}Total`] += 1;
                            returnObj[objProp][`${where}Rating`] += rating.answer || 0;
                        } else {
                            if(thirdQDiff < lastDiff) {
                                const objProp = thirdQuarter.format("YYYY-MM-DD");
                                returnObj[objProp][`${where}Total`] += 1;
                                returnObj[objProp][`${where}Rating`] += rating.answer || 0;
                            } else {
                                const objProp = lastDate.format("YYYY-MM-DD");
                                returnObj[objProp][`${where}Total`] += 1;
                                returnObj[objProp][`${where}Rating`] += rating.answer || 0;
                            }
                        }
                    }
                }
            })
        }
        function dateDiff(first, second) {
            let theDiff = first.diff(second, 'hours') || 0;
            if(theDiff < 0) {
                theDiff = theDiff * -1;
            }
            return theDiff;
        }
        return returnObj;
      }


    return (
        <canvas width="500px" height="100%" id="" ref={chartRef} {...props} />
    )
}
export default MerchantGraph;
