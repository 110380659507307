import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, Select } from 'antd';
import { requiredField } from '../../../../../utils/validations';
import PhoneInput from '../../../../Global/PhoneInput';
import LocationSelectInput from '../../../../Global/LocationSelectInput';
import TimezoneSelectInput from '../../../../Global/TimezoneSelectInput';
import LocationSearchInput from '../../../../Global/LocationSearchInput';
import { getCountryCallingCode } from 'react-phone-number-input';
import request from '../../../../../core/request';
import './styles.css';

const PROVIDERS = [
  { value: 'twilio', label: 'Twilio' },
  { value: 'bandwidth', label: 'Bandwidth' },
];

const StoreLocation = (props) => {
  const {
    form,
    index,
    locationsIndexes,
    stores: {
      accountInformation: {
        accountName,
      },
    },
    bandwidthPeerId,
    bandwidthPeerName,
    setBandwidthPeerId,
    setBandwidthPeerName,
    twilioFriendlyName,
    twilioAuthToken,
    twilioSid,
    setTwilioFriendlyName,
    setTwilioAuthToken,
    setTwilioSid,
  } = props;

  const [searchStatus, setSearchStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [providerAreaCode, setProviderAreaCode] = useState('');

  const provider = Form.useWatch(`${index}-provider`, form);

  const disableProviderButton = useMemo(() => (
    bandwidthPeerId || bandwidthPeerName || twilioFriendlyName || twilioAuthToken || twilioSid
  ), [bandwidthPeerId, bandwidthPeerName, twilioFriendlyName, twilioAuthToken, twilioSid]);

  const getFieldName = useCallback((fieldName) => {
    return `${index}-${fieldName}`;
  }, [index]);

  const getFieldValue = useCallback((fieldName) => {
    const fieldNameWithIndex = getFieldName(fieldName);

    return form.getFieldValue(fieldNameWithIndex);
  }, [getFieldName, form]);

  const setFieldsValue = (fields) => {
    const fieldsWithIndex = {};

    Object.keys(fields).forEach(key => {
      const fieldNameWithIndex = getFieldName(key);

      fieldsWithIndex[fieldNameWithIndex] = fields[key];
    });

    form.setFieldsValue(fieldsWithIndex);
  }

  const preventPhoneNumberCountryCodeChange = () => {
    const providerAreaCodeCountryCode = getFieldValue('providerAreaCodeCountryCode');
    const callingCode = `+${getCountryCallingCode(providerAreaCodeCountryCode)}`;

    setTimeout(() => {
      setFieldsValue({
        phoneNumberCountryCode: providerAreaCodeCountryCode,
        phoneNumber: callingCode,
      });
    }, 100);
  }

  const handleSelectLocation = (location) => {
    if (location) {
      let street1 = '';

      if (location.streetNumber) {
        street1 += `${location.streetNumber}`;
      }

      if (location.street) {
        street1 += ` ${location.street}`;
      }

      setFieldsValue({
        street1: street1,
        country: location.country,
        state: location.state,
        city: location.city,
        zipCode: location.zipCode,
        timezone: location.timezone,
        lat: location.lat,
        lng: location.lng,
        reviewLink: 'https://search.google.com/local/writereview?placeid=',
      });
    }
  }

  const handleChangePhoneNumberCountryCode = (countryCode) => {
    if (paymentStatus === 'success') {
      preventPhoneNumberCountryCodeChange();
    } else {
      countryCode = countryCode || getFieldValue('phoneNumberCountryCode');

      const callingCode = `+${getCountryCallingCode(countryCode)}`;

      setFieldsValue({
        phoneNumberCountryCode: countryCode,
        phoneNumber: callingCode,
      });

      setTimeout(() => {
        setFieldsValue({
          providerAreaCodeCountryCode: countryCode,
          providerNumber: '',
        });

        handleChangeProviderAreaCode(callingCode);
      }, 100);
    }
  }

  const handleChangeProviderAreaCodeCountryCode = (countryCode) => {
    countryCode = countryCode || getFieldValue('providerAreaCodeCountryCode');

    const callingCode = `+${getCountryCallingCode(countryCode)}`;

    setFieldsValue({ providerAreaCodeCountryCode: countryCode });
    handleChangeProviderAreaCode(callingCode);

    setTimeout(() => {
      setFieldsValue({
        phoneNumberCountryCode: countryCode,
        phoneNumber: callingCode,
        providerNumber: '',
      });
    }, 100);
  }

  const handleChangeProviderAreaCode = (val) => {
    setFieldsValue({ providerAreaCode: val || null });
    setProviderAreaCode(val || null);
  }

  const handleSearch = async () => {
    handleChangeSearchStatus('loading');

    try {
      const countryCode = getFieldValue('providerAreaCodeCountryCode');
      const callingCode = getCountryCallingCode(countryCode);
      const areaCode = getFieldValue('providerAreaCode').replace(`+${callingCode}`, '');
      const requestData = {
        countryCode,
        areaCode,
        provider,
        function: 'listNumbers',
      };

      const response = await request.twilio.post('/twiliomigration', requestData);

      if (response.data.length) {
        setFieldsValue({ providerNumber: response.data[0] });
        handleChangeSearchStatus('success');
      } else {
        handleChangeSearchStatus('not_available_numbers');
      }
    } catch (error) {
      handleChangeSearchStatus('error');
    }
  }

  const handleCreateSubAccount = async () => {
    let friendlyName = accountName
      .replace(/[^a-zA-Z0-9]/g, '')
      .toLowerCase();

    if(provider === 'bandwidth') {
      friendlyName = `${friendlyName}-${Math.floor(Math.random() * 10).toString()}`;
    }

    // if (process.env.REACT_APP_API_ENV !== 'production') {
    //   friendlyName += `dev`;
    // }

    let requestData = {
      env: process.env.REACT_APP_API_ENV,
      function: 'createSubAccount',
      provider,
      friendlyName,
    };

    const { data } = await request.twilio.post('/twiliomigration', requestData);

    return data;
  }

  const handlePay = async () => {
    handleChangePaymentStatus('loading');

    try {
      const phoneNumber = getFieldValue('providerNumber');
      const locationName = getFieldValue('locationName');

      let friendlyName = `${accountName}${locationName}`
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase();

      // if (process.env.REACT_APP_API_ENV !== 'production') {
      //   friendlyName += `dev`;
      // }

      const requestData = {
        friendlyName,
        phoneNumber,
        provider,
        function: 'createNumber',
      };

      if (provider === 'twilio') {
        if (!friendlyName || !twilioAuthToken || !twilioSid) {
          const subAccount = await handleCreateSubAccount();

          requestData['sid'] = subAccount.sid
          requestData['authToken'] = subAccount.authToken

          await request.twilio.post('/twiliomigration', requestData);

          handleChangePaymentStatus('success');

          setTwilioFriendlyName(subAccount.friendlyName);
          setTwilioAuthToken(subAccount.authToken);
          setTwilioSid(subAccount.sid);
        } else {
          requestData['sid'] = twilioSid
          requestData['authToken'] = twilioAuthToken

          const subAccount = await request.twilio.post('/twiliomigration', requestData);

          handleChangePaymentStatus('success');

          setTwilioFriendlyName(subAccount.friendlyName);
          setTwilioAuthToken(subAccount.authToken);
          setTwilioSid(subAccount.sid);
        }
      } else {
        if (!bandwidthPeerId || !bandwidthPeerName) {
          const peer = await handleCreateSubAccount();

          requestData['peerId'] = peer.peerId;
          requestData['peerName'] = peer.peerName;

          await request.twilio.post('/twiliomigration', requestData);

          handleChangePaymentStatus('success');

          setBandwidthPeerId(peer.peerId);
          setBandwidthPeerName(peer.peerName);
        } else {
          requestData['peerId'] = bandwidthPeerId;
          requestData['peerName'] = bandwidthPeerName;

          const peer = await request.twilio.post('/twiliomigration', requestData);

          handleChangePaymentStatus('success');

          setBandwidthPeerId(peer.peerId);
          setBandwidthPeerName(peer.peerName);
        }
      }
    } catch (error) {
      handleChangePaymentStatus('error');
    }
  }

  const providerAreaCodeButtonDisabledAttr = () => {
    const providerAreaCodeCountryCode = getFieldValue('providerAreaCodeCountryCode');

    if (!providerAreaCodeCountryCode || !providerAreaCode) return true;

    const countryCallingCode = getCountryCallingCode(providerAreaCodeCountryCode);

    return providerAreaCode === `+${countryCallingCode}`;
  }

  const providerAreaCodeValidateStatusAttr = () => {
    switch (searchStatus) {
      case 'not_available_numbers':
      case 'error':
        return 'error';
      default:
        return undefined;
    }
  }

  const providerAreaCodeHelpAttr = () => {
    switch (searchStatus) {
      case 'not_available_numbers':
        return 'No available numbers in this area code. Please enter a different area code.';
      case 'error':
        return 'An error has occurred, please try again.';
      default:
        return null;
    }
  }

  const providerNumberValidateStatusAttr = () => {
    switch (paymentStatus) {
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      default:
        return undefined;
    }
  }

  const providerNumberHelpAttr = () => {
    switch (paymentStatus) {
      case 'success':
        return 'Number has been purchased successfully.';
      case 'error':
        return 'Number is no longer available. Please search again.';
      default:
        return null;
    }
  }

  const validateProviderNumberPaymentStatus = () => ({
    validator(_, value) {
      if (!value || paymentStatus === 'success') {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error('Number payment required.'));
      }
    }
  });

  const handleChangeSearchStatus = (val) => {
    setFieldsValue({ searchStatus: val });
    setSearchStatus(val);
  }

  const handleChangePaymentStatus = (val) => {
    setFieldsValue({ paymentStatus: val });
    setPaymentStatus(val);
  }

  useEffect(() => {
    const paymentStatusList = locationsIndexes.map((locationIndex) => {
      return form.getFieldValue(`${locationIndex}-paymentStatus`);
    });

    if (paymentStatusList.some(item => item === 'success')) {
      return
    }

    locationsIndexes.forEach((locationIndex) => {
      form.setFieldValue(`${locationIndex}-provider`, provider);
      form.setFieldValue(`${locationIndex}-providerNumber`, '');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsIndexes, provider]);

  useEffect(() => {
    const searchStatus = getFieldValue('searchStatus');
    const paymentStatus = getFieldValue('paymentStatus');
    const providerAreaCode = getFieldValue('providerAreaCode');

    setSearchStatus(searchStatus);
    setPaymentStatus(paymentStatus);
    setProviderAreaCode(providerAreaCode);
  }, [getFieldValue]);

  return (
    <>
      <Form.Item
        name={`${index}-locationName`}
        label="Location name"
        rules={[requiredField('Location name')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={`${index}-street1`}
        label="Street 1"
        rules={[requiredField('Street 1')]}
      >
        <LocationSearchInput onSelect={handleSelectLocation} />
      </Form.Item>

      <Form.Item
        name={`${index}-street2`}
        label="Street 2"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={`${index}-city`}
        label="City"
        rules={[requiredField('City')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={`${index}-state`}
        label="State"
        rules={[requiredField('State')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={`${index}-zipCode`}
        label="Zip code"
        rules={[requiredField('Zip code')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={`${index}-country`}
        label="Country"
        rules={[requiredField('Country')]}
      >
        <LocationSelectInput
          locationType="country"
          showSearch
        />
      </Form.Item>

      <Form.Item
        name={`${index}-lng`}
        label="Longitude"
        rules={[requiredField('Longitude')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={`${index}-lat`}
        label="Latitude"
        rules={[requiredField('Latitude')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={`${index}-timezone`}
        label="Timezone"
        rules={[requiredField('Timezone')]}
      >
        <TimezoneSelectInput showSearch />
      </Form.Item>

      <Form.Item
        name={`${index}-reviewLink`}
        label="Review Link"
        rules={[requiredField('Review Link')]}
      >
        <Input />
      </Form.Item>

      {Object.keys(props.stores.storeLocations).length > 1 && (
        <Form.Item
          name="salesforceAccountId"
          label="Store Salesforce ID"
          rules={[requiredField('Store Salesforce ID')]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        name={`${index}-phoneNumber`}
        label="Phone number"
        rules={[requiredField('Phone number')]}
      >
        {/* @TODO: When US country option is selected, sometimes it shows international number */}
        <PhoneInput
          international={true}
          withCountryCallingCode={true}
          defaultCountry={getFieldValue('phoneNumberCountryCode')}
          onCountryChange={handleChangePhoneNumberCountryCode}
        />
      </Form.Item>

      <Form.Item
        name={`${index}-provider`}
        label="Provider"
        rules={[requiredField('Provider')]}
      >
        <Select
          placeholder="Enter provider"
          disabled={disableProviderButton}
          style={{ width: '100%' }}
        >
          { PROVIDERS.map(item => (
            <Select.Option
              key={item.value}
              value={item.value}
            >
              { item.label }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Provider area code search"
        className="provider__main_control"
      >
        <Form.Item
          name={`${index}-providerAreaCode`}
          className="provider__child-control"
          hasFeedback={searchStatus === 'error'}
          validateStatus={providerAreaCodeValidateStatusAttr()}
          help={providerAreaCodeHelpAttr()}
        >
          {/* @TODO: When US country option is selected, sometimes it shows international number */}
          <PhoneInput
            onChange={handleChangeProviderAreaCode}
            international={true}
            withCountryCallingCode={true}
            readOnly={searchStatus === 'loading'}
            disabled={paymentStatus === 'success'}
            defaultCountry={getFieldValue('providerAreaCodeCountryCode')}
            onCountryChange={handleChangeProviderAreaCodeCountryCode}
          />
        </Form.Item>

        <Button
          type="primary"
          className="provider__button"
          loading={searchStatus === 'loading'}
          readOnly={searchStatus === 'loading'}
          disabled={
            providerAreaCodeButtonDisabledAttr() ||
            paymentStatus === 'success'
          }
          onClick={handleSearch}
        >
          Search
        </Button>
      </Form.Item>

      <Form.Item
        label="Provider number"
        className="provider__main_control"
      >
        <Form.Item
          name={`${index}-providerNumber`}
          rules={[
            requiredField('Provider number'),
            validateProviderNumberPaymentStatus(),
          ]}
          className="provider__child-control"
          hasFeedback={
            paymentStatus === 'success' ||
            paymentStatus === 'error'
          }
          validateStatus={providerNumberValidateStatusAttr()}
          help={providerNumberHelpAttr()}
        >
          <Input readOnly />
        </Form.Item>

        {
          searchStatus === 'success' &&
          paymentStatus !== 'success' &&
          <Button
            type="primary"
            className="provider__button"
            loading={paymentStatus === 'loading'}
            readOnly={paymentStatus === 'loading'}
            onClick={handlePay}
          >
            Buy
          </Button>
        }
      </Form.Item>
    </>
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocation);
