import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import { Spin } from 'antd';
import { STEPS } from './shared/constants';
import MerchantSetupHeader from './Layout/Header';
import MerchantSetupBody from './Layout/Body';
import StoreType from './children/StoreType';
import AccountInformation from './children/AccountInformation';
import OwnerInformation from './children/OwnerInformation';
import StoreLocations from './children/StoreLocations';
import AccountsOnHold from './children/AccountsOnHold';

const CHILD_COMPONENTS = [
  <AccountsOnHold />,
  <StoreType />,
  <AccountInformation />,
  <OwnerInformation />,
  <StoreLocations />,
];

const MerchantSetup = props => {
  const [title, setTitle] = useState('');
  const { setDefaultValues } = props;
  const { loading, currentStep } = props.stores;

  useEffect(() => {
    setTitle(STEPS[currentStep].title);
  }, [currentStep]);

  useEffect(() => {
    return () => {
      setDefaultValues();
    }
  }, [setDefaultValues]);

  return (
    <PrimaryLayout>
      <Spin spinning={loading}>
        <div className="p-20">
          <MerchantSetupHeader />

          <h2 className="m-top-25 m-btm-25">{ title }</h2>

          <MerchantSetupBody>
            { CHILD_COMPONENTS[currentStep] }
          </MerchantSetupBody>
        </div>
      </Spin>
    </PrimaryLayout>
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setDefaultValues() {
    dispatch({
      type: 'SET_DEFAULT_VALUES',
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSetup);
