import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Upload, Space } from 'antd';
import { requiredField } from '../../../../utils/validations';
import { csvFileToArray } from '../../../../core/utils';
import MerchantSelectInput from '../../../Global/MerchantSelectInput';

const UploadAssociateAssignmentsUploadStep = (props) => {
  const {
    currentStep,
    setCurrentStep,
    merchant,
    setMerchant,
    fileList,
    setFileList,
    setAssociateAssignments,
  } = props;

  const initialValues = useMemo(() => ({
    merchant: merchant,
    clients_csv: fileList,
  }), [fileList, merchant]);

  const handleSetFileList = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const handleFinish = async (fields) => {
    setMerchant(fields.merchant);
    setFileList(fields.clients_csv);

    const rawFile = fields.clients_csv[0].originFileObj;
    const newAssociateAssignments = await csvFileToArray(rawFile);

    setAssociateAssignments(newAssociateAssignments);
    setCurrentStep(currentStep + 1);
  }

  return (
    <Form
      onFinish={handleFinish}
      initialValues={initialValues}
      style={{ maxWidth: '650px' }}
    >
      <Form.Item
        name="merchant"
        label="Merchant"
        rules={[requiredField('Merchant')]}
      >
        <MerchantSelectInput
          valueKey="id"
          labelKey="name"
        />
      </Form.Item>

      <Form.Item
        name="clients_csv"
        valuePropName="fileList"
        getValueFromEvent={handleSetFileList}
        rules={[requiredField('Clients CSV')]}
      >
        <Upload.Dragger
          maxCount={1}
          accept=".csv"
          customRequest={({ onSuccess }) => onSuccess('ok')}
          style={{ maxWidth: '700px' }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>

          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>

          <p className="ant-upload-hint">
            You can upload any CSV file with any set of columns as long as it has 1 record per row.
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item>
        <Space>
          <InfoCircleOutlined /> You must include a header row in your csv. Only unassigned clients will be updated.
        </Space>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </Form.Item>
    </Form>
  );
}

const mapStateToProps = state => state.uploadAssociateAssignments;

const mapDispatchToProps = dispatch => ({
  setCurrentStep(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_CURRENT_STEP',
      payload
    });
  },
  setMerchant(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_MERCHANT',
      payload,
    });
  },
  setFileList(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_FILE_LIST',
      payload,
    });
  },
  setIncludeHeaders(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_INCLUDE_HEADERS',
      payload,
    });
  },
  setDeleteOldAssociatesAssigned(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_DELETE_OLD_ASSOCIATES_ASSIGNED',
      payload,
    });
  },
  setAssociateAssignments(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_ASSOCIATE_ASSIGNMENTS',
      payload,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadAssociateAssignmentsUploadStep);
