import React, { useEffect, useMemo, useState } from "react";
import PrimaryLayout from "../../../Layout/PrimaryLayout";
import { Select, Table } from "antd";
import {useHistory, useParams} from 'react-router-dom';
import moment from 'moment';
import { connect } from "react-redux";
import SmileyFace from "../HomeImages/SmileyFace";
import NeutralFace from "../HomeImages/NeutralFace";
import SadFace from "../HomeImages/SadFace";
import SatisfactionCard from "../../../Layout/SatisfactionCard";
import MerchantGraph from "./children/MerchantGraph";
import request from "../../../../core/request";

const TIME_FRAMES = ['30 days', '60 days', '90 days', 'All Time'];

const DEFAULT_TIME_FRAME = '30 days';

const MerchantRating = (props) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(DEFAULT_TIME_FRAME);
  const [storesList, setStoresList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  const { setRatingState } = props;
  const { merchantName } = useParams()
  const history = useHistory();

  const filters = useMemo(() => ({
    license_key: {
      $eq: merchantName,
    }
  }), [merchantName]);

  useEffect(() => {
    const getAllStores = async () => {
      try {
        const config = { params: { filters, pageSize: 1000 }};
        const response = await request.basic.get('/stores/getStores', config);

        setStoresList(response.data.results);
      } catch (error) {
        // ERROR HANDLER
      }
    }

    getAllStores();
  }, [filters]);

  useEffect(() => {
    const getFeedback = async () => {
      const response = await request.basic.get(`/accounts/getFeedback?timeFrame=${selectedTimeFrame}&selectedStore=${selectedStore}`)
      setRatingState({ allRatings: response.data });
    }

    getFeedback();
  }, [selectedStore, selectedTimeFrame, setRatingState]);

  const columns = [
    {
      title: 'Account',
      dataIndex: 'merchant',
      key: 'merchant',
    },
    {
      title: 'Name',
      dataIndex: 'associate_name',
      key: 'associate_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Platform',
      dataIndex: 'device',
      key: 'device',
    },
    {
      title: 'Screen',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Date / Time',
      dataIndex: 'date',
      key: 'date',
      render: (t) => {
        return  moment(t, 'YYYYMMDDHHmmss').format('MM/DD/YYYY')
      }
    },
    {
      title: 'Response',
      dataIndex: 'status',
      key: 'status',
      render: (t,o ) => {
        if(t < 0) {
          return <SadFace height="16" width="16" />
        } else if (t > 0) {
          return <SmileyFace height="16" width="16" />

        } else {
          return <NeutralFace height="16" width="16" />
        }
      }
    },
  ];

  const buildAssociateData = () => {
    const associateObj = {};
    props.ratings.allRatings.forEach(row => {
      if(row.merchant === merchantName) {

        if(!associateObj.hasOwnProperty(row.associate_id)) {
          associateObj[row.associate_id] = {
            status: row.answer,
            ...row
          };
        } else {
          associateObj[row.associate_id].status += row.answer;
        }
      }
    })
    return Object.values(associateObj);
  }

  const metricData =() => {

    return props.ratings.allRatings.filter(row => {
      if(row.merchant === merchantName) {
        return true
      } else return false
    })
  }

  return (
  <PrimaryLayout header="Home">
    <div style={{paddingBottom: '50px'}}>
    <div
      className="flex-row-wrap"
      style={{ justifyContent: "space-between" }}
    >
      <div className="flex-row-wrap">
      <span className="fs-28 m-right-30">{merchantName}</span>
      </div>
      <div>
      <Select
        style={{width: '144px', height: '32px'}}
        placeholder="Locations"
        value={selectedStore}
        onChange={val => setSelectedStore(val)}
        allowClear
        >
        {storesList.map(st => <Select.Option value={st.id} key={st.id}>{st.name}</Select.Option>)}
      </Select>
      <Select
        style={{width: '144px', height: '32px'}}
        placeholder="Time Frame"
        value={selectedTimeFrame}
        onChange={val => setSelectedTimeFrame(val)}
        >
        {TIME_FRAMES.map(tf => <Select.Option key={tf}>{tf}</Select.Option>)}
      </Select>
      </div>
    </div>
    <div className="m-btm-50 align-center" >
      <span className="fs-25" >Merchant ({merchantName}) Ratings VS Clientbook Overall</span>
    </div>
    <div style={{height: '20vh'}} className="bg-white rem-pad-width p-left-15 p-right-15 p-top-15 p-btm-15">
      <MerchantGraph
        configObj={{}}
        associateRatings={buildAssociateData()}
        allRatings={props.ratings.allRatings}
        merchantName={merchantName}
        selectedTimeFrame={selectedTimeFrame}
      />
    </div>
    <div className="flex-row-wrap  m-top-15" style={{justifyContent: "space-between"}}>
      <SatisfactionCard
        image={SmileyFace}
        type={"Satisfied"}
        calcValue={1}
        allFeedback={metricData()}
      />
      <SatisfactionCard
        image={NeutralFace}
        type={"Neutral"}
        calcValue={0}
        allFeedback={metricData()}
      />
      <SatisfactionCard
        image={SadFace}
        type={"Unsatisfied"}
        calcValue={-1}
        allFeedback={metricData()}
      />
    </div>
    <div className="m-top-15 m-btm-50">
      <Table
        dataSource={buildAssociateData()}
        rowKey="date"
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => history.push(`/associates/${record.associate_id}/ratings`),
        })}
      />
    </div>
    </div>
  </PrimaryLayout>
  );
};

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setRatingState(val){
    dispatch({
      type: "SET_RATING_STATE",
      payload: val
    })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantRating);
