import React from 'react'
import { Form, Button, Input } from 'antd';
import { requiredField } from '../../../../utils/validations';
import request from '../../../../core/request';

const PhoneLookupForm = (props) => {
  const {
    form,
    loading,
    setLoading,
    setInformation,
  } = props;

  const handleSubmit = async (fields) => {
    setLoading(true);

    try {
      const phoneNumber = fields.phone_number;

      const requestData = {
        function: 'phoneLookup',
        phoneNumber,
      }

      const response = await request.twilio.post('/twiliomigration', requestData);

      setInformation(response.data);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form
      form={form}
      labelCol={{
        sm: { span: 24 },
        md: { span: 6 },
      }}
      wrapperCol={{
        sm: { span: 24 },
        md: { span: 18 },
      }}
      labelAlign="left"
      autoComplete="off"
      disabled={loading}
      onFinish={handleSubmit}
      labelWrap
    >
      <Form.Item
        name="phone_number"
        label="Phone number"
        rules={[requiredField('Phone number')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PhoneLookupForm;
