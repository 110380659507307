import React from "react";

const SadFace = (props) => {
  const height = props.height || 48;
  const width = props.width || 48;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#FF7C7C" />
      <path
        d="M14.3325 31.6567C16.8677 28.6039 20.573 26.8542 24.5089 26.8542C28.4447 26.8542 32.15 28.6039 34.6675 31.6567C35.1728 32.2548 35.093 33.1564 34.4903 33.6652C33.8609 34.2008 32.9656 34.058 32.4957 33.4867C30.5101 31.0944 27.5937 29.7197 24.5 29.7197C21.4063 29.7197 18.4899 31.0944 16.5043 33.4867C16.0078 34.0937 15.1125 34.174 14.5097 33.6652C13.907 33.1653 13.8272 32.2637 14.3325 31.6567Z"
        fill="black"
      />
      <path
        d="M20.2539 16.8565C20.2539 18.4365 18.9863 19.713 17.4173 19.713C15.8483 19.713 14.5807 18.4365 14.5807 16.8565C14.5807 15.2765 15.8483 14 17.4173 14C18.9863 14 20.2539 15.2765 20.2539 16.8565Z"
        fill="black"
      />
      <path
        d="M34.4371 16.8565C34.4371 18.4365 33.1694 19.713 31.6004 19.713C30.0314 19.713 28.7638 18.4365 28.7638 16.8565C28.7638 15.2765 30.0314 14 31.6004 14C33.1694 14 34.4371 15.2765 34.4371 16.8565Z"
        fill="black"
      />
    </svg>
  );
};
export default SadFace;
