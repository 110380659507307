import React, { useMemo } from 'react'
import usePaginatedRequest from '../../../hooks/usePaginatedRequest'
import PrimaryLayout from '../../Layout/PrimaryLayout'
import Table from '../../Global/Table'
import QuickLogin from './components/QuickLogin'
import request from '../../../core/request'
import { Tag } from 'antd'
import CopyToClipboardColumn from '../../Global/Table/components/CopyToClipboardColumn'

const ROLE_FILTERS = [
  { value: 'ASSOCIATE', text: 'ASSOCIATE' },
  { value: 'MANAGER', text: 'MANAGER' },
  { value: 'ADMIN', text: 'ADMIN' },
]

const REQUEST_METHOD = ({ params = {} }) => {
  return request.basic.get(`/accounts/getAccounts`, { params })
}

const Accounts = () => {
  const PARAMS = useMemo(() => new URLSearchParams(window.location.search), []);
  const DEFAULT_TEXT_SEARCH = useMemo(() => PARAMS.get('search') || '', [PARAMS]);

  const TABLE_COLUMNS = useMemo(() => ([
    {
      title: 'Merchant Name',
      dataIndex: 'merchant_name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Associate ID',
      dataIndex: 'associate_id',
      ellipsis: true,
    },
    {
      title: 'Associate Name',
      dataIndex: 'associate_name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Associate Email',
      dataIndex: 'associate_email',
      sorter: true,
      ellipsis: true,
      render: (_, item) => (
        <CopyToClipboardColumn
          value={item.email}
          label={item.email}
        />
      ),
    },
    {
      title: 'Associate Password',
      dataIndex: 'associate_password',
      ellipsis: true,
      render: (_, item) => (
        <CopyToClipboardColumn
          value={item.password}
          label={item.password}
        />
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      ellipsis: true,
      filters: ROLE_FILTERS,
      render: (value) => value ? <Tag color="blue">{value}</Tag> : null,
    },
    {
      key: 'login',
      title: 'Login',
      fixed: 'right',
      ellipsis: true,
      render: (_, item) => <QuickLogin {...item} />
    }
  ]), []);

  const paginatedRequest = usePaginatedRequest({
    defaultSearch: DEFAULT_TEXT_SEARCH,
    requestMethod: REQUEST_METHOD,
  });

  return (
    <PrimaryLayout header="Dashboard accounts">
      <Table
        {...paginatedRequest}
        tableRowKey="uuid"
        tableColumns={TABLE_COLUMNS}
        exportUri="/accounts/export"
      />
    </PrimaryLayout>
  );
}

export default Accounts;
