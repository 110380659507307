import React from 'react';
import { useEffect } from 'react';
import {connect} from 'react-redux';
import Router from './core/router';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom'
import request from './core/request';
import './App.css';

library.add(fas, fal, far, fad, fab);

function App(props) {
  const history = useHistory();
  useEffect(() => {
    let localStorageJwtToken = localStorage.getItem('jwtToken');
    let localStorageApiToken = localStorage.getItem('apiToken');

    let jwtToken = props.jwtToken || localStorageJwtToken;
    let apiToken = props.apiToken || localStorageApiToken;

    if(!jwtToken || !apiToken) {
      history.push('/login')
    }

    if((!props.jwtToken && jwtToken) || (!props.apiToken && apiToken)) {
      request.login.defaults.headers.common['Authorization'] = jwtToken;
      request.login.defaults.headers.common['x-api-key'] = jwtToken;

      request.basic.defaults.headers.common['x-api-key'] = jwtToken;
      request.twilio.defaults.headers.common['x-api-key'] = jwtToken;


      request.login.post(`/auth/tokenLogin`, { id: 8675309 }, {
        headers: { 'x-api-key': jwtToken }
      })
      .then(res => {
        localStorage.setItem('jwtToken', res.data?.apiKey);
        localStorage.setItem('apiToken', res.data?.apiToken);

        props.setAuth({
          apiKey: res.data?.apiKey,
          apiToken: res.data?.apiKey,
        });
      })
      .catch(err => {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("apiToken");
        history.push('/login')
      })

    }
  }, []);

  return (
    <Router />
  );
}

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  setAuth(val) {
    dispatch({
      type: "SET_JWT_TOKEN",
      payload: val.apiKey,
    });

    dispatch({
      type: "SET_API_TOKEN",
      payload: val.apiToken,
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
