import { format } from 'date-fns';

export const REPORTS = {
  '/reports/hemline-messaging': {
    name: 'Hemline Messaging',
    description: 'The Hemline Messaging report lists the Mass Messaging usage by location for all Hemline accounts.',
    link: '/reports/hemline-messaging',
    endpoint: '/reports/getHemlineMessaging',
    exportEndpoint: '/reports/exportHemlineMessaging',
    showRangeSelector: true,
    showSearch: false,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        ellipsis: true,
      },
      {
        title: 'License key',
        dataIndex: 'license_key',
        ellipsis: true,
      },
      {
        title: 'Message Limit',
        dataIndex: 'message_limit',
        ellipsis: true,
      },
      {
        title: 'Message Sent',
        dataIndex: 'message_sent',
        ellipsis: true,
      },
    ],
  },
  '/reports/inactivity': {
    name: 'Inactivity Report',
    description: 'The Inactivity Report lists the account that have had no application activity in the last 30 days.',
    link: '/reports/inactivity',
    endpoint: '/reports/getInactivity',
    exportEndpoint: '/reports/exportInactivity',
    showRangeSelector: false,
    showSearch: true,
    columns: [
      {
        title: 'License key',
        dataIndex: 'license_key',
        sorter: true,
        ellipsis: true,
      },
      {
        title: 'Last Login Date',
        dataIndex: 'last_login_date',
        sorter: true,
        ellipsis: true,
        render: value => (value ? format(new Date(value), 'yyyy-mm-dd') : ''),
      },
    ],
  },
  '/reports/license-seat-overages': {
    name: 'License Seat Overages',
    description: 'The License Seat Overages report generates a list of accounts where the number of associate profiles exceeds their License Seat plan limit.',
    link: '/reports/license-seat-overages',
    endpoint: '/reports/getLicenseSeatOverages',
    exportEndpoint: '/reports/exportLicenseSeatOverages',
    showRangeSelector: false,
    showSearch: true,
    columns: [
      {
        title: 'Merchant Name',
        dataIndex: 'name',
        sorter: true,
        ellipsis: true,
      },
      {
        title: 'License Key',
        dataIndex: 'license_key',
        sorter: true,
        ellipsis: true,
      },
      {
        title: 'Available Licenses',
        dataIndex: 'available_licenses',
        ellipsis: true,
      },
      {
        title: 'Used Licenses',
        dataIndex: 'used_licenses',
        ellipsis: true,
      },
    ],
  },
  '/reports/mass-message-overages': {
    name: 'Mass Message Overages',
    description: 'The Mass Message Overage report displays a list of Merchants who have exceeded their Mass Message usage limit. Report is run by month.',
    link: '/reports/mass-message-overages',
    endpoint: '/reports/getMassMessageOverages',
    exportEndpoint: '/reports/exportMassMessageOverages',
    showRangeSelector: true,
    showSearch: true,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        ellipsis: true,
      },
      {
        title: 'License key',
        dataIndex: 'license_key',
        sorter: true,
        ellipsis: true,
      },
      {
        title: 'Message Limit',
        dataIndex: 'message_limit',
        ellipsis: true,
      },
      {
        title: 'Message Sent',
        dataIndex: 'message_sent',
        ellipsis: true,
      },
    ],
  },
}
