import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Select, Switch, Tooltip } from 'antd';
import request from "../../../../../core/request";
import { useHistory } from 'react-router';
import './styles.css';

const Settings = ( { merchant, onSuccess } ) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);

  const packageOptions = useMemo(() => packages.map(item => ({
    value: item.code,
    label: item.name,
  })), [packages]);

  const mass_messaging_enabled = Form.useWatch('mass_messaging_enabled', form);
  const mass_messaging = Form.useWatch('mass_messaging', form);
  const license_count = Form.useWatch('license_count', form);
  const package_code = Form.useWatch('package_code', form);
  const hide_message_intro = Form.useWatch('hide_message_intro', form);
  const salesforce_billing_account_id = Form.useWatch('salesforce_billing_account_id', form);

  const initialValues = useMemo(() => ({
    mass_messaging_enabled: Boolean(merchant?.mass_messaging_enabled),
    mass_messaging: merchant?.mass_messaging,
    license_count: merchant?.license_count,
    package_code: merchant?.package_code,
    hide_message_intro: merchant?.hide_message_intro,
    salesforce_billing_account_id: merchant?.salesforce_billing_account_id,
  }), [merchant]);

  const disableFields = useMemo(() => package_code !== merchant?.package_code, [merchant, package_code]);

  const history = useHistory();

  const getPackages = useCallback(async () => {
    try {
      const response = await request.basic.get('/packages/getPackages');
      setPackages(response.data);
    } catch (error) {
      history.push('/merchants')
    }
  }, [history]);

  const handleChangeProductCode = useCallback((value) => {
    const packageItem = packages.find(item => item.code === value);

    if (packageItem) {
      const massMessagingFeature = packageItem.features.find(item => item.code === 'mass_messaging');

      if (massMessagingFeature) {
        form.setFieldsValue({
          mass_messaging: massMessagingFeature.value,
        });
      }
    }
  }, [packages, form]);

  const handleSubmit = async ()  => {
    setLoading(true);

    try {
      const data = {
        merchant_id: merchant.id,
        mass_messaging_enabled,
        mass_messaging,
        license_count,
        package_code,
        hide_message_intro,
        salesforce_billing_account_id,
      };

      await request.basic.put(`/merchants/updateMerchantSettings`, data);

      message.success('Merchant settings updated successfully.');

      onSuccess()
    } catch (error) {
      message.error('Something went wrong.');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    console.log('mass_messaging_enabled', mass_messaging_enabled);
  }, [mass_messaging_enabled]);

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (disableFields) {
      form.setFieldsValue({
        mass_messaging_enabled: Boolean(merchant?.mass_messaging_enabled),
        mass_messaging: merchant?.mass_messaging,
        license_count: merchant?.license_count,
      })
    }
  }, [disableFields, form, merchant]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        disabled={loading}
        onFinish={handleSubmit}
        style={{ maxWidth: '450px' }}
      >
        <Form.Item
          name="mass_messaging"
          className="cb-merchant-settings--mass-message-limit"
          label={
            <>
              <span>Mass Message Limit</span>

              <Tooltip title="Mass Message Access">
                <div>
                  <Form.Item
                    name="mass_messaging_enabled"
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </Tooltip>
            </>
          }
        >
          <InputNumber
            placeholder="Enter the mass message limit"
            controls={false}
            disabled={disableFields}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          name="license_count"
          label="License Seats"
        >
          <InputNumber
            placeholder="Enter the amount of seats"
            controls={false}
            disabled={disableFields}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          name="package_code"
          label="Product Tier"
        >
          <Select
            placeholder="Product Tier"
            style={{ width: '100%' }}
            onChange={handleChangeProductCode}
          >
            {packageOptions.map(item => (
              <Select.Option
                key={item.value}
                value={item.value}
              >
                { item.label }
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="salesforce_billing_account_id"
          label="Salesforce Billing Account ID"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={'hide_message_intro'}
          label="Hide Message Intro"
          valuePropName="checked"
        >
          <Switch style={{ borderRadius: '100px' }} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Settings;
