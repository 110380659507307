import React from "react";
import { Descriptions } from "antd";
import moment from "moment";
import { timeFormat14, timeFormat8, truthTextHandler } from "../../../../../../../core/utils";

const DataAssignmentListItems = (props) => {
  const {
    salesTransactions,
    salesOpps,
    relatives,
    lifeEvents,
    tags,
    collections,
    associates,
    history,
    address,
    client,
    preferences,
    chatMessages
  } = props.fullClientDetails;
  const clientObj = client || {};
  const { setTempClientDetails, tempClientDetails } = props;

  const clientStateHandler = (val, innerProp) => {
    setTempClientDetails({
      ...tempClientDetails,
      client: { ...tempClientDetails.client, [innerProp]: val },
    });
  };

  const mainStateHandler = (val, innerProp) => {
    const newValObj = {...val, newAttribute: true}
    if(!newValObj.primaryExists) {
      setTempClientDetails({
        ...tempClientDetails,
        [innerProp]: [...tempClientDetails[innerProp], newValObj],
      });
    }
  };

  const listFilter = (arr, clientProp, filterProp) => {
    const {primaryClient} = props;
    const primaryArr = primaryClient[clientProp] || [];
    const tempPriArr = tempClientDetails[clientProp] || [];
    const filterContent = [
      ...primaryArr.map(item => item[filterProp]),
      ...tempPriArr.map(item => item[filterProp]),
    ];
    // return arr.filter(item => !filterContent.includes(item[filterProp]))
    return arr.map(item => ({...item, primaryExists: filterContent.includes(item[filterProp])}))
  }

  const cliPrpCmp = (theProp) => {
    if (clientObj[theProp] === props.primaryClient.client[theProp] || clientObj[theProp] === tempClientDetails.client[theProp]) {
      return "nonClickableDescription"
    }
    return "clickableDescription";
  }

  return (
    <div>
      <Descriptions key={"clientObj"} title="Client" column={1}>
        <Descriptions.Item label="Name">
          <span
            className={cliPrpCmp('name')}
            onClick={() => clientStateHandler(clientObj.name, "name")}
          >
            {clientObj.name}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Mobile">
          <span
            className={cliPrpCmp('mobile')}
            onClick={() => clientStateHandler(clientObj.mobile, "mobile")}
          >
            {clientObj.mobile}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          <span
            className={cliPrpCmp('email')}
            onClick={() => clientStateHandler(clientObj.email, "email")}
          >
            {clientObj.email}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="PosRefNum">
          <span
            className={cliPrpCmp('pos_ref_num')}
            onClick={() => clientStateHandler(clientObj.pos_ref_num, "pos_ref_num")}
          >
            {clientObj.pos_ref_num}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Preferred Name">
          <span
            className={cliPrpCmp('preferred_name')}
            onClick={() =>
              clientStateHandler(clientObj.preferred_name, "preferred_name")
            }
          >
            {clientObj.preferred_name}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Pref Contact Type">
          <span
            className={cliPrpCmp('pref_contact_type')}
            onClick={() =>
              clientStateHandler(clientObj.pref_contact_type, "pref_contact_type")
            }
          >
            {clientObj.pref_contact_type}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Home Phone">
          <span
            className={cliPrpCmp('home_phone')}
            onClick={() =>
              clientStateHandler(clientObj.home_phone, "home_phone")
            }
          >
          {clientObj.home_phone}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Dont Contact SMS">
          <span
            className={cliPrpCmp('dont_contact_sms')}
            onClick={() =>
              clientStateHandler(clientObj.dont_contact_sms, "dont_contact_sms")
            }
          >
          {truthTextHandler(clientObj.dont_contact_sms)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Dont Contact Email">
          <span
            className={cliPrpCmp('dont_contact_email')}
            onClick={() =>
              clientStateHandler(clientObj.dont_contact_email, "dont_contact_email")
            }
          >
          {truthTextHandler(clientObj.dont_contact_email)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Image">
          <span
            className={cliPrpCmp('image')}
            onClick={() =>
              clientStateHandler(clientObj.image, "image")
            }
          >
          {clientObj.image}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Opt In Date">
          <span
            className={cliPrpCmp('opt_in_date')}
            onClick={() =>
              clientStateHandler(clientObj.opt_in_date, "opt_in_date")
            }
          >
          {timeFormat14(clientObj.opt_in_date)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Opt In Req Date">
        <span
            className={cliPrpCmp('opt_in_req_date')}
            onClick={() =>
              clientStateHandler(clientObj.opt_in_req_date, "opt_in_req_date")
            }
          >
          {timeFormat14(clientObj.opt_in_req_date)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Opt Out Date">
          <span
            className={cliPrpCmp('opt_out_date')}
            onClick={() =>
              clientStateHandler(clientObj.opt_out_date, "opt_out_date")
            }
          >
          {timeFormat14(clientObj.opt_out_date)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Opt In Src">
          <span
            className={cliPrpCmp('opt_in_src')}
            onClick={() =>
              clientStateHandler(clientObj.opt_in_src, "opt_in_src")
            }
          >
          {clientObj.opt_in_src}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Mobile Validated">
          <span
            className={cliPrpCmp('mobile_validated')}
            onClick={() =>
              clientStateHandler(clientObj.mobile_validated, "mobile_validated")
            }
          >
          {clientObj.mobile_validated}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Mobile Validation Date">
          <span
            className={cliPrpCmp('mobile_validation_date')}
            onClick={() =>
              clientStateHandler(clientObj.mobile_validation_date, "mobile_validation_date")
            }
          >
          {timeFormat8(clientObj.mobile_validation_date)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Auto Review Timestamp">
          <span
            className={cliPrpCmp('auto_review_ts')}
            onClick={() =>
              clientStateHandler(clientObj.auto_review_ts, "auto_review_ts")
            }
          >
          {clientObj.auto_review_ts}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Last Visit Date">
          <span
            className={cliPrpCmp('last_visit_date')}
            onClick={() =>
              clientStateHandler(clientObj.last_visit_date, "last_visit_date")
            }
          >
          {timeFormat8(clientObj.last_visit_date)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Last Contact Date">
          <span
            className={cliPrpCmp('last_contact_date')}
            onClick={() =>
              clientStateHandler(clientObj.last_contact_date, "last_contact_date")
            }
          >
          {timeFormat8(clientObj.last_contact_date)}
          </span>
        </Descriptions.Item>
      </Descriptions>
      {address.length > 0
        ? listFilter(address, "address", "id").map((addr) => {
            return (
              <Descriptions column={1} title="Address" key={`Addr${addr.id}`}>
                <Descriptions.Item label="Address 1">
                  <span onClick={() => mainStateHandler(addr, 'address')} className={addr.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{addr.address_1}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Address 2">
                  <span onClick={() => mainStateHandler(addr, 'address')} className={addr.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{addr.address_2}</span>
                </Descriptions.Item>
                <Descriptions.Item label="City">
                  <span onClick={() => mainStateHandler(addr, 'address')} className={addr.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{addr.city}</span>
                </Descriptions.Item>
                <Descriptions.Item label="State">
                  <span onClick={() => mainStateHandler(addr, 'address')} className={addr.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{addr.state}</span>
                </Descriptions.Item>
                <Descriptions.Item label="ZIP">
                  <span onClick={() => mainStateHandler(addr, 'address')} className={addr.primaryExists ? "nonClickableDescription" : "clickableDescription"}>
                    {addr.postal_code}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                  <span onClick={() => mainStateHandler(addr, 'address')} className={addr.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{addr.type}</span>
                </Descriptions.Item>
              </Descriptions>
            );
          })
        : null}

      {lifeEvents.length > 0
        ? listFilter(lifeEvents, "lifeEvents", "type").map((lifeE) => {
            return (
              <Descriptions
                key={`LifeEvent${lifeE.id}`}
                column={1}
                title="Life Event"
              >
                <Descriptions.Item label="Type"><span onClick={() => mainStateHandler(lifeE, 'lifeEvents')} className={lifeE.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{lifeE.type}</span></Descriptions.Item>
                <Descriptions.Item label="Date"><span onClick={() => mainStateHandler(lifeE, 'lifeEvents')} className={lifeE.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{lifeE.date}</span></Descriptions.Item>
              </Descriptions>
            );
          })
        : null}
      {relatives.length > 0 ? (
        <Descriptions column={1} key={`Relative`} title="Relative">
          {listFilter(relatives, "relatives", "relationship").map((relat) => {
            return (
              <Descriptions.Item
                key={`${relat.client_id}${relat.related_client_id}${relat.relationship}`}
                label={relat.relationship}
              >
                <span
                  className={relat.primaryExists ? "nonClickableDescription" : "clickableDescription"}
                  onClick={() => mainStateHandler({...relat, fromClient: relat.client_id}, 'relatives')}
                >
                  {relat.client_name} and {relat.related_client_name}
                </span>
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      <Descriptions column={1} key={"tags"} title="Tags">
        {listFilter(tags, "tags", "name").map((tag) => (
          <Descriptions.Item key={tag.id}>
            <span onClick={() => mainStateHandler(tag, 'tags')} className={tag.primaryExists ? "nonClickableDescription" : "clickableDescription"}>{tag.name}</span>
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions column={1} key={"Personalizations"} title="Preferences">
        {listFilter(preferences, "preferences", "personalization_config_id").map((pref) => {
          return (
            <Descriptions.Item key={pref.id} label={pref.name}>
                <span className={pref.primaryExists ? "nonClickableDescription" : "clickableDescription"} onClick={() => mainStateHandler(pref, 'preferences')}>
                    {pref.value}
                </span>
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Descriptions
        column={1}
        key={`AssignedAscts`}
        title="Assigned Associates"
      >
        {listFilter(associates, "associates", "id").map((asct) => {
          return (
            <Descriptions.Item key={asct.id}>
              <span className={asct.primaryExists ? "nonClickableDescription" : "clickableDescription"} onClick={() => mainStateHandler(asct, 'associates')}>
                {asct.first_name} {asct.last_name}
              </span>
            </Descriptions.Item>
          );
        })}

      </Descriptions>
      <Descriptions column={1} key={"ThemSales"} title="Sales History">
        {listFilter(salesTransactions, "salesTransactions", "id").map((sale) => {
          return (
            <Descriptions.Item
              key={sale.id}
              label={`Sale on ${moment(
                sale.transaction_date_time,
                "YYYYMMDDHHmmss"
              ).format("MM/DD/YYYY")}`}
            >
                <span className={sale.primaryExists ? "nonClickableDescription" : "clickableDescription"} onClick={() => mainStateHandler(sale, 'salesTransactions')}>
                    {sale.unit_quantity} {sale.category} for ${sale.unit_price}
                </span>
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Descriptions column={1} key={"SalesOpz"} title="Sales Opportunities">
        {listFilter(salesOpps, "salesOpps", "name").map((sOpp) => {
          return (
            <Descriptions.Item key={sOpp.id}>
                <span className={sOpp.primaryExists ? "nonClickableDescription" : "clickableDescription"} onClick={() => mainStateHandler(sOpp, 'salesOpps')}>
                    {sOpp.complete_date ? "COMPLETE" : "INCOMPLETE"}:<br />
                    {`\n ${sOpp.name} - ${sOpp.stage_name}`}
                </span>
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Descriptions column={1} key={"ClientHistory"} title="Client History">
        {listFilter(history, "history", "type").map((hist) => {
          return (
            <Descriptions.Item
              key={hist.id}
              label={moment(hist.created_date, "YYYYMMDDHHmmss").format(
                "MM/DD/YYYY"
              )}
            >
                <span className={hist.primaryExists ? "nonClickableDescription" : "clickableDescription"} onClick={() => mainStateHandler(hist, 'history')}>
                    {hist.type}
                </span>
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Descriptions column={1} key={"TheCollectionz"} title="Collections">
        {listFilter(collections, "collections", "name").map((coll) => {
          return (
            <Descriptions.Item key={coll.id} label={coll.name}>
                <span className={coll.primaryExists ? "nonClickableDescription" : "clickableDescription"}  onClick={() => mainStateHandler(coll, 'collections')}>
                    Item Count: {coll.item_count}
                </span>
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      {chatMessages.length > 0 && chatMessages[0].hasOwnProperty('msgs') && chatMessages[0].msgs > 0 ?
        <Descriptions column={1} key={"ChatMSgs"} title="Chat Messages">
          <Descriptions.Item key={"primarychat"} label={'Message Count'}>
            <span
              className={tempClientDetails.chatMessages.hasOwnProperty(clientObj.id) ? "nonClickableDescription" : "clickableDescription"}
              onClick={() => {
                setTempClientDetails({...tempClientDetails, chatMessages: {...tempClientDetails.chatMessages, [clientObj.id]: {clientId: clientObj.id, msgs: chatMessages[0].msgs || 0}}})
              }}
            >
              {chatMessages.length > 0 ?
                `${chatMessages[0].msgs ||0}`
                : '0'}
            </span>
          </Descriptions.Item>
        </Descriptions>
      : null}
    </div>
  );
};
export default DataAssignmentListItems;
