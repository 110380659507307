import React from "react";

const NeutralFace = props => {
  const height = props.height || 48;
  const width = props.width || 48;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#FFCA00" />
      <path
        d="M19.7143 16.8571C19.7143 18.4375 18.4375 19.7143 16.8571 19.7143C15.2768 19.7143 14 18.4375 14 16.8571C14 15.2768 15.2768 14 16.8571 14C18.4375 14 19.7143 15.2768 19.7143 16.8571Z"
        fill="black"
      />
      <path
        d="M28.2857 16.8571C28.2857 15.2768 29.5625 14 31.1429 14C32.7232 14 34 15.2768 34 16.8571C34 18.4375 32.7232 19.7143 31.1429 19.7143C29.5625 19.7143 28.2857 18.4375 28.2857 16.8571Z"
        fill="black"
      />
      <path
        d="M15.4286 31.2857L32.5714 28C33.3571 28 34 28.6429 34 29.4286C34 30.2143 33.3571 30.8571 32.5714 30.8571L15.4286 34.1429C14.6429 34.1429 14 33.5 14 32.7143C14 31.9286 14.6429 31.2857 15.4286 31.2857Z"
        fill="black"
      />
    </svg>
  );
};
export default NeutralFace;
