import React, { useState } from 'react';
import {
  Alert,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
} from 'antd';
import request from '../../../../core/request';
import BulkActionsModalFooter from './BulkActionsModalFooter';
import { useParams } from 'react-router';

const INITIAL_VALUES = {
  archived: {
    update: false,
    value: false,
  },
  opt_in_req_date: {
    update: false,
    value: null,
  },
  opt_in_date: {
    update: false,
    value: null,
  },
  opt_in_src: {
    update: false,
    value: null,
  },
}

const OPT_IN_SRC_OPTIONS = [
  { value: 'CSV', label: 'CSV' },
  { value: 'IMPOR', label: 'IMPOR' },
  { value: 'KIOSK', label: 'KIOSK' },
  { value: 'MAN', label: 'MAN' },
  { value: 'PAY', label: 'PAY' },
  { value: 'SMS', label: 'SMS' },
  { value: 'WEB', label: 'WEB' },
]

const MerchantClientsBulkActionsModal = (props) => {
  const {
    open,
    setOpen,
    onSuccess,
    selectedRowKeys,
  } = props;

  const { uuid } = useParams()

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();

  const handleSuccess = () => {
    message.success('Clients updated successfully.');
    setOpen(false);
    onSuccess();
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const fields = Object
        .entries(form.getFieldsValue())
        .reduce((acc, [key, value]) => {
          if (value.update) {
            return { ...acc, [key]: value.value }
          }

          return acc;
        }, {});

      await request.basic.put('/clients/updateClients', {
        clients: selectedRowKeys,
        merchantUuid: uuid,
        fields,
      });

      handleSuccess();
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
    }
  }

  const handleChangeValues = () => {
    const fields = form.getFieldsValue();

    const someInputChecked = Object
      .values(fields)
      .some(item => item.update);

    setDisabled(!someInputChecked);
  }

  return (
    <Modal
      title="Update selected"
      open={open}
      onCancel={() => setOpen(false)}
      footer={(
        <BulkActionsModalFooter
          title={`${selectedRowKeys.length} rows will be modified. Do you want to continue?`}
          loading={loading}
          disabled={disabled}
          onConfirm={handleSubmit}
          onCancel={() => setOpen(false)}
        />
      )}
    >
      <Alert
        type="info"
        message="Check the fields you want to update"
        showIcon
        style={{ marginBottom: '12px' }}
      />

      <Form
        form={form}
        onValuesChange={handleChangeValues}
        initialValues={INITIAL_VALUES}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          sm: { span: 24 },
          md: { span: 6 },
        }}
        wrapperCol={{
          sm: { span: 24 },
          md: { span: 18 },
        }}
      >
        <Form.Item
          name="archived"
          noStyle
        >
          <Input.Group
            compact
            style={{ display: 'flex' }}
          >
            <Form.Item
              name={['archived', 'update']}
              valuePropName="checked"
              style={{ marginRight: '10px' }}
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              name={['archived', 'value']}
              label="Archived"
              valuePropName="checked"
              style={{ flex: 1 }}
            >
              <Switch />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="opt_in_req_date"
          noStyle
        >
          <Input.Group
            compact
            style={{ display: 'flex' }}
          >
            <Form.Item
              name={['opt_in_req_date', 'update']}
              valuePropName="checked"
              style={{ marginRight: '10px' }}
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              name={['opt_in_req_date', 'value']}
              label="Opt in req date"
              style={{ flex: 1 }}
            >
              <DatePicker
                allowClear
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="opt_in_date"
          noStyle
        >
          <Input.Group
            compact
            style={{ display: 'flex' }}
          >
            <Form.Item
              name={['opt_in_date', 'update']}
              valuePropName="checked"
              style={{ marginRight: '10px' }}
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              name={['opt_in_date', 'value']}
              label="Opt in date"
              style={{ flex: 1 }}
            >
              <DatePicker
                allowClear
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="opt_in_src"
          noStyle
        >
          <Input.Group
            compact
            style={{ display: 'flex' }}
          >
            <Form.Item
              name={['opt_in_src', 'update']}
              valuePropName="checked"
              style={{ marginRight: '10px' }}
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              name={['opt_in_src', 'value']}
              label="Opt in source"
              style={{ flex: 1 }}
            >
              <Select
                placeholder="Select opt in source"
                allowClear
              >
                {OPT_IN_SRC_OPTIONS.map(item => (
                  <Select.Option
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default MerchantClientsBulkActionsModal;
