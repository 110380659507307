import React from 'react';
import { connect } from 'react-redux';
import { Form, Space,Button } from 'antd';

const MerchantSetupFooter = (props) => {
  const { currentStep, loading } = props.stores;

  const decreaseCurrentStep = () => {
    setTimeout(() => {
      props.setCurrentStep(currentStep - 1);
    }, 100);
  }

  return (
    <Form.Item>
      <Space>
        {
          currentStep > 0 &&
          <>
            <Button
              onClick={decreaseCurrentStep}
              className="m-right-15"
              readOnly={loading}
              loading={loading}
            >
              Back
            </Button>

            <Button
              type="primary"
              readOnly={loading}
              loading={loading}
              onClick={() => props.form.submit()}
            >
              Next
            </Button>
          </>
        }
      </Space>
    </Form.Item>
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setCurrentStep(val) {
    dispatch({
      type: 'SET_CURRENT_STEP',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSetupFooter);
