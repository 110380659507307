import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router';
import usePaginatedRequest from '../../../hooks/usePaginatedRequest';
import { Button, Space, Tooltip } from 'antd';
import { DeleteOutlined, PlusOutlined, ShopOutlined } from '@ant-design/icons';
import EditStoreModal from './components/EditStoreModal';
import DeleteStoreModal from './components/DeleteStoreModal';
import {
  DEFAULT_TOOLTIP,
  INSUFFICIENT_STORES_TOOLTIP,
  REQUEST_METHOD,
} from './shared/constants';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import Table from '../../Global/Table';
import CopyToClipboardColumn from '../../Global/Table/components/CopyToClipboardColumn';

const StoresList = () => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const history = useHistory();

  const handleOpenEditModal = (store) => {
    setSelectedStore(store);
    setShowEditModal(true);
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false);

    setTimeout(() => {
      setSelectedStore(null);
    }, 500);
  }

  const handleOpenDeleteModal = (store) => {
    setSelectedStore(store);
    setShowDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);

    setTimeout(() => {
      setSelectedStore(null);
    }, 500);
  }

  const paginatedRequest = usePaginatedRequest({
    requestMethod: REQUEST_METHOD,
  });

  const tableColumns = useMemo(() => ([
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Store UUID',
      dataIndex: 'uuid',
      ellipsis: true,
      render: (_, item) => (
        <CopyToClipboardColumn
          value={item.uuid}
          label={item.uuid}
        />
      ),
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchant_name',
      sorter: true,
      ellipsis: true,
    },
    {
      key: 'options',
      title: 'Options',
      fixed: 'right',
      ellipsis: true,
      render: (_, item) => {
        const disabled = item.total_stores < 2;

        const tooltip = disabled
          ? INSUFFICIENT_STORES_TOOLTIP
          : DEFAULT_TOOLTIP;

        return (
          <Space>
            <Tooltip title="Edit Store Info">
              <Button
                type="primary"
                icon={<ShopOutlined />}
                onClick={() => handleOpenEditModal(item)}
                ghost
              />
            </Tooltip>

            <Tooltip title={tooltip}>
              <Button
                icon={<DeleteOutlined />}
                disabled={disabled}
                onClick={() => handleOpenDeleteModal(item)}
                danger
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ]), []);

  const headerChildren = useMemo(() => (
    <Button
      type="primary"
      size="large"
      icon={<PlusOutlined />}
      onClick={() => history.push('/stores/new')}
    >
      Create Store
    </Button>
  ), [history]);

  return (
    <PrimaryLayout header="Stores">
      <Table
        {...paginatedRequest}
        tableRowKey="uuid"
        tableColumns={tableColumns}
        headerChildren={headerChildren}
      />

      {
        selectedStore &&
        <EditStoreModal
          open={showEditModal}
          onCancel={handleCloseEditModal}
          onSuccess={() => paginatedRequest.getResults(true)}
          store={selectedStore}
        />
      }

      {
        selectedStore &&
        <DeleteStoreModal
          open={showDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSuccess={() => paginatedRequest.getResults(true)}
          store={selectedStore}
        />
      }
    </PrimaryLayout>
  );
}

export default StoresList;
