export const requiredField = (fieldName) => {
  return {
    required: true,
    message: `${fieldName} is required.`,
  }
}

export const urlField = () => {
  return {
    type: 'url',
    message: 'Enter a valid url.',
  }
}

export const emailField = () => {
  return {
    type: 'email',
    message: 'Enter a valid email.',
  }
}
