const initialState = {
  jwtToken: '',
  apiToken: '',
};

export default function reducer(state = initialState, action) {
  let state2 = state;

  switch (action.type) {
    case "SET_JWT_TOKEN":
      return {...state2, jwtToken: action.payload}
    case "SET_API_TOKEN":
      return {...state2, apiToken: action.payload}
    default:
      break; 
  }
  return state2;
}

