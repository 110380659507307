import React, { useCallback, useEffect, useState } from 'react'
import { Button, Tabs, Typography } from 'antd';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import SalesActionConfigs from './components/SalesActionConfigs';
import GeneralInformation from './components/GeneralInformation';
import Settings from "./components/Settings";
import { useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';
import request from '../../../core/request';

const DEFAULT_TAB = 'GENERAL_INFORMATION';

const StoresList = () => {
  const { uuid } = useParams()
  const [tab, setTab] = useState(DEFAULT_TAB);
  const [merchant, setMerchant] = useState(null);
  const [showCreateSalesActionConfigModal, setShowCreateSalesActionConfigModal] = useState(false);

  const history = useHistory();

  const getMerchant = useCallback(async () => {
    try {
      const response = await request.basic.get('/merchants/getMerchant', { params: { uuid } });
      setMerchant(response.data);
    } catch (error) {
      history.push('/merchants')
    }
  }, [history, uuid]);

  const TABS = useMemo(() => ({
    GENERAL_INFORMATION: {
      key: 'GENERAL_INFORMATION',
      label: 'General information',
      children: (
        <GeneralInformation merchant={merchant} />
      ),
    },
    SALES_ACTION_CONFIGS: {
      key: 'SALES_ACTION_CONFIGS',
      label: 'Sales action configs',
      children: (
        <SalesActionConfigs
          merchant={merchant}
          showCreateModal={showCreateSalesActionConfigModal}
          setShowCreateModal={setShowCreateSalesActionConfigModal}
        />
      ),
      tabBarExtraContent: (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowCreateSalesActionConfigModal(true)}
        >
          Create
        </Button>
      ),
    },
    SETTINGS: {
      key: 'SETTINGS',
      label: 'Merchant settings',
      children: (
        <Settings merchant={merchant} onSuccess={getMerchant} />
      ),
    },
  }), [getMerchant, merchant, showCreateSalesActionConfigModal]);

  useEffect(() => {
    getMerchant();
  }, [getMerchant]);

  return (
    <PrimaryLayout>
      <Typography.Title level={3}>
        Edit Merchant
      </Typography.Title>

      <Tabs
        defaultActiveKey={DEFAULT_TAB}
        items={Object.values(TABS)}
        onChange={setTab}
        tabBarExtraContent={TABS[tab].tabBarExtraContent}
      />
    </PrimaryLayout>
  );
}

export default StoresList;
