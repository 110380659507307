import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../components/Routes/Home';
import Associates from '../components/Routes/Associates';
import Merchants from '../components/Routes/Merchants';
import EditMerchant from '../components/Routes/EditMerchant';
import MerchantClients from '../components/Routes/MerchantClients';
import Login from '../components/Routes/Login';
import Accounts from '../components/Routes/Accounts';
import DuplicatedClients from '../components/Routes/DuplicatedClients';
import UploadAssociateAssignments from '../components/Routes/UploadAssociateAssignments';
import MerchantRating from '../components/Routes/Home/MerchantRating';
import AssociateRating from '../components/Routes/Home/MerchantRating/AssociateRating';
import ViewAllRatings from '../components/Routes/Home/ViewAllRatings';
import Stores from '../components/Routes/Stores';
import MerchantSetup from '../components/Routes/MerchantSetup';
import CreateStore from '../components/Routes/CreateStore';
import PhoneLookup from '../components/Routes/PhoneLookup';
import Reports from '../components/Routes/Reports';
import ReportDetails from '../components/Routes/Reports/components/Details';

const Router = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/merchants/:merchantName/ratings" exact component={MerchantRating} />
      <Route path="/associates/:associateId/ratings" exact component={AssociateRating} />
      <Route path="/associates/ratings" exact component={ViewAllRatings} />
      <Route path="/merchants" exact component={Merchants} />
      <Route path="/merchants/new" exact component={MerchantSetup} />
      <Route path="/merchants/:uuid/edit" exact component={EditMerchant} />
      <Route path="/merchants/:uuid/clients" exact component={MerchantClients} />
      <Route path="/associates" exact component={Associates} />
      <Route path="/accounts" exact component={Accounts} />
      <Route path="/clients/duplicated" exact component={DuplicatedClients} />
      <Route path="/clients/upload-associate-assignments" exact component={UploadAssociateAssignments} />
      <Route path="/stores/list" exact component={Stores} />
      <Route path="/stores/new" exact component={CreateStore} />
      <Route path="/phone-lookup" exact component={PhoneLookup} />
      <Route path="/reports" exact component={Reports} />
      <Route path="/reports/:slug" exact component={ReportDetails} />
      <Route path="/login" exact component={Login} />
    </Switch>
  );
}

export default Router;
