import React, { useCallback, useMemo, useState } from 'react'
import PrimaryLayout from '../../Layout/PrimaryLayout'
import SearchAssociatesModal from './components/SearchAssociatesModal'
import Table from '../../Global/Table'
import usePaginatedRequest from '../../../hooks/usePaginatedRequest'
import CopyToClipboardColumn from '../../Global/Table/components/CopyToClipboardColumn'
import { CheckOutlined } from '@ant-design/icons'
import request from '../../../core/request'

const DEFAULT_SELECTED_MERCHANT = {
  uuid: '',
  name: '',
};

const REQUEST_METHOD = ({ params = {} }) => {
  return request.basic.get(`/associates/getAssociates`, { params })
}

const Associates = () => {
  const PARAMS = useMemo(() => new URLSearchParams(window.location.search), []);
  const DEFAULT_TEXT_SEARCH = useMemo(() => PARAMS.get('search') || '', [PARAMS]);

  const [showModal, setShowModal] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(DEFAULT_SELECTED_MERCHANT);

  const TABLE_COLUMNS = useMemo(() => ([
    {
      title: 'Merchant Name',
      dataIndex: 'merchant_name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Store Name',
      dataIndex: 'store_name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Associate ID',
      dataIndex: 'associate_id',
      ellipsis: true,
    },
    {
      title: 'Associate Name',
      dataIndex: 'associate_name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Pin',
      dataIndex: 'pin',
      ellipsis: true,
      render: (_, item) => (
        <CopyToClipboardColumn
          value={item.pin}
          label={item.pin}
        />
      ),
    },
    {
      title: 'Email',
      dataIndex: 'associate_email',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Password',
      dataIndex: 'associate_password',
      ellipsis: true,
    },
    {
      title: 'Survey Sent',
      dataIndex: 'survey_sent',
      sorter: true,
      ellipsis: true,
      render: value => (Number(value) ? <CheckOutlined /> : null),
    },
    {
      title: 'Message Limit',
      dataIndex: 'message_limit',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Associate UUID' ,
      dataIndex: 'associate_uuid',
      ellipsis: true,
      render: (_, item) => (
        <CopyToClipboardColumn
          value={item.associate_uuid}
          label={item.associate_uuid}
        />
      ),
    },
  ]), []);

  const paginatedRequest = usePaginatedRequest({
    defaultSearch: DEFAULT_TEXT_SEARCH,
    requestMethod: REQUEST_METHOD,
  });

  const handleClickRow = useCallback((record) => {
    setSelectedMerchant({
      uuid: record.merchant_uuid,
      name: record.merchant_name,
    });

    setShowModal(true);
  }, []);


  const tableProps = useMemo(() => ({
    onRow: record => ({
      onClick: () => handleClickRow(record)
    })
  }), [handleClickRow]);

  const handleSearchByMerchant = () => {
    paginatedRequest.setSearch(selectedMerchant.uuid);

    setShowModal(false);
    setSelectedMerchant(DEFAULT_SELECTED_MERCHANT);
  }

  return (
    <PrimaryLayout header="Associates">
      <Table
        {...paginatedRequest}
        tableRowKey="associate_uuid"
        tableColumns={TABLE_COLUMNS}
        tableProps={tableProps}
        exportUri="/associates/export"
      />

      <SearchAssociatesModal
        open={showModal}
        setOpen={setShowModal}
        onSuccess={handleSearchByMerchant}
        merchantName={selectedMerchant.merchant_name}
      />
    </PrimaryLayout>
  );
}

export default Associates;
