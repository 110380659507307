import React from 'react';
import { Button, message, Space } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';

const CopyToClipboardColumn = (props) => {
  const { value, label } = props;

  const handleClick = () => {
    message.success('Copied to clipboard')
  }

  if (!value || !value) {
    return null;
  }

  return (
    <Space>
      <span>{label}</span>

      <CopyToClipboard
        text={value}
        onCopy={handleClick}
      >
        <Button
          type="text"
          size="small"
          icon={<CopyOutlined />}
        />
      </CopyToClipboard>
    </Space>
  );
}

export default CopyToClipboardColumn;
