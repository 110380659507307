import { Button } from 'antd';
import React from 'react';


 
const VersionRefreshNotification = () => {

    return (
        <div className="flex-col-left rem-pad-width p-16">
            <span>Please refresh this page to get the latest version.</span>
            <Button type="primary" style={{padding: '4px'}} onClick={() => window.location.reload()}>Refresh</Button>
        </div>
    )
}
export default VersionRefreshNotification