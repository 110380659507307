import React, { useEffect } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import useGoogleMapsService from '../../../hooks/useGoogleMapsService';
import { Input } from 'antd';
import {
  getInputPropsWithId,
  getLocationDetails,
  getSuggestionItemClassName,
  getSuggestionItemStyles,
} from './helpers';
import './styles.css';

const LocationSearchInput = (props) => {
  const { id, onSelect, ...newProps } = props;

  const {
    loaded,
    loadLibraries,
    getLocationTimezone,
  } = useGoogleMapsService({
    libraries: ['places'],
  });

  const handleSelect = async (_, placeId) => {
    if (onSelect) {
      try {
        const results = await geocodeByPlaceId(placeId);

        if (results.length) {
          const firstResult = results[0];
          const firstResultDetails = await getLocationDetails(firstResult, getLocationTimezone, placeId);

          onSelect(firstResultDetails);
        }
      } catch (error) {
        return onSelect(null);
      }
    }
  };

  useEffect(() => {
    if (!loaded) loadLibraries();
  }, [loaded, loadLibraries]);

  return (
    loaded
      ? <PlacesAutocomplete
        {...newProps}
        onSelect={handleSelect}
        searchOptions={{
          types: ['address'],
        }}
      >
        {({
          getSuggestionItemProps,
          getInputProps,
          suggestions,
          loading
        }) => (
          <React.Fragment>
            <Input
              {...getInputPropsWithId(id, props, getInputProps)}
              placeholder="Type to autocomplete address"
            />

            <div className="autocomplete-dropdown-container">
              { loading ? <div>Loading...</div> : null }

              { suggestions.map((suggestion) => {
                const suggestionItemProps = {
                  ...getSuggestionItemProps(suggestion, {
                    style: getSuggestionItemStyles(suggestion.active),
                    className: getSuggestionItemClassName(suggestion.active),
                  })
                };

                return (
                  <div {...suggestionItemProps} key={suggestion.placeId}>
                    <div>{suggestion.description}</div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </PlacesAutocomplete>
      : null
  );
}

export default LocationSearchInput;
