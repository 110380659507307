import React, { useEffect, useMemo, useState } from 'react';
import { getCountryCallingCode } from 'libphonenumber-js';
import request from '../../../core/request';
import PhoneInput2 from '../PhoneInput2';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const DEFAULT_COUNTRY = 'US';
const GENERIC_ERROR = 'An error has occurred, please try again.'

const getProviderNumbersList = (provider, countryCode, areaCode) => {
  const callingCode = `+${getCountryCallingCode(countryCode)}`;
  const fixedAreaCode = Number(areaCode.replace(callingCode, ''));

  const requestData = {
    function: 'listNumbers',
    areaCode: fixedAreaCode,
    countryCode,
    provider,
  }

  return request.twilio.post('/twiliomigration', requestData);
}

const ProviderNumbersSearchInput = (props) => {
  const {
    provider,
    disabled,
    countryValue,
    onChange,
    onSuccess,
    onError,
    onCountryChange,
    ...rest
  } = props;

  const [countryCode, setCountryCode] = useState(countryValue || DEFAULT_COUNTRY);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const getResults = async () => {
    if (loading || !countryCode || !phoneNumber) return;

    setLoading(true);

    try {
      const response = await getProviderNumbersList(provider, countryCode, phoneNumber);

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (error) {
      if (onError) {
        onError(GENERIC_ERROR);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleChangePhoneNumber = (value) => {
    setPhoneNumber(value);

    if (onChange) {
      onChange(value)
    }
  }

  useEffect(() => {
    setCountryCode(countryValue || DEFAULT_COUNTRY)
  }, [countryValue]);

  useEffect(() => {
    if (onCountryChange) {
      onCountryChange(countryCode)
    }
  }, [onCountryChange, countryCode]);

  const disableButton = useMemo(() => (
    disabled ||loading ||!countryCode ||!phoneNumber
  ), [disabled, loading, countryCode, phoneNumber]);

  return (
    <PhoneInput2
      {...rest}
      type="number"
      onChange={handleChangePhoneNumber}
      countryValue={countryCode}
      onCountryChange={setCountryCode}
      disabled={disabled || loading}
      style={{ flex: 1 }}
    >
      <Button
        type="primary"
        loading={loading}
        onClick={getResults}
        icon={<SearchOutlined/>}
        disabled={disableButton}
      />
    </PhoneInput2>
  );
}

export default ProviderNumbersSearchInput;
