import React from 'react';
import { Typography, Divider } from 'antd';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import ReportsList from './components/List';

const Reports = () => {
  return (
    <PrimaryLayout>
      <div style={{ padding: '12px 50px' }}>
        <Typography.Title level={3}>Reports</Typography.Title>

        <Divider />

        <ReportsList />
      </div>
    </PrimaryLayout>
  );
}

export default Reports;
