import React, { useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import TableHeader from './components/Header';
import TableBody from './components/Body';
import FiltersModal from './components/FiltersModal';

const Table = (props) => {
  const {
    setFilters,
    loading,
    results,
    getResults,
    exportUri,
    showSearch = true,
    showAdvancedFilters = true,
    tableColumns,
    tableBulkActionsModal,
    filtersModalProps,
    filtersModalFormProps,
  } = props;

  const [tableFilters, setTableFilters] = useState({});
  const [modalFilters, setModalFilters] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showBulkActionsModal, setShowBulkActionsModal] = useState(false);

  const showBulkActionsButton = useMemo(() => (
    Boolean(tableBulkActionsModal)
  ), [tableBulkActionsModal]);

  const bulkActionsButtonDisabled = useMemo(() => (
    !selectedRowKeys.length
  ), [selectedRowKeys]);

  const tableFields = useMemo(() => {
    return tableColumns
      .filter(item => item.key !== 'options')
      .map(item => ({
        value: item.dataIndex,
        label: item.title,
      }));
  }, [tableColumns]);

  const handleClearFiltersModal = async (newModalFilters) => {
    message.success('Filters cleared successfully');

    setFilters({ ...tableFilters, ...newModalFilters });
    setModalFilters(newModalFilters);
    setShowFiltersModal(false);
  }

  const handleSuccessFiltersModal = async (newModalFilters) => {
    message.success('Filters applied successfully');

    setFilters({ ...tableFilters, ...newModalFilters });
    setModalFilters(newModalFilters);
    setShowFiltersModal(false);
  }

  useEffect(() => {
    setSelectedRowKeys([])
  }, [results])

  return (
    <div className="cb-filtered-table">
      <TableHeader
        {...props}
        exportUri={exportUri}
        showSearch={showSearch}
        showAdvancedFilters={showAdvancedFilters}
        showBulkActionsButton={showBulkActionsButton}
        bulkActionsButtonDisabled={bulkActionsButtonDisabled}
        onClickFiltersButton={() => setShowFiltersModal(true)}
        onClickBulkActionsButton={() => setShowBulkActionsModal(true)}
      />

      <TableBody
        {...props}
        modalFilters={modalFilters}
        setTableFilters={setTableFilters}
        tableBulkActionsModal={tableBulkActionsModal}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />

      <FiltersModal
        filtersModalProps={filtersModalProps}
        filtersModalFormProps={filtersModalFormProps}
        open={showFiltersModal}
        setOpen={setShowFiltersModal}
        loading={loading}
        fields={tableFields}
        onClear={handleClearFiltersModal}
        onSuccess={handleSuccessFiltersModal}
      />

      {tableBulkActionsModal &&
      tableBulkActionsModal({
        open: showBulkActionsModal,
        setOpen: setShowBulkActionsModal,
        selectedRowKeys: selectedRowKeys,
        onSuccess: () => getResults(),
      })}
    </div>
  );
}

export default Table;
