import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Space, Table, notification } from 'antd';
import { HEADERS } from '../shared/constants';
import request from '../../../../core/request';
import Axios from 'axios';

const UploadAssociateAssignmentsConfirmStep = (props) => {
  const {
    merchant,
    fileList,
    currentStep,
    setCurrentStep,
    setSuccess,
    setNotFound,
    deleteOldAssociatesAssigned,
    associateAssignmentsHeaders,
  } = props;

  const [loading, setLoading] = useState(false);
  const [clientsDuplicateError, setClientsDuplicateError] = useState(false);

  const columns = useMemo(() => Object
    .entries(associateAssignmentsHeaders)
    .map(([key, value]) => ({
      title: HEADERS.find(e => e.value === value).label,
      dataIndex: key,
      key: value,
    })), [associateAssignmentsHeaders]);

  const EXAMPLES = useMemo(() => Array
    .from(Array(1).keys())
    .fill(Object
      .entries(associateAssignmentsHeaders)
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key]: `${HEADERS.find(item => item.value === value).label} example`,
      }), {})), [associateAssignmentsHeaders]);

  const handleGetPresignedUrl = async (file) => {
    const response = await request.basic.put('/files/getPresignedUrl', {
      fileExtension: file.name.split('.').pop(),
      fileType: file.type,
    });

    return response.data;
  }

  const handleUpload = (presignedUrl, file) => {
    const requestConfig = {
      headers: {
        'Content-Type': file.type,
      },
    }

    return Axios.put(presignedUrl, file, requestConfig);
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const rawFile = fileList[0].originFileObj;
      const presignedUrlResponse = await handleGetPresignedUrl(rawFile);

      await handleUpload(presignedUrlResponse.presignedUrl, rawFile);

      const data = {
        associate_assignments_map: associateAssignmentsHeaders,
        associate_assignments_file_name: presignedUrlResponse.fileName,
        merchant_id: merchant.value,
        deleteOldAssociatesAssigned
      }

      const response = await request.basic.post('/associates/uploadAssignments', data);
      if (response.data.error) {
        setClientsDuplicateError(true)
      } else {
        setSuccess(response.data.success);
        setNotFound(response.data.notFound);
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      // HANDLE ERROR
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Alert
        type="warning"
        message="Warning"
        description="Clients that already have an associate assigned will be ignored and some clients or associates may not be found during the sync  process."
        style={{ marginBottom: '20px' }}
        showIcon
      />

      <Table
        dataSource={EXAMPLES}
        columns={columns}
        loading={loading}
        scroll={{ x: 'auto' }}
      />

      {clientsDuplicateError &&
        <Alert
          type="error"
          message="Error uploading"
          description="This file contains multiple assignments per client. Ensure each client is assigned to one associate, then reupload"
          style={{ marginBottom: '20px' }}
          showIcon
        />
      }

      <Space>
        <Button
          disabled={loading}
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </Button>

        <Button
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </Space>
    </>
  );
}

const mapStateToProps = state => state.uploadAssociateAssignments;

const mapDispatchToProps = dispatch => ({
  setCurrentStep(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_CURRENT_STEP',
      payload
    });
  },
  setSuccess(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_SUCCESS',
      payload
    });
  },
  setNotFound(payload) {
    dispatch({
      type: 'uploadAssociateAssignments/SET_NOT_FOUND',
      payload
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadAssociateAssignmentsConfirmStep);
