import React from 'react'
import { Form, message, Modal, Select } from 'antd'
import { useState } from 'react'
import request from '../../../../../../core/request';
import { requiredField } from '../../../../../../utils/validations';

const UPDATE_SUCCESS_MESSAGE = 'Sales actions config setup successfully.';

const INDUSTRY_TYPES = [
  { value: 'default', label: 'Default' },
  { value: 'jewelry', label: 'Jewelry' },
  { value: 'music', label: 'Music' },
];

const SalesActionConfigSetupModal = ({ open, onCancel, onSuccess, licenseKey }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const industryType = Form.useWatch('industry_type', form);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = {
        function: 'salesActionConfigSetup',
        licenseKey,
        industryType,
      };

      await request.twilio.post('/twiliomigration', data);

      message.success(UPDATE_SUCCESS_MESSAGE);

      if (onSuccess) onSuccess()
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
      onCancel();
    }
  }

  return (
    <Modal
      title="Sales action config setup"
      open={open}
      okText="Confirm"
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      okButtonProps={{ disabled: !industryType }}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="industry_type"
          label="Industry type"
          rules={[requiredField('Industry type')]}
        >
          <Select
            placeholder="Enter industry type"
            style={{ width: '100%' }}
            allowClear
          >
            { INDUSTRY_TYPES.map(item => (
              <Select.Option
                key={item.value}
                value={item.value}
              >
                { item.label }
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SalesActionConfigSetupModal;
