import React from 'react';
import { Select } from 'antd';
import COUNTRIES from './countries';

const CountrySelect = (props) => {
    const { handleFilter, ...inputProps } = props;

    return (
        <Select
            {...inputProps}
            filterOption={handleFilter}
            style={{ width: '100%' }}
        >
            { COUNTRIES.map(item => (
                <Select.Option
                    key={item.code}
                    value={item.code}
                >
                    { item.name }
                </Select.Option>
            ))}
        </Select>
    );
}

export default CountrySelect;
