import React, { useCallback, useMemo, useState } from 'react';
import { Layout, Menu, Tooltip } from 'antd';
import {
  UserOutlined,
  VideoCameraOutlined,
  LogoutOutlined,
  HomeOutlined,
  ShopOutlined,
  SearchOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import clientbookLogo from '../../../images/cbfeatherz.svg'
import VersionCheck from '../../Global/VersionCheck';
import { useHistory, useLocation, } from 'react-router-dom';
import '../layout.css';

const getSelectedKeys = (pathname) => {
  return pathname.includes('/reports') ? '/reports'  : pathname
}

const PrimaryLayout = props => {
  const { logout, header, children } =  props;

  const history = useHistory();
  const location = useLocation();

  const defaultOpenKeys = useMemo(() => {
    const split = location.pathname.split('/');

    return split.length > 2 ? [`/${split[1]}`] : [];
  }, [location.pathname]);

  const [selectedKeys, setSelectedKeys] = useState(getSelectedKeys(location.pathname));

  const handleLogout = useCallback(() => {
    logout();
    history.push('/login');
  }, [history, logout]);

  const menuItems = useMemo(() => [
    {
      key: '/',
      icon: <HomeOutlined />,
      label: <Link to='/'>Home</Link>,
    },
    {
      key: '/merchants',
      icon: <VideoCameraOutlined />,
      label: <Link to='/merchants'>Merchants</Link>,
    },
    {
      key: '/associates',
      icon: <UserOutlined />,
      label: <Link to='/associates'>Associates</Link>,
    },
    {
      key: '/accounts',
      icon: <UserOutlined />,
      label: <Link to='/accounts'>Dashboard Accounts</Link>,
    },
    {
      key: '/clients',
      label: 'Clients',
      icon: <UserOutlined />,
      children: [
        {
          key: '/clients/duplicated',
          label: <Link to='/clients/duplicated'>Duplicated Clients</Link>,
        },
        {
          key: '/clients/upload-associate-assignments',
          label: (
            <Tooltip placement="right" title="Upload Associate Assignments">
              <Link to='/clients/upload-associate-assignments'>Upload Associate Assignments</Link>
            </Tooltip>
          ),
        },
      ]
    },
    {
      key: '/stores',
      label: 'Stores',
      icon: <ShopOutlined />,
      children: [
        {
          key: '/stores/list',
          label: <Link to='/stores/list'>List</Link>,
        },
        {
          key: '/stores/new',
          label: <Link to='/stores/new'>Create</Link>,
        },
      ]
    },
    {
      key: '/merchants/new',
      icon: <ShopOutlined />,
      label: <Link to='/merchants/new'>Merchant Setup</Link>,
    },
    {
      key: '/phone-lookup',
      icon: <SearchOutlined />,
      label: <Link to='/phone-lookup'>Phone Lookup</Link>,
    },
    {
      key: '/reports',
      icon: <FileTextOutlined />,
      label: <Link to='/reports'>Reports</Link>,
    },
    {
      key: '/logout',
      icon: <LogoutOutlined />,
      label: 'Log Out',
      onClick: handleLogout,
    },
  ], [handleLogout]);

  return (
    <div className="w-100-vw h-100-vh">
      <Layout className="h-100-P">
        <Layout.Sider className="white ">
          <div className="m-left-24 m-top-24 m-btm-50">
            <img src={clientbookLogo} alt="Clientbook"/>

            <span>Clientbook Console</span>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            items={menuItems}
            selectedKeys={selectedKeys}
            defaultOpenKeys={defaultOpenKeys}
            onClick={item => setSelectedKeys(getSelectedKeys(item.key))}
          />
        </Layout.Sider>

        <Layout style={{ height: "100vh", overflowY: "auto" }}>
          <Layout.Header>
            <span className="white">{header || ''}</span>
          </Layout.Header>

          <Layout.Content className="p-left-15 p-top-15 p-btm-15 p-right-15 rem-pad-width">
            {children || ''}
          </Layout.Content>
        </Layout>
      </Layout>

      <VersionCheck />
    </div>
  );
};

const mapStateToProps = state => state.auth;

const mapDispatchToProps = dispatch => ({
  logout() {
    localStorage.clear();

    dispatch({
      type: 'SET_JWT_TOKEN',
      payload: ''
    });

    dispatch({
      type: 'SET_API_TOKEN',
      payload: ''
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryLayout);
