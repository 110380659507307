import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Modal, notification, Skeleton, Spin } from "antd";
import request from "../../../../core/request";
import Axios from "axios";

const MobileNumberValidatorModal = ({ open, closeModal, selectedMerchant }) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (open && !loading) {
      setLoading(true);
      checkClientCount();
    }
    if (!open) {
      setLoading(false);
    }
    return () => {
      setLoading(false);
      setCount(0);
    };
  }, [open]);

  const checkClientCount = async () => {
    try {
      const params = { params: { merchantId: selectedMerchant.id } };
      const resp = await request.basic.get("/clients/getMobileClientCount", params);

      setCount(resp?.data?.cnt || 0);
    } catch (e) {
      //err handle
    } finally {
      setLoading(false);
    }
  };

  const wrapItUp = () => {
    setLoading(false);
    setCount(0);
    closeModal();
  };

  const sendMobileInvalidatorRequest = async () => {
    setLoading(true);

    try {

        const twilioReqData = {
          function: 'mobileInvalidator',
          licenseKey: selectedMerchant.license_key,
          env: process.env.REACT_APP_API_ENV,
        }

        await request.twilio.post(`/twiliomigration`, twilioReqData);
        wrapItUp();

    } catch (error) {
      notification.error({ message: "Error with Mobile Validation request" });
      wrapItUp();
    }
  };

  return (
    <Modal
      title="Run Mobile Validator"
      open={open}
      onCancel={closeModal}
      footer={null}
    >
      {!loading ? (
        <div>
          <p>License Key <b>{selectedMerchant.license_key}</b> has <b>{count}</b> clients with mobile numbers.</p>
          <p>When a retailer onboards, it is up to sales to collect fees for this phone number validation. Therefore, CS doesn't need to worry about any fees for newly signed up customers. If the customer wants this run again down the road, then please see the below explanation.</p>
          <p>If an existing retailer wants this run for all of their numbers, the cost to them will be <b>${(count * 0.02).toFixed(2)}</b>. Have you confirmed these charges with the client and added them to billing?</p>
          <br />
          <Button
            size="large"
            type="primary"
            onClick={sendMobileInvalidatorRequest}
            style={{ marginBottom: "16px" }}
            block
          >
            Start Mobile Validation
          </Button>

          <Button size="large" type={"danger"} onClick={wrapItUp} block>
            Cancel
          </Button>
        </div>
      ) : (
        <div>
          <div style={{ height: "40px", marginBottom: "16px", marginTop: "15px" }}>
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
          </div>
          <div style={{ height: "20px", width: "100%" }}>
            <Skeleton active />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MobileNumberValidatorModal;
