import React, { useState } from 'react'
import { Button, Form, Input, message, Select, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useParams } from 'react-router';
import request from '../../../../../core/request';
import POS_TYPES from '../../../../../shared/constants/pos-types';
import { requiredField } from '../../../../../utils/validations';

const UPDATE_SUCCESS_MESSAGE = 'Account updated successfully.';

const GeneralInformation = ({ merchant }) => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const posType = Form.useWatch('pos_type', form);
  const posRefNum = Form.useWatch('pos_ref_num', form);
  const shopify_url = Form.useWatch('shopify_url', form);
  const shopify_admin_token = Form.useWatch('shopify_admin_token', form);
  const import_complete_ts = Form.useWatch('import_complete_ts', form);

  const shopifyFields = () => {
    if (posType === 'SHOPIFY') {
      return (
        <>
          <Form.Item
            name="shopify_url"
            initialValue={merchant?.store_url}
            label="Shopify URL"
          >
            <Input
              placeholder="Enter the Shopify Store URL"
            />
          </Form.Item>

          <Form.Item
            name="shopify_admin_token"
            initialValue={merchant?.admin_token}
            label="Shopify Admin Token"
          >
            <Input
              placeholder="Enter the Shopify Admin Token"
            />
          </Form.Item>
        </>
      )
    }
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = {
        merchant_id: merchant.id,
        uuid,
        pos_type: posType,
        pos_ref_num: posType ? posRefNum : null,
        shopify_url: posType === 'SHOPIFY' ? shopify_url : null,
        shopify_admin_token: posType === 'SHOPIFY' ? shopify_admin_token : null,
        import_complete_ts: form.isFieldTouched('import_complete_ts') ? Boolean(import_complete_ts) : null
      };

      await request.basic.put(`/merchants/updateMerchant`, data);

      message.success(UPDATE_SUCCESS_MESSAGE);
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
    }
  }

  if (!merchant) {
    return <div>Loading...</div>
  }

  return (
    merchant && <Form
      form={form}
      layout="vertical"
      disabled={loading}
      onFinish={handleSubmit}
      style={{ maxWidth: '450px' }}
    >
      <Form.Item
        name="pos_type"
        initialValue={merchant?.pos_type}
        label="POS type"
      >
        <Select
          placeholder="Select POS type or leave empty"
          style={{ width: '100%' }}
          allowClear
        >
          { POS_TYPES.map(posType => (
            <Select.Option
              key={posType.value}
              value={posType.value}
            >
              { posType.label }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="pos_ref_num"
        initialValue={merchant?.pos_ref_num}
        label="POS ref number"
        rules={posType ? [requiredField('pos_ref_num')] : undefined}
      >
        <Input
          placeholder="Enter the new POS ref number"
          disabled={!posType}
        />
      </Form.Item>
      {shopifyFields()}
      <Form.Item
        name="import_complete_ts"
        initialValue={merchant?.import_complete_ts}
        valuePropName="checked"
        label={(
          <>
            POS import complete
            <Tooltip
              placement='bottom'
              overlayInnerStyle={{
                width: '380px'
              }}
              title={(
                <>
                This toggle must be on for new clients to be assigned when they make their first purchase.<br /><br />

                Make sure all associates have been mapped correctly and that clients, products and sales have all been imported before turning this on.<br /><br />

                When enabled, this will trigger a job to run that looks for unassigned clients and assigns them based on sales history to the mapped associate that sold them the most total dollars.<br /><br />

                Turning this toggle off will stop clients from being assigned with their first purchase. Turning it off and then on again will trigger the job to run again.

                </>
              )}
            >
              <QuestionCircleOutlined style={{marginLeft: '8px'}}/>
            </Tooltip>
          </>
        )}
      >
        <Switch  />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}

export default GeneralInformation;
