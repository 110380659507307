import React from 'react';
import { connect } from 'react-redux';
import UploadAssociateAssignmentsUploadStep from '../components/UploadStep';
import UploadAssociateAssignmentsEditStep from '../components/EditStep';
import UploadAssociateAssignmentsConfirmStep from '../components/ConfirmStep';
import UploadAssociateAssignmentsStatusStep from '../components/StatusStep';

const COMPONENTS = [
  <UploadAssociateAssignmentsUploadStep />,
  <UploadAssociateAssignmentsEditStep />,
  <UploadAssociateAssignmentsConfirmStep />,
  <UploadAssociateAssignmentsStatusStep />,
];

const UploadAssociateAssignmentsBody = ({ currentStep }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      {COMPONENTS[currentStep]}
    </div>
  );
}

const mapStateToProps = state => state.uploadAssociateAssignments;

export default connect(mapStateToProps, {})(UploadAssociateAssignmentsBody);
