import React from 'react';
import { connect } from 'react-redux';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import './styles.css';

const UploadAssociateAssignmentsStatusStep = (props) => {
  const { success, notFound } = props;

  return (
    <div className="cb-upload-associate-assignments__status">
      <Row gutter={16}>
        <Col span={12}>
          <Card bordered={false}>
            <CheckCircleOutlined style={{ color: '#52c41a' }} />
            <p>{success} Success</p>
          </Card>
        </Col>

        <Col span={12}>
          <Card bordered={false}>
            <WarningOutlined style={{ color: '#faad14' }} />
            <p>{notFound} Not Found</p>
          </Card>
        </Col>
      </Row>

      <Link to="/">
        <Button type="primary" success>
          Finish
        </Button>
      </Link>
    </div>
  );
}

const mapStateToProps = state => state.uploadAssociateAssignments;

export default connect(mapStateToProps, {})(UploadAssociateAssignmentsStatusStep);
