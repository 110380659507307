import React from 'react';
import { Button, Checkbox, Select } from 'antd';
import MerchantSelectInput from '../../../../Global/MerchantSelectInput';
import './styles.css';

const DuplicatedClientsHeader = (props) => {
  const {
    loading,
    includeArchived,
    setIncludeArchived,
    searchClients,
    selectedMerchant,
    setSelectedMerchant,
    onClearMerchant,
    selectedDuplicatedBy,
    setSelectedDuplicatedBy,
  } = props;

  return (
    <div className="cb-duplicated-clients__header">
      <div className="cb-duplicated-clients__header-left">
        <MerchantSelectInput
          size="large"
          loading={loading}
          onChange={setSelectedMerchant}
          placeholder="Select a Merchant"
          valueKey="uuid"
          labelKey="name"
          onClear={onClearMerchant}
          className="cb-duplicated-clients__search-input"
        />
        <Select
          size="large"
          onChange={(option) => setSelectedDuplicatedBy(option.value)}
          value={selectedDuplicatedBy}
          options={[
            {
              label: 'Duplicate Names',
              value: 'name',
            },
            {
              label: 'Duplicate Emails',
              value: 'email',
            },
            {
              label: 'Duplicate Phone Numbers',
              value: 'mobile',
            },
          ]}
          labelInValue
        />
        <Checkbox
          checked={includeArchived}
          onChange={() => setIncludeArchived(!includeArchived)}
        >
          Include Archived Clients
        </Checkbox>
      </div>

      <Button
        size="large"
        type="primary"
        loading={loading}
        onClick={searchClients}
        disabled={!selectedMerchant?.value}
      >
        Search
      </Button>
    </div>
  );
};

export default DuplicatedClientsHeader;
