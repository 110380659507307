import React from 'react';

 
const SatisfactionCard = (props) => {
    const SVGImage = props.image;
    const calcValue = props.calcValue;
    const allFeedback = props.allFeedback;

    const calcFeedbackPerc = () => {
        const filterList = allFeedback.filter(afe => afe.answer === props.calcValue);
        if (!filterList.length && !allFeedback.length) return "-";
        return `${((filterList.length / allFeedback.length) * 100).toFixed(2)}%`;
    }

    const calcFeedbackFraction = () => {
        const filterList = allFeedback.filter(afe => afe.answer === props.calcValue);
        return `${filterList.length}/${allFeedback.length}`
    }

    return (
        <div className="flex-row-wrap bg-white h-104 p-left-15 p-right-15 rem-pad-width" style={{justifyContent: "space-between", width: '32%', alignContent: 'center'}}>
            <div className="flex-row-wrap">
                <div className="m-right-15">
                    <SVGImage className=""/>
                </div>
                <div className="flex-col-center" style={{alignItems: "flex-start", justifyContent: 'center'}}>
                    <span>{props.type}</span>
                    <span className="fs-20 fw-700">{calcFeedbackPerc()}</span>
                </div>
            </div>
            <div className="flex-col-center" style={{alignItems: "flex-end", justifyContent: 'center'}}>
                <span>{calcFeedbackFraction()}</span>
                <span>responses</span>
            </div>
        </div>
    )
}
export default SatisfactionCard