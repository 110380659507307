import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PrimaryLayout from '../../../../Layout/PrimaryLayout';
import { useHistory } from 'react-router-dom';
import { REPORTS } from '../../shared/constants';
import request from '../../../../../core/request';
import ReportDetailsHeader from './components/Header';
import usePaginatedRequest from '../../../../../hooks/usePaginatedRequest';
import Table from '../../../../Global/Table';
import { Divider } from 'antd';
import { endOfDay, format, startOfMonth, subMonths } from 'date-fns';

const DATE_RANGE_FORMAT = 'yyyyMMddhhmmss';

const ReportDetails = () => {
  const [rangeType, setRangeType] = useState(0);
  const [firstSearch, setFirstSearch] = useState(false);

  const history = useHistory();

  const report = useMemo(() => REPORTS[history.location.pathname], [history.location.pathname]);

  const dateRange = useMemo(() => {
    const today = new Date();

    const startDate = startOfMonth(today);
    const endDate = endOfDay(today);

    return [
      Number(format(subMonths(startDate, rangeType), DATE_RANGE_FORMAT)),
      Number(format(endDate, DATE_RANGE_FORMAT)),
    ];
  }, [rangeType]);

  const defaultFilters = useMemo(() => report.showRangeSelector ? {
    started_date_time: {
      $gte: dateRange[0],
      $lte: dateRange[1],
    }
  } : {}, [report.showRangeSelector, dateRange]);

  const requestMethod = useCallback(({ params }) => {
    return request.basic.get(report.endpoint, {
      params: { ...params },
    });
  }, [report.endpoint]);

  const paginatedRequest = usePaginatedRequest({
    defaultFilters,
    requestMethod,
    automaticRequest: false,
  });

  const {
    loading,
    page,
    total,
    pageSize,
    setFilters,
    getResults,
  } = paginatedRequest;

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
    showSizeChanger: true,
  }), [page, pageSize, total]);

  const handleSubmit = () => {
    getResults();
    setFirstSearch(true);
  }

  useEffect(() => {
    setFilters(defaultFilters);
  }, [setFilters, defaultFilters]);

  return (
    <PrimaryLayout>
      <ReportDetailsHeader
        report={report}
        loading={loading}
        rangeType={rangeType}
        setRangeType={setRangeType}
        onSubmit={handleSubmit}
      />

      {firstSearch &&
      <div style={{ padding: '10px 50px' }}>
        <Divider />

        <Table
          {...paginatedRequest}
          tableRowKey="id"
          tableColumns={report.columns}
          pagination={pagination}
          disabled={!firstSearch}
          exportUri={report.exportEndpoint}
          showAdvancedFilters={false}
        />
      </div>}
    </PrimaryLayout>
  );
}

export default ReportDetails;
