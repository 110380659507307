import React from 'react';
import { Alert, Button, Space } from 'antd';

const AllRowsSelectAlert = (props) => {
  const {
    loading,
    rowsLength,
    onClickSelect,
    onClickUnselect
  } = props;

  return (
    <Alert
      message="All rows selected"
      description={`${rowsLength} rows have been selected. Would you like to select only the rows on this page?`}
      type="info"
      showIcon
      style={{ marginBottom: '20px' }}
      action={
        <Space>
          <Button
            type="primary"
            loading={loading}
            onClick={onClickSelect}
          >
            Select page rows
          </Button>

          <Button
            type="primary"
            loading={loading}
            onClick={onClickUnselect}
          >
            Unselect all
          </Button>
        </Space>
      }
    />
  );
}

export default AllRowsSelectAlert;
