import { createStore, applyMiddleware, combineReducers } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import auth from '../reducers/auth';
import ratings from '../reducers/ratings';
import stores from '../reducers/stores';
import table from '../reducers/table';
import uploadAssociateAssignments from '../reducers/uploadAssociateAssignments';

export default createStore(
  combineReducers ({
    auth,
    ratings,
    stores,
    table,
    uploadAssociateAssignments,
  }),
  applyMiddleware(promiseMiddleware),
);
