import React, { useCallback } from 'react'
import { Form, Modal } from 'antd';
import FiltersModalFooter from './components/Footer';
import FiltersModalForm from './components/Form';

const FiltersModal = (props) => {
  const {
    open,
    setOpen,
    loading,
    fields,
    onClear,
    onSuccess,
    filtersModalProps,
    filtersModalFormProps,
  } = props;

  const [form] = Form.useForm();

  const handleClear = useCallback(() => {
    form.resetFields();
    onClear({});
  }, [form, onClear])

  const handleSuccess = useCallback(() => {
    const filters = {}
    const fieldsValue = form.getFieldsValue();

    Object
      .entries(fieldsValue)
      .forEach(([key, value]) => {
        if (value?.fields?.length && value?.value) {
          value.fields.forEach(field => {
            filters[field] = {
              [key]: value.value,
            };
          });
        }
      });

    onSuccess(filters);
  }, [form, onSuccess]);

  return (
    <Modal
      {...filtersModalProps}
      title="Filters"
      open={open}
      onCancel={() => setOpen(false)}
      footer={(
        <FiltersModalFooter
          loading={loading}
          onClear={handleClear}
          onCancel={() => setOpen(false)}
          onSuccess={handleSuccess}
        />
      )}
    >
      <FiltersModalForm
        {...filtersModalFormProps}
        form={form}
        fields={fields}
      />
    </Modal>
  );
}

export default FiltersModal;
