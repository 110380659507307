import React from 'react';
import CountrySelect from './CountrySelect';
import StateSelect from './StateSelect';

const LocationSelectInput = (props) => {
    const { locationType, ...otherProps } = props;

    const handleFilter = (input, option) => (
        option
            .children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0 ||
        option
            .value
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
    );

    return (
        locationType === 'country'
            ? <CountrySelect
                {...otherProps}
                handleFilter={handleFilter}
            />
            : <StateSelect
                {...otherProps}
                handleFilter={handleFilter}
            />
    );
}

export default LocationSelectInput;
