import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import TIMEZONES from './timezones';

const TimezoneSelectInput = (props) => {
    const [timezoneGroups, setTimezoneGroups] = useState([...TIMEZONES]);

    const {
        timezone,
        handleFilter,
        ...inputProps
    } = props;

    const findTimezones = useCallback((item) => {
        return item.match(new RegExp(timezone, 'g'));
    }, [timezone]);

    const mapTimezones = useCallback((item) => {
        return item.zones.map(zone => zone.match(new RegExp(timezone, 'g')));
    }, [timezone]);

    useEffect(() => {
        if (timezone) {
            const newTimezoneGroups = TIMEZONES.filter(item => item.zones.some(zone => findTimezones(zone)));
            const newTimezones = newTimezoneGroups.map(item => mapTimezones(item));

            setTimezoneGroups(newTimezones)
        } else {
            setTimezoneGroups([...TIMEZONES])
        }
    }, [findTimezones, mapTimezones, timezone]);

    return (
        <Select
            {...inputProps}
            filterOption={handleFilter}
            style={{ width: '100%' }}
        >
            { timezoneGroups
                .map(item => (
                    <Select.OptGroup key={item.group} label={item.group}>
                        { item.zones.map(zone => (
                            <Select.Option
                                key={zone}
                                value={zone}
                            >
                                { zone }
                            </Select.Option>
                        ))
                        }
                    </Select.OptGroup>
                ))
            }
        </Select>
    );
}

export default TimezoneSelectInput;
