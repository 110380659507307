export const FORM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 18 },
  },
};

export const STEPS = [
  { title: 'Ready For Account Creation', status: 'progress' },
  { title: 'Merchant Type', status: 'wait' },
  { title: 'Account Information', status: 'wait' },
  { title: 'Owner Information', status: 'wait' },
  { title: 'Store Location', status: 'wait' },
];
