import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useState } from 'react';
import usePaginatedRequest from '../../../hooks/usePaginatedRequest';
import useDebounce from '../../../hooks/useDebounce';

const DEFAULT_FILTERS = {}
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PLACEHOLDER = 'Type to search';

const getLabelValue = (labelKey, item) => {
  if (Array.isArray(labelKey)) {
    return labelKey.map(key => item[key] || '').join(' ');
  }

  return item[labelKey]
}

const AsyncPaginatedSelect = props => {
  const {
    value,
    filters,
    onChange,
    pageSize,
    valueKey,
    labelKey,
    placeholder,
    requestMethod,
    onListChange,
    ...otherProps
  } = props;

  const [currentValue, setCurrentValue] = useState(value);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearch = useDebounce(searchValue);

  const {
    total,
    page,
    setPage,
    loading,
    results,
    setSearch,
    setFilters,
    setPageSize,
  } = usePaginatedRequest({
    defaultFilters: filters || DEFAULT_FILTERS,
    defaultPageSize: pageSize || DEFAULT_PAGE_SIZE,
    mergeResults: true,
    requestMethod,
  });

  const handleChange = newValue => {
    setCurrentValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }

  const handleScroll = async (event) => {
    const target = event.target;

    if (
      !loading &&
      options.length &&
      options.length < total &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setPage(page + 1);
      target.scrollTo(0, target.scrollHeight);
    }
  };

  useEffect(() => {
    setFilters(filters);
  }, [setFilters, filters]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [setPageSize, pageSize]);

  useEffect(() => {
    if (!currentValue || debouncedSearch) {
      setSearch(debouncedSearch);
    }
  }, [setSearch, currentValue, debouncedSearch]);

  useEffect(() => {
    const stringifiedOptions = results
      .map(item => JSON.stringify({
        value: item[valueKey],
        label: getLabelValue(labelKey, item),
      }));

    if (currentValue) {
      stringifiedOptions.push(JSON.stringify({
        value: currentValue.value,
        label: currentValue.label,
      }));
    }

    const newOptions = [...new Set(stringifiedOptions)].map(JSON.parse);

    setOptions(newOptions);
  }, [results, currentValue, labelKey, valueKey]);

  useEffect(() => {
    if (onListChange) {
      onListChange(results);
    }
  }, [onListChange, results]);

  return (
    <Select
      {...otherProps}
      value={currentValue}
      loading={loading || otherProps.loading}
      filterOption={false}
      searchValue={searchValue}
      placeholder={placeholder || DEFAULT_PLACEHOLDER}
      onChange={handleChange}
      onSearch={setSearchValue}
      onPopupScroll={handleScroll}
      labelInValue
      allowClear
      showSearch
    >
      {options.map(item => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}

      {loading && (
        <Select.Option disabled>
          <h4>Loading...</h4>
        </Select.Option>
      )}

      {!loading && options.length && options.length === total && (
        <Select.Option disabled>
          <h4>No more elements</h4>
        </Select.Option>
      )}
    </Select>
  );
}

export default AsyncPaginatedSelect;
