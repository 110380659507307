import React, { useCallback, useMemo, useState } from 'react'
import { Alert, Button, Space, Table, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
import usePaginatedRequest from '../../../../../hooks/usePaginatedRequest';
import { useParams } from 'react-router';
import request from '../../../../../core/request';
import CreateSalesActionConfigModal from './components/CreateSalesActionConfigModal';
// import EditSalesActionConfigModal from './components/EditSalesActionConfigModal';
import DeleteSalesActionConfigModal from './components/DeleteSalesActionConfigModal';
import SalesActionConfigSetupModal from './components/SalesActionConfigSetupModal';

// const DEFAULT_EDIT_TOOLTIP = 'Edit sales action config';

const DEFAULT_DELETE_TOOLTIP = 'Delete sales action config';

const SALES_TRIGGER_ACTION_ASSOCIATED_TOOLTIP = 'Sales action config associated with sales trigger action. It\'s not possible to modify';

const SalesActionConfigs = (props) => {
  const { uuid } = useParams()
  const { merchant, showCreateModal, setShowCreateModal } = props;
  const [showSetupModal, setShowSetupModal] = useState(false);
  // const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSalesActionConfig, setSelectedSalesActionConfig] = useState(null);

  const requestMethod = useCallback(({ params }) => {
    return request.basic.get('/merchants/getSalesActionsConfigs', {
      params: { ...params, merchantUuid: uuid },
    });
  }, [uuid]);

  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    total,
    results,
    loading,
    getResults,
  } = usePaginatedRequest({
    requestMethod,
  });

  // const handleOpenEditModal = (item) => {
  //   setSelectedSalesActionConfig(item);
  //   setShowEditModal(true);
  // }

  // const handleCloseEditModal = () => {
  //   setShowEditModal(false);

  //   setTimeout(() => {
  //     setSelectedSalesActionConfig(null);
  //   }, 500);
  // }

  const handleOpenDeleteModal = (item) => {
    setSelectedSalesActionConfig(item);
    setShowDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);

    setTimeout(() => {
      setSelectedSalesActionConfig(null);
    }, 500);
  }

  const columns = useMemo(() => [
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Options',
      key: 'options',
      render: (_, item) => {
        const disabled = item.sales_trigger_actions_count > 0;

        // const editTooltipText = disabled
        //   ? SALES_TRIGGER_ACTION_ASSOCIATED_TOOLTIP
        //   : DEFAULT_EDIT_TOOLTIP;

        const deleteTooltipText = disabled
          ? SALES_TRIGGER_ACTION_ASSOCIATED_TOOLTIP
          : DEFAULT_DELETE_TOOLTIP;

        return (
          <Space>
            {/* <Tooltip title={editTooltipText}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => handleOpenEditModal(item)}
                disabled={disabled}
                ghost
              />
            </Tooltip> */}

            <Tooltip title={deleteTooltipText}>
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => handleOpenDeleteModal(item)}
                disabled={disabled}
                ghost
              />
            </Tooltip>
          </Space>
        );
      }
    },
  ], []);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
    showSizeChanger: true,
  }), [page, pageSize, total]);

  const handleChangePagination = useCallback((value) => {
    if (value.current !== pagination.page) {
      setPage(value.current)
    }

    if (value.pageSize !== pagination.pageSize) {
      setPageSize(value.pageSize)
    }
  }, [
      pagination.page,
      pagination.pageSize,
      setPage,
      setPageSize,
    ],
  );

  return (
    <>
      {!loading &&
      !results.length &&
      <Alert
        type="info"
        message="Empty results"
        description="You can autofill sales action configs based on industry type. Click continue to start the setup."
        style={{ marginBottom: '20px' }}
        action={
          <Button
            type="primary"
            ghost
            onClick={() => setShowSetupModal(true)}
          >
            Continue
          </Button>
        }
        showIcon
      />}

      <Table
        rowKey={r => r.id}
        dataSource={results}
        loading={loading}
        pagination={pagination}
        columns={columns}
        onChange={handleChangePagination}
        scroll={{ x: 'auto' }}
      />

      <CreateSalesActionConfigModal
        open={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onSuccess={() => getResults(true)}
      />

      <SalesActionConfigSetupModal
        open={showSetupModal}
        onCancel={() => setShowSetupModal(false)}
        onSuccess={() => getResults(true)}
        licenseKey={merchant?.license_key}
      />

      {/* {
        selectedSalesActionConfig &&
        <EditSalesActionConfigModal
          open={showEditModal}
          onCancel={handleCloseEditModal}
          onSuccess={() => getResults(true)}
          salesActionConfig={selectedSalesActionConfig}
        />
      } */}

      {
        selectedSalesActionConfig &&
        <DeleteSalesActionConfigModal
          open={showDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSuccess={() => getResults(true)}
          salesActionConfig={selectedSalesActionConfig}
        />
      }
    </>
  );
}

export default SalesActionConfigs;
