const fetchFileFromUrl = async (url) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const temp = URL.createObjectURL(blob);
  const element = document.createElement("a");

  element.href = temp;

  document.body.appendChild(element);

  element.click();

  URL.revokeObjectURL(temp);

  element.remove();
}

export default fetchFileFromUrl;
