const STATES = [
  {
      name: "Badakhshan",
      code: "BDS",
      country_code: "AF",
  },
  {
      name: "Badghis",
      code: "BDG",
      country_code: "AF",
  },
  {
      name: "Baghlan",
      code: "BGL",
      country_code: "AF",
  },
  {
      name: "Balkh",
      code: "BAL",
      country_code: "AF",
  },
  {
      name: "Bamyan",
      code: "BAM",
      country_code: "AF",
  },
  {
      name: "Daykundi",
      code: "DAY",
      country_code: "AF",
  },
  {
      name: "Farah",
      code: "FRA",
      country_code: "AF",
  },
  {
      name: "Faryab",
      code: "FYB",
      country_code: "AF",
  },
  {
      name: "Ghazni",
      code: "GHA",
      country_code: "AF",
  },
  {
      name: "Ghōr",
      code: "GHO",
      country_code: "AF",
  },
  {
      name: "Helmand",
      code: "HEL",
      country_code: "AF",
  },
  {
      name: "Herat",
      code: "HER",
      country_code: "AF",
  },
  {
      name: "Jowzjan",
      code: "JOW",
      country_code: "AF",
  },
  {
      name: "Kabul",
      code: "KAB",
      country_code: "AF",
  },
  {
      name: "Kandahar",
      code: "KAN",
      country_code: "AF",
  },
  {
      name: "Kapisa",
      code: "KAP",
      country_code: "AF",
  },
  {
      name: "Khost",
      code: "KHO",
      country_code: "AF",
  },
  {
      name: "Kunar",
      code: "KNR",
      country_code: "AF",
  },
  {
      name: "Kunduz Province",
      code: "KDZ",
      country_code: "AF",
  },
  {
      name: "Laghman",
      code: "LAG",
      country_code: "AF",
  },
  {
      name: "Logar",
      code: "LOG",
      country_code: "AF",
  },
  {
      name: "Nangarhar",
      code: "NAN",
      country_code: "AF",
  },
  {
      name: "Nimruz",
      code: "NIM",
      country_code: "AF",
  },
  {
      name: "Nuristan",
      code: "NUR",
      country_code: "AF",
  },
  {
      name: "Paktia",
      code: "PIA",
      country_code: "AF",
  },
  {
      name: "Paktika",
      code: "PKA",
      country_code: "AF",
  },
  {
      name: "Panjshir",
      code: "PAN",
      country_code: "AF",
  },
  {
      name: "Parwan",
      code: "PAR",
      country_code: "AF",
  },
  {
      name: "Samangan",
      code: "SAM",
      country_code: "AF",
  },
  {
      name: "Sar-e Pol",
      code: "SAR",
      country_code: "AF",
  },
  {
      name: "Takhar",
      code: "TAK",
      country_code: "AF",
  },
  {
      name: "Urozgan",
      code: "URU",
      country_code: "AF",
  },
  {
      name: "Zabul",
      code: "ZAB",
      country_code: "AF",
  },
  {
      name: "Berat County",
      code: "01",
      country_code: "AL",
  },
  {
      name: "Berat District",
      code: "BR",
      country_code: "AL",
  },
  {
      name: "Bulqizë District",
      code: "BU",
      country_code: "AL",
  },
  {
      name: "Delvinë District",
      code: "DL",
      country_code: "AL",
  },
  {
      name: "Devoll District",
      code: "DV",
      country_code: "AL",
  },
  {
      name: "Dibër County",
      code: "09",
      country_code: "AL",
  },
  {
      name: "Dibër District",
      code: "DI",
      country_code: "AL",
  },
  {
      name: "Durrës County",
      code: "02",
      country_code: "AL",
  },
  {
      name: "Durrës District",
      code: "DR",
      country_code: "AL",
  },
  {
      name: "Elbasan County",
      code: "03",
      country_code: "AL",
  },
  {
      name: "Fier County",
      code: "04",
      country_code: "AL",
  },
  {
      name: "Fier District",
      code: "FR",
      country_code: "AL",
  },
  {
      name: "Gjirokastër County",
      code: "05",
      country_code: "AL",
  },
  {
      name: "Gjirokastër District",
      code: "GJ",
      country_code: "AL",
  },
  {
      name: "Gramsh District",
      code: "GR",
      country_code: "AL",
  },
  {
      name: "Has District",
      code: "HA",
      country_code: "AL",
  },
  {
      name: "Kavajë District",
      code: "KA",
      country_code: "AL",
  },
  {
      name: "Kolonjë District",
      code: "ER",
      country_code: "AL",
  },
  {
      name: "Korçë County",
      code: "06",
      country_code: "AL",
  },
  {
      name: "Korçë District",
      code: "KO",
      country_code: "AL",
  },
  {
      name: "Krujë District",
      code: "KR",
      country_code: "AL",
  },
  {
      name: "Kuçovë District",
      code: "KC",
      country_code: "AL",
  },
  {
      name: "Kukës County",
      code: "07",
      country_code: "AL",
  },
  {
      name: "Kukës District",
      code: "KU",
      country_code: "AL",
  },
  {
      name: "Kurbin District",
      code: "KB",
      country_code: "AL",
  },
  {
      name: "Lezhë County",
      code: "08",
      country_code: "AL",
  },
  {
      name: "Lezhë District",
      code: "LE",
      country_code: "AL",
  },
  {
      name: "Librazhd District",
      code: "LB",
      country_code: "AL",
  },
  {
      name: "Lushnjë District",
      code: "LU",
      country_code: "AL",
  },
  {
      name: "Malësi e Madhe District",
      code: "MM",
      country_code: "AL",
  },
  {
      name: "Mallakastër District",
      code: "MK",
      country_code: "AL",
  },
  {
      name: "Mat District",
      code: "MT",
      country_code: "AL",
  },
  {
      name: "Mirditë District",
      code: "MR",
      country_code: "AL",
  },
  {
      name: "Peqin District",
      code: "PQ",
      country_code: "AL",
  },
  {
      name: "Përmet District",
      code: "PR",
      country_code: "AL",
  },
  {
      name: "Pogradec District",
      code: "PG",
      country_code: "AL",
  },
  {
      name: "Pukë District",
      code: "PU",
      country_code: "AL",
  },
  {
      name: "Sarandë District",
      code: "SR",
      country_code: "AL",
  },
  {
      name: "Shkodër County",
      code: "10",
      country_code: "AL",
  },
  {
      name: "Shkodër District",
      code: "SH",
      country_code: "AL",
  },
  {
      name: "Skrapar District",
      code: "SK",
      country_code: "AL",
  },
  {
      name: "Tepelenë District",
      code: "TE",
      country_code: "AL",
  },
  {
      name: "Tirana County",
      code: "11",
      country_code: "AL",
  },
  {
      name: "Tirana District",
      code: "TR",
      country_code: "AL",
  },
  {
      name: "Tropojë District",
      code: "TP",
      country_code: "AL",
  },
  {
      name: "Vlorë County",
      code: "12",
      country_code: "AL",
  },
  {
      name: "Vlorë District",
      code: "VL",
      country_code: "AL",
  },
  {
      name: "Adrar",
      code: "01",
      country_code: "DZ",
  },
  {
      name: "Aïn Defla",
      code: "44",
      country_code: "DZ",
  },
  {
      name: "Aïn Témouchent",
      code: "46",
      country_code: "DZ",
  },
  {
      name: "Algiers",
      code: "16",
      country_code: "DZ",
  },
  {
      name: "Annaba",
      code: "23",
      country_code: "DZ",
  },
  {
      name: "Batna",
      code: "05",
      country_code: "DZ",
  },
  {
      name: "Béchar",
      code: "08",
      country_code: "DZ",
  },
  {
      name: "Béjaïa",
      code: "06",
      country_code: "DZ",
  },
  {
      name: "Béni Abbès",
      code: "53",
      country_code: "DZ",
  },
  {
      name: "Biskra",
      code: "07",
      country_code: "DZ",
  },
  {
      name: "Blida",
      code: "09",
      country_code: "DZ",
  },
  {
      name: "Bordj Baji Mokhtar",
      code: "52",
      country_code: "DZ",
  },
  {
      name: "Bordj Bou Arréridj",
      code: "34",
      country_code: "DZ",
  },
  {
      name: "Bouïra",
      code: "10",
      country_code: "DZ",
  },
  {
      name: "Boumerdès",
      code: "35",
      country_code: "DZ",
  },
  {
      name: "Chlef",
      code: "02",
      country_code: "DZ",
  },
  {
      name: "Constantine",
      code: "25",
      country_code: "DZ",
  },
  {
      name: "Djanet",
      code: "56",
      country_code: "DZ",
  },
  {
      name: "Djelfa",
      code: "17",
      country_code: "DZ",
  },
  {
      name: "El Bayadh",
      code: "32",
      country_code: "DZ",
  },
  {
      name: "El M'ghair",
      code: "49",
      country_code: "DZ",
  },
  {
      name: "El Menia",
      code: "50",
      country_code: "DZ",
  },
  {
      name: "El Oued",
      code: "39",
      country_code: "DZ",
  },
  {
      name: "El Tarf",
      code: "36",
      country_code: "DZ",
  },
  {
      name: "Ghardaïa",
      code: "47",
      country_code: "DZ",
  },
  {
      name: "Guelma",
      code: "24",
      country_code: "DZ",
  },
  {
      name: "Illizi",
      code: "33",
      country_code: "DZ",
  },
  {
      name: "In Guezzam",
      code: "58",
      country_code: "DZ",
  },
  {
      name: "In Salah",
      code: "57",
      country_code: "DZ",
  },
  {
      name: "Jijel",
      code: "18",
      country_code: "DZ",
  },
  {
      name: "Khenchela",
      code: "40",
      country_code: "DZ",
  },
  {
      name: "Laghouat",
      code: "03",
      country_code: "DZ",
  },
  {
      name: "M'Sila",
      code: "28",
      country_code: "DZ",
  },
  {
      name: "Mascara",
      code: "29",
      country_code: "DZ",
  },
  {
      name: "Médéa",
      code: "26",
      country_code: "DZ",
  },
  {
      name: "Mila",
      code: "43",
      country_code: "DZ",
  },
  {
      name: "Mostaganem",
      code: "27",
      country_code: "DZ",
  },
  {
      name: "Naama",
      code: "45",
      country_code: "DZ",
  },
  {
      name: "Oran",
      code: "31",
      country_code: "DZ",
  },
  {
      name: "Ouargla",
      code: "30",
      country_code: "DZ",
  },
  {
      name: "Ouled Djellal",
      code: "51",
      country_code: "DZ",
  },
  {
      name: "Oum El Bouaghi",
      code: "04",
      country_code: "DZ",
  },
  {
      name: "Relizane",
      code: "48",
      country_code: "DZ",
  },
  {
      name: "Saïda",
      code: "20",
      country_code: "DZ",
  },
  {
      name: "Sétif",
      code: "19",
      country_code: "DZ",
  },
  {
      name: "Sidi Bel Abbès",
      code: "22",
      country_code: "DZ",
  },
  {
      name: "Skikda",
      code: "21",
      country_code: "DZ",
  },
  {
      name: "Souk Ahras",
      code: "41",
      country_code: "DZ",
  },
  {
      name: "Tamanghasset",
      code: "11",
      country_code: "DZ",
  },
  {
      name: "Tébessa",
      code: "12",
      country_code: "DZ",
  },
  {
      name: "Tiaret",
      code: "14",
      country_code: "DZ",
  },
  {
      name: "Timimoun",
      code: "54",
      country_code: "DZ",
  },
  {
      name: "Tindouf",
      code: "37",
      country_code: "DZ",
  },
  {
      name: "Tipasa",
      code: "42",
      country_code: "DZ",
  },
  {
      name: "Tissemsilt",
      code: "38",
      country_code: "DZ",
  },
  {
      name: "Tizi Ouzou",
      code: "15",
      country_code: "DZ",
  },
  {
      name: "Tlemcen",
      code: "13",
      country_code: "DZ",
  },
  {
      name: "Touggourt",
      code: "55",
      country_code: "DZ",
  },
  {
      name: "Andorra la Vella",
      code: "07",
      country_code: "AD",
  },
  {
      name: "Canillo",
      code: "02",
      country_code: "AD",
  },
  {
      name: "Encamp",
      code: "03",
      country_code: "AD",
  },
  {
      name: "Escaldes-Engordany",
      code: "08",
      country_code: "AD",
  },
  {
      name: "La Massana",
      code: "04",
      country_code: "AD",
  },
  {
      name: "Ordino",
      code: "05",
      country_code: "AD",
  },
  {
      name: "Sant Julià de Lòria",
      code: "06",
      country_code: "AD",
  },
  {
      name: "Bengo Province",
      code: "BGO",
      country_code: "AO",
  },
  {
      name: "Benguela Province",
      code: "BGU",
      country_code: "AO",
  },
  {
      name: "Bié Province",
      code: "BIE",
      country_code: "AO",
  },
  {
      name: "Cabinda Province",
      code: "CAB",
      country_code: "AO",
  },
  {
      name: "Cuando Cubango Province",
      code: "CCU",
      country_code: "AO",
  },
  {
      name: "Cuanza Norte Province",
      code: "CNO",
      country_code: "AO",
  },
  {
      name: "Cuanza Sul",
      code: "CUS",
      country_code: "AO",
  },
  {
      name: "Cunene Province",
      code: "CNN",
      country_code: "AO",
  },
  {
      name: "Huambo Province",
      code: "HUA",
      country_code: "AO",
  },
  {
      name: "Huíla Province",
      code: "HUI",
      country_code: "AO",
  },
  {
      name: "Luanda Province",
      code: "LUA",
      country_code: "AO",
  },
  {
      name: "Lunda Norte Province",
      code: "LNO",
      country_code: "AO",
  },
  {
      name: "Lunda Sul Province",
      code: "LSU",
      country_code: "AO",
  },
  {
      name: "Malanje Province",
      code: "MAL",
      country_code: "AO",
  },
  {
      name: "Moxico Province",
      code: "MOX",
      country_code: "AO",
  },
  {
      name: "Uíge Province",
      code: "UIG",
      country_code: "AO",
  },
  {
      name: "Zaire Province",
      code: "ZAI",
      country_code: "AO",
  },
  {
      name: "Barbuda",
      code: "10",
      country_code: "AG",
  },
  {
      name: "Redonda",
      code: "11",
      country_code: "AG",
  },
  {
      name: "Saint George Parish",
      code: "03",
      country_code: "AG",
  },
  {
      name: "Saint John Parish",
      code: "04",
      country_code: "AG",
  },
  {
      name: "Saint Mary Parish",
      code: "05",
      country_code: "AG",
  },
  {
      name: "Saint Paul Parish",
      code: "06",
      country_code: "AG",
  },
  {
      name: "Saint Peter Parish",
      code: "07",
      country_code: "AG",
  },
  {
      name: "Saint Philip Parish",
      code: "08",
      country_code: "AG",
  },
  {
      name: "Autonomous City Of Buenos Aires",
      code: "C",
      country_code: "AR",
  },
  {
      name: "Buenos Aires Province",
      code: "B",
      country_code: "AR",
  },
  {
      name: "Catamarca Province",
      code: "K",
      country_code: "AR",
  },
  {
      name: "Chaco Province",
      code: "H",
      country_code: "AR",
  },
  {
      name: "Chubut Province",
      code: "U",
      country_code: "AR",
  },
  {
      name: "Córdoba Province",
      code: "X",
      country_code: "AR",
  },
  {
      name: "Corrientes",
      code: "W",
      country_code: "AR",
  },
  {
      name: "Entre Ríos Province",
      code: "E",
      country_code: "AR",
  },
  {
      name: "Formosa Province",
      code: "P",
      country_code: "AR",
  },
  {
      name: "Jujuy Province",
      code: "Y",
      country_code: "AR",
  },
  {
      name: "La Pampa",
      code: "L",
      country_code: "AR",
  },
  {
      name: "La Rioja Province",
      code: "F",
      country_code: "AR",
  },
  {
      name: "Mendoza",
      code: "M",
      country_code: "AR",
  },
  {
      name: "Misiones Province",
      code: "N",
      country_code: "AR",
  },
  {
      name: "Neuquén Province",
      code: "Q",
      country_code: "AR",
  },
  {
      name: "Río Negro Province",
      code: "R",
      country_code: "AR",
  },
  {
      name: "Salta Province",
      code: "A",
      country_code: "AR",
  },
  {
      name: "San Juan Province",
      code: "J",
      country_code: "AR",
  },
  {
      name: "San Luis Province",
      code: "D",
      country_code: "AR",
  },
  {
      name: "Santa Cruz Province",
      code: "Z",
      country_code: "AR",
  },
  {
      name: "Santa Fe Province",
      code: "S",
      country_code: "AR",
  },
  {
      name: "Santiago del Estero Province",
      code: "G",
      country_code: "AR",
  },
  {
      name: "Tierra del Fuego Province",
      code: "V",
      country_code: "AR",
  },
  {
      name: "Tucumán Province",
      code: "T",
      country_code: "AR",
  },
  {
      name: "Aragatsotn Region",
      code: "AG",
      country_code: "AM",
  },
  {
      name: "Ararat Province",
      code: "AR",
      country_code: "AM",
  },
  {
      name: "Armavir Region",
      code: "AV",
      country_code: "AM",
  },
  {
      name: "Gegharkunik Province",
      code: "GR",
      country_code: "AM",
  },
  {
      name: "Kotayk Region",
      code: "KT",
      country_code: "AM",
  },
  {
      name: "Lori Region",
      code: "LO",
      country_code: "AM",
  },
  {
      name: "Shirak Region",
      code: "SH",
      country_code: "AM",
  },
  {
      name: "Syunik Province",
      code: "SU",
      country_code: "AM",
  },
  {
      name: "Tavush Region",
      code: "TV",
      country_code: "AM",
  },
  {
      name: "Vayots Dzor Region",
      code: "VD",
      country_code: "AM",
  },
  {
      name: "Yerevan",
      code: "ER",
      country_code: "AM",
  },
  {
      name: "Australian Capital Territory",
      code: "ACT",
      country_code: "AU",
  },
  {
      name: "New South Wales",
      code: "NSW",
      country_code: "AU",
  },
  {
      name: "Northern Territory",
      code: "NT",
      country_code: "AU",
  },
  {
      name: "Queensland",
      code: "QLD",
      country_code: "AU",
  },
  {
      name: "South Australia",
      code: "SA",
      country_code: "AU",
  },
  {
      name: "Tasmania",
      code: "TAS",
      country_code: "AU",
  },
  {
      name: "Victoria",
      code: "VIC",
      country_code: "AU",
  },
  {
      name: "Western Australia",
      code: "WA",
      country_code: "AU",
  },
  {
      name: "Burgenland",
      code: "1",
      country_code: "AT",
  },
  {
      name: "Carinthia",
      code: "2",
      country_code: "AT",
  },
  {
      name: "Lower Austria",
      code: "3",
      country_code: "AT",
  },
  {
      name: "Salzburg",
      code: "5",
      country_code: "AT",
  },
  {
      name: "Styria",
      code: "6",
      country_code: "AT",
  },
  {
      name: "Tyrol",
      code: "7",
      country_code: "AT",
  },
  {
      name: "Upper Austria",
      code: "4",
      country_code: "AT",
  },
  {
      name: "Vienna",
      code: "9",
      country_code: "AT",
  },
  {
      name: "Vorarlberg",
      code: "8",
      country_code: "AT",
  },
  {
      name: "Absheron District",
      code: "ABS",
      country_code: "AZ",
  },
  {
      name: "Agdam District",
      code: "AGM",
      country_code: "AZ",
  },
  {
      name: "Agdash District",
      code: "AGS",
      country_code: "AZ",
  },
  {
      name: "Aghjabadi District",
      code: "AGC",
      country_code: "AZ",
  },
  {
      name: "Agstafa District",
      code: "AGA",
      country_code: "AZ",
  },
  {
      name: "Agsu District",
      code: "AGU",
      country_code: "AZ",
  },
  {
      name: "Astara District",
      code: "AST",
      country_code: "AZ",
  },
  {
      name: "Babek District",
      code: "BAB",
      country_code: "AZ",
  },
  {
      name: "Baku",
      code: "BA",
      country_code: "AZ",
  },
  {
      name: "Balakan District",
      code: "BAL",
      country_code: "AZ",
  },
  {
      name: "Barda District",
      code: "BAR",
      country_code: "AZ",
  },
  {
      name: "Beylagan District",
      code: "BEY",
      country_code: "AZ",
  },
  {
      name: "Bilasuvar District",
      code: "BIL",
      country_code: "AZ",
  },
  {
      name: "Dashkasan District",
      code: "DAS",
      country_code: "AZ",
  },
  {
      name: "Fizuli District",
      code: "FUZ",
      country_code: "AZ",
  },
  {
      name: "Ganja",
      code: "GA",
      country_code: "AZ",
  },
  {
      name: "Gədəbəy",
      code: "GAD",
      country_code: "AZ",
  },
  {
      name: "Gobustan District",
      code: "QOB",
      country_code: "AZ",
  },
  {
      name: "Goranboy District",
      code: "GOR",
      country_code: "AZ",
  },
  {
      name: "Goychay",
      code: "GOY",
      country_code: "AZ",
  },
  {
      name: "Goygol District",
      code: "GYG",
      country_code: "AZ",
  },
  {
      name: "Hajigabul District",
      code: "HAC",
      country_code: "AZ",
  },
  {
      name: "Imishli District",
      code: "IMI",
      country_code: "AZ",
  },
  {
      name: "Ismailli District",
      code: "ISM",
      country_code: "AZ",
  },
  {
      name: "Jabrayil District",
      code: "CAB",
      country_code: "AZ",
  },
  {
      name: "Jalilabad District",
      code: "CAL",
      country_code: "AZ",
  },
  {
      name: "Julfa District",
      code: "CUL",
      country_code: "AZ",
  },
  {
      name: "Kalbajar District",
      code: "KAL",
      country_code: "AZ",
  },
  {
      name: "Kangarli District",
      code: "KAN",
      country_code: "AZ",
  },
  {
      name: "Khachmaz District",
      code: "XAC",
      country_code: "AZ",
  },
  {
      name: "Khizi District",
      code: "XIZ",
      country_code: "AZ",
  },
  {
      name: "Khojali District",
      code: "XCI",
      country_code: "AZ",
  },
  {
      name: "Kurdamir District",
      code: "KUR",
      country_code: "AZ",
  },
  {
      name: "Lachin District",
      code: "LAC",
      country_code: "AZ",
  },
  {
      name: "Lankaran",
      code: "LAN",
      country_code: "AZ",
  },
  {
      name: "Lankaran District",
      code: "LA",
      country_code: "AZ",
  },
  {
      name: "Lerik District",
      code: "LER",
      country_code: "AZ",
  },
  {
      name: "Martuni",
      code: "XVD",
      country_code: "AZ",
  },
  {
      name: "Masally District",
      code: "MAS",
      country_code: "AZ",
  },
  {
      name: "Mingachevir",
      code: "MI",
      country_code: "AZ",
  },
  {
      name: "Nakhchivan Autonomous Republic",
      code: "NX",
      country_code: "AZ",
  },
  {
      name: "Neftchala District",
      code: "NEF",
      country_code: "AZ",
  },
  {
      name: "Oghuz District",
      code: "OGU",
      country_code: "AZ",
  },
  {
      name: "Ordubad District",
      code: "ORD",
      country_code: "AZ",
  },
  {
      name: "Qabala District",
      code: "QAB",
      country_code: "AZ",
  },
  {
      name: "Qakh District",
      code: "QAX",
      country_code: "AZ",
  },
  {
      name: "Qazakh District",
      code: "QAZ",
      country_code: "AZ",
  },
  {
      name: "Quba District",
      code: "QBA",
      country_code: "AZ",
  },
  {
      name: "Qubadli District",
      code: "QBI",
      country_code: "AZ",
  },
  {
      name: "Qusar District",
      code: "QUS",
      country_code: "AZ",
  },
  {
      name: "Saatly District",
      code: "SAT",
      country_code: "AZ",
  },
  {
      name: "Sabirabad District",
      code: "SAB",
      country_code: "AZ",
  },
  {
      name: "Sadarak District",
      code: "SAD",
      country_code: "AZ",
  },
  {
      name: "Salyan District",
      code: "SAL",
      country_code: "AZ",
  },
  {
      name: "Samukh District",
      code: "SMX",
      country_code: "AZ",
  },
  {
      name: "Shabran District",
      code: "SBN",
      country_code: "AZ",
  },
  {
      name: "Shahbuz District",
      code: "SAH",
      country_code: "AZ",
  },
  {
      name: "Shaki",
      code: "SA",
      country_code: "AZ",
  },
  {
      name: "Shaki District",
      code: "SAK",
      country_code: "AZ",
  },
  {
      name: "Shamakhi District",
      code: "SMI",
      country_code: "AZ",
  },
  {
      name: "Shamkir District",
      code: "SKR",
      country_code: "AZ",
  },
  {
      name: "Sharur District",
      code: "SAR",
      country_code: "AZ",
  },
  {
      name: "Shirvan",
      code: "SR",
      country_code: "AZ",
  },
  {
      name: "Shusha District",
      code: "SUS",
      country_code: "AZ",
  },
  {
      name: "Siazan District",
      code: "SIY",
      country_code: "AZ",
  },
  {
      name: "Sumqayit",
      code: "SM",
      country_code: "AZ",
  },
  {
      name: "Tartar District",
      code: "TAR",
      country_code: "AZ",
  },
  {
      name: "Tovuz District",
      code: "TOV",
      country_code: "AZ",
  },
  {
      name: "Ujar District",
      code: "UCA",
      country_code: "AZ",
  },
  {
      name: "Yardymli District",
      code: "YAR",
      country_code: "AZ",
  },
  {
      name: "Yevlakh",
      code: "YE",
      country_code: "AZ",
  },
  {
      name: "Yevlakh District",
      code: "YEV",
      country_code: "AZ",
  },
  {
      name: "Zangilan District",
      code: "ZAN",
      country_code: "AZ",
  },
  {
      name: "Zaqatala District",
      code: "ZAQ",
      country_code: "AZ",
  },
  {
      name: "Zardab District",
      code: "ZAR",
      country_code: "AZ",
  },
  {
      name: "Acklins",
      code: "AK",
      country_code: "BS",
  },
  {
      name: "Acklins and Crooked Islands",
      code: "AC",
      country_code: "BS",
  },
  {
      name: "Berry Islands",
      code: "BY",
      country_code: "BS",
  },
  {
      name: "Bimini",
      code: "BI",
      country_code: "BS",
  },
  {
      name: "Black Point",
      code: "BP",
      country_code: "BS",
  },
  {
      name: "Cat Island",
      code: "CI",
      country_code: "BS",
  },
  {
      name: "Central Abaco",
      code: "CO",
      country_code: "BS",
  },
  {
      name: "Central Andros",
      code: "CS",
      country_code: "BS",
  },
  {
      name: "Central Eleuthera",
      code: "CE",
      country_code: "BS",
  },
  {
      name: "Crooked Island",
      code: "CK",
      country_code: "BS",
  },
  {
      name: "East Grand Bahama",
      code: "EG",
      country_code: "BS",
  },
  {
      name: "Exuma",
      code: "EX",
      country_code: "BS",
  },
  {
      name: "Freeport",
      code: "FP",
      country_code: "BS",
  },
  {
      name: "Fresh Creek",
      code: "FC",
      country_code: "BS",
  },
  {
      name: "Governor's Harbour",
      code: "GH",
      country_code: "BS",
  },
  {
      name: "Grand Cay",
      code: "GC",
      country_code: "BS",
  },
  {
      name: "Green Turtle Cay",
      code: "GT",
      country_code: "BS",
  },
  {
      name: "Harbour Island",
      code: "HI",
      country_code: "BS",
  },
  {
      name: "High Rock",
      code: "HR",
      country_code: "BS",
  },
  {
      name: "Hope Town",
      code: "HT",
      country_code: "BS",
  },
  {
      name: "Inagua",
      code: "IN",
      country_code: "BS",
  },
  {
      name: "Kemps Bay",
      code: "KB",
      country_code: "BS",
  },
  {
      name: "Long Island",
      code: "LI",
      country_code: "BS",
  },
  {
      name: "Mangrove Cay",
      code: "MC",
      country_code: "BS",
  },
  {
      name: "Marsh Harbour",
      code: "MH",
      country_code: "BS",
  },
  {
      name: "Mayaguana District",
      code: "MG",
      country_code: "BS",
  },
  {
      name: "New Providence",
      code: "NP",
      country_code: "BS",
  },
  {
      name: "Nichollstown and Berry Islands",
      code: "NB",
      country_code: "BS",
  },
  {
      name: "North Abaco",
      code: "NO",
      country_code: "BS",
  },
  {
      name: "North Andros",
      code: "NS",
      country_code: "BS",
  },
  {
      name: "North Eleuthera",
      code: "NE",
      country_code: "BS",
  },
  {
      name: "Ragged Island",
      code: "RI",
      country_code: "BS",
  },
  {
      name: "Rock Sound",
      code: "RS",
      country_code: "BS",
  },
  {
      name: "Rum Cay District",
      code: "RC",
      country_code: "BS",
  },
  {
      name: "San Salvador and Rum Cay",
      code: "SR",
      country_code: "BS",
  },
  {
      name: "San Salvador Island",
      code: "SS",
      country_code: "BS",
  },
  {
      name: "Sandy Point",
      code: "SP",
      country_code: "BS",
  },
  {
      name: "South Abaco",
      code: "SO",
      country_code: "BS",
  },
  {
      name: "South Andros",
      code: "SA",
      country_code: "BS",
  },
  {
      name: "South Eleuthera",
      code: "SE",
      country_code: "BS",
  },
  {
      name: "Spanish Wells",
      code: "SW",
      country_code: "BS",
  },
  {
      name: "West Grand Bahama",
      code: "WG",
      country_code: "BS",
  },
  {
      name: "Capital Governorate",
      code: "13",
      country_code: "BH",
  },
  {
      name: "Central Governorate",
      code: "16",
      country_code: "BH",
  },
  {
      name: "Muharraq Governorate",
      code: "15",
      country_code: "BH",
  },
  {
      name: "Northern Governorate",
      code: "17",
      country_code: "BH",
  },
  {
      name: "Southern Governorate",
      code: "14",
      country_code: "BH",
  },
  {
      name: "Bagerhat District",
      code: "05",
      country_code: "BD",
  },
  {
      name: "Bahadia",
      code: "33",
      country_code: "BD",
  },
  {
      name: "Bandarban District",
      code: "01",
      country_code: "BD",
  },
  {
      name: "Barguna District",
      code: "02",
      country_code: "BD",
  },
  {
      name: "Barisal District",
      code: "06",
      country_code: "BD",
  },
  {
      name: "Barisal Division",
      code: "A",
      country_code: "BD",
  },
  {
      name: "Bhola District",
      code: "07",
      country_code: "BD",
  },
  {
      name: "Bogra District",
      code: "03",
      country_code: "BD",
  },
  {
      name: "Brahmanbaria District",
      code: "04",
      country_code: "BD",
  },
  {
      name: "Chandpur District",
      code: "09",
      country_code: "BD",
  },
  {
      name: "Chapai Nawabganj District",
      code: "45",
      country_code: "BD",
  },
  {
      name: "Chittagong District",
      code: "10",
      country_code: "BD",
  },
  {
      name: "Chittagong Division",
      code: "B",
      country_code: "BD",
  },
  {
      name: "Chuadanga District",
      code: "12",
      country_code: "BD",
  },
  {
      name: "Comilla District",
      code: "08",
      country_code: "BD",
  },
  {
      name: "Cox's Bazar District",
      code: "11",
      country_code: "BD",
  },
  {
      name: "Dhaka District",
      code: "13",
      country_code: "BD",
  },
  {
      name: "Dhaka Division",
      code: "C",
      country_code: "BD",
  },
  {
      name: "Dinajpur District",
      code: "14",
      country_code: "BD",
  },
  {
      name: "Faridpur District",
      code: "15",
      country_code: "BD",
  },
  {
      name: "Feni District",
      code: "16",
      country_code: "BD",
  },
  {
      name: "Gaibandha District",
      code: "19",
      country_code: "BD",
  },
  {
      name: "Gazipur District",
      code: "18",
      country_code: "BD",
  },
  {
      name: "Gopalganj District",
      code: "17",
      country_code: "BD",
  },
  {
      name: "Habiganj District",
      code: "20",
      country_code: "BD",
  },
  {
      name: "Jamalpur District",
      code: "21",
      country_code: "BD",
  },
  {
      name: "Jessore District",
      code: "22",
      country_code: "BD",
  },
  {
      name: "Jhalokati District",
      code: "25",
      country_code: "BD",
  },
  {
      name: "Jhenaidah District",
      code: "23",
      country_code: "BD",
  },
  {
      name: "Joypurhat District",
      code: "24",
      country_code: "BD",
  },
  {
      name: "Khagrachari District",
      code: "29",
      country_code: "BD",
  },
  {
      name: "Khulna District",
      code: "27",
      country_code: "BD",
  },
  {
      name: "Khulna Division",
      code: "D",
      country_code: "BD",
  },
  {
      name: "Kishoreganj District",
      code: "26",
      country_code: "BD",
  },
  {
      name: "Kurigram District",
      code: "28",
      country_code: "BD",
  },
  {
      name: "Kushtia District",
      code: "30",
      country_code: "BD",
  },
  {
      name: "Lakshmipur District",
      code: "31",
      country_code: "BD",
  },
  {
      name: "Lalmonirhat District",
      code: "32",
      country_code: "BD",
  },
  {
      name: "Madaripur District",
      code: "36",
      country_code: "BD",
  },
  {
      name: "Meherpur District",
      code: "39",
      country_code: "BD",
  },
  {
      name: "Moulvibazar District",
      code: "38",
      country_code: "BD",
  },
  {
      name: "Munshiganj District",
      code: "35",
      country_code: "BD",
  },
  {
      name: "Mymensingh District",
      code: "34",
      country_code: "BD",
  },
  {
      name: "Mymensingh Division",
      code: "H",
      country_code: "BD",
  },
  {
      name: "Naogaon District",
      code: "48",
      country_code: "BD",
  },
  {
      name: "Narail District",
      code: "43",
      country_code: "BD",
  },
  {
      name: "Narayanganj District",
      code: "40",
      country_code: "BD",
  },
  {
      name: "Natore District",
      code: "44",
      country_code: "BD",
  },
  {
      name: "Netrokona District",
      code: "41",
      country_code: "BD",
  },
  {
      name: "Nilphamari District",
      code: "46",
      country_code: "BD",
  },
  {
      name: "Noakhali District",
      code: "47",
      country_code: "BD",
  },
  {
      name: "Pabna District",
      code: "49",
      country_code: "BD",
  },
  {
      name: "Panchagarh District",
      code: "52",
      country_code: "BD",
  },
  {
      name: "Patuakhali District",
      code: "51",
      country_code: "BD",
  },
  {
      name: "Pirojpur District",
      code: "50",
      country_code: "BD",
  },
  {
      name: "Rajbari District",
      code: "53",
      country_code: "BD",
  },
  {
      name: "Rajshahi District",
      code: "54",
      country_code: "BD",
  },
  {
      name: "Rajshahi Division",
      code: "E",
      country_code: "BD",
  },
  {
      name: "Rangamati Hill District",
      code: "56",
      country_code: "BD",
  },
  {
      name: "Rangpur District",
      code: "55",
      country_code: "BD",
  },
  {
      name: "Rangpur Division",
      code: "F",
      country_code: "BD",
  },
  {
      name: "Satkhira District",
      code: "58",
      country_code: "BD",
  },
  {
      name: "Shariatpur District",
      code: "62",
      country_code: "BD",
  },
  {
      name: "Sherpur District",
      code: "57",
      country_code: "BD",
  },
  {
      name: "Sirajganj District",
      code: "59",
      country_code: "BD",
  },
  {
      name: "Sunamganj District",
      code: "61",
      country_code: "BD",
  },
  {
      name: "Sylhet District",
      code: "60",
      country_code: "BD",
  },
  {
      name: "Sylhet Division",
      code: "G",
      country_code: "BD",
  },
  {
      name: "Tangail District",
      code: "63",
      country_code: "BD",
  },
  {
      name: "Thakurgaon District",
      code: "64",
      country_code: "BD",
  },
  {
      name: "Christ Church",
      code: "01",
      country_code: "BB",
  },
  {
      name: "Saint Andrew",
      code: "02",
      country_code: "BB",
  },
  {
      name: "Saint George",
      code: "03",
      country_code: "BB",
  },
  {
      name: "Saint James",
      code: "04",
      country_code: "BB",
  },
  {
      name: "Saint John",
      code: "05",
      country_code: "BB",
  },
  {
      name: "Saint Joseph",
      code: "06",
      country_code: "BB",
  },
  {
      name: "Saint Lucy",
      code: "07",
      country_code: "BB",
  },
  {
      name: "Saint Michael",
      code: "08",
      country_code: "BB",
  },
  {
      name: "Saint Peter",
      code: "09",
      country_code: "BB",
  },
  {
      name: "Saint Philip",
      code: "10",
      country_code: "BB",
  },
  {
      name: "Saint Thomas",
      code: "11",
      country_code: "BB",
  },
  {
      name: "Brest Region",
      code: "BR",
      country_code: "BY",
  },
  {
      name: "Gomel Region",
      code: "HO",
      country_code: "BY",
  },
  {
      name: "Grodno Region",
      code: "HR",
      country_code: "BY",
  },
  {
      name: "Minsk",
      code: "HM",
      country_code: "BY",
  },
  {
      name: "Minsk Region",
      code: "MI",
      country_code: "BY",
  },
  {
      name: "Mogilev Region",
      code: "MA",
      country_code: "BY",
  },
  {
      name: "Vitebsk Region",
      code: "VI",
      country_code: "BY",
  },
  {
      name: "Antwerp",
      code: "VAN",
      country_code: "BE",
  },
  {
      name: "Brussels-Capital Region",
      code: "BRU",
      country_code: "BE",
  },
  {
      name: "East Flanders",
      code: "VOV",
      country_code: "BE",
  },
  {
      name: "Flanders",
      code: "VLG",
      country_code: "BE",
  },
  {
      name: "Flemish Brabant",
      code: "VBR",
      country_code: "BE",
  },
  {
      name: "Hainaut",
      code: "WHT",
      country_code: "BE",
  },
  {
      name: "Liège",
      code: "WLG",
      country_code: "BE",
  },
  {
      name: "Limburg",
      code: "VLI",
      country_code: "BE",
  },
  {
      name: "Luxembourg",
      code: "WLX",
      country_code: "BE",
  },
  {
      name: "Namur",
      code: "WNA",
      country_code: "BE",
  },
  {
      name: "Wallonia",
      code: "WAL",
      country_code: "BE",
  },
  {
      name: "Walloon Brabant",
      code: "WBR",
      country_code: "BE",
  },
  {
      name: "West Flanders",
      code: "VWV",
      country_code: "BE",
  },
  {
      name: "Belize District",
      code: "BZ",
      country_code: "BZ",
  },
  {
      name: "Cayo District",
      code: "CY",
      country_code: "BZ",
  },
  {
      name: "Corozal District",
      code: "CZL",
      country_code: "BZ",
  },
  {
      name: "Orange Walk District",
      code: "OW",
      country_code: "BZ",
  },
  {
      name: "Stann Creek District",
      code: "SC",
      country_code: "BZ",
  },
  {
      name: "Toledo District",
      code: "TOL",
      country_code: "BZ",
  },
  {
      name: "Alibori Department",
      code: "AL",
      country_code: "BJ",
  },
  {
      name: "Atakora Department",
      code: "AK",
      country_code: "BJ",
  },
  {
      name: "Atlantique Department",
      code: "AQ",
      country_code: "BJ",
  },
  {
      name: "Borgou Department",
      code: "BO",
      country_code: "BJ",
  },
  {
      name: "Collines Department",
      code: "CO",
      country_code: "BJ",
  },
  {
      name: "Donga Department",
      code: "DO",
      country_code: "BJ",
  },
  {
      name: "Kouffo Department",
      code: "KO",
      country_code: "BJ",
  },
  {
      name: "Littoral Department",
      code: "LI",
      country_code: "BJ",
  },
  {
      name: "Mono Department",
      code: "MO",
      country_code: "BJ",
  },
  {
      name: "Ouémé Department",
      code: "OU",
      country_code: "BJ",
  },
  {
      name: "Plateau Department",
      code: "PL",
      country_code: "BJ",
  },
  {
      name: "Zou Department",
      code: "ZO",
      country_code: "BJ",
  },
  {
      name: "Devonshire Parish",
      code: "DEV",
      country_code: "BM",
  },
  {
      name: "Hamilton Municipality",
      code: "HAM",
      country_code: "BM",
  },
  {
      name: "Hamilton Parish",
      code: "HA",
      country_code: "BM",
  },
  {
      name: "Paget Parish",
      code: "PAG",
      country_code: "BM",
  },
  {
      name: "Pembroke Parish",
      code: "PEM",
      country_code: "BM",
  },
  {
      name: "Saint George's Municipality",
      code: "SG",
      country_code: "BM",
  },
  {
      name: "Saint George's Parish",
      code: "SGE",
      country_code: "BM",
  },
  {
      name: "Sandys Parish",
      code: "SAN",
      country_code: "BM",
  },
  {
      name: "Smith's Parish,",
      code: "SMI",
      country_code: "BM",
  },
  {
      name: "Southampton Parish",
      code: "SOU",
      country_code: "BM",
  },
  {
      name: "Warwick Parish",
      code: "WAR",
      country_code: "BM",
  },
  {
      name: "Bumthang District",
      code: "33",
      country_code: "BT",
  },
  {
      name: "Chukha District",
      code: "12",
      country_code: "BT",
  },
  {
      name: "Dagana District",
      code: "22",
      country_code: "BT",
  },
  {
      name: "Gasa District",
      code: "GA",
      country_code: "BT",
  },
  {
      name: "Haa District",
      code: "13",
      country_code: "BT",
  },
  {
      name: "Lhuntse District",
      code: "44",
      country_code: "BT",
  },
  {
      name: "Mongar District",
      code: "42",
      country_code: "BT",
  },
  {
      name: "Paro District",
      code: "11",
      country_code: "BT",
  },
  {
      name: "Pemagatshel District",
      code: "43",
      country_code: "BT",
  },
  {
      name: "Punakha District",
      code: "23",
      country_code: "BT",
  },
  {
      name: "Samdrup Jongkhar District",
      code: "45",
      country_code: "BT",
  },
  {
      name: "Samtse District",
      code: "14",
      country_code: "BT",
  },
  {
      name: "Sarpang District",
      code: "31",
      country_code: "BT",
  },
  {
      name: "Thimphu District",
      code: "15",
      country_code: "BT",
  },
  {
      name: "Trashigang District",
      code: "41",
      country_code: "BT",
  },
  {
      name: "Trongsa District",
      code: "32",
      country_code: "BT",
  },
  {
      name: "Tsirang District",
      code: "21",
      country_code: "BT",
  },
  {
      name: "Wangdue Phodrang District",
      code: "24",
      country_code: "BT",
  },
  {
      name: "Zhemgang District",
      code: "34",
      country_code: "BT",
  },
  {
      name: "Beni Department",
      code: "B",
      country_code: "BO",
  },
  {
      name: "Chuquisaca Department",
      code: "H",
      country_code: "BO",
  },
  {
      name: "Cochabamba Department",
      code: "C",
      country_code: "BO",
  },
  {
      name: "La Paz Department",
      code: "L",
      country_code: "BO",
  },
  {
      name: "Oruro Department",
      code: "O",
      country_code: "BO",
  },
  {
      name: "Pando Department",
      code: "N",
      country_code: "BO",
  },
  {
      name: "Potosí Department",
      code: "P",
      country_code: "BO",
  },
  {
      name: "Santa Cruz Department",
      code: "S",
      country_code: "BO",
  },
  {
      name: "Tarija Department",
      code: "T",
      country_code: "BO",
  },
  {
      name: "Bosnian Podrinje Canton",
      code: "05",
      country_code: "BA",
  },
  {
      name: "Brčko District",
      code: "BRC",
      country_code: "BA",
  },
  {
      name: "Canton 10",
      code: "10",
      country_code: "BA",
  },
  {
      name: "Central Bosnia Canton",
      code: "06",
      country_code: "BA",
  },
  {
      name: "Federation of Bosnia and Herzegovina",
      code: "BIH",
      country_code: "BA",
  },
  {
      name: "Herzegovina-Neretva Canton",
      code: "07",
      country_code: "BA",
  },
  {
      name: "Posavina Canton",
      code: "02",
      country_code: "BA",
  },
  {
      name: "Republika Srpska",
      code: "SRP",
      country_code: "BA",
  },
  {
      name: "Sarajevo Canton",
      code: "09",
      country_code: "BA",
  },
  {
      name: "Tuzla Canton",
      code: "03",
      country_code: "BA",
  },
  {
      name: "Una-Sana Canton",
      code: "01",
      country_code: "BA",
  },
  {
      name: "West Herzegovina Canton",
      code: "08",
      country_code: "BA",
  },
  {
      name: "Zenica-Doboj Canton",
      code: "04",
      country_code: "BA",
  },
  {
      name: "Central District",
      code: "CE",
      country_code: "BW",
  },
  {
      name: "Ghanzi District",
      code: "GH",
      country_code: "BW",
  },
  {
      name: "Kgalagadi District",
      code: "KG",
      country_code: "BW",
  },
  {
      name: "Kgatleng District",
      code: "KL",
      country_code: "BW",
  },
  {
      name: "Kweneng District",
      code: "KW",
      country_code: "BW",
  },
  {
      name: "Ngamiland",
      code: "NG",
      country_code: "BW",
  },
  {
      name: "North-East District",
      code: "NE",
      country_code: "BW",
  },
  {
      name: "North-West District",
      code: "NW",
      country_code: "BW",
  },
  {
      name: "South-East District",
      code: "SE",
      country_code: "BW",
  },
  {
      name: "Southern District",
      code: "SO",
      country_code: "BW",
  },
  {
      name: "Acre",
      code: "AC",
      country_code: "BR",
  },
  {
      name: "Alagoas",
      code: "AL",
      country_code: "BR",
  },
  {
      name: "Amapá",
      code: "AP",
      country_code: "BR",
  },
  {
      name: "Amazonas",
      code: "AM",
      country_code: "BR",
  },
  {
      name: "Bahia",
      code: "BA",
      country_code: "BR",
  },
  {
      name: "Ceará",
      code: "CE",
      country_code: "BR",
  },
  {
      name: "Distrito Federal",
      code: "DF",
      country_code: "BR",
  },
  {
      name: "Espírito Santo",
      code: "ES",
      country_code: "BR",
  },
  {
      name: "Goiás",
      code: "GO",
      country_code: "BR",
  },
  {
      name: "Maranhão",
      code: "MA",
      country_code: "BR",
  },
  {
      name: "Mato Grosso",
      code: "MT",
      country_code: "BR",
  },
  {
      name: "Mato Grosso do Sul",
      code: "MS",
      country_code: "BR",
  },
  {
      name: "Minas Gerais",
      code: "MG",
      country_code: "BR",
  },
  {
      name: "Pará",
      code: "PA",
      country_code: "BR",
  },
  {
      name: "Paraíba",
      code: "PB",
      country_code: "BR",
  },
  {
      name: "Paraná",
      code: "PR",
      country_code: "BR",
  },
  {
      name: "Pernambuco",
      code: "PE",
      country_code: "BR",
  },
  {
      name: "Piauí",
      code: "PI",
      country_code: "BR",
  },
  {
      name: "Rio de Janeiro",
      code: "RJ",
      country_code: "BR",
  },
  {
      name: "Rio Grande do Norte",
      code: "RN",
      country_code: "BR",
  },
  {
      name: "Rio Grande do Sul",
      code: "RS",
      country_code: "BR",
  },
  {
      name: "Rondônia",
      code: "RO",
      country_code: "BR",
  },
  {
      name: "Roraima",
      code: "RR",
      country_code: "BR",
  },
  {
      name: "Santa Catarina",
      code: "SC",
      country_code: "BR",
  },
  {
      name: "São Paulo",
      code: "SP",
      country_code: "BR",
  },
  {
      name: "Sergipe",
      code: "SE",
      country_code: "BR",
  },
  {
      name: "Tocantins",
      code: "TO",
      country_code: "BR",
  },
  {
      name: "Belait District",
      code: "BE",
      country_code: "BN",
  },
  {
      name: "Brunei-Muara District",
      code: "BM",
      country_code: "BN",
  },
  {
      name: "Temburong District",
      code: "TE",
      country_code: "BN",
  },
  {
      name: "Tutong District",
      code: "TU",
      country_code: "BN",
  },
  {
      name: "Blagoevgrad Province",
      code: "01",
      country_code: "BG",
  },
  {
      name: "Burgas Province",
      code: "02",
      country_code: "BG",
  },
  {
      name: "Dobrich Province",
      code: "08",
      country_code: "BG",
  },
  {
      name: "Gabrovo Province",
      code: "07",
      country_code: "BG",
  },
  {
      name: "Haskovo Province",
      code: "26",
      country_code: "BG",
  },
  {
      name: "Kardzhali Province",
      code: "09",
      country_code: "BG",
  },
  {
      name: "Kyustendil Province",
      code: "10",
      country_code: "BG",
  },
  {
      name: "Lovech Province",
      code: "11",
      country_code: "BG",
  },
  {
      name: "Montana Province",
      code: "12",
      country_code: "BG",
  },
  {
      name: "Pazardzhik Province",
      code: "13",
      country_code: "BG",
  },
  {
      name: "Pernik Province",
      code: "14",
      country_code: "BG",
  },
  {
      name: "Pleven Province",
      code: "15",
      country_code: "BG",
  },
  {
      name: "Plovdiv Province",
      code: "16",
      country_code: "BG",
  },
  {
      name: "Razgrad Province",
      code: "17",
      country_code: "BG",
  },
  {
      name: "Ruse Province",
      code: "18",
      country_code: "BG",
  },
  {
      name: "Shumen",
      code: "27",
      country_code: "BG",
  },
  {
      name: "Silistra Province",
      code: "19",
      country_code: "BG",
  },
  {
      name: "Sliven Province",
      code: "20",
      country_code: "BG",
  },
  {
      name: "Smolyan Province",
      code: "21",
      country_code: "BG",
  },
  {
      name: "Sofia City Province",
      code: "22",
      country_code: "BG",
  },
  {
      name: "Sofia Province",
      code: "23",
      country_code: "BG",
  },
  {
      name: "Stara Zagora Province",
      code: "24",
      country_code: "BG",
  },
  {
      name: "Targovishte Province",
      code: "25",
      country_code: "BG",
  },
  {
      name: "Varna Province",
      code: "03",
      country_code: "BG",
  },
  {
      name: "Veliko Tarnovo Province",
      code: "04",
      country_code: "BG",
  },
  {
      name: "Vidin Province",
      code: "05",
      country_code: "BG",
  },
  {
      name: "Vratsa Province",
      code: "06",
      country_code: "BG",
  },
  {
      name: "Yambol Province",
      code: "28",
      country_code: "BG",
  },
  {
      name: "Balé Province",
      code: "BAL",
      country_code: "BF",
  },
  {
      name: "Bam Province",
      code: "BAM",
      country_code: "BF",
  },
  {
      name: "Banwa Province",
      code: "BAN",
      country_code: "BF",
  },
  {
      name: "Bazèga Province",
      code: "BAZ",
      country_code: "BF",
  },
  {
      name: "Boucle du Mouhoun Region",
      code: "01",
      country_code: "BF",
  },
  {
      name: "Bougouriba Province",
      code: "BGR",
      country_code: "BF",
  },
  {
      name: "Boulgou",
      code: "BLG",
      country_code: "BF",
  },
  {
      name: "Cascades Region",
      code: "02",
      country_code: "BF",
  },
  {
      name: "Centre",
      code: "03",
      country_code: "BF",
  },
  {
      name: "Centre-Est Region",
      code: "04",
      country_code: "BF",
  },
  {
      name: "Centre-Nord Region",
      code: "05",
      country_code: "BF",
  },
  {
      name: "Centre-Ouest Region",
      code: "06",
      country_code: "BF",
  },
  {
      name: "Centre-Sud Region",
      code: "07",
      country_code: "BF",
  },
  {
      name: "Comoé Province",
      code: "COM",
      country_code: "BF",
  },
  {
      name: "Est Region",
      code: "08",
      country_code: "BF",
  },
  {
      name: "Ganzourgou Province",
      code: "GAN",
      country_code: "BF",
  },
  {
      name: "Gnagna Province",
      code: "GNA",
      country_code: "BF",
  },
  {
      name: "Gourma Province",
      code: "GOU",
      country_code: "BF",
  },
  {
      name: "Hauts-Bassins Region",
      code: "09",
      country_code: "BF",
  },
  {
      name: "Houet Province",
      code: "HOU",
      country_code: "BF",
  },
  {
      name: "Ioba Province",
      code: "IOB",
      country_code: "BF",
  },
  {
      name: "Kadiogo Province",
      code: "KAD",
      country_code: "BF",
  },
  {
      name: "Kénédougou Province",
      code: "KEN",
      country_code: "BF",
  },
  {
      name: "Komondjari Province",
      code: "KMD",
      country_code: "BF",
  },
  {
      name: "Kompienga Province",
      code: "KMP",
      country_code: "BF",
  },
  {
      name: "Kossi Province",
      code: "KOS",
      country_code: "BF",
  },
  {
      name: "Koulpélogo Province",
      code: "KOP",
      country_code: "BF",
  },
  {
      name: "Kouritenga Province",
      code: "KOT",
      country_code: "BF",
  },
  {
      name: "Kourwéogo Province",
      code: "KOW",
      country_code: "BF",
  },
  {
      name: "Léraba Province",
      code: "LER",
      country_code: "BF",
  },
  {
      name: "Loroum Province",
      code: "LOR",
      country_code: "BF",
  },
  {
      name: "Mouhoun",
      code: "MOU",
      country_code: "BF",
  },
  {
      name: "Nahouri Province",
      code: "NAO",
      country_code: "BF",
  },
  {
      name: "Namentenga Province",
      code: "NAM",
      country_code: "BF",
  },
  {
      name: "Nayala Province",
      code: "NAY",
      country_code: "BF",
  },
  {
      name: "Nord Region, Burkina Faso",
      code: "10",
      country_code: "BF",
  },
  {
      name: "Noumbiel Province",
      code: "NOU",
      country_code: "BF",
  },
  {
      name: "Oubritenga Province",
      code: "OUB",
      country_code: "BF",
  },
  {
      name: "Oudalan Province",
      code: "OUD",
      country_code: "BF",
  },
  {
      name: "Passoré Province",
      code: "PAS",
      country_code: "BF",
  },
  {
      name: "Plateau-Central Region",
      code: "11",
      country_code: "BF",
  },
  {
      name: "Poni Province",
      code: "PON",
      country_code: "BF",
  },
  {
      name: "Sahel Region",
      code: "12",
      country_code: "BF",
  },
  {
      name: "Sanguié Province",
      code: "SNG",
      country_code: "BF",
  },
  {
      name: "Sanmatenga Province",
      code: "SMT",
      country_code: "BF",
  },
  {
      name: "Séno Province",
      code: "SEN",
      country_code: "BF",
  },
  {
      name: "Sissili Province",
      code: "SIS",
      country_code: "BF",
  },
  {
      name: "Soum Province",
      code: "SOM",
      country_code: "BF",
  },
  {
      name: "Sourou Province",
      code: "SOR",
      country_code: "BF",
  },
  {
      name: "Sud-Ouest Region",
      code: "13",
      country_code: "BF",
  },
  {
      name: "Tapoa Province",
      code: "TAP",
      country_code: "BF",
  },
  {
      name: "Tuy Province",
      code: "TUI",
      country_code: "BF",
  },
  {
      name: "Yagha Province",
      code: "YAG",
      country_code: "BF",
  },
  {
      name: "Yatenga Province",
      code: "YAT",
      country_code: "BF",
  },
  {
      name: "Ziro Province",
      code: "ZIR",
      country_code: "BF",
  },
  {
      name: "Zondoma Province",
      code: "ZON",
      country_code: "BF",
  },
  {
      name: "Zoundwéogo Province",
      code: "ZOU",
      country_code: "BF",
  },
  {
      name: "Bubanza Province",
      code: "BB",
      country_code: "BI",
  },
  {
      name: "Bujumbura Mairie Province",
      code: "BM",
      country_code: "BI",
  },
  {
      name: "Bujumbura Rural Province",
      code: "BL",
      country_code: "BI",
  },
  {
      name: "Bururi Province",
      code: "BR",
      country_code: "BI",
  },
  {
      name: "Cankuzo Province",
      code: "CA",
      country_code: "BI",
  },
  {
      name: "Cibitoke Province",
      code: "CI",
      country_code: "BI",
  },
  {
      name: "Gitega Province",
      code: "GI",
      country_code: "BI",
  },
  {
      name: "Karuzi Province",
      code: "KR",
      country_code: "BI",
  },
  {
      name: "Kayanza Province",
      code: "KY",
      country_code: "BI",
  },
  {
      name: "Kirundo Province",
      code: "KI",
      country_code: "BI",
  },
  {
      name: "Makamba Province",
      code: "MA",
      country_code: "BI",
  },
  {
      name: "Muramvya Province",
      code: "MU",
      country_code: "BI",
  },
  {
      name: "Muyinga Province",
      code: "MY",
      country_code: "BI",
  },
  {
      name: "Mwaro Province",
      code: "MW",
      country_code: "BI",
  },
  {
      name: "Ngozi Province",
      code: "NG",
      country_code: "BI",
  },
  {
      name: "Rumonge Province",
      code: "RM",
      country_code: "BI",
  },
  {
      name: "Rutana Province",
      code: "RT",
      country_code: "BI",
  },
  {
      name: "Ruyigi Province",
      code: "RY",
      country_code: "BI",
  },
  {
      name: "Banteay Meanchey Province",
      code: "1",
      country_code: "KH",
  },
  {
      name: "Battambang Province",
      code: "2",
      country_code: "KH",
  },
  {
      name: "Kampong Cham Province",
      code: "3",
      country_code: "KH",
  },
  {
      name: "Kampong Chhnang Province",
      code: "4",
      country_code: "KH",
  },
  {
      name: "Kampong Speu Province",
      code: "5",
      country_code: "KH",
  },
  {
      name: "Kampot Province",
      code: "7",
      country_code: "KH",
  },
  {
      name: "Kandal Province",
      code: "8",
      country_code: "KH",
  },
  {
      name: "Kep Province",
      code: "23",
      country_code: "KH",
  },
  {
      name: "Koh Kong Province",
      code: "9",
      country_code: "KH",
  },
  {
      name: "Kratié Province",
      code: "10",
      country_code: "KH",
  },
  {
      name: "Mondulkiri Province",
      code: "11",
      country_code: "KH",
  },
  {
      name: "Oddar Meanchey Province",
      code: "22",
      country_code: "KH",
  },
  {
      name: "Pailin Province",
      code: "24",
      country_code: "KH",
  },
  {
      name: "Phnom Penh",
      code: "12",
      country_code: "KH",
  },
  {
      name: "Preah Vihear Province",
      code: "13",
      country_code: "KH",
  },
  {
      name: "Prey Veng Province",
      code: "14",
      country_code: "KH",
  },
  {
      name: "Pursat Province",
      code: "15",
      country_code: "KH",
  },
  {
      name: "Ratanakiri Province",
      code: "16",
      country_code: "KH",
  },
  {
      name: "Siem Reap Province",
      code: "17",
      country_code: "KH",
  },
  {
      name: "Sihanoukville Province",
      code: "18",
      country_code: "KH",
  },
  {
      name: "Stung Treng Province",
      code: "19",
      country_code: "KH",
  },
  {
      name: "Svay Rieng Province",
      code: "20",
      country_code: "KH",
  },
  {
      name: "Takéo Province",
      code: "21",
      country_code: "KH",
  },
  {
      name: "Adamawa",
      code: "AD",
      country_code: "CM",
  },
  {
      name: "Centre",
      code: "CE",
      country_code: "CM",
  },
  {
      name: "East",
      code: "ES",
      country_code: "CM",
  },
  {
      name: "Far North",
      code: "EN",
      country_code: "CM",
  },
  {
      name: "Littoral",
      code: "LT",
      country_code: "CM",
  },
  {
      name: "North",
      code: "NO",
      country_code: "CM",
  },
  {
      name: "Northwest",
      code: "NW",
      country_code: "CM",
  },
  {
      name: "South",
      code: "SU",
      country_code: "CM",
  },
  {
      name: "Southwest",
      code: "SW",
      country_code: "CM",
  },
  {
      name: "West",
      code: "OU",
      country_code: "CM",
  },
  {
      name: "Alberta",
      code: "AB",
      country_code: "CA",
  },
  {
      name: "British Columbia",
      code: "BC",
      country_code: "CA",
  },
  {
      name: "Manitoba",
      code: "MB",
      country_code: "CA",
  },
  {
      name: "New Brunswick",
      code: "NB",
      country_code: "CA",
  },
  {
      name: "Newfoundland and Labrador",
      code: "NL",
      country_code: "CA",
  },
  {
      name: "Northwest Territories",
      code: "NT",
      country_code: "CA",
  },
  {
      name: "Nova Scotia",
      code: "NS",
      country_code: "CA",
  },
  {
      name: "Nunavut",
      code: "NU",
      country_code: "CA",
  },
  {
      name: "Ontario",
      code: "ON",
      country_code: "CA",
  },
  {
      name: "Prince Edward Island",
      code: "PE",
      country_code: "CA",
  },
  {
      name: "Quebec",
      code: "QC",
      country_code: "CA",
  },
  {
      name: "Saskatchewan",
      code: "SK",
      country_code: "CA",
  },
  {
      name: "Yukon",
      code: "YT",
      country_code: "CA",
  },
  {
      name: "Barlavento Islands",
      code: "B",
      country_code: "CV",
  },
  {
      name: "Boa Vista",
      code: "BV",
      country_code: "CV",
  },
  {
      name: "Brava",
      code: "BR",
      country_code: "CV",
  },
  {
      name: "Maio Municipality",
      code: "MA",
      country_code: "CV",
  },
  {
      name: "Mosteiros",
      code: "MO",
      country_code: "CV",
  },
  {
      name: "Paul",
      code: "PA",
      country_code: "CV",
  },
  {
      name: "Porto Novo",
      code: "PN",
      country_code: "CV",
  },
  {
      name: "Praia",
      code: "PR",
      country_code: "CV",
  },
  {
      name: "Ribeira Brava Municipality",
      code: "RB",
      country_code: "CV",
  },
  {
      name: "Ribeira Grande",
      code: "RG",
      country_code: "CV",
  },
  {
      name: "Ribeira Grande de Santiago",
      code: "RS",
      country_code: "CV",
  },
  {
      name: "Sal",
      code: "SL",
      country_code: "CV",
  },
  {
      name: "Santa Catarina",
      code: "CA",
      country_code: "CV",
  },
  {
      name: "Santa Catarina do Fogo",
      code: "CF",
      country_code: "CV",
  },
  {
      name: "Santa Cruz",
      code: "CR",
      country_code: "CV",
  },
  {
      name: "São Domingos",
      code: "SD",
      country_code: "CV",
  },
  {
      name: "São Filipe",
      code: "SF",
      country_code: "CV",
  },
  {
      name: "São Lourenço dos Órgãos",
      code: "SO",
      country_code: "CV",
  },
  {
      name: "São Miguel",
      code: "SM",
      country_code: "CV",
  },
  {
      name: "São Vicente",
      code: "SV",
      country_code: "CV",
  },
  {
      name: "Sotavento Islands",
      code: "S",
      country_code: "CV",
  },
  {
      name: "Tarrafal",
      code: "TA",
      country_code: "CV",
  },
  {
      name: "Tarrafal de São Nicolau",
      code: "TS",
      country_code: "CV",
  },
  {
      name: "Bamingui-Bangoran Prefecture",
      code: "BB",
      country_code: "CF",
  },
  {
      name: "Bangui",
      code: "BGF",
      country_code: "CF",
  },
  {
      name: "Basse-Kotto Prefecture",
      code: "BK",
      country_code: "CF",
  },
  {
      name: "Haut-Mbomou Prefecture",
      code: "HM",
      country_code: "CF",
  },
  {
      name: "Haute-Kotto Prefecture",
      code: "HK",
      country_code: "CF",
  },
  {
      name: "Kémo Prefecture",
      code: "KG",
      country_code: "CF",
  },
  {
      name: "Lobaye Prefecture",
      code: "LB",
      country_code: "CF",
  },
  {
      name: "Mambéré-Kadéï",
      code: "HS",
      country_code: "CF",
  },
  {
      name: "Mbomou Prefecture",
      code: "MB",
      country_code: "CF",
  },
  {
      name: "Nana-Grébizi Economic Prefecture",
      code: "KB",
      country_code: "CF",
  },
  {
      name: "Nana-Mambéré Prefecture",
      code: "NM",
      country_code: "CF",
  },
  {
      name: "Ombella-M'Poko Prefecture",
      code: "MP",
      country_code: "CF",
  },
  {
      name: "Ouaka Prefecture",
      code: "UK",
      country_code: "CF",
  },
  {
      name: "Ouham Prefecture",
      code: "AC",
      country_code: "CF",
  },
  {
      name: "Ouham-Pendé Prefecture",
      code: "OP",
      country_code: "CF",
  },
  {
      name: "Sangha-Mbaéré",
      code: "SE",
      country_code: "CF",
  },
  {
      name: "Vakaga Prefecture",
      code: "VK",
      country_code: "CF",
  },
  {
      name: "Bahr el Gazel",
      code: "BG",
      country_code: "TD",
  },
  {
      name: "Batha Region",
      code: "BA",
      country_code: "TD",
  },
  {
      name: "Borkou",
      code: "BO",
      country_code: "TD",
  },
  {
      name: "Ennedi Region",
      code: "EN",
      country_code: "TD",
  },
  {
      name: "Ennedi-Est",
      code: "EE",
      country_code: "TD",
  },
  {
      name: "Ennedi-Ouest",
      code: "EO",
      country_code: "TD",
  },
  {
      name: "Guéra Region",
      code: "GR",
      country_code: "TD",
  },
  {
      name: "Hadjer-Lamis",
      code: "HL",
      country_code: "TD",
  },
  {
      name: "Kanem Region",
      code: "KA",
      country_code: "TD",
  },
  {
      name: "Lac Region",
      code: "LC",
      country_code: "TD",
  },
  {
      name: "Logone Occidental Region",
      code: "LO",
      country_code: "TD",
  },
  {
      name: "Logone Oriental Region",
      code: "LR",
      country_code: "TD",
  },
  {
      name: "Mandoul Region",
      code: "MA",
      country_code: "TD",
  },
  {
      name: "Mayo-Kebbi Est Region",
      code: "ME",
      country_code: "TD",
  },
  {
      name: "Mayo-Kebbi Ouest Region",
      code: "MO",
      country_code: "TD",
  },
  {
      name: "Moyen-Chari Region",
      code: "MC",
      country_code: "TD",
  },
  {
      name: "N'Djamena",
      code: "ND",
      country_code: "TD",
  },
  {
      name: "Ouaddaï Region",
      code: "OD",
      country_code: "TD",
  },
  {
      name: "Salamat Region",
      code: "SA",
      country_code: "TD",
  },
  {
      name: "Sila Region",
      code: "SI",
      country_code: "TD",
  },
  {
      name: "Tandjilé Region",
      code: "TA",
      country_code: "TD",
  },
  {
      name: "Tibesti Region",
      code: "TI",
      country_code: "TD",
  },
  {
      name: "Wadi Fira Region",
      code: "WF",
      country_code: "TD",
  },
  {
      name: "Aisén del General Carlos Ibañez del Campo",
      code: "AI",
      country_code: "CL",
  },
  {
      name: "Antofagasta",
      code: "AN",
      country_code: "CL",
  },
  {
      name: "Arica y Parinacota",
      code: "AP",
      country_code: "CL",
  },
  {
      name: "Atacama",
      code: "AT",
      country_code: "CL",
  },
  {
      name: "Biobío",
      code: "BI",
      country_code: "CL",
  },
  {
      name: "Coquimbo",
      code: "CO",
      country_code: "CL",
  },
  {
      name: "La Araucanía",
      code: "AR",
      country_code: "CL",
  },
  {
      name: "Libertador General Bernardo O'Higgins",
      code: "LI",
      country_code: "CL",
  },
  {
      name: "Los Lagos",
      code: "LL",
      country_code: "CL",
  },
  {
      name: "Los Ríos",
      code: "LR",
      country_code: "CL",
  },
  {
      name: "Magallanes y de la Antártica Chilena",
      code: "MA",
      country_code: "CL",
  },
  {
      name: "Maule",
      code: "ML",
      country_code: "CL",
  },
  {
      name: "Ñuble",
      code: "NB",
      country_code: "CL",
  },
  {
      name: "Región Metropolitana de Santiago",
      code: "RM",
      country_code: "CL",
  },
  {
      name: "Tarapacá",
      code: "TA",
      country_code: "CL",
  },
  {
      name: "Valparaíso",
      code: "VS",
      country_code: "CL",
  },
  {
      name: "Anhui",
      code: "AH",
      country_code: "CN",
  },
  {
      name: "Beijing",
      code: "BJ",
      country_code: "CN",
  },
  {
      name: "Chongqing",
      code: "CQ",
      country_code: "CN",
  },
  {
      name: "Fujian",
      code: "FJ",
      country_code: "CN",
  },
  {
      name: "Gansu",
      code: "GS",
      country_code: "CN",
  },
  {
      name: "Guangdong",
      code: "GD",
      country_code: "CN",
  },
  {
      name: "Guangxi Zhuang Autonomous Region",
      code: "GX",
      country_code: "CN",
  },
  {
      name: "Guizhou",
      code: "GZ",
      country_code: "CN",
  },
  {
      name: "Hainan",
      code: "HI",
      country_code: "CN",
  },
  {
      name: "Hebei",
      code: "HE",
      country_code: "CN",
  },
  {
      name: "Heilongjiang",
      code: "HL",
      country_code: "CN",
  },
  {
      name: "Henan",
      code: "HA",
      country_code: "CN",
  },
  {
      name: "Hong Kong",
      code: "HK",
      country_code: "CN",
  },
  {
      name: "Hubei",
      code: "HB",
      country_code: "CN",
  },
  {
      name: "Hunan",
      code: "HN",
      country_code: "CN",
  },
  {
      name: "Inner Mongolia",
      code: "NM",
      country_code: "CN",
  },
  {
      name: "Jiangsu",
      code: "JS",
      country_code: "CN",
  },
  {
      name: "Jiangxi",
      code: "JX",
      country_code: "CN",
  },
  {
      name: "Jilin",
      code: "JL",
      country_code: "CN",
  },
  {
      name: "Keelung",
      code: "TW-KEE",
      country_code: "CN",
  },
  {
      name: "Liaoning",
      code: "LN",
      country_code: "CN",
  },
  {
      name: "Macau",
      code: "MO",
      country_code: "CN",
  },
  {
      name: "Ningxia Hui Autonomous Region",
      code: "NX",
      country_code: "CN",
  },
  {
      name: "Qinghai",
      code: "QH",
      country_code: "CN",
  },
  {
      name: "Shaanxi",
      code: "SN",
      country_code: "CN",
  },
  {
      name: "Shandong",
      code: "SD",
      country_code: "CN",
  },
  {
      name: "Shanghai",
      code: "SH",
      country_code: "CN",
  },
  {
      name: "Shanxi",
      code: "SX",
      country_code: "CN",
  },
  {
      name: "Sichuan",
      code: "SC",
      country_code: "CN",
  },
  {
      name: "Taiwan Province, People's Republic of China",
      code: "TW",
      country_code: "CN",
  },
  {
      name: "Tibet Autonomous Region",
      code: "XZ",
      country_code: "CN",
  },
  {
      name: "Xinjiang",
      code: "XJ",
      country_code: "CN",
  },
  {
      name: "Yunnan",
      code: "YN",
      country_code: "CN",
  },
  {
      name: "Zhejiang",
      code: "ZJ",
      country_code: "CN",
  },
  {
      name: "Amazonas",
      code: "AMA",
      country_code: "CO",
  },
  {
      name: "Antioquia",
      code: "ANT",
      country_code: "CO",
  },
  {
      name: "Arauca",
      code: "ARA",
      country_code: "CO",
  },
  {
      name: "Atlántico",
      code: "ATL",
      country_code: "CO",
  },
  {
      name: "Bogotá",
      code: "DC",
      country_code: "CO",
  },
  {
      name: "Bolívar",
      code: "BOL",
      country_code: "CO",
  },
  {
      name: "Boyacá",
      code: "BOY",
      country_code: "CO",
  },
  {
      name: "Caldas",
      code: "CAL",
      country_code: "CO",
  },
  {
      name: "Caquetá",
      code: "CAQ",
      country_code: "CO",
  },
  {
      name: "Casanare",
      code: "CAS",
      country_code: "CO",
  },
  {
      name: "Cauca",
      code: "CAU",
      country_code: "CO",
  },
  {
      name: "Cesar",
      code: "CES",
      country_code: "CO",
  },
  {
      name: "Chocó",
      code: "CHO",
      country_code: "CO",
  },
  {
      name: "Córdoba",
      code: "COR",
      country_code: "CO",
  },
  {
      name: "Cundinamarca",
      code: "CUN",
      country_code: "CO",
  },
  {
      name: "Guainía",
      code: "GUA",
      country_code: "CO",
  },
  {
      name: "Guaviare",
      code: "GUV",
      country_code: "CO",
  },
  {
      name: "Huila",
      code: "HUI",
      country_code: "CO",
  },
  {
      name: "La Guajira",
      code: "LAG",
      country_code: "CO",
  },
  {
      name: "Magdalena",
      code: "MAG",
      country_code: "CO",
  },
  {
      name: "Meta",
      code: "MET",
      country_code: "CO",
  },
  {
      name: "Nariño",
      code: "NAR",
      country_code: "CO",
  },
  {
      name: "Norte de Santander",
      code: "NSA",
      country_code: "CO",
  },
  {
      name: "Putumayo",
      code: "PUT",
      country_code: "CO",
  },
  {
      name: "Quindío",
      code: "QUI",
      country_code: "CO",
  },
  {
      name: "Risaralda",
      code: "RIS",
      country_code: "CO",
  },
  {
      name: "San Andrés, Providencia y Santa Catalina",
      code: "SAP",
      country_code: "CO",
  },
  {
      name: "Santander",
      code: "SAN",
      country_code: "CO",
  },
  {
      name: "Sucre",
      code: "SUC",
      country_code: "CO",
  },
  {
      name: "Tolima",
      code: "TOL",
      country_code: "CO",
  },
  {
      name: "Valle del Cauca",
      code: "VAC",
      country_code: "CO",
  },
  {
      name: "Vaupés",
      code: "VAU",
      country_code: "CO",
  },
  {
      name: "Vichada",
      code: "VID",
      country_code: "CO",
  },
  {
      name: "Anjouan",
      code: "A",
      country_code: "KM",
  },
  {
      name: "Grande Comore",
      code: "G",
      country_code: "KM",
  },
  {
      name: "Mohéli",
      code: "M",
      country_code: "KM",
  },
  {
      name: "Bouenza Department",
      code: "11",
      country_code: "CG",
  },
  {
      name: "Brazzaville",
      code: "BZV",
      country_code: "CG",
  },
  {
      name: "Cuvette Department",
      code: "8",
      country_code: "CG",
  },
  {
      name: "Cuvette-Ouest Department",
      code: "15",
      country_code: "CG",
  },
  {
      name: "Kouilou Department",
      code: "5",
      country_code: "CG",
  },
  {
      name: "Lékoumou Department",
      code: "2",
      country_code: "CG",
  },
  {
      name: "Likouala Department",
      code: "7",
      country_code: "CG",
  },
  {
      name: "Niari Department",
      code: "9",
      country_code: "CG",
  },
  {
      name: "Plateaux Department",
      code: "14",
      country_code: "CG",
  },
  {
      name: "Pointe-Noire",
      code: "16",
      country_code: "CG",
  },
  {
      name: "Pool Department",
      code: "12",
      country_code: "CG",
  },
  {
      name: "Sangha Department",
      code: "13",
      country_code: "CG",
  },
  {
      name: "Alajuela Province",
      code: "A",
      country_code: "CR",
  },
  {
      name: "Guanacaste Province",
      code: "G",
      country_code: "CR",
  },
  {
      name: "Heredia Province",
      code: "H",
      country_code: "CR",
  },
  {
      name: "Limón Province",
      code: "L",
      country_code: "CR",
  },
  {
      name: "Provincia de Cartago",
      code: "C",
      country_code: "CR",
  },
  {
      name: "Puntarenas Province",
      code: "P",
      country_code: "CR",
  },
  {
      name: "San José Province",
      code: "SJ",
      country_code: "CR",
  },
  {
      name: "Abidjan",
      code: "AB",
      country_code: "CI",
  },
  {
      name: "Agnéby",
      code: "16",
      country_code: "CI",
  },
  {
      name: "Bafing Region",
      code: "17",
      country_code: "CI",
  },
  {
      name: "Bas-Sassandra District",
      code: "BS",
      country_code: "CI",
  },
  {
      name: "Bas-Sassandra Region",
      code: "09",
      country_code: "CI",
  },
  {
      name: "Comoé District",
      code: "CM",
      country_code: "CI",
  },
  {
      name: "Denguélé District",
      code: "DN",
      country_code: "CI",
  },
  {
      name: "Denguélé Region",
      code: "10",
      country_code: "CI",
  },
  {
      name: "Dix-Huit Montagnes",
      code: "06",
      country_code: "CI",
  },
  {
      name: "Fromager",
      code: "18",
      country_code: "CI",
  },
  {
      name: "Gôh-Djiboua District",
      code: "GD",
      country_code: "CI",
  },
  {
      name: "Haut-Sassandra",
      code: "02",
      country_code: "CI",
  },
  {
      name: "Lacs District",
      code: "LC",
      country_code: "CI",
  },
  {
      name: "Lacs Region",
      code: "07",
      country_code: "CI",
  },
  {
      name: "Lagunes District",
      code: "LG",
      country_code: "CI",
  },
  {
      name: "Lagunes region",
      code: "01",
      country_code: "CI",
  },
  {
      name: "Marahoué Region",
      code: "12",
      country_code: "CI",
  },
  {
      name: "Montagnes District",
      code: "MG",
      country_code: "CI",
  },
  {
      name: "Moyen-Cavally",
      code: "19",
      country_code: "CI",
  },
  {
      name: "Moyen-Comoé",
      code: "05",
      country_code: "CI",
  },
  {
      name: "N'zi-Comoé",
      code: "11",
      country_code: "CI",
  },
  {
      name: "Sassandra-Marahoué District",
      code: "SM",
      country_code: "CI",
  },
  {
      name: "Savanes Region",
      code: "03",
      country_code: "CI",
  },
  {
      name: "Sud-Bandama",
      code: "15",
      country_code: "CI",
  },
  {
      name: "Sud-Comoé",
      code: "13",
      country_code: "CI",
  },
  {
      name: "Vallée du Bandama District",
      code: "VB",
      country_code: "CI",
  },
  {
      name: "Vallée du Bandama Region",
      code: "04",
      country_code: "CI",
  },
  {
      name: "Woroba District",
      code: "WR",
      country_code: "CI",
  },
  {
      name: "Worodougou",
      code: "14",
      country_code: "CI",
  },
  {
      name: "Yamoussoukro",
      code: "YM",
      country_code: "CI",
  },
  {
      name: "Zanzan Region",
      code: "ZZ",
      country_code: "CI",
  },
  {
      name: "Bjelovar-Bilogora County",
      code: "07",
      country_code: "HR",
  },
  {
      name: "Brod-Posavina County",
      code: "12",
      country_code: "HR",
  },
  {
      name: "Dubrovnik-Neretva County",
      code: "19",
      country_code: "HR",
  },
  {
      name: "Istria County",
      code: "18",
      country_code: "HR",
  },
  {
      name: "Koprivnica-Križevci County",
      code: "06",
      country_code: "HR",
  },
  {
      name: "Krapina-Zagorje County",
      code: "02",
      country_code: "HR",
  },
  {
      name: "Lika-Senj County",
      code: "09",
      country_code: "HR",
  },
  {
      name: "Međimurje County",
      code: "20",
      country_code: "HR",
  },
  {
      name: "Osijek-Baranja County",
      code: "14",
      country_code: "HR",
  },
  {
      name: "Požega-Slavonia County",
      code: "11",
      country_code: "HR",
  },
  {
      name: "Primorje-Gorski Kotar County",
      code: "08",
      country_code: "HR",
  },
  {
      name: "Šibenik-Knin County",
      code: "15",
      country_code: "HR",
  },
  {
      name: "Sisak-Moslavina County",
      code: "03",
      country_code: "HR",
  },
  {
      name: "Split-Dalmatia County",
      code: "17",
      country_code: "HR",
  },
  {
      name: "Varaždin County",
      code: "05",
      country_code: "HR",
  },
  {
      name: "Virovitica-Podravina County",
      code: "10",
      country_code: "HR",
  },
  {
      name: "Vukovar-Syrmia County",
      code: "16",
      country_code: "HR",
  },
  {
      name: "Zadar County",
      code: "13",
      country_code: "HR",
  },
  {
      name: "Zagreb",
      code: "21",
      country_code: "HR",
  },
  {
      name: "Zagreb County",
      code: "01",
      country_code: "HR",
  },
  {
      name: "Artemisa Province",
      code: "15",
      country_code: "CU",
  },
  {
      name: "Camagüey Province",
      code: "09",
      country_code: "CU",
  },
  {
      name: "Ciego de Ávila Province",
      code: "08",
      country_code: "CU",
  },
  {
      name: "Cienfuegos Province",
      code: "06",
      country_code: "CU",
  },
  {
      name: "Granma Province",
      code: "12",
      country_code: "CU",
  },
  {
      name: "Guantánamo Province",
      code: "14",
      country_code: "CU",
  },
  {
      name: "Havana Province",
      code: "03",
      country_code: "CU",
  },
  {
      name: "Holguín Province",
      code: "11",
      country_code: "CU",
  },
  {
      name: "Isla de la Juventud",
      code: "99",
      country_code: "CU",
  },
  {
      name: "Las Tunas Province",
      code: "10",
      country_code: "CU",
  },
  {
      name: "Matanzas Province",
      code: "04",
      country_code: "CU",
  },
  {
      name: "Mayabeque Province",
      code: "16",
      country_code: "CU",
  },
  {
      name: "Pinar del Río Province",
      code: "01",
      country_code: "CU",
  },
  {
      name: "Sancti Spíritus Province",
      code: "07",
      country_code: "CU",
  },
  {
      name: "Santiago de Cuba Province",
      code: "13",
      country_code: "CU",
  },
  {
      name: "Villa Clara Province",
      code: "05",
      country_code: "CU",
  },
  {
      name: "Famagusta District",
      code: "04",
      country_code: "CY",
  },
  {
      name: "Kyrenia District",
      code: "06",
      country_code: "CY",
  },
  {
      name: "Larnaca District",
      code: "03",
      country_code: "CY",
  },
  {
      name: "Limassol District",
      code: "02",
      country_code: "CY",
  },
  {
      name: "Nicosia District",
      code: "01",
      country_code: "CY",
  },
  {
      name: "Paphos District",
      code: "05",
      country_code: "CY",
  },
  {
      name: "Benešov",
      code: "201",
      country_code: "CZ",
  },
  {
      name: "Beroun",
      code: "202",
      country_code: "CZ",
  },
  {
      name: "Blansko",
      code: "641",
      country_code: "CZ",
  },
  {
      name: "Břeclav",
      code: "644",
      country_code: "CZ",
  },
  {
      name: "Brno-město",
      code: "642",
      country_code: "CZ",
  },
  {
      name: "Brno-venkov",
      code: "643",
      country_code: "CZ",
  },
  {
      name: "Bruntál",
      code: "801",
      country_code: "CZ",
  },
  {
      name: "Česká Lípa",
      code: "511",
      country_code: "CZ",
  },
  {
      name: "České Budějovice",
      code: "311",
      country_code: "CZ",
  },
  {
      name: "Český Krumlov",
      code: "312",
      country_code: "CZ",
  },
  {
      name: "Cheb",
      code: "411",
      country_code: "CZ",
  },
  {
      name: "Chomutov",
      code: "422",
      country_code: "CZ",
  },
  {
      name: "Chrudim",
      code: "531",
      country_code: "CZ",
  },
  {
      name: "Děčín",
      code: "421",
      country_code: "CZ",
  },
  {
      name: "Domažlice",
      code: "321",
      country_code: "CZ",
  },
  {
      name: "Frýdek-Místek",
      code: "802",
      country_code: "CZ",
  },
  {
      name: "Havlíčkův Brod",
      code: "631",
      country_code: "CZ",
  },
  {
      name: "Hodonín",
      code: "645",
      country_code: "CZ",
  },
  {
      name: "Hradec Králové",
      code: "521",
      country_code: "CZ",
  },
  {
      name: "Jablonec nad Nisou",
      code: "512",
      country_code: "CZ",
  },
  {
      name: "Jeseník",
      code: "711",
      country_code: "CZ",
  },
  {
      name: "Jičín",
      code: "522",
      country_code: "CZ",
  },
  {
      name: "Jihlava",
      code: "632",
      country_code: "CZ",
  },
  {
      name: "Jihočeský kraj",
      code: "31",
      country_code: "CZ",
  },
  {
      name: "Jihomoravský kraj",
      code: "64",
      country_code: "CZ",
  },
  {
      name: "Jindřichův Hradec",
      code: "313",
      country_code: "CZ",
  },
  {
      name: "Karlovarský kraj",
      code: "41",
      country_code: "CZ",
  },
  {
      name: "Karlovy Vary",
      code: "412",
      country_code: "CZ",
  },
  {
      name: "Karviná",
      code: "803",
      country_code: "CZ",
  },
  {
      name: "Kladno",
      code: "203",
      country_code: "CZ",
  },
  {
      name: "Klatovy",
      code: "322",
      country_code: "CZ",
  },
  {
      name: "Kolín",
      code: "204",
      country_code: "CZ",
  },
  {
      name: "Kraj Vysočina",
      code: "63",
      country_code: "CZ",
  },
  {
      name: "Královéhradecký kraj",
      code: "52",
      country_code: "CZ",
  },
  {
      name: "Kroměříž",
      code: "721",
      country_code: "CZ",
  },
  {
      name: "Kutná Hora",
      code: "205",
      country_code: "CZ",
  },
  {
      name: "Liberec",
      code: "513",
      country_code: "CZ",
  },
  {
      name: "Liberecký kraj",
      code: "51",
      country_code: "CZ",
  },
  {
      name: "Litoměřice",
      code: "423",
      country_code: "CZ",
  },
  {
      name: "Louny",
      code: "424",
      country_code: "CZ",
  },
  {
      name: "Mělník",
      code: "206",
      country_code: "CZ",
  },
  {
      name: "Mladá Boleslav",
      code: "207",
      country_code: "CZ",
  },
  {
      name: "Moravskoslezský kraj",
      code: "80",
      country_code: "CZ",
  },
  {
      name: "Most",
      code: "425",
      country_code: "CZ",
  },
  {
      name: "Náchod",
      code: "523",
      country_code: "CZ",
  },
  {
      name: "Nový Jičín",
      code: "804",
      country_code: "CZ",
  },
  {
      name: "Nymburk",
      code: "208",
      country_code: "CZ",
  },
  {
      name: "Olomouc",
      code: "712",
      country_code: "CZ",
  },
  {
      name: "Olomoucký kraj",
      code: "71",
      country_code: "CZ",
  },
  {
      name: "Opava",
      code: "805",
      country_code: "CZ",
  },
  {
      name: "Ostrava-město",
      code: "806",
      country_code: "CZ",
  },
  {
      name: "Pardubice",
      code: "532",
      country_code: "CZ",
  },
  {
      name: "Pardubický kraj",
      code: "53",
      country_code: "CZ",
  },
  {
      name: "Pelhřimov",
      code: "633",
      country_code: "CZ",
  },
  {
      name: "Písek",
      code: "314",
      country_code: "CZ",
  },
  {
      name: "Plzeň-jih",
      code: "324",
      country_code: "CZ",
  },
  {
      name: "Plzeň-město",
      code: "323",
      country_code: "CZ",
  },
  {
      name: "Plzeň-sever",
      code: "325",
      country_code: "CZ",
  },
  {
      name: "Plzeňský kraj",
      code: "32",
      country_code: "CZ",
  },
  {
      name: "Prachatice",
      code: "315",
      country_code: "CZ",
  },
  {
      name: "Praha-východ",
      code: "209",
      country_code: "CZ",
  },
  {
      name: "Praha-západ",
      code: "20A",
      country_code: "CZ",
  },
  {
      name: "Praha, Hlavní město",
      code: "10",
      country_code: "CZ",
  },
  {
      name: "Přerov",
      code: "714",
      country_code: "CZ",
  },
  {
      name: "Příbram",
      code: "20B",
      country_code: "CZ",
  },
  {
      name: "Prostějov",
      code: "713",
      country_code: "CZ",
  },
  {
      name: "Rakovník",
      code: "20C",
      country_code: "CZ",
  },
  {
      name: "Rokycany",
      code: "326",
      country_code: "CZ",
  },
  {
      name: "Rychnov nad Kněžnou",
      code: "524",
      country_code: "CZ",
  },
  {
      name: "Semily",
      code: "514",
      country_code: "CZ",
  },
  {
      name: "Sokolov",
      code: "413",
      country_code: "CZ",
  },
  {
      name: "Strakonice",
      code: "316",
      country_code: "CZ",
  },
  {
      name: "Středočeský kraj",
      code: "20",
      country_code: "CZ",
  },
  {
      name: "Šumperk",
      code: "715",
      country_code: "CZ",
  },
  {
      name: "Svitavy",
      code: "533",
      country_code: "CZ",
  },
  {
      name: "Tábor",
      code: "317",
      country_code: "CZ",
  },
  {
      name: "Tachov",
      code: "327",
      country_code: "CZ",
  },
  {
      name: "Teplice",
      code: "426",
      country_code: "CZ",
  },
  {
      name: "Třebíč",
      code: "634",
      country_code: "CZ",
  },
  {
      name: "Trutnov",
      code: "525",
      country_code: "CZ",
  },
  {
      name: "Uherské Hradiště",
      code: "722",
      country_code: "CZ",
  },
  {
      name: "Ústecký kraj",
      code: "42",
      country_code: "CZ",
  },
  {
      name: "Ústí nad Labem",
      code: "427",
      country_code: "CZ",
  },
  {
      name: "Ústí nad Orlicí",
      code: "534",
      country_code: "CZ",
  },
  {
      name: "Vsetín",
      code: "723",
      country_code: "CZ",
  },
  {
      name: "Vyškov",
      code: "646",
      country_code: "CZ",
  },
  {
      name: "Žďár nad Sázavou",
      code: "635",
      country_code: "CZ",
  },
  {
      name: "Zlín",
      code: "724",
      country_code: "CZ",
  },
  {
      name: "Zlínský kraj",
      code: "72",
      country_code: "CZ",
  },
  {
      name: "Znojmo",
      code: "647",
      country_code: "CZ",
  },
  {
      name: "Bas-Uélé",
      code: "BU",
      country_code: "CD",
  },
  {
      name: "Équateur",
      code: "EQ",
      country_code: "CD",
  },
  {
      name: "Haut-Katanga",
      code: "HK",
      country_code: "CD",
  },
  {
      name: "Haut-Lomami",
      code: "HL",
      country_code: "CD",
  },
  {
      name: "Haut-Uélé",
      code: "HU",
      country_code: "CD",
  },
  {
      name: "Ituri",
      code: "IT",
      country_code: "CD",
  },
  {
      name: "Kasaï",
      code: "KS",
      country_code: "CD",
  },
  {
      name: "Kasaï Central",
      code: "KC",
      country_code: "CD",
  },
  {
      name: "Kasaï Oriental",
      code: "KE",
      country_code: "CD",
  },
  {
      name: "Kinshasa",
      code: "KN",
      country_code: "CD",
  },
  {
      name: "Kongo Central",
      code: "BC",
      country_code: "CD",
  },
  {
      name: "Kwango",
      code: "KG",
      country_code: "CD",
  },
  {
      name: "Kwilu",
      code: "KL",
      country_code: "CD",
  },
  {
      name: "Lomami",
      code: "LO",
      country_code: "CD",
  },
  {
      name: "Lualaba",
      code: "LU",
      country_code: "CD",
  },
  {
      name: "Mai-Ndombe",
      code: "MN",
      country_code: "CD",
  },
  {
      name: "Maniema",
      code: "MA",
      country_code: "CD",
  },
  {
      name: "Mongala",
      code: "MO",
      country_code: "CD",
  },
  {
      name: "Nord-Kivu",
      code: "NK",
      country_code: "CD",
  },
  {
      name: "Nord-Ubangi",
      code: "NU",
      country_code: "CD",
  },
  {
      name: "Sankuru",
      code: "SA",
      country_code: "CD",
  },
  {
      name: "Sud-Kivu",
      code: "SK",
      country_code: "CD",
  },
  {
      name: "Sud-Ubangi",
      code: "SU",
      country_code: "CD",
  },
  {
      name: "Tanganyika",
      code: "TA",
      country_code: "CD",
  },
  {
      name: "Tshopo",
      code: "TO",
      country_code: "CD",
  },
  {
      name: "Tshuapa",
      code: "TU",
      country_code: "CD",
  },
  {
      name: "Capital Region of Denmark",
      code: "84",
      country_code: "DK",
  },
  {
      name: "Central Denmark Region",
      code: "82",
      country_code: "DK",
  },
  {
      name: "North Denmark Region",
      code: "81",
      country_code: "DK",
  },
  {
      name: "Region of Southern Denmark",
      code: "83",
      country_code: "DK",
  },
  {
      name: "Region Zealand",
      code: "85",
      country_code: "DK",
  },
  {
      name: "Ali Sabieh Region",
      code: "AS",
      country_code: "DJ",
  },
  {
      name: "Arta Region",
      code: "AR",
      country_code: "DJ",
  },
  {
      name: "Dikhil Region",
      code: "DI",
      country_code: "DJ",
  },
  {
      name: "Djibouti",
      code: "DJ",
      country_code: "DJ",
  },
  {
      name: "Obock Region",
      code: "OB",
      country_code: "DJ",
  },
  {
      name: "Tadjourah Region",
      code: "TA",
      country_code: "DJ",
  },
  {
      name: "Saint Andrew Parish",
      code: "02",
      country_code: "DM",
  },
  {
      name: "Saint David Parish",
      code: "03",
      country_code: "DM",
  },
  {
      name: "Saint George Parish",
      code: "04",
      country_code: "DM",
  },
  {
      name: "Saint John Parish",
      code: "05",
      country_code: "DM",
  },
  {
      name: "Saint Joseph Parish",
      code: "06",
      country_code: "DM",
  },
  {
      name: "Saint Luke Parish",
      code: "07",
      country_code: "DM",
  },
  {
      name: "Saint Mark Parish",
      code: "08",
      country_code: "DM",
  },
  {
      name: "Saint Patrick Parish",
      code: "09",
      country_code: "DM",
  },
  {
      name: "Saint Paul Parish",
      code: "10",
      country_code: "DM",
  },
  {
      name: "Saint Peter Parish",
      code: "11",
      country_code: "DM",
  },
  {
      name: "Azua Province",
      code: "02",
      country_code: "DO",
  },
  {
      name: "Baoruco Province",
      code: "03",
      country_code: "DO",
  },
  {
      name: "Barahona Province",
      code: "04",
      country_code: "DO",
  },
  {
      name: "Dajabón Province",
      code: "05",
      country_code: "DO",
  },
  {
      name: "Distrito Nacional",
      code: "01",
      country_code: "DO",
  },
  {
      name: "Duarte Province",
      code: "06",
      country_code: "DO",
  },
  {
      name: "El Seibo Province",
      code: "08",
      country_code: "DO",
  },
  {
      name: "Espaillat Province",
      code: "09",
      country_code: "DO",
  },
  {
      name: "Hato Mayor Province",
      code: "30",
      country_code: "DO",
  },
  {
      name: "Hermanas Mirabal Province",
      code: "19",
      country_code: "DO",
  },
  {
      name: "Independencia",
      code: "10",
      country_code: "DO",
  },
  {
      name: "La Altagracia Province",
      code: "11",
      country_code: "DO",
  },
  {
      name: "La Romana Province",
      code: "12",
      country_code: "DO",
  },
  {
      name: "La Vega Province",
      code: "13",
      country_code: "DO",
  },
  {
      name: "María Trinidad Sánchez Province",
      code: "14",
      country_code: "DO",
  },
  {
      name: "Monseñor Nouel Province",
      code: "28",
      country_code: "DO",
  },
  {
      name: "Monte Cristi Province",
      code: "15",
      country_code: "DO",
  },
  {
      name: "Monte Plata Province",
      code: "29",
      country_code: "DO",
  },
  {
      name: "Pedernales Province",
      code: "16",
      country_code: "DO",
  },
  {
      name: "Peravia Province",
      code: "17",
      country_code: "DO",
  },
  {
      name: "Puerto Plata Province",
      code: "18",
      country_code: "DO",
  },
  {
      name: "Samaná Province",
      code: "20",
      country_code: "DO",
  },
  {
      name: "San Cristóbal Province",
      code: "21",
      country_code: "DO",
  },
  {
      name: "San José de Ocoa Province",
      code: "31",
      country_code: "DO",
  },
  {
      name: "San Juan Province",
      code: "22",
      country_code: "DO",
  },
  {
      name: "San Pedro de Macorís",
      code: "23",
      country_code: "DO",
  },
  {
      name: "Sánchez Ramírez Province",
      code: "24",
      country_code: "DO",
  },
  {
      name: "Santiago Province",
      code: "25",
      country_code: "DO",
  },
  {
      name: "Santiago Rodríguez Province",
      code: "26",
      country_code: "DO",
  },
  {
      name: "Santo Domingo Province",
      code: "32",
      country_code: "DO",
  },
  {
      name: "Valverde Province",
      code: "27",
      country_code: "DO",
  },
  {
      name: "Aileu municipality",
      code: "AL",
      country_code: "TL",
  },
  {
      name: "Ainaro Municipality",
      code: "AN",
      country_code: "TL",
  },
  {
      name: "Baucau Municipality",
      code: "BA",
      country_code: "TL",
  },
  {
      name: "Bobonaro Municipality",
      code: "BO",
      country_code: "TL",
  },
  {
      name: "Cova Lima Municipality",
      code: "CO",
      country_code: "TL",
  },
  {
      name: "Dili municipality",
      code: "DI",
      country_code: "TL",
  },
  {
      name: "Ermera District",
      code: "ER",
      country_code: "TL",
  },
  {
      name: "Lautém Municipality",
      code: "LA",
      country_code: "TL",
  },
  {
      name: "Liquiçá Municipality",
      code: "LI",
      country_code: "TL",
  },
  {
      name: "Manatuto District",
      code: "MT",
      country_code: "TL",
  },
  {
      name: "Manufahi Municipality",
      code: "MF",
      country_code: "TL",
  },
  {
      name: "Viqueque Municipality",
      code: "VI",
      country_code: "TL",
  },
  {
      name: "Azuay Province",
      code: "A",
      country_code: "EC",
  },
  {
      name: "Bolívar Province",
      code: "B",
      country_code: "EC",
  },
  {
      name: "Cañar Province",
      code: "F",
      country_code: "EC",
  },
  {
      name: "Carchi Province",
      code: "C",
      country_code: "EC",
  },
  {
      name: "Chimborazo Province",
      code: "H",
      country_code: "EC",
  },
  {
      name: "Cotopaxi Province",
      code: "X",
      country_code: "EC",
  },
  {
      name: "El Oro Province",
      code: "O",
      country_code: "EC",
  },
  {
      name: "Esmeraldas",
      code: "E",
      country_code: "EC",
  },
  {
      name: "Galápagos Province",
      code: "W",
      country_code: "EC",
  },
  {
      name: "Guayas Province",
      code: "G",
      country_code: "EC",
  },
  {
      name: "Imbabura Province",
      code: "I",
      country_code: "EC",
  },
  {
      name: "Los Ríos Province",
      code: "R",
      country_code: "EC",
  },
  {
      name: "Manabí Province",
      code: "M",
      country_code: "EC",
  },
  {
      name: "Morona-Santiago Province",
      code: "S",
      country_code: "EC",
  },
  {
      name: "Napo Province",
      code: "N",
      country_code: "EC",
  },
  {
      name: "Orellana Province",
      code: "D",
      country_code: "EC",
  },
  {
      name: "Pastaza Province",
      code: "Y",
      country_code: "EC",
  },
  {
      name: "Pichincha Province",
      code: "P",
      country_code: "EC",
  },
  {
      name: "Santa Elena Province",
      code: "SE",
      country_code: "EC",
  },
  {
      name: "Santo Domingo de los Tsáchilas Province",
      code: "SD",
      country_code: "EC",
  },
  {
      name: "Sucumbíos Province",
      code: "U",
      country_code: "EC",
  },
  {
      name: "Tungurahua Province",
      code: "T",
      country_code: "EC",
  },
  {
      name: "Zamora-Chinchipe Province",
      code: "Z",
      country_code: "EC",
  },
  {
      name: "Alexandria Governorate",
      code: "ALX",
      country_code: "EG",
  },
  {
      name: "Aswan Governorate",
      code: "ASN",
      country_code: "EG",
  },
  {
      name: "Asyut Governorate",
      code: "AST",
      country_code: "EG",
  },
  {
      name: "Beheira Governorate",
      code: "BH",
      country_code: "EG",
  },
  {
      name: "Beni Suef Governorate",
      code: "BNS",
      country_code: "EG",
  },
  {
      name: "Cairo Governorate",
      code: "C",
      country_code: "EG",
  },
  {
      name: "Dakahlia Governorate",
      code: "DK",
      country_code: "EG",
  },
  {
      name: "Damietta Governorate",
      code: "DT",
      country_code: "EG",
  },
  {
      name: "Faiyum Governorate",
      code: "FYM",
      country_code: "EG",
  },
  {
      name: "Gharbia Governorate",
      code: "GH",
      country_code: "EG",
  },
  {
      name: "Giza Governorate",
      code: "GZ",
      country_code: "EG",
  },
  {
      name: "Ismailia Governorate",
      code: "IS",
      country_code: "EG",
  },
  {
      name: "Kafr el-Sheikh Governorate",
      code: "KFS",
      country_code: "EG",
  },
  {
      name: "Luxor Governorate",
      code: "LX",
      country_code: "EG",
  },
  {
      name: "Matrouh Governorate",
      code: "MT",
      country_code: "EG",
  },
  {
      name: "Minya Governorate",
      code: "MN",
      country_code: "EG",
  },
  {
      name: "Monufia Governorate",
      code: "MNF",
      country_code: "EG",
  },
  {
      name: "New Valley Governorate",
      code: "WAD",
      country_code: "EG",
  },
  {
      name: "North Sinai Governorate",
      code: "SIN",
      country_code: "EG",
  },
  {
      name: "Port Said Governorate",
      code: "PTS",
      country_code: "EG",
  },
  {
      name: "Qalyubia Governorate",
      code: "KB",
      country_code: "EG",
  },
  {
      name: "Qena Governorate",
      code: "KN",
      country_code: "EG",
  },
  {
      name: "Red Sea Governorate",
      code: "BA",
      country_code: "EG",
  },
  {
      name: "Sohag Governorate",
      code: "SHG",
      country_code: "EG",
  },
  {
      name: "South Sinai Governorate",
      code: "JS",
      country_code: "EG",
  },
  {
      name: "Suez Governorate",
      code: "SUZ",
      country_code: "EG",
  },
  {
      name: "Ahuachapán Department",
      code: "AH",
      country_code: "SV",
  },
  {
      name: "Cabañas Department",
      code: "CA",
      country_code: "SV",
  },
  {
      name: "Chalatenango Department",
      code: "CH",
      country_code: "SV",
  },
  {
      name: "Cuscatlán Department",
      code: "CU",
      country_code: "SV",
  },
  {
      name: "La Libertad Department",
      code: "LI",
      country_code: "SV",
  },
  {
      name: "La Paz Department",
      code: "PA",
      country_code: "SV",
  },
  {
      name: "La Unión Department",
      code: "UN",
      country_code: "SV",
  },
  {
      name: "Morazán Department",
      code: "MO",
      country_code: "SV",
  },
  {
      name: "San Miguel Department",
      code: "SM",
      country_code: "SV",
  },
  {
      name: "San Salvador Department",
      code: "SS",
      country_code: "SV",
  },
  {
      name: "San Vicente Department",
      code: "SV",
      country_code: "SV",
  },
  {
      name: "Santa Ana Department",
      code: "SA",
      country_code: "SV",
  },
  {
      name: "Sonsonate Department",
      code: "SO",
      country_code: "SV",
  },
  {
      name: "Usulután Department",
      code: "US",
      country_code: "SV",
  },
  {
      name: "Annobón Province",
      code: "AN",
      country_code: "GQ",
  },
  {
      name: "Bioko Norte Province",
      code: "BN",
      country_code: "GQ",
  },
  {
      name: "Bioko Sur Province",
      code: "BS",
      country_code: "GQ",
  },
  {
      name: "Centro Sur Province",
      code: "CS",
      country_code: "GQ",
  },
  {
      name: "Insular Region",
      code: "I",
      country_code: "GQ",
  },
  {
      name: "Kié-Ntem Province",
      code: "KN",
      country_code: "GQ",
  },
  {
      name: "Litoral Province",
      code: "LI",
      country_code: "GQ",
  },
  {
      name: "Río Muni",
      code: "C",
      country_code: "GQ",
  },
  {
      name: "Wele-Nzas Province",
      code: "WN",
      country_code: "GQ",
  },
  {
      name: "Anseba Region",
      code: "AN",
      country_code: "ER",
  },
  {
      name: "Debub Region",
      code: "DU",
      country_code: "ER",
  },
  {
      name: "Gash-Barka Region",
      code: "GB",
      country_code: "ER",
  },
  {
      name: "Maekel Region",
      code: "MA",
      country_code: "ER",
  },
  {
      name: "Northern Red Sea Region",
      code: "SK",
      country_code: "ER",
  },
  {
      name: "Southern Red Sea Region",
      code: "DK",
      country_code: "ER",
  },
  {
      name: "Harju County",
      code: "37",
      country_code: "EE",
  },
  {
      name: "Hiiu County",
      code: "39",
      country_code: "EE",
  },
  {
      name: "Ida-Viru County",
      code: "44",
      country_code: "EE",
  },
  {
      name: "Järva County",
      code: "51",
      country_code: "EE",
  },
  {
      name: "Jõgeva County",
      code: "49",
      country_code: "EE",
  },
  {
      name: "Lääne County",
      code: "57",
      country_code: "EE",
  },
  {
      name: "Lääne-Viru County",
      code: "59",
      country_code: "EE",
  },
  {
      name: "Pärnu County",
      code: "67",
      country_code: "EE",
  },
  {
      name: "Põlva County",
      code: "65",
      country_code: "EE",
  },
  {
      name: "Rapla County",
      code: "70",
      country_code: "EE",
  },
  {
      name: "Saare County",
      code: "74",
      country_code: "EE",
  },
  {
      name: "Tartu County",
      code: "78",
      country_code: "EE",
  },
  {
      name: "Valga County",
      code: "82",
      country_code: "EE",
  },
  {
      name: "Viljandi County",
      code: "84",
      country_code: "EE",
  },
  {
      name: "Võru County",
      code: "86",
      country_code: "EE",
  },
  {
      name: "Addis Ababa",
      code: "AA",
      country_code: "ET",
  },
  {
      name: "Afar Region",
      code: "AF",
      country_code: "ET",
  },
  {
      name: "Amhara Region",
      code: "AM",
      country_code: "ET",
  },
  {
      name: "Benishangul-Gumuz Region",
      code: "BE",
      country_code: "ET",
  },
  {
      name: "Dire Dawa",
      code: "DD",
      country_code: "ET",
  },
  {
      name: "Gambela Region",
      code: "GA",
      country_code: "ET",
  },
  {
      name: "Harari Region",
      code: "HA",
      country_code: "ET",
  },
  {
      name: "Oromia Region",
      code: "OR",
      country_code: "ET",
  },
  {
      name: "Somali Region",
      code: "SO",
      country_code: "ET",
  },
  {
      name: "Southern Nations, Nationalities, and Peoples' Region",
      code: "SN",
      country_code: "ET",
  },
  {
      name: "Tigray Region",
      code: "TI",
      country_code: "ET",
  },
  {
      name: "Ba",
      code: "01",
      country_code: "FJ",
  },
  {
      name: "Bua",
      code: "02",
      country_code: "FJ",
  },
  {
      name: "Cakaudrove",
      code: "03",
      country_code: "FJ",
  },
  {
      name: "Central Division",
      code: "C",
      country_code: "FJ",
  },
  {
      name: "Eastern Division",
      code: "E",
      country_code: "FJ",
  },
  {
      name: "Kadavu",
      code: "04",
      country_code: "FJ",
  },
  {
      name: "Lau",
      code: "05",
      country_code: "FJ",
  },
  {
      name: "Lomaiviti",
      code: "06",
      country_code: "FJ",
  },
  {
      name: "Macuata",
      code: "07",
      country_code: "FJ",
  },
  {
      name: "Nadroga-Navosa",
      code: "08",
      country_code: "FJ",
  },
  {
      name: "Naitasiri",
      code: "09",
      country_code: "FJ",
  },
  {
      name: "Namosi",
      code: "10",
      country_code: "FJ",
  },
  {
      name: "Northern Division",
      code: "N",
      country_code: "FJ",
  },
  {
      name: "Ra",
      code: "11",
      country_code: "FJ",
  },
  {
      name: "Rewa",
      code: "12",
      country_code: "FJ",
  },
  {
      name: "Rotuma",
      code: "R",
      country_code: "FJ",
  },
  {
      name: "Serua",
      code: "13",
      country_code: "FJ",
  },
  {
      name: "Tailevu",
      code: "14",
      country_code: "FJ",
  },
  {
      name: "Western Division",
      code: "W",
      country_code: "FJ",
  },
  {
      name: "Åland Islands",
      code: "01",
      country_code: "FI",
  },
  {
      name: "Central Finland",
      code: "08",
      country_code: "FI",
  },
  {
      name: "Central Ostrobothnia",
      code: "07",
      country_code: "FI",
  },
  {
      name: "Eastern Finland Province",
      code: "IS",
      country_code: "FI",
  },
  {
      name: "Finland Proper",
      code: "19",
      country_code: "FI",
  },
  {
      name: "Kainuu",
      code: "05",
      country_code: "FI",
  },
  {
      name: "Kymenlaakso",
      code: "09",
      country_code: "FI",
  },
  {
      name: "Lapland",
      code: "LL",
      country_code: "FI",
  },
  {
      name: "North Karelia",
      code: "13",
      country_code: "FI",
  },
  {
      name: "Northern Ostrobothnia",
      code: "14",
      country_code: "FI",
  },
  {
      name: "Northern Savonia",
      code: "15",
      country_code: "FI",
  },
  {
      name: "Ostrobothnia",
      code: "12",
      country_code: "FI",
  },
  {
      name: "Oulu Province",
      code: "OL",
      country_code: "FI",
  },
  {
      name: "Päijänne Tavastia",
      code: "16",
      country_code: "FI",
  },
  {
      name: "Pirkanmaa",
      code: "11",
      country_code: "FI",
  },
  {
      name: "Satakunta",
      code: "17",
      country_code: "FI",
  },
  {
      name: "South Karelia",
      code: "02",
      country_code: "FI",
  },
  {
      name: "Southern Ostrobothnia",
      code: "03",
      country_code: "FI",
  },
  {
      name: "Southern Savonia",
      code: "04",
      country_code: "FI",
  },
  {
      name: "Tavastia Proper",
      code: "06",
      country_code: "FI",
  },
  {
      name: "Uusimaa",
      code: "18",
      country_code: "FI",
  },
  {
      name: "Alo",
      code: "WF-AL",
      country_code: "FR",
  },
  {
      name: "Alsace",
      code: "A",
      country_code: "FR",
  },
  {
      name: "Aquitaine",
      code: "B",
      country_code: "FR",
  },
  {
      name: "Auvergne",
      code: "C",
      country_code: "FR",
  },
  {
      name: "Auvergne-Rhône-Alpes",
      code: "ARA",
      country_code: "FR",
  },
  {
      name: "Bourgogne-Franche-Comté",
      code: "BFC",
      country_code: "FR",
  },
  {
      name: "Brittany",
      code: "BRE",
      country_code: "FR",
  },
  {
      name: "Burgundy",
      code: "D",
      country_code: "FR",
  },
  {
      name: "Centre-Val de Loire",
      code: "CVL",
      country_code: "FR",
  },
  {
      name: "Champagne-Ardenne",
      code: "G",
      country_code: "FR",
  },
  {
      name: "Corsica",
      code: "COR",
      country_code: "FR",
  },
  {
      name: "Franche-Comté",
      code: "I",
      country_code: "FR",
  },
  {
      name: "French Guiana",
      code: "GF",
      country_code: "FR",
  },
  {
      name: "French Polynesia",
      code: "PF",
      country_code: "FR",
  },
  {
      name: "Grand Est",
      code: "GES",
      country_code: "FR",
  },
  {
      name: "Guadeloupe",
      code: "GP",
      country_code: "FR",
  },
  {
      name: "Hauts-de-France",
      code: "HDF",
      country_code: "FR",
  },
  {
      name: "Île-de-France",
      code: "IDF",
      country_code: "FR",
  },
  {
      name: "Languedoc-Roussillon",
      code: "K",
      country_code: "FR",
  },
  {
      name: "Limousin",
      code: "L",
      country_code: "FR",
  },
  {
      name: "Lorraine",
      code: "M",
      country_code: "FR",
  },
  {
      name: "Lower Normandy",
      code: "P",
      country_code: "FR",
  },
  {
      name: "Martinique",
      code: "MQ",
      country_code: "FR",
  },
  {
      name: "Mayotte",
      code: "YT",
      country_code: "FR",
  },
  {
      name: "Nord-Pas-de-Calais",
      code: "O",
      country_code: "FR",
  },
  {
      name: "Normandy",
      code: "NOR",
      country_code: "FR",
  },
  {
      name: "Nouvelle-Aquitaine",
      code: "NAQ",
      country_code: "FR",
  },
  {
      name: "Occitania",
      code: "OCC",
      country_code: "FR",
  },
  {
      name: "Paris",
      code: "75",
      country_code: "FR",
  },
  {
      name: "Pays de la Loire",
      code: "PDL",
      country_code: "FR",
  },
  {
      name: "Picardy",
      code: "S",
      country_code: "FR",
  },
  {
      name: "Poitou-Charentes",
      code: "T",
      country_code: "FR",
  },
  {
      name: "Provence-Alpes-Côte d'Azur",
      code: "PAC",
      country_code: "FR",
  },
  {
      name: "Réunion",
      code: "RE",
      country_code: "FR",
  },
  {
      name: "Rhône-Alpes",
      code: "V",
      country_code: "FR",
  },
  {
      name: "Saint Barthélemy",
      code: "BL",
      country_code: "FR",
  },
  {
      name: "Saint Martin",
      code: "MF",
      country_code: "FR",
  },
  {
      name: "Saint Pierre and Miquelon",
      code: "PM",
      country_code: "FR",
  },
  {
      name: "Sigave",
      code: "WF-SG",
      country_code: "FR",
  },
  {
      name: "Upper Normandy",
      code: "Q",
      country_code: "FR",
  },
  {
      name: "Uvea",
      code: "WF-UV",
      country_code: "FR",
  },
  {
      name: "Wallis and Futuna",
      code: "WF",
      country_code: "FR",
  },
  {
      name: "Estuaire Province",
      code: "1",
      country_code: "GA",
  },
  {
      name: "Haut-Ogooué Province",
      code: "2",
      country_code: "GA",
  },
  {
      name: "Moyen-Ogooué Province",
      code: "3",
      country_code: "GA",
  },
  {
      name: "Ngounié Province",
      code: "4",
      country_code: "GA",
  },
  {
      name: "Nyanga Province",
      code: "5",
      country_code: "GA",
  },
  {
      name: "Ogooué-Ivindo Province",
      code: "6",
      country_code: "GA",
  },
  {
      name: "Ogooué-Lolo Province",
      code: "7",
      country_code: "GA",
  },
  {
      name: "Ogooué-Maritime Province",
      code: "8",
      country_code: "GA",
  },
  {
      name: "Woleu-Ntem Province",
      code: "9",
      country_code: "GA",
  },
  {
      name: "Banjul",
      code: "B",
      country_code: "GM",
  },
  {
      name: "Central River Division",
      code: "M",
      country_code: "GM",
  },
  {
      name: "Lower River Division",
      code: "L",
      country_code: "GM",
  },
  {
      name: "North Bank Division",
      code: "N",
      country_code: "GM",
  },
  {
      name: "Upper River Division",
      code: "U",
      country_code: "GM",
  },
  {
      name: "West Coast Division",
      code: "W",
      country_code: "GM",
  },
  {
      name: "Adjara",
      code: "AJ",
      country_code: "GE",
  },
  {
      name: "Autonomous Republic of Abkhazia",
      code: "AB",
      country_code: "GE",
  },
  {
      name: "Guria",
      code: "GU",
      country_code: "GE",
  },
  {
      name: "Imereti",
      code: "IM",
      country_code: "GE",
  },
  {
      name: "Kakheti",
      code: "KA",
      country_code: "GE",
  },
  {
      name: "Khelvachauri Municipality",
      code: "29",
      country_code: "GE",
  },
  {
      name: "Kvemo Kartli",
      code: "KK",
      country_code: "GE",
  },
  {
      name: "Mtskheta-Mtianeti",
      code: "MM",
      country_code: "GE",
  },
  {
      name: "Racha-Lechkhumi and Kvemo Svaneti",
      code: "RL",
      country_code: "GE",
  },
  {
      name: "Samegrelo-Zemo Svaneti",
      code: "SZ",
      country_code: "GE",
  },
  {
      name: "Samtskhe-Javakheti",
      code: "SJ",
      country_code: "GE",
  },
  {
      name: "Senaki Municipality",
      code: "50",
      country_code: "GE",
  },
  {
      name: "Shida Kartli",
      code: "SK",
      country_code: "GE",
  },
  {
      name: "Tbilisi",
      code: "TB",
      country_code: "GE",
  },
  {
      name: "Baden-Württemberg",
      code: "BW",
      country_code: "DE",
  },
  {
      name: "Bavaria",
      code: "BY",
      country_code: "DE",
  },
  {
      name: "Berlin",
      code: "BE",
      country_code: "DE",
  },
  {
      name: "Brandenburg",
      code: "BB",
      country_code: "DE",
  },
  {
      name: "Bremen",
      code: "HB",
      country_code: "DE",
  },
  {
      name: "Hamburg",
      code: "HH",
      country_code: "DE",
  },
  {
      name: "Hesse",
      code: "HE",
      country_code: "DE",
  },
  {
      name: "Lower Saxony",
      code: "NI",
      country_code: "DE",
  },
  {
      name: "Mecklenburg-Vorpommern",
      code: "MV",
      country_code: "DE",
  },
  {
      name: "North Rhine-Westphalia",
      code: "NW",
      country_code: "DE",
  },
  {
      name: "Rhineland-Palatinate",
      code: "RP",
      country_code: "DE",
  },
  {
      name: "Saarland",
      code: "SL",
      country_code: "DE",
  },
  {
      name: "Saxony",
      code: "SN",
      country_code: "DE",
  },
  {
      name: "Saxony-Anhalt",
      code: "ST",
      country_code: "DE",
  },
  {
      name: "Schleswig-Holstein",
      code: "SH",
      country_code: "DE",
  },
  {
      name: "Thuringia",
      code: "TH",
      country_code: "DE",
  },
  {
      name: "Ashanti Region",
      code: "AH",
      country_code: "GH",
  },
  {
      name: "Brong-Ahafo Region",
      code: "BA",
      country_code: "GH",
  },
  {
      name: "Central Region",
      code: "CP",
      country_code: "GH",
  },
  {
      name: "Eastern Region",
      code: "EP",
      country_code: "GH",
  },
  {
      name: "Greater Accra Region",
      code: "AA",
      country_code: "GH",
  },
  {
      name: "Northern Region",
      code: "NP",
      country_code: "GH",
  },
  {
      name: "Upper East Region",
      code: "UE",
      country_code: "GH",
  },
  {
      name: "Upper West Region",
      code: "UW",
      country_code: "GH",
  },
  {
      name: "Volta Region",
      code: "TV",
      country_code: "GH",
  },
  {
      name: "Western Region",
      code: "WP",
      country_code: "GH",
  },
  {
      name: "Achaea Regional Unit",
      code: "13",
      country_code: "GR",
  },
  {
      name: "Aetolia-Acarnania Regional Unit",
      code: "01",
      country_code: "GR",
  },
  {
      name: "Arcadia Prefecture",
      code: "12",
      country_code: "GR",
  },
  {
      name: "Argolis Regional Unit",
      code: "11",
      country_code: "GR",
  },
  {
      name: "Attica Region",
      code: "I",
      country_code: "GR",
  },
  {
      name: "Boeotia Regional Unit",
      code: "03",
      country_code: "GR",
  },
  {
      name: "Central Greece Region",
      code: "H",
      country_code: "GR",
  },
  {
      name: "Central Macedonia",
      code: "B",
      country_code: "GR",
  },
  {
      name: "Chania Regional Unit",
      code: "94",
      country_code: "GR",
  },
  {
      name: "Corfu Prefecture",
      code: "22",
      country_code: "GR",
  },
  {
      name: "Corinthia Regional Unit",
      code: "15",
      country_code: "GR",
  },
  {
      name: "Crete Region",
      code: "M",
      country_code: "GR",
  },
  {
      name: "Drama Regional Unit",
      code: "52",
      country_code: "GR",
  },
  {
      name: "East Attica Regional Unit",
      code: "A2",
      country_code: "GR",
  },
  {
      name: "East Macedonia and Thrace",
      code: "A",
      country_code: "GR",
  },
  {
      name: "Epirus Region",
      code: "D",
      country_code: "GR",
  },
  {
      name: "Euboea",
      code: "04",
      country_code: "GR",
  },
  {
      name: "Grevena Prefecture",
      code: "51",
      country_code: "GR",
  },
  {
      name: "Imathia Regional Unit",
      code: "53",
      country_code: "GR",
  },
  {
      name: "Ioannina Regional Unit",
      code: "33",
      country_code: "GR",
  },
  {
      name: "Ionian Islands Region",
      code: "F",
      country_code: "GR",
  },
  {
      name: "Karditsa Regional Unit",
      code: "41",
      country_code: "GR",
  },
  {
      name: "Kastoria Regional Unit",
      code: "56",
      country_code: "GR",
  },
  {
      name: "Kefalonia Prefecture",
      code: "23",
      country_code: "GR",
  },
  {
      name: "Kilkis Regional Unit",
      code: "57",
      country_code: "GR",
  },
  {
      name: "Kozani Prefecture",
      code: "58",
      country_code: "GR",
  },
  {
      name: "Laconia",
      code: "16",
      country_code: "GR",
  },
  {
      name: "Larissa Prefecture",
      code: "42",
      country_code: "GR",
  },
  {
      name: "Lefkada Regional Unit",
      code: "24",
      country_code: "GR",
  },
  {
      name: "Pella Regional Unit",
      code: "59",
      country_code: "GR",
  },
  {
      name: "Peloponnese Region",
      code: "J",
      country_code: "GR",
  },
  {
      name: "Phthiotis Prefecture",
      code: "06",
      country_code: "GR",
  },
  {
      name: "Preveza Prefecture",
      code: "34",
      country_code: "GR",
  },
  {
      name: "Serres Prefecture",
      code: "62",
      country_code: "GR",
  },
  {
      name: "South Aegean",
      code: "L",
      country_code: "GR",
  },
  {
      name: "Thessaloniki Regional Unit",
      code: "54",
      country_code: "GR",
  },
  {
      name: "West Greece Region",
      code: "G",
      country_code: "GR",
  },
  {
      name: "West Macedonia Region",
      code: "C",
      country_code: "GR",
  },
  {
      name: "Carriacou and Petite Martinique",
      code: "10",
      country_code: "GD",
  },
  {
      name: "Saint Andrew Parish",
      code: "01",
      country_code: "GD",
  },
  {
      name: "Saint David Parish",
      code: "02",
      country_code: "GD",
  },
  {
      name: "Saint George Parish",
      code: "03",
      country_code: "GD",
  },
  {
      name: "Saint John Parish",
      code: "04",
      country_code: "GD",
  },
  {
      name: "Saint Mark Parish",
      code: "05",
      country_code: "GD",
  },
  {
      name: "Saint Patrick Parish",
      code: "06",
      country_code: "GD",
  },
  {
      name: "Alta Verapaz Department",
      code: "AV",
      country_code: "GT",
  },
  {
      name: "Baja Verapaz Department",
      code: "BV",
      country_code: "GT",
  },
  {
      name: "Chimaltenango Department",
      code: "CM",
      country_code: "GT",
  },
  {
      name: "Chiquimula Department",
      code: "CQ",
      country_code: "GT",
  },
  {
      name: "El Progreso Department",
      code: "PR",
      country_code: "GT",
  },
  {
      name: "Escuintla Department",
      code: "ES",
      country_code: "GT",
  },
  {
      name: "Guatemala Department",
      code: "GU",
      country_code: "GT",
  },
  {
      name: "Huehuetenango Department",
      code: "HU",
      country_code: "GT",
  },
  {
      name: "Izabal Department",
      code: "IZ",
      country_code: "GT",
  },
  {
      name: "Jalapa Department",
      code: "JA",
      country_code: "GT",
  },
  {
      name: "Jutiapa Department",
      code: "JU",
      country_code: "GT",
  },
  {
      name: "Petén Department",
      code: "PE",
      country_code: "GT",
  },
  {
      name: "Quetzaltenango Department",
      code: "QZ",
      country_code: "GT",
  },
  {
      name: "Quiché Department",
      code: "QC",
      country_code: "GT",
  },
  {
      name: "Retalhuleu Department",
      code: "RE",
      country_code: "GT",
  },
  {
      name: "Sacatepéquez Department",
      code: "SA",
      country_code: "GT",
  },
  {
      name: "San Marcos Department",
      code: "SM",
      country_code: "GT",
  },
  {
      name: "Santa Rosa Department",
      code: "SR",
      country_code: "GT",
  },
  {
      name: "Sololá Department",
      code: "SO",
      country_code: "GT",
  },
  {
      name: "Suchitepéquez Department",
      code: "SU",
      country_code: "GT",
  },
  {
      name: "Totonicapán Department",
      code: "TO",
      country_code: "GT",
  },
  {
      name: "Beyla Prefecture",
      code: "BE",
      country_code: "GN",
  },
  {
      name: "Boffa Prefecture",
      code: "BF",
      country_code: "GN",
  },
  {
      name: "Boké Prefecture",
      code: "BK",
      country_code: "GN",
  },
  {
      name: "Boké Region",
      code: "B",
      country_code: "GN",
  },
  {
      name: "Conakry",
      code: "C",
      country_code: "GN",
  },
  {
      name: "Coyah Prefecture",
      code: "CO",
      country_code: "GN",
  },
  {
      name: "Dabola Prefecture",
      code: "DB",
      country_code: "GN",
  },
  {
      name: "Dalaba Prefecture",
      code: "DL",
      country_code: "GN",
  },
  {
      name: "Dinguiraye Prefecture",
      code: "DI",
      country_code: "GN",
  },
  {
      name: "Dubréka Prefecture",
      code: "DU",
      country_code: "GN",
  },
  {
      name: "Faranah Prefecture",
      code: "FA",
      country_code: "GN",
  },
  {
      name: "Forécariah Prefecture",
      code: "FO",
      country_code: "GN",
  },
  {
      name: "Fria Prefecture",
      code: "FR",
      country_code: "GN",
  },
  {
      name: "Gaoual Prefecture",
      code: "GA",
      country_code: "GN",
  },
  {
      name: "Guéckédou Prefecture",
      code: "GU",
      country_code: "GN",
  },
  {
      name: "Kankan Prefecture",
      code: "KA",
      country_code: "GN",
  },
  {
      name: "Kankan Region",
      code: "K",
      country_code: "GN",
  },
  {
      name: "Kérouané Prefecture",
      code: "KE",
      country_code: "GN",
  },
  {
      name: "Kindia Prefecture",
      code: "KD",
      country_code: "GN",
  },
  {
      name: "Kindia Region",
      code: "D",
      country_code: "GN",
  },
  {
      name: "Kissidougou Prefecture",
      code: "KS",
      country_code: "GN",
  },
  {
      name: "Koubia Prefecture",
      code: "KB",
      country_code: "GN",
  },
  {
      name: "Koundara Prefecture",
      code: "KN",
      country_code: "GN",
  },
  {
      name: "Kouroussa Prefecture",
      code: "KO",
      country_code: "GN",
  },
  {
      name: "Labé Prefecture",
      code: "LA",
      country_code: "GN",
  },
  {
      name: "Labé Region",
      code: "L",
      country_code: "GN",
  },
  {
      name: "Lélouma Prefecture",
      code: "LE",
      country_code: "GN",
  },
  {
      name: "Lola Prefecture",
      code: "LO",
      country_code: "GN",
  },
  {
      name: "Macenta Prefecture",
      code: "MC",
      country_code: "GN",
  },
  {
      name: "Mali Prefecture",
      code: "ML",
      country_code: "GN",
  },
  {
      name: "Mamou Prefecture",
      code: "MM",
      country_code: "GN",
  },
  {
      name: "Mamou Region",
      code: "M",
      country_code: "GN",
  },
  {
      name: "Mandiana Prefecture",
      code: "MD",
      country_code: "GN",
  },
  {
      name: "Nzérékoré Prefecture",
      code: "NZ",
      country_code: "GN",
  },
  {
      name: "Nzérékoré Region",
      code: "N",
      country_code: "GN",
  },
  {
      name: "Pita Prefecture",
      code: "PI",
      country_code: "GN",
  },
  {
      name: "Siguiri Prefecture",
      code: "SI",
      country_code: "GN",
  },
  {
      name: "Télimélé Prefecture",
      code: "TE",
      country_code: "GN",
  },
  {
      name: "Tougué Prefecture",
      code: "TO",
      country_code: "GN",
  },
  {
      name: "Yomou Prefecture",
      code: "YO",
      country_code: "GN",
  },
  {
      name: "Bafatá",
      code: "BA",
      country_code: "GW",
  },
  {
      name: "Biombo Region",
      code: "BM",
      country_code: "GW",
  },
  {
      name: "Bolama Region",
      code: "BL",
      country_code: "GW",
  },
  {
      name: "Cacheu Region",
      code: "CA",
      country_code: "GW",
  },
  {
      name: "Gabú Region",
      code: "GA",
      country_code: "GW",
  },
  {
      name: "Leste Province",
      code: "L",
      country_code: "GW",
  },
  {
      name: "Norte Province",
      code: "N",
      country_code: "GW",
  },
  {
      name: "Oio Region",
      code: "OI",
      country_code: "GW",
  },
  {
      name: "Quinara Region",
      code: "QU",
      country_code: "GW",
  },
  {
      name: "Sul Province",
      code: "S",
      country_code: "GW",
  },
  {
      name: "Tombali Region",
      code: "TO",
      country_code: "GW",
  },
  {
      name: "Barima-Waini",
      code: "BA",
      country_code: "GY",
  },
  {
      name: "Cuyuni-Mazaruni",
      code: "CU",
      country_code: "GY",
  },
  {
      name: "Demerara-Mahaica",
      code: "DE",
      country_code: "GY",
  },
  {
      name: "East Berbice-Corentyne",
      code: "EB",
      country_code: "GY",
  },
  {
      name: "Essequibo Islands-West Demerara",
      code: "ES",
      country_code: "GY",
  },
  {
      name: "Mahaica-Berbice",
      code: "MA",
      country_code: "GY",
  },
  {
      name: "Pomeroon-Supenaam",
      code: "PM",
      country_code: "GY",
  },
  {
      name: "Potaro-Siparuni",
      code: "PT",
      country_code: "GY",
  },
  {
      name: "Upper Demerara-Berbice",
      code: "UD",
      country_code: "GY",
  },
  {
      name: "Upper Takutu-Upper Essequibo",
      code: "UT",
      country_code: "GY",
  },
  {
      name: "Artibonite",
      code: "AR",
      country_code: "HT",
  },
  {
      name: "Centre",
      code: "CE",
      country_code: "HT",
  },
  {
      name: "Grand'Anse",
      code: "GA",
      country_code: "HT",
  },
  {
      name: "Nippes",
      code: "NI",
      country_code: "HT",
  },
  {
      name: "Nord",
      code: "ND",
      country_code: "HT",
  },
  {
      name: "Nord-Est",
      code: "NE",
      country_code: "HT",
  },
  {
      name: "Nord-Ouest",
      code: "NO",
      country_code: "HT",
  },
  {
      name: "Ouest",
      code: "OU",
      country_code: "HT",
  },
  {
      name: "Sud",
      code: "SD",
      country_code: "HT",
  },
  {
      name: "Sud-Est",
      code: "SE",
      country_code: "HT",
  },
  {
      name: "Atlántida Department",
      code: "AT",
      country_code: "HN",
  },
  {
      name: "Bay Islands Department",
      code: "IB",
      country_code: "HN",
  },
  {
      name: "Choluteca Department",
      code: "CH",
      country_code: "HN",
  },
  {
      name: "Colón Department",
      code: "CL",
      country_code: "HN",
  },
  {
      name: "Comayagua Department",
      code: "CM",
      country_code: "HN",
  },
  {
      name: "Copán Department",
      code: "CP",
      country_code: "HN",
  },
  {
      name: "Cortés Department",
      code: "CR",
      country_code: "HN",
  },
  {
      name: "El Paraíso Department",
      code: "EP",
      country_code: "HN",
  },
  {
      name: "Francisco Morazán Department",
      code: "FM",
      country_code: "HN",
  },
  {
      name: "Gracias a Dios Department",
      code: "GD",
      country_code: "HN",
  },
  {
      name: "Intibucá Department",
      code: "IN",
      country_code: "HN",
  },
  {
      name: "La Paz Department",
      code: "LP",
      country_code: "HN",
  },
  {
      name: "Lempira Department",
      code: "LE",
      country_code: "HN",
  },
  {
      name: "Ocotepeque Department",
      code: "OC",
      country_code: "HN",
  },
  {
      name: "Olancho Department",
      code: "OL",
      country_code: "HN",
  },
  {
      name: "Santa Bárbara Department",
      code: "SB",
      country_code: "HN",
  },
  {
      name: "Valle Department",
      code: "VA",
      country_code: "HN",
  },
  {
      name: "Yoro Department",
      code: "YO",
      country_code: "HN",
  },
  {
      name: "Central and Western District",
      code: "HCW",
      country_code: "HK",
  },
  {
      name: "Eastern",
      code: "HEA",
      country_code: "HK",
  },
  {
      name: "Islands District",
      code: "NIS",
      country_code: "HK",
  },
  {
      name: "Kowloon City",
      code: "KKC",
      country_code: "HK",
  },
  {
      name: "Kwai Tsing",
      code: "NKT",
      country_code: "HK",
  },
  {
      name: "Kwun Tong",
      code: "KKT",
      country_code: "HK",
  },
  {
      name: "North",
      code: "NNO",
      country_code: "HK",
  },
  {
      name: "Sai Kung District",
      code: "NSK",
      country_code: "HK",
  },
  {
      name: "Sha Tin",
      code: "NST",
      country_code: "HK",
  },
  {
      name: "Sham Shui Po",
      code: "KSS",
      country_code: "HK",
  },
  {
      name: "Southern",
      code: "HSO",
      country_code: "HK",
  },
  {
      name: "Tai Po District",
      code: "NTP",
      country_code: "KH",
  },
  {
      name: "Tsuen Wan District",
      code: "NTW",
      country_code: "HK",
  },
  {
      name: "Tuen Mun",
      code: "NTM",
      country_code: "HK",
  },
  {
      name: "Wan Chai",
      code: "HWC",
      country_code: "HK",
  },
  {
      name: "Wong Tai Sin",
      code: "KWT",
      country_code: "HK",
  },
  {
      name: "Yau Tsim Mong",
      code: "KYT",
      country_code: "HK",
  },
  {
      name: "Yuen Long District",
      code: "NYL",
      country_code: "HK",
  },
  {
      name: "Bács-Kiskun County",
      code: "BK",
      country_code: "HU",
  },
  {
      name: "Baranya County",
      code: "BA",
      country_code: "HU",
  },
  {
      name: "Békés County",
      code: "BE",
      country_code: "HU",
  },
  {
      name: "Békéscsaba",
      code: "BC",
      country_code: "HU",
  },
  {
      name: "Borsod-Abaúj-Zemplén County",
      code: "BZ",
      country_code: "HU",
  },
  {
      name: "Budapest",
      code: "BU",
      country_code: "HU",
  },
  {
      name: "Csongrád County",
      code: "CS",
      country_code: "HU",
  },
  {
      name: "Debrecen",
      code: "DE",
      country_code: "HU",
  },
  {
      name: "Dunaújváros",
      code: "DU",
      country_code: "HU",
  },
  {
      name: "Eger",
      code: "EG",
      country_code: "HU",
  },
  {
      name: "Érd",
      code: "ER",
      country_code: "HU",
  },
  {
      name: "Fejér County",
      code: "FE",
      country_code: "HU",
  },
  {
      name: "Győr",
      code: "GY",
      country_code: "HU",
  },
  {
      name: "Győr-Moson-Sopron County",
      code: "GS",
      country_code: "HU",
  },
  {
      name: "Hajdú-Bihar County",
      code: "HB",
      country_code: "HU",
  },
  {
      name: "Heves County",
      code: "HE",
      country_code: "HU",
  },
  {
      name: "Hódmezővásárhely",
      code: "HV",
      country_code: "HU",
  },
  {
      name: "Jász-Nagykun-Szolnok County",
      code: "JN",
      country_code: "HU",
  },
  {
      name: "Kaposvár",
      code: "KV",
      country_code: "HU",
  },
  {
      name: "Kecskemét",
      code: "KM",
      country_code: "HU",
  },
  {
      name: "Miskolc",
      code: "MI",
      country_code: "HU",
  },
  {
      name: "Nagykanizsa",
      code: "NK",
      country_code: "HU",
  },
  {
      name: "Nógrád County",
      code: "NO",
      country_code: "HU",
  },
  {
      name: "Nyíregyháza",
      code: "NY",
      country_code: "HU",
  },
  {
      name: "Pécs",
      code: "PS",
      country_code: "HU",
  },
  {
      name: "Pest County",
      code: "PE",
      country_code: "HU",
  },
  {
      name: "Salgótarján",
      code: "ST",
      country_code: "HU",
  },
  {
      name: "Somogy County",
      code: "SO",
      country_code: "HU",
  },
  {
      name: "Sopron",
      code: "SN",
      country_code: "HU",
  },
  {
      name: "Szabolcs-Szatmár-Bereg County",
      code: "SZ",
      country_code: "HU",
  },
  {
      name: "Szeged",
      code: "SD",
      country_code: "HU",
  },
  {
      name: "Székesfehérvár",
      code: "SF",
      country_code: "HU",
  },
  {
      name: "Szekszárd",
      code: "SS",
      country_code: "HU",
  },
  {
      name: "Szolnok",
      code: "SK",
      country_code: "HU",
  },
  {
      name: "Szombathely",
      code: "SH",
      country_code: "HU",
  },
  {
      name: "Tatabánya",
      code: "TB",
      country_code: "HU",
  },
  {
      name: "Tolna County",
      code: "TO",
      country_code: "HU",
  },
  {
      name: "Vas County",
      code: "VA",
      country_code: "HU",
  },
  {
      name: "Veszprém",
      code: "VM",
      country_code: "HU",
  },
  {
      name: "Veszprém County",
      code: "VE",
      country_code: "HU",
  },
  {
      name: "Zala County",
      code: "ZA",
      country_code: "HU",
  },
  {
      name: "Zalaegerszeg",
      code: "ZE",
      country_code: "HU",
  },
  {
      name: "Capital Region",
      code: "1",
      country_code: "IS",
  },
  {
      name: "Eastern Region",
      code: "7",
      country_code: "IS",
  },
  {
      name: "Northeastern Region",
      code: "6",
      country_code: "IS",
  },
  {
      name: "Northwestern Region",
      code: "5",
      country_code: "IS",
  },
  {
      name: "Southern Peninsula Region",
      code: "2",
      country_code: "IS",
  },
  {
      name: "Southern Region",
      code: "8",
      country_code: "IS",
  },
  {
      name: "Western Region",
      code: "3",
      country_code: "IS",
  },
  {
      name: "Westfjords",
      code: "4",
      country_code: "IS",
  },
  {
      name: "Andaman and Nicobar Islands",
      code: "AN",
      country_code: "IN",
  },
  {
      name: "Andhra Pradesh",
      code: "AP",
      country_code: "IN",
  },
  {
      name: "Arunachal Pradesh",
      code: "AR",
      country_code: "IN",
  },
  {
      name: "Assam",
      code: "AS",
      country_code: "IN",
  },
  {
      name: "Bihar",
      code: "BR",
      country_code: "IN",
  },
  {
      name: "Chandigarh",
      code: "CH",
      country_code: "IN",
  },
  {
      name: "Chhattisgarh",
      code: "CT",
      country_code: "IN",
  },
  {
      name: "Dadra and Nagar Haveli and Daman and Diu",
      code: "DH",
      country_code: "IN",
  },
  {
      name: "Delhi",
      code: "DL",
      country_code: "IN",
  },
  {
      name: "Goa",
      code: "GA",
      country_code: "IN",
  },
  {
      name: "Gujarat",
      code: "GJ",
      country_code: "IN",
  },
  {
      name: "Haryana",
      code: "HR",
      country_code: "IN",
  },
  {
      name: "Himachal Pradesh",
      code: "HP",
      country_code: "IN",
  },
  {
      name: "Jammu and Kashmir",
      code: "JK",
      country_code: "IN",
  },
  {
      name: "Jharkhand",
      code: "JH",
      country_code: "IN",
  },
  {
      name: "Karnataka",
      code: "KA",
      country_code: "IN",
  },
  {
      name: "Kerala",
      code: "KL",
      country_code: "IN",
  },
  {
      name: "Ladakh",
      code: "LA",
      country_code: "IN",
  },
  {
      name: "Lakshadweep",
      code: "LD",
      country_code: "IN",
  },
  {
      name: "Madhya Pradesh",
      code: "MP",
      country_code: "IN",
  },
  {
      name: "Maharashtra",
      code: "MH",
      country_code: "IN",
  },
  {
      name: "Manipur",
      code: "MN",
      country_code: "IN",
  },
  {
      name: "Meghalaya",
      code: "ML",
      country_code: "IN",
  },
  {
      name: "Mizoram",
      code: "MZ",
      country_code: "IN",
  },
  {
      name: "Nagaland",
      code: "NL",
      country_code: "IN",
  },
  {
      name: "Odisha",
      code: "OR",
      country_code: "IN",
  },
  {
      name: "Puducherry",
      code: "PY",
      country_code: "IN",
  },
  {
      name: "Punjab",
      code: "PB",
      country_code: "IN",
  },
  {
      name: "Rajasthan",
      code: "RJ",
      country_code: "IN",
  },
  {
      name: "Sikkim",
      code: "SK",
      country_code: "IN",
  },
  {
      name: "Tamil Nadu",
      code: "TN",
      country_code: "IN",
  },
  {
      name: "Telangana",
      code: "TG",
      country_code: "IN",
  },
  {
      name: "Tripura",
      code: "TR",
      country_code: "IN",
  },
  {
      name: "Uttar Pradesh",
      code: "UP",
      country_code: "IN",
  },
  {
      name: "Uttarakhand",
      code: "UT",
      country_code: "IN",
  },
  {
      name: "West Bengal",
      code: "WB",
      country_code: "IN",
  },
  {
      name: "Aceh",
      code: "AC",
      country_code: "ID",
  },
  {
      name: "Bali",
      code: "BA",
      country_code: "ID",
  },
  {
      name: "Banten",
      code: "BT",
      country_code: "ID",
  },
  {
      name: "Bengkulu",
      code: "BE",
      country_code: "ID",
  },
  {
      name: "DI Yogyakarta",
      code: "YO",
      country_code: "ID",
  },
  {
      name: "DKI Jakarta",
      code: "JK",
      country_code: "ID",
  },
  {
      name: "Gorontalo",
      code: "GO",
      country_code: "ID",
  },
  {
      name: "Jambi",
      code: "JA",
      country_code: "ID",
  },
  {
      name: "Jawa Barat",
      code: "JB",
      country_code: "ID",
  },
  {
      name: "Jawa Tengah",
      code: "JT",
      country_code: "ID",
  },
  {
      name: "Jawa Timur",
      code: "JI",
      country_code: "ID",
  },
  {
      name: "Kalimantan Barat",
      code: "KA",
      country_code: "ID",
  },
  {
      name: "Kalimantan Selatan",
      code: "KS",
      country_code: "ID",
  },
  {
      name: "Kalimantan Tengah",
      code: "KT",
      country_code: "ID",
  },
  {
      name: "Kalimantan Timur",
      code: "KI",
      country_code: "ID",
  },
  {
      name: "Kalimantan Utara",
      code: "KU",
      country_code: "ID",
  },
  {
      name: "Kepulauan Bangka Belitung",
      code: "BB",
      country_code: "ID",
  },
  {
      name: "Kepulauan Riau",
      code: "KR",
      country_code: "ID",
  },
  {
      name: "Lampung",
      code: "LA",
      country_code: "ID",
  },
  {
      name: "Maluku",
      code: "MA",
      country_code: "ID",
  },
  {
      name: "Maluku Utara",
      code: "MU",
      country_code: "ID",
  },
  {
      name: "Nusa Tenggara Barat",
      code: "NB",
      country_code: "ID",
  },
  {
      name: "Nusa Tenggara Timur",
      code: "NT",
      country_code: "ID",
  },
  {
      name: "Papua",
      code: "PA",
      country_code: "ID",
  },
  {
      name: "Papua Barat",
      code: "PB",
      country_code: "ID",
  },
  {
      name: "Riau",
      code: "RI",
      country_code: "ID",
  },
  {
      name: "Sulawesi Barat",
      code: "SR",
      country_code: "ID",
  },
  {
      name: "Sulawesi Selatan",
      code: "SN",
      country_code: "ID",
  },
  {
      name: "Sulawesi Tengah",
      code: "ST",
      country_code: "ID",
  },
  {
      name: "Sulawesi Tenggara",
      code: "SG",
      country_code: "ID",
  },
  {
      name: "Sulawesi Utara",
      code: "SA",
      country_code: "ID",
  },
  {
      name: "Sumatera Barat",
      code: "SB",
      country_code: "ID",
  },
  {
      name: "Sumatera Selatan",
      code: "SS",
      country_code: "ID",
  },
  {
      name: "Sumatera Utara",
      code: "SU",
      country_code: "ID",
  },
  {
      name: "Alborz Province",
      code: "30",
      country_code: "IR",
  },
  {
      name: "Ardabil Province",
      code: "24",
      country_code: "IR",
  },
  {
      name: "Bushehr Province",
      code: "18",
      country_code: "IR",
  },
  {
      name: "Chaharmahal and Bakhtiari Province",
      code: "14",
      country_code: "IR",
  },
  {
      name: "East Azerbaijan Province",
      code: "03",
      country_code: "IR",
  },
  {
      name: "Fars Province",
      code: "07",
      country_code: "IR",
  },
  {
      name: "Gilan Province",
      code: "01",
      country_code: "IR",
  },
  {
      name: "Golestan Province",
      code: "27",
      country_code: "IR",
  },
  {
      name: "Hamadan Province",
      code: "13",
      country_code: "IR",
  },
  {
      name: "Hormozgan Province",
      code: "22",
      country_code: "IR",
  },
  {
      name: "Ilam Province",
      code: "16",
      country_code: "IR",
  },
  {
      name: "Isfahan Province",
      code: "10",
      country_code: "IR",
  },
  {
      name: "Kerman Province",
      code: "08",
      country_code: "IR",
  },
  {
      name: "Kermanshah Province",
      code: "05",
      country_code: "IR",
  },
  {
      name: "Khuzestan Province",
      code: "06",
      country_code: "IR",
  },
  {
      name: "Kohgiluyeh and Boyer-Ahmad Province",
      code: "17",
      country_code: "IR",
  },
  {
      name: "Kurdistan Province",
      code: "12",
      country_code: "IR",
  },
  {
      name: "Lorestan Province",
      code: "15",
      country_code: "IR",
  },
  {
      name: "Markazi Province",
      code: "00",
      country_code: "IR",
  },
  {
      name: "Mazandaran Province",
      code: "02",
      country_code: "IR",
  },
  {
      name: "North Khorasan Province",
      code: "28",
      country_code: "IR",
  },
  {
      name: "Qazvin Province",
      code: "26",
      country_code: "IR",
  },
  {
      name: "Qom Province",
      code: "25",
      country_code: "IR",
  },
  {
      name: "Razavi Khorasan Province",
      code: "09",
      country_code: "IR",
  },
  {
      name: "Semnan Province",
      code: "20",
      country_code: "IR",
  },
  {
      name: "Sistan and Baluchestan",
      code: "11",
      country_code: "IR",
  },
  {
      name: "South Khorasan Province",
      code: "29",
      country_code: "IR",
  },
  {
      name: "Tehran Province",
      code: "23",
      country_code: "IR",
  },
  {
      name: "West Azarbaijan Province",
      code: "04",
      country_code: "IR",
  },
  {
      name: "Yazd Province",
      code: "21",
      country_code: "IR",
  },
  {
      name: "Zanjan Province",
      code: "19",
      country_code: "IR",
  },
  {
      name: "Al Anbar Governorate",
      code: "AN",
      country_code: "IQ",
  },
  {
      name: "Al Muthanna Governorate",
      code: "MU",
      country_code: "IQ",
  },
  {
      name: "Al-Qādisiyyah Governorate",
      code: "QA",
      country_code: "IQ",
  },
  {
      name: "Babylon Governorate",
      code: "BB",
      country_code: "IQ",
  },
  {
      name: "Baghdad Governorate",
      code: "BG",
      country_code: "IQ",
  },
  {
      name: "Basra Governorate",
      code: "BA",
      country_code: "IQ",
  },
  {
      name: "Dhi Qar Governorate",
      code: "DQ",
      country_code: "IQ",
  },
  {
      name: "Diyala Governorate",
      code: "DI",
      country_code: "IQ",
  },
  {
      name: "Dohuk Governorate",
      code: "DA",
      country_code: "IQ",
  },
  {
      name: "Erbil Governorate",
      code: "AR",
      country_code: "IQ",
  },
  {
      name: "Karbala Governorate",
      code: "KA",
      country_code: "IQ",
  },
  {
      name: "Kirkuk Governorate",
      code: "KI",
      country_code: "IQ",
  },
  {
      name: "Maysan Governorate",
      code: "MA",
      country_code: "IQ",
  },
  {
      name: "Najaf Governorate",
      code: "NA",
      country_code: "IQ",
  },
  {
      name: "Nineveh Governorate",
      code: "NI",
      country_code: "IQ",
  },
  {
      name: "Saladin Governorate",
      code: "SD",
      country_code: "IQ",
  },
  {
      name: "Sulaymaniyah Governorate",
      code: "SU",
      country_code: "IQ",
  },
  {
      name: "Wasit Governorate",
      code: "WA",
      country_code: "IQ",
  },
  {
      name: "Connacht",
      code: "C",
      country_code: "IE",
  },
  {
      name: "County Carlow",
      code: "CW",
      country_code: "IE",
  },
  {
      name: "County Cavan",
      code: "CN",
      country_code: "IE",
  },
  {
      name: "County Clare",
      code: "CE",
      country_code: "IE",
  },
  {
      name: "County Cork",
      code: "CO",
      country_code: "IE",
  },
  {
      name: "County Donegal",
      code: "DL",
      country_code: "IE",
  },
  {
      name: "County Dublin",
      code: "D",
      country_code: "IE",
  },
  {
      name: "County Galway",
      code: "G",
      country_code: "IE",
  },
  {
      name: "County Kerry",
      code: "KY",
      country_code: "IE",
  },
  {
      name: "County Kildare",
      code: "KE",
      country_code: "IE",
  },
  {
      name: "County Kilkenny",
      code: "KK",
      country_code: "IE",
  },
  {
      name: "County Laois",
      code: "LS",
      country_code: "IE",
  },
  {
      name: "County Limerick",
      code: "LK",
      country_code: "IE",
  },
  {
      name: "County Longford",
      code: "LD",
      country_code: "IE",
  },
  {
      name: "County Louth",
      code: "LH",
      country_code: "IE",
  },
  {
      name: "County Mayo",
      code: "MO",
      country_code: "IE",
  },
  {
      name: "County Meath",
      code: "MH",
      country_code: "IE",
  },
  {
      name: "County Monaghan",
      code: "MN",
      country_code: "IE",
  },
  {
      name: "County Offaly",
      code: "OY",
      country_code: "IE",
  },
  {
      name: "County Roscommon",
      code: "RN",
      country_code: "IE",
  },
  {
      name: "County Sligo",
      code: "SO",
      country_code: "IE",
  },
  {
      name: "County Tipperary",
      code: "TA",
      country_code: "IE",
  },
  {
      name: "County Waterford",
      code: "WD",
      country_code: "IE",
  },
  {
      name: "County Westmeath",
      code: "WH",
      country_code: "IE",
  },
  {
      name: "County Wexford",
      code: "WX",
      country_code: "IE",
  },
  {
      name: "County Wicklow",
      code: "WW",
      country_code: "IE",
  },
  {
      name: "Leinster",
      code: "L",
      country_code: "IE",
  },
  {
      name: "Munster",
      code: "M",
      country_code: "IE",
  },
  {
      name: "Ulster",
      code: "U",
      country_code: "IE",
  },
  {
      name: "Central District",
      code: "M",
      country_code: "IL",
  },
  {
      name: "Haifa District",
      code: "HA",
      country_code: "IL",
  },
  {
      name: "Jerusalem District",
      code: "JM",
      country_code: "IL",
  },
  {
      name: "Northern District",
      code: "Z",
      country_code: "IL",
  },
  {
      name: "Southern District",
      code: "D",
      country_code: "IL",
  },
  {
      name: "Tel Aviv District",
      code: "TA",
      country_code: "IL",
  },
  {
      name: "Abruzzo",
      code: "65",
      country_code: "IT",
  },
  {
      name: "Aosta Valley",
      code: "23",
      country_code: "IT",
  },
  {
      name: "Apulia",
      code: "75",
      country_code: "IT",
  },
  {
      name: "Basilicata",
      code: "77",
      country_code: "IT",
  },
  {
      name: "Benevento Province",
      code: "BN",
      country_code: "IT",
  },
  {
      name: "Calabria",
      code: "78",
      country_code: "IT",
  },
  {
      name: "Campania",
      code: "72",
      country_code: "IT",
  },
  {
      name: "Emilia-Romagna",
      code: "45",
      country_code: "IT",
  },
  {
      name: "Friuli–Venezia Giulia",
      code: "36",
      country_code: "IT",
  },
  {
      name: "Lazio",
      code: "62",
      country_code: "IT",
  },
  {
      name: "Libero consorzio comunale di Agrigento",
      code: "AG",
      country_code: "IT",
  },
  {
      name: "Libero consorzio comunale di Caltanissetta",
      code: "CL",
      country_code: "IT",
  },
  {
      name: "Libero consorzio comunale di Enna",
      code: "EN",
      country_code: "IT",
  },
  {
      name: "Libero consorzio comunale di Ragusa",
      code: "RG",
      country_code: "IT",
  },
  {
      name: "Libero consorzio comunale di Siracusa",
      code: "SR",
      country_code: "IT",
  },
  {
      name: "Libero consorzio comunale di Trapani",
      code: "TP",
      country_code: "IT",
  },
  {
      name: "Liguria",
      code: "42",
      country_code: "IT",
  },
  {
      name: "Lombardy",
      code: "25",
      country_code: "IT",
  },
  {
      name: "Marche",
      code: "57",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Bari",
      code: "BA",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Bologna",
      code: "BO",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Cagliari",
      code: "CA",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Catania",
      code: "CT",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Florence",
      code: "FI",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Genoa",
      code: "GE",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Messina",
      code: "ME",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Milan",
      code: "MI",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Naples",
      code: "NA",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Palermo",
      code: "PA",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Reggio Calabria",
      code: "RC",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Rome",
      code: "RM",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Turin",
      code: "TO",
      country_code: "IT",
  },
  {
      name: "Metropolitan City of Venice",
      code: "VE",
      country_code: "IT",
  },
  {
      name: "Molise",
      code: "67",
      country_code: "IT",
  },
  {
      name: "Pesaro and Urbino Province",
      code: "PU",
      country_code: "IT",
  },
  {
      name: "Piedmont",
      code: "21",
      country_code: "IT",
  },
  {
      name: "Province of Alessandria",
      code: "AL",
      country_code: "IT",
  },
  {
      name: "Province of Ancona",
      code: "AN",
      country_code: "IT",
  },
  {
      name: "Province of Ascoli Piceno",
      code: "AP",
      country_code: "IT",
  },
  {
      name: "Province of Asti",
      code: "AT",
      country_code: "IT",
  },
  {
      name: "Province of Avellino",
      code: "AV",
      country_code: "IT",
  },
  {
      name: "Province of Barletta-Andria-Trani",
      code: "BT",
      country_code: "IT",
  },
  {
      name: "Province of Belluno",
      code: "BL",
      country_code: "IT",
  },
  {
      name: "Province of Bergamo",
      code: "BG",
      country_code: "IT",
  },
  {
      name: "Province of Biella",
      code: "BI",
      country_code: "IT",
  },
  {
      name: "Province of Brescia",
      code: "BS",
      country_code: "IT",
  },
  {
      name: "Province of Brindisi",
      code: "BR",
      country_code: "IT",
  },
  {
      name: "Province of Campobasso",
      code: "CB",
      country_code: "IT",
  },
  {
      name: "Province of Carbonia-Iglesias",
      code: "CI",
      country_code: "IT",
  },
  {
      name: "Province of Caserta",
      code: "CE",
      country_code: "IT",
  },
  {
      name: "Province of Catanzaro",
      code: "CZ",
      country_code: "IT",
  },
  {
      name: "Province of Chieti",
      code: "CH",
      country_code: "IT",
  },
  {
      name: "Province of Como",
      code: "CO",
      country_code: "IT",
  },
  {
      name: "Province of Cosenza",
      code: "CS",
      country_code: "IT",
  },
  {
      name: "Province of Cremona",
      code: "CR",
      country_code: "IT",
  },
  {
      name: "Province of Crotone",
      code: "KR",
      country_code: "IT",
  },
  {
      name: "Province of Cuneo",
      code: "CN",
      country_code: "IT",
  },
  {
      name: "Province of Fermo",
      code: "FM",
      country_code: "IT",
  },
  {
      name: "Province of Ferrara",
      code: "FE",
      country_code: "IT",
  },
  {
      name: "Province of Foggia",
      code: "FG",
      country_code: "IT",
  },
  {
      name: "Province of Forlì-Cesena",
      code: "FC",
      country_code: "IT",
  },
  {
      name: "Province of Frosinone",
      code: "FR",
      country_code: "IT",
  },
  {
      name: "Province of Gorizia",
      code: "GO",
      country_code: "IT",
  },
  {
      name: "Province of Grosseto",
      code: "GR",
      country_code: "IT",
  },
  {
      name: "Province of Imperia",
      code: "IM",
      country_code: "IT",
  },
  {
      name: "Province of Isernia",
      code: "IS",
      country_code: "IT",
  },
  {
      name: "Province of L'Aquila",
      code: "AQ",
      country_code: "IT",
  },
  {
      name: "Province of La Spezia",
      code: "SP",
      country_code: "IT",
  },
  {
      name: "Province of Latina",
      code: "LT",
      country_code: "IT",
  },
  {
      name: "Province of Lecce",
      code: "LE",
      country_code: "IT",
  },
  {
      name: "Province of Lecco",
      code: "LC",
      country_code: "IT",
  },
  {
      name: "Province of Livorno",
      code: "LI",
      country_code: "IT",
  },
  {
      name: "Province of Lodi",
      code: "LO",
      country_code: "IT",
  },
  {
      name: "Province of Lucca",
      code: "LU",
      country_code: "IT",
  },
  {
      name: "Province of Macerata",
      code: "MC",
      country_code: "IT",
  },
  {
      name: "Province of Mantua",
      code: "MN",
      country_code: "IT",
  },
  {
      name: "Province of Massa and Carrara",
      code: "MS",
      country_code: "IT",
  },
  {
      name: "Province of Matera",
      code: "MT",
      country_code: "IT",
  },
  {
      name: "Province of Medio Campidano",
      code: "VS",
      country_code: "IT",
  },
  {
      name: "Province of Modena",
      code: "MO",
      country_code: "IT",
  },
  {
      name: "Province of Monza and Brianza",
      code: "MB",
      country_code: "IT",
  },
  {
      name: "Province of Novara",
      code: "NO",
      country_code: "IT",
  },
  {
      name: "Province of Nuoro",
      code: "NU",
      country_code: "IT",
  },
  {
      name: "Province of Ogliastra",
      code: "OG",
      country_code: "IT",
  },
  {
      name: "Province of Olbia-Tempio",
      code: "OT",
      country_code: "IT",
  },
  {
      name: "Province of Oristano",
      code: "OR",
      country_code: "IT",
  },
  {
      name: "Province of Padua",
      code: "PD",
      country_code: "IT",
  },
  {
      name: "Province of Parma",
      code: "PR",
      country_code: "IT",
  },
  {
      name: "Province of Pavia",
      code: "PV",
      country_code: "IT",
  },
  {
      name: "Province of Perugia",
      code: "PG",
      country_code: "IT",
  },
  {
      name: "Province of Pescara",
      code: "PE",
      country_code: "IT",
  },
  {
      name: "Province of Piacenza",
      code: "PC",
      country_code: "IT",
  },
  {
      name: "Province of Pisa",
      code: "PI",
      country_code: "IT",
  },
  {
      name: "Province of Pistoia",
      code: "PT",
      country_code: "IT",
  },
  {
      name: "Province of Pordenone",
      code: "PN",
      country_code: "IT",
  },
  {
      name: "Province of Potenza",
      code: "PZ",
      country_code: "IT",
  },
  {
      name: "Province of Prato",
      code: "PO",
      country_code: "IT",
  },
  {
      name: "Province of Ravenna",
      code: "RA",
      country_code: "IT",
  },
  {
      name: "Province of Reggio Emilia",
      code: "RE",
      country_code: "IT",
  },
  {
      name: "Province of Rieti",
      code: "RI",
      country_code: "IT",
  },
  {
      name: "Province of Rimini",
      code: "RN",
      country_code: "IT",
  },
  {
      name: "Province of Rovigo",
      code: "RO",
      country_code: "IT",
  },
  {
      name: "Province of Salerno",
      code: "SA",
      country_code: "IT",
  },
  {
      name: "Province of Sassari",
      code: "SS",
      country_code: "IT",
  },
  {
      name: "Province of Savona",
      code: "SV",
      country_code: "IT",
  },
  {
      name: "Province of Siena",
      code: "SI",
      country_code: "IT",
  },
  {
      name: "Province of Sondrio",
      code: "SO",
      country_code: "IT",
  },
  {
      name: "Province of Taranto",
      code: "TA",
      country_code: "IT",
  },
  {
      name: "Province of Teramo",
      code: "TE",
      country_code: "IT",
  },
  {
      name: "Province of Terni",
      code: "TR",
      country_code: "IT",
  },
  {
      name: "Province of Treviso",
      code: "TV",
      country_code: "IT",
  },
  {
      name: "Province of Trieste",
      code: "TS",
      country_code: "IT",
  },
  {
      name: "Province of Udine",
      code: "UD",
      country_code: "IT",
  },
  {
      name: "Province of Varese",
      code: "VA",
      country_code: "IT",
  },
  {
      name: "Province of Verbano-Cusio-Ossola",
      code: "VB",
      country_code: "IT",
  },
  {
      name: "Province of Vercelli",
      code: "VC",
      country_code: "IT",
  },
  {
      name: "Province of Verona",
      code: "VR",
      country_code: "IT",
  },
  {
      name: "Province of Vibo Valentia",
      code: "VV",
      country_code: "IT",
  },
  {
      name: "Province of Vicenza",
      code: "VI",
      country_code: "IT",
  },
  {
      name: "Province of Viterbo",
      code: "VT",
      country_code: "IT",
  },
  {
      name: "Sardinia",
      code: "88",
      country_code: "IT",
  },
  {
      name: "Sicily",
      code: "82",
      country_code: "IT",
  },
  {
      name: "South Tyrol",
      code: "BZ",
      country_code: "IT",
  },
  {
      name: "Trentino",
      code: "TN",
      country_code: "IT",
  },
  {
      name: "Trentino-South Tyrol",
      code: "32",
      country_code: "IT",
  },
  {
      name: "Tuscany",
      code: "52",
      country_code: "IT",
  },
  {
      name: "Umbria",
      code: "55",
      country_code: "IT",
  },
  {
      name: "Veneto",
      code: "34",
      country_code: "IT",
  },
  {
      name: "Clarendon Parish",
      code: "13",
      country_code: "JM",
  },
  {
      name: "Hanover Parish",
      code: "09",
      country_code: "JM",
  },
  {
      name: "Kingston Parish",
      code: "01",
      country_code: "JM",
  },
  {
      name: "Manchester Parish",
      code: "12",
      country_code: "JM",
  },
  {
      name: "Portland Parish",
      code: "04",
      country_code: "JM",
  },
  {
      name: "Saint Andrew",
      code: "02",
      country_code: "JM",
  },
  {
      name: "Saint Ann Parish",
      code: "06",
      country_code: "JM",
  },
  {
      name: "Saint Catherine Parish",
      code: "14",
      country_code: "JM",
  },
  {
      name: "Saint Elizabeth Parish",
      code: "11",
      country_code: "JM",
  },
  {
      name: "Saint James Parish",
      code: "08",
      country_code: "JM",
  },
  {
      name: "Saint Mary Parish",
      code: "05",
      country_code: "JM",
  },
  {
      name: "Saint Thomas Parish",
      code: "03",
      country_code: "JM",
  },
  {
      name: "Trelawny Parish",
      code: "07",
      country_code: "JM",
  },
  {
      name: "Westmoreland Parish",
      code: "10",
      country_code: "JM",
  },
  {
      name: "Aichi Prefecture",
      code: "23",
      country_code: "JP",
  },
  {
      name: "Akita Prefecture",
      code: "05",
      country_code: "JP",
  },
  {
      name: "Aomori Prefecture",
      code: "02",
      country_code: "JP",
  },
  {
      name: "Chiba Prefecture",
      code: "12",
      country_code: "JP",
  },
  {
      name: "Ehime Prefecture",
      code: "38",
      country_code: "JP",
  },
  {
      name: "Fukui Prefecture",
      code: "18",
      country_code: "JP",
  },
  {
      name: "Fukuoka Prefecture",
      code: "40",
      country_code: "JP",
  },
  {
      name: "Fukushima Prefecture",
      code: "07",
      country_code: "JP",
  },
  {
      name: "Gifu Prefecture",
      code: "21",
      country_code: "JP",
  },
  {
      name: "Gunma Prefecture",
      code: "10",
      country_code: "JP",
  },
  {
      name: "Hiroshima Prefecture",
      code: "34",
      country_code: "JP",
  },
  {
      name: "Hokkaidō Prefecture",
      code: "01",
      country_code: "JP",
  },
  {
      name: "Hyōgo Prefecture",
      code: "28",
      country_code: "JP",
  },
  {
      name: "Ibaraki Prefecture",
      code: "08",
      country_code: "JP",
  },
  {
      name: "Ishikawa Prefecture",
      code: "17",
      country_code: "JP",
  },
  {
      name: "Iwate Prefecture",
      code: "03",
      country_code: "JP",
  },
  {
      name: "Kagawa Prefecture",
      code: "37",
      country_code: "JP",
  },
  {
      name: "Kagoshima Prefecture",
      code: "46",
      country_code: "JP",
  },
  {
      name: "Kanagawa Prefecture",
      code: "14",
      country_code: "JP",
  },
  {
      name: "Kōchi Prefecture",
      code: "39",
      country_code: "JP",
  },
  {
      name: "Kumamoto Prefecture",
      code: "43",
      country_code: "JP",
  },
  {
      name: "Kyōto Prefecture",
      code: "26",
      country_code: "JP",
  },
  {
      name: "Mie Prefecture",
      code: "24",
      country_code: "JP",
  },
  {
      name: "Miyagi Prefecture",
      code: "04",
      country_code: "JP",
  },
  {
      name: "Miyazaki Prefecture",
      code: "45",
      country_code: "JP",
  },
  {
      name: "Nagano Prefecture",
      code: "20",
      country_code: "JP",
  },
  {
      name: "Nagasaki Prefecture",
      code: "42",
      country_code: "JP",
  },
  {
      name: "Nara Prefecture",
      code: "29",
      country_code: "JP",
  },
  {
      name: "Niigata Prefecture",
      code: "15",
      country_code: "JP",
  },
  {
      name: "Ōita Prefecture",
      code: "44",
      country_code: "JP",
  },
  {
      name: "Okayama Prefecture",
      code: "33",
      country_code: "JP",
  },
  {
      name: "Okinawa Prefecture",
      code: "47",
      country_code: "JP",
  },
  {
      name: "Ōsaka Prefecture",
      code: "27",
      country_code: "JP",
  },
  {
      name: "Saga Prefecture",
      code: "41",
      country_code: "JP",
  },
  {
      name: "Saitama Prefecture",
      code: "11",
      country_code: "JP",
  },
  {
      name: "Shiga Prefecture",
      code: "25",
      country_code: "JP",
  },
  {
      name: "Shimane Prefecture",
      code: "32",
      country_code: "JP",
  },
  {
      name: "Shizuoka Prefecture",
      code: "22",
      country_code: "JP",
  },
  {
      name: "Tochigi Prefecture",
      code: "09",
      country_code: "JP",
  },
  {
      name: "Tokushima Prefecture",
      code: "36",
      country_code: "JP",
  },
  {
      name: "Tokyo",
      code: "13",
      country_code: "JP",
  },
  {
      name: "Tottori Prefecture",
      code: "31",
      country_code: "JP",
  },
  {
      name: "Toyama Prefecture",
      code: "16",
      country_code: "JP",
  },
  {
      name: "Wakayama Prefecture",
      code: "30",
      country_code: "JP",
  },
  {
      name: "Yamagata Prefecture",
      code: "06",
      country_code: "JP",
  },
  {
      name: "Yamaguchi Prefecture",
      code: "35",
      country_code: "JP",
  },
  {
      name: "Yamanashi Prefecture",
      code: "19",
      country_code: "JP",
  },
  {
      name: "Ajloun Governorate",
      code: "AJ",
      country_code: "JO",
  },
  {
      name: "Amman Governorate",
      code: "AM",
      country_code: "JO",
  },
  {
      name: "Aqaba Governorate",
      code: "AQ",
      country_code: "JO",
  },
  {
      name: "Balqa Governorate",
      code: "BA",
      country_code: "JO",
  },
  {
      name: "Irbid Governorate",
      code: "IR",
      country_code: "JO",
  },
  {
      name: "Jerash Governorate",
      code: "JA",
      country_code: "JO",
  },
  {
      name: "Karak Governorate",
      code: "KA",
      country_code: "JO",
  },
  {
      name: "Ma'an Governorate",
      code: "MN",
      country_code: "JO",
  },
  {
      name: "Madaba Governorate",
      code: "MD",
      country_code: "JO",
  },
  {
      name: "Mafraq Governorate",
      code: "MA",
      country_code: "JO",
  },
  {
      name: "Tafilah Governorate",
      code: "AT",
      country_code: "JO",
  },
  {
      name: "Zarqa Governorate",
      code: "AZ",
      country_code: "JO",
  },
  {
      name: "Akmola Region",
      code: "AKM",
      country_code: "KZ",
  },
  {
      name: "Aktobe Region",
      code: "AKT",
      country_code: "KZ",
  },
  {
      name: "Almaty",
      code: "ALA",
      country_code: "KZ",
  },
  {
      name: "Almaty Region",
      code: "ALM",
      country_code: "KZ",
  },
  {
      name: "Atyrau Region",
      code: "ATY",
      country_code: "KZ",
  },
  {
      name: "Baikonur",
      code: "BAY",
      country_code: "KZ",
  },
  {
      name: "East Kazakhstan Region",
      code: "VOS",
      country_code: "KZ",
  },
  {
      name: "Jambyl Region",
      code: "ZHA",
      country_code: "KZ",
  },
  {
      name: "Karaganda Region",
      code: "KAR",
      country_code: "KZ",
  },
  {
      name: "Kostanay Region",
      code: "KUS",
      country_code: "KZ",
  },
  {
      name: "Kyzylorda Region",
      code: "KZY",
      country_code: "KZ",
  },
  {
      name: "Mangystau Region",
      code: "MAN",
      country_code: "KZ",
  },
  {
      name: "North Kazakhstan Region",
      code: "SEV",
      country_code: "KZ",
  },
  {
      name: "Nur-Sultan",
      code: "AST",
      country_code: "KZ",
  },
  {
      name: "Pavlodar Region",
      code: "PAV",
      country_code: "KZ",
  },
  {
      name: "Turkestan Region",
      code: "YUZ",
      country_code: "KZ",
  },
  {
      name: "West Kazakhstan Province",
      code: "ZAP",
      country_code: "KZ",
  },
  {
      name: "Baringo County",
      code: "01",
      country_code: "KE",
  },
  {
      name: "Bomet County",
      code: "02",
      country_code: "KE",
  },
  {
      name: "Bungoma County",
      code: "03",
      country_code: "KE",
  },
  {
      name: "Busia County",
      code: "04",
      country_code: "KE",
  },
  {
      name: "Central Province",
      code: "200",
      country_code: "KE",
  },
  {
      name: "Coast Province",
      code: "300",
      country_code: "KE",
  },
  {
      name: "Eastern Province",
      code: "400",
      country_code: "KE",
  },
  {
      name: "Elgeyo-Marakwet County",
      code: "05",
      country_code: "KE",
  },
  {
      name: "Embu County",
      code: "06",
      country_code: "KE",
  },
  {
      name: "Garissa County",
      code: "07",
      country_code: "KE",
  },
  {
      name: "Homa Bay County",
      code: "08",
      country_code: "KE",
  },
  {
      name: "Isiolo County",
      code: "09",
      country_code: "KE",
  },
  {
      name: "Kajiado County",
      code: "10",
      country_code: "KE",
  },
  {
      name: "Kakamega County",
      code: "11",
      country_code: "KE",
  },
  {
      name: "Kericho County",
      code: "12",
      country_code: "KE",
  },
  {
      name: "Kiambu County",
      code: "13",
      country_code: "KE",
  },
  {
      name: "Kilifi County",
      code: "14",
      country_code: "KE",
  },
  {
      name: "Kirinyaga County",
      code: "15",
      country_code: "KE",
  },
  {
      name: "Kisii County",
      code: "16",
      country_code: "KE",
  },
  {
      name: "Kisumu County",
      code: "17",
      country_code: "KE",
  },
  {
      name: "Kitui County",
      code: "18",
      country_code: "KE",
  },
  {
      name: "Kwale County",
      code: "19",
      country_code: "KE",
  },
  {
      name: "Laikipia County",
      code: "20",
      country_code: "KE",
  },
  {
      name: "Lamu County",
      code: "21",
      country_code: "KE",
  },
  {
      name: "Machakos County",
      code: "22",
      country_code: "KE",
  },
  {
      name: "Makueni County",
      code: "23",
      country_code: "KE",
  },
  {
      name: "Mandera County",
      code: "24",
      country_code: "KE",
  },
  {
      name: "Marsabit County",
      code: "25",
      country_code: "KE",
  },
  {
      name: "Meru County",
      code: "26",
      country_code: "KE",
  },
  {
      name: "Migori County",
      code: "27",
      country_code: "KE",
  },
  {
      name: "Mombasa County",
      code: "28",
      country_code: "KE",
  },
  {
      name: "Muranga County",
      code: "29",
      country_code: "KE",
  },
  {
      name: "Nairobi",
      code: "110",
      country_code: "KE",
  },
  {
      name: "Nakuru County",
      code: "31",
      country_code: "KE",
  },
  {
      name: "Nandi District",
      code: "32",
      country_code: "KE",
  },
  {
      name: "Narok County",
      code: "33",
      country_code: "KE",
  },
  {
      name: "North Eastern Province",
      code: "500",
      country_code: "KE",
  },
  {
      name: "Nyamira County",
      code: "34",
      country_code: "KE",
  },
  {
      name: "Nyandarua County",
      code: "35",
      country_code: "KE",
  },
  {
      name: "Nyanza Province",
      code: "600",
      country_code: "KE",
  },
  {
      name: "Nyeri County",
      code: "36",
      country_code: "KE",
  },
  {
      name: "Rift Valley Province",
      code: "700",
      country_code: "KE",
  },
  {
      name: "Samburu County",
      code: "37",
      country_code: "KE",
  },
  {
      name: "Siaya County",
      code: "38",
      country_code: "KE",
  },
  {
      name: "Taita–Taveta County",
      code: "39",
      country_code: "KE",
  },
  {
      name: "Tana River County",
      code: "40",
      country_code: "KE",
  },
  {
      name: "Tharaka Nithi County",
      code: "41",
      country_code: "KE",
  },
  {
      name: "Trans-Nzoia District",
      code: "42",
      country_code: "KE",
  },
  {
      name: "Turkana County",
      code: "43",
      country_code: "KE",
  },
  {
      name: "Uasin Gishu",
      code: "44",
      country_code: "KE",
  },
  {
      name: "Vihiga County",
      code: "45",
      country_code: "KE",
  },
  {
      name: "Wajir County",
      code: "46",
      country_code: "KE",
  },
  {
      name: "West Pokot County",
      code: "47",
      country_code: "KE",
  },
  {
      name: "Western Province",
      code: "800",
      country_code: "KE",
  },
  {
      name: "Gilbert Islands",
      code: "G",
      country_code: "KI",
  },
  {
      name: "Line Islands",
      code: "L",
      country_code: "KI",
  },
  {
      name: "Phoenix Islands",
      code: "P",
      country_code: "KI",
  },
  {
      name: "Đakovica District (Gjakove)",
      code: "XDG",
      country_code: "XK",
  },
  {
      name: "Gjilan District",
      code: "XGJ",
      country_code: "XK",
  },
  {
      name: "Kosovska Mitrovica District",
      code: "XKM",
      country_code: "XK",
  },
  {
      name: "Peć District",
      code: "XPE",
      country_code: "XK",
  },
  {
      name: "Pristina (Priştine)",
      code: "XPI",
      country_code: "XK",
  },
  {
      name: "Prizren District",
      code: "XPR",
      country_code: "XK",
  },
  {
      name: "Uroševac District (Ferizaj)",
      code: "XUF",
      country_code: "XK",
  },
  {
      name: "Al Ahmadi Governorate",
      code: "AH",
      country_code: "KW",
  },
  {
      name: "Al Farwaniyah Governorate",
      code: "FA",
      country_code: "KW",
  },
  {
      name: "Al Jahra Governorate",
      code: "JA",
      country_code: "KW",
  },
  {
      name: "Capital Governorate",
      code: "KU",
      country_code: "KW",
  },
  {
      name: "Hawalli Governorate",
      code: "HA",
      country_code: "KW",
  },
  {
      name: "Mubarak Al-Kabeer Governorate",
      code: "MU",
      country_code: "KW",
  },
  {
      name: "Batken Region",
      code: "B",
      country_code: "KG",
  },
  {
      name: "Bishkek",
      code: "GB",
      country_code: "KG",
  },
  {
      name: "Chuy Region",
      code: "C",
      country_code: "KG",
  },
  {
      name: "Issyk-Kul Region",
      code: "Y",
      country_code: "KG",
  },
  {
      name: "Jalal-Abad Region",
      code: "J",
      country_code: "KG",
  },
  {
      name: "Naryn Region",
      code: "N",
      country_code: "KG",
  },
  {
      name: "Osh",
      code: "GO",
      country_code: "KG",
  },
  {
      name: "Osh Region",
      code: "O",
      country_code: "KG",
  },
  {
      name: "Talas Region",
      code: "T",
      country_code: "KG",
  },
  {
      name: "Attapeu Province",
      code: "AT",
      country_code: "LA",
  },
  {
      name: "Bokeo Province",
      code: "BK",
      country_code: "LA",
  },
  {
      name: "Bolikhamsai Province",
      code: "BL",
      country_code: "LA",
  },
  {
      name: "Champasak Province",
      code: "CH",
      country_code: "LA",
  },
  {
      name: "Houaphanh Province",
      code: "HO",
      country_code: "LA",
  },
  {
      name: "Khammouane Province",
      code: "KH",
      country_code: "LA",
  },
  {
      name: "Luang Namtha Province",
      code: "LM",
      country_code: "LA",
  },
  {
      name: "Luang Prabang Province",
      code: "LP",
      country_code: "LA",
  },
  {
      name: "Oudomxay Province",
      code: "OU",
      country_code: "LA",
  },
  {
      name: "Phongsaly Province",
      code: "PH",
      country_code: "LA",
  },
  {
      name: "Sainyabuli Province",
      code: "XA",
      country_code: "LA",
  },
  {
      name: "Salavan Province",
      code: "SL",
      country_code: "LA",
  },
  {
      name: "Savannakhet Province",
      code: "SV",
      country_code: "LA",
  },
  {
      name: "Sekong Province",
      code: "XE",
      country_code: "LA",
  },
  {
      name: "Vientiane Prefecture",
      code: "VT",
      country_code: "LA",
  },
  {
      name: "Vientiane Province",
      code: "VI",
      country_code: "LA",
  },
  {
      name: "Xaisomboun",
      code: "XN",
      country_code: "LA",
  },
  {
      name: "Xaisomboun Province",
      code: "XS",
      country_code: "LA",
  },
  {
      name: "Xiangkhouang Province",
      code: "XI",
      country_code: "LA",
  },
  {
      name: "Aglona Municipality",
      code: "001",
      country_code: "LV",
  },
  {
      name: "Aizkraukle Municipality",
      code: "002",
      country_code: "LV",
  },
  {
      name: "Aizpute Municipality",
      code: "003",
      country_code: "LV",
  },
  {
      name: "Aknīste Municipality",
      code: "004",
      country_code: "LV",
  },
  {
      name: "Aloja Municipality",
      code: "005",
      country_code: "LV",
  },
  {
      name: "Alsunga Municipality",
      code: "006",
      country_code: "LV",
  },
  {
      name: "Alūksne Municipality",
      code: "007",
      country_code: "LV",
  },
  {
      name: "Amata Municipality",
      code: "008",
      country_code: "LV",
  },
  {
      name: "Ape Municipality",
      code: "009",
      country_code: "LV",
  },
  {
      name: "Auce Municipality",
      code: "010",
      country_code: "LV",
  },
  {
      name: "Babīte Municipality",
      code: "012",
      country_code: "LV",
  },
  {
      name: "Baldone Municipality",
      code: "013",
      country_code: "LV",
  },
  {
      name: "Baltinava Municipality",
      code: "014",
      country_code: "LV",
  },
  {
      name: "Balvi Municipality",
      code: "015",
      country_code: "LV",
  },
  {
      name: "Bauska Municipality",
      code: "016",
      country_code: "LV",
  },
  {
      name: "Beverīna Municipality",
      code: "017",
      country_code: "LV",
  },
  {
      name: "Brocēni Municipality",
      code: "018",
      country_code: "LV",
  },
  {
      name: "Burtnieki Municipality",
      code: "019",
      country_code: "LV",
  },
  {
      name: "Carnikava Municipality",
      code: "020",
      country_code: "LV",
  },
  {
      name: "Cēsis Municipality",
      code: "022",
      country_code: "LV",
  },
  {
      name: "Cesvaine Municipality",
      code: "021",
      country_code: "LV",
  },
  {
      name: "Cibla Municipality",
      code: "023",
      country_code: "LV",
  },
  {
      name: "Dagda Municipality",
      code: "024",
      country_code: "LV",
  },
  {
      name: "Daugavpils",
      code: "DGV",
      country_code: "LV",
  },
  {
      name: "Daugavpils Municipality",
      code: "025",
      country_code: "LV",
  },
  {
      name: "Dobele Municipality",
      code: "026",
      country_code: "LV",
  },
  {
      name: "Dundaga Municipality",
      code: "027",
      country_code: "LV",
  },
  {
      name: "Durbe Municipality",
      code: "028",
      country_code: "LV",
  },
  {
      name: "Engure Municipality",
      code: "029",
      country_code: "LV",
  },
  {
      name: "Ērgļi Municipality",
      code: "030",
      country_code: "LV",
  },
  {
      name: "Garkalne Municipality",
      code: "031",
      country_code: "LV",
  },
  {
      name: "Grobiņa Municipality",
      code: "032",
      country_code: "LV",
  },
  {
      name: "Gulbene Municipality",
      code: "033",
      country_code: "LV",
  },
  {
      name: "Iecava Municipality",
      code: "034",
      country_code: "LV",
  },
  {
      name: "Ikšķile Municipality",
      code: "035",
      country_code: "LV",
  },
  {
      name: "Ilūkste Municipality",
      code: "036",
      country_code: "LV",
  },
  {
      name: "Inčukalns Municipality",
      code: "037",
      country_code: "LV",
  },
  {
      name: "Jaunjelgava Municipality",
      code: "038",
      country_code: "LV",
  },
  {
      name: "Jaunpiebalga Municipality",
      code: "039",
      country_code: "LV",
  },
  {
      name: "Jaunpils Municipality",
      code: "040",
      country_code: "LV",
  },
  {
      name: "Jēkabpils",
      code: "JKB",
      country_code: "LV",
  },
  {
      name: "Jēkabpils Municipality",
      code: "042",
      country_code: "LV",
  },
  {
      name: "Jelgava",
      code: "JEL",
      country_code: "LV",
  },
  {
      name: "Jelgava Municipality",
      code: "041",
      country_code: "LV",
  },
  {
      name: "Jūrmala",
      code: "JUR",
      country_code: "LV",
  },
  {
      name: "Kandava Municipality",
      code: "043",
      country_code: "LV",
  },
  {
      name: "Kārsava Municipality",
      code: "044",
      country_code: "LV",
  },
  {
      name: "Ķegums Municipality",
      code: "051",
      country_code: "LV",
  },
  {
      name: "Ķekava Municipality",
      code: "052",
      country_code: "LV",
  },
  {
      name: "Kocēni Municipality",
      code: "045",
      country_code: "LV",
  },
  {
      name: "Koknese Municipality",
      code: "046",
      country_code: "LV",
  },
  {
      name: "Krāslava Municipality",
      code: "047",
      country_code: "LV",
  },
  {
      name: "Krimulda Municipality",
      code: "048",
      country_code: "LV",
  },
  {
      name: "Krustpils Municipality",
      code: "049",
      country_code: "LV",
  },
  {
      name: "Kuldīga Municipality",
      code: "050",
      country_code: "LV",
  },
  {
      name: "Lielvārde Municipality",
      code: "053",
      country_code: "LV",
  },
  {
      name: "Liepāja",
      code: "LPX",
      country_code: "LV",
  },
  {
      name: "Līgatne Municipality",
      code: "055",
      country_code: "LV",
  },
  {
      name: "Limbaži Municipality",
      code: "054",
      country_code: "LV",
  },
  {
      name: "Līvāni Municipality",
      code: "056",
      country_code: "LV",
  },
  {
      name: "Lubāna Municipality",
      code: "057",
      country_code: "LV",
  },
  {
      name: "Ludza Municipality",
      code: "058",
      country_code: "LV",
  },
  {
      name: "Madona Municipality",
      code: "059",
      country_code: "LV",
  },
  {
      name: "Mālpils Municipality",
      code: "061",
      country_code: "LV",
  },
  {
      name: "Mārupe Municipality",
      code: "062",
      country_code: "LV",
  },
  {
      name: "Mazsalaca Municipality",
      code: "060",
      country_code: "LV",
  },
  {
      name: "Mērsrags Municipality",
      code: "063",
      country_code: "LV",
  },
  {
      name: "Naukšēni Municipality",
      code: "064",
      country_code: "LV",
  },
  {
      name: "Nereta Municipality",
      code: "065",
      country_code: "LV",
  },
  {
      name: "Nīca Municipality",
      code: "066",
      country_code: "LV",
  },
  {
      name: "Ogre Municipality",
      code: "067",
      country_code: "LV",
  },
  {
      name: "Olaine Municipality",
      code: "068",
      country_code: "LV",
  },
  {
      name: "Ozolnieki Municipality",
      code: "069",
      country_code: "LV",
  },
  {
      name: "Pārgauja Municipality",
      code: "070",
      country_code: "LV",
  },
  {
      name: "Pāvilosta Municipality",
      code: "071",
      country_code: "LV",
  },
  {
      name: "Pļaviņas Municipality",
      code: "072",
      country_code: "LV",
  },
  {
      name: "Preiļi Municipality",
      code: "073",
      country_code: "LV",
  },
  {
      name: "Priekule Municipality",
      code: "074",
      country_code: "LV",
  },
  {
      name: "Priekuļi Municipality",
      code: "075",
      country_code: "LV",
  },
  {
      name: "Rauna Municipality",
      code: "076",
      country_code: "LV",
  },
  {
      name: "Rēzekne",
      code: "REZ",
      country_code: "LV",
  },
  {
      name: "Rēzekne Municipality",
      code: "077",
      country_code: "LV",
  },
  {
      name: "Riebiņi Municipality",
      code: "078",
      country_code: "LV",
  },
  {
      name: "Riga",
      code: "RIX",
      country_code: "LV",
  },
  {
      name: "Roja Municipality",
      code: "079",
      country_code: "LV",
  },
  {
      name: "Ropaži Municipality",
      code: "080",
      country_code: "LV",
  },
  {
      name: "Rucava Municipality",
      code: "081",
      country_code: "LV",
  },
  {
      name: "Rugāji Municipality",
      code: "082",
      country_code: "LV",
  },
  {
      name: "Rūjiena Municipality",
      code: "084",
      country_code: "LV",
  },
  {
      name: "Rundāle Municipality",
      code: "083",
      country_code: "LV",
  },
  {
      name: "Sala Municipality",
      code: "085",
      country_code: "LV",
  },
  {
      name: "Salacgrīva Municipality",
      code: "086",
      country_code: "LV",
  },
  {
      name: "Salaspils Municipality",
      code: "087",
      country_code: "LV",
  },
  {
      name: "Saldus Municipality",
      code: "088",
      country_code: "LV",
  },
  {
      name: "Saulkrasti Municipality",
      code: "089",
      country_code: "LV",
  },
  {
      name: "Sēja Municipality",
      code: "090",
      country_code: "LV",
  },
  {
      name: "Sigulda Municipality",
      code: "091",
      country_code: "LV",
  },
  {
      name: "Skrīveri Municipality",
      code: "092",
      country_code: "LV",
  },
  {
      name: "Skrunda Municipality",
      code: "093",
      country_code: "LV",
  },
  {
      name: "Smiltene Municipality",
      code: "094",
      country_code: "LV",
  },
  {
      name: "Stopiņi Municipality",
      code: "095",
      country_code: "LV",
  },
  {
      name: "Strenči Municipality",
      code: "096",
      country_code: "LV",
  },
  {
      name: "Talsi Municipality",
      code: "097",
      country_code: "LV",
  },
  {
      name: "Tērvete Municipality",
      code: "098",
      country_code: "LV",
  },
  {
      name: "Tukums Municipality",
      code: "099",
      country_code: "LV",
  },
  {
      name: "Vaiņode Municipality",
      code: "100",
      country_code: "LV",
  },
  {
      name: "Valka Municipality",
      code: "101",
      country_code: "LV",
  },
  {
      name: "Valmiera",
      code: "VMR",
      country_code: "LV",
  },
  {
      name: "Varakļāni Municipality",
      code: "102",
      country_code: "LV",
  },
  {
      name: "Vārkava Municipality",
      code: "103",
      country_code: "LV",
  },
  {
      name: "Vecpiebalga Municipality",
      code: "104",
      country_code: "LV",
  },
  {
      name: "Vecumnieki Municipality",
      code: "105",
      country_code: "LV",
  },
  {
      name: "Ventspils",
      code: "VEN",
      country_code: "LV",
  },
  {
      name: "Ventspils Municipality",
      code: "106",
      country_code: "LV",
  },
  {
      name: "Viesīte Municipality",
      code: "107",
      country_code: "LV",
  },
  {
      name: "Viļaka Municipality",
      code: "108",
      country_code: "LV",
  },
  {
      name: "Viļāni Municipality",
      code: "109",
      country_code: "LV",
  },
  {
      name: "Zilupe Municipality",
      code: "110",
      country_code: "LV",
  },
  {
      name: "Akkar Governorate",
      code: "AK",
      country_code: "LB",
  },
  {
      name: "Baalbek-Hermel Governorate",
      code: "BH",
      country_code: "LB",
  },
  {
      name: "Beirut Governorate",
      code: "BA",
      country_code: "LB",
  },
  {
      name: "Beqaa Governorate",
      code: "BI",
      country_code: "LB",
  },
  {
      name: "Mount Lebanon Governorate",
      code: "JL",
      country_code: "LB",
  },
  {
      name: "Nabatieh Governorate",
      code: "NA",
      country_code: "LB",
  },
  {
      name: "North Governorate",
      code: "AS",
      country_code: "LB",
  },
  {
      name: "South Governorate",
      code: "JA",
      country_code: "LB",
  },
  {
      name: "Berea District",
      code: "D",
      country_code: "LS",
  },
  {
      name: "Butha-Buthe District",
      code: "B",
      country_code: "LS",
  },
  {
      name: "Leribe District",
      code: "C",
      country_code: "LS",
  },
  {
      name: "Mafeteng District",
      code: "E",
      country_code: "LS",
  },
  {
      name: "Maseru District",
      code: "A",
      country_code: "LS",
  },
  {
      name: "Mohale's Hoek District",
      code: "F",
      country_code: "LS",
  },
  {
      name: "Mokhotlong District",
      code: "J",
      country_code: "LS",
  },
  {
      name: "Qacha's Nek District",
      code: "H",
      country_code: "LS",
  },
  {
      name: "Quthing District",
      code: "G",
      country_code: "LS",
  },
  {
      name: "Thaba-Tseka District",
      code: "K",
      country_code: "LS",
  },
  {
      name: "Bomi County",
      code: "BM",
      country_code: "LR",
  },
  {
      name: "Bong County",
      code: "BG",
      country_code: "LR",
  },
  {
      name: "Gbarpolu County",
      code: "GP",
      country_code: "LR",
  },
  {
      name: "Grand Bassa County",
      code: "GB",
      country_code: "LR",
  },
  {
      name: "Grand Cape Mount County",
      code: "CM",
      country_code: "LR",
  },
  {
      name: "Grand Gedeh County",
      code: "GG",
      country_code: "LR",
  },
  {
      name: "Grand Kru County",
      code: "GK",
      country_code: "LR",
  },
  {
      name: "Lofa County",
      code: "LO",
      country_code: "LR",
  },
  {
      name: "Margibi County",
      code: "MG",
      country_code: "LR",
  },
  {
      name: "Maryland County",
      code: "MY",
      country_code: "LR",
  },
  {
      name: "Montserrado County",
      code: "MO",
      country_code: "LR",
  },
  {
      name: "Nimba",
      code: "NI",
      country_code: "LR",
  },
  {
      name: "River Cess County",
      code: "RI",
      country_code: "LR",
  },
  {
      name: "River Gee County",
      code: "RG",
      country_code: "LR",
  },
  {
      name: "Sinoe County",
      code: "SI",
      country_code: "LR",
  },
  {
      name: "Al Wahat District",
      code: "WA",
      country_code: "LY",
  },
  {
      name: "Benghazi",
      code: "BA",
      country_code: "LY",
  },
  {
      name: "Derna District",
      code: "DR",
      country_code: "LY",
  },
  {
      name: "Ghat District",
      code: "GT",
      country_code: "LY",
  },
  {
      name: "Jabal al Akhdar",
      code: "JA",
      country_code: "LY",
  },
  {
      name: "Jabal al Gharbi District",
      code: "JG",
      country_code: "LY",
  },
  {
      name: "Jafara",
      code: "JI",
      country_code: "LY",
  },
  {
      name: "Jufra",
      code: "JU",
      country_code: "LY",
  },
  {
      name: "Kufra District",
      code: "KF",
      country_code: "LY",
  },
  {
      name: "Marj District",
      code: "MJ",
      country_code: "LY",
  },
  {
      name: "Misrata District",
      code: "MI",
      country_code: "LY",
  },
  {
      name: "Murqub",
      code: "MB",
      country_code: "LY",
  },
  {
      name: "Murzuq District",
      code: "MQ",
      country_code: "LY",
  },
  {
      name: "Nalut District",
      code: "NL",
      country_code: "LY",
  },
  {
      name: "Nuqat al Khams",
      code: "NQ",
      country_code: "LY",
  },
  {
      name: "Sabha District",
      code: "SB",
      country_code: "LY",
  },
  {
      name: "Sirte District",
      code: "SR",
      country_code: "LY",
  },
  {
      name: "Tripoli District",
      code: "TB",
      country_code: "LY",
  },
  {
      name: "Wadi al Hayaa District",
      code: "WD",
      country_code: "LY",
  },
  {
      name: "Wadi al Shatii District",
      code: "WS",
      country_code: "LY",
  },
  {
      name: "Zawiya District",
      code: "ZA",
      country_code: "LY",
  },
  {
      name: "Balzers",
      code: "01",
      country_code: "LI",
  },
  {
      name: "Eschen",
      code: "02",
      country_code: "LI",
  },
  {
      name: "Gamprin",
      code: "03",
      country_code: "LI",
  },
  {
      name: "Mauren",
      code: "04",
      country_code: "LI",
  },
  {
      name: "Planken",
      code: "05",
      country_code: "LI",
  },
  {
      name: "Ruggell",
      code: "06",
      country_code: "LI",
  },
  {
      name: "Schaan",
      code: "07",
      country_code: "LI",
  },
  {
      name: "Schellenberg",
      code: "08",
      country_code: "LI",
  },
  {
      name: "Triesen",
      code: "09",
      country_code: "LI",
  },
  {
      name: "Triesenberg",
      code: "10",
      country_code: "LI",
  },
  {
      name: "Vaduz",
      code: "11",
      country_code: "LI",
  },
  {
      name: "Akmenė District Municipality",
      code: "01",
      country_code: "LT",
  },
  {
      name: "Alytus City Municipality",
      code: "02",
      country_code: "LT",
  },
  {
      name: "Alytus County",
      code: "AL",
      country_code: "LT",
  },
  {
      name: "Alytus District Municipality",
      code: "03",
      country_code: "LT",
  },
  {
      name: "Birštonas Municipality",
      code: "05",
      country_code: "LT",
  },
  {
      name: "Biržai District Municipality",
      code: "06",
      country_code: "LT",
  },
  {
      name: "Druskininkai municipality",
      code: "07",
      country_code: "LT",
  },
  {
      name: "Elektrėnai municipality",
      code: "08",
      country_code: "LT",
  },
  {
      name: "Ignalina District Municipality",
      code: "09",
      country_code: "LT",
  },
  {
      name: "Jonava District Municipality",
      code: "10",
      country_code: "LT",
  },
  {
      name: "Joniškis District Municipality",
      code: "11",
      country_code: "LT",
  },
  {
      name: "Jurbarkas District Municipality",
      code: "12",
      country_code: "LT",
  },
  {
      name: "Kaišiadorys District Municipality",
      code: "13",
      country_code: "LT",
  },
  {
      name: "Kalvarija municipality",
      code: "14",
      country_code: "LT",
  },
  {
      name: "Kaunas City Municipality",
      code: "15",
      country_code: "LT",
  },
  {
      name: "Kaunas County",
      code: "KU",
      country_code: "LT",
  },
  {
      name: "Kaunas District Municipality",
      code: "16",
      country_code: "LT",
  },
  {
      name: "Kazlų Rūda municipality",
      code: "17",
      country_code: "LT",
  },
  {
      name: "Kėdainiai District Municipality",
      code: "18",
      country_code: "LT",
  },
  {
      name: "Kelmė District Municipality",
      code: "19",
      country_code: "LT",
  },
  {
      name: "Klaipeda City Municipality",
      code: "20",
      country_code: "LT",
  },
  {
      name: "Klaipėda County",
      code: "KL",
      country_code: "LT",
  },
  {
      name: "Klaipėda District Municipality",
      code: "21",
      country_code: "LT",
  },
  {
      name: "Kretinga District Municipality",
      code: "22",
      country_code: "LT",
  },
  {
      name: "Kupiškis District Municipality",
      code: "23",
      country_code: "LT",
  },
  {
      name: "Lazdijai District Municipality",
      code: "24",
      country_code: "LT",
  },
  {
      name: "Marijampolė County",
      code: "MR",
      country_code: "LT",
  },
  {
      name: "Marijampolė Municipality",
      code: "25",
      country_code: "LT",
  },
  {
      name: "Mažeikiai District Municipality",
      code: "26",
      country_code: "LT",
  },
  {
      name: "Molėtai District Municipality",
      code: "27",
      country_code: "LT",
  },
  {
      name: "Neringa Municipality",
      code: "28",
      country_code: "LT",
  },
  {
      name: "Pagėgiai municipality",
      code: "29",
      country_code: "LT",
  },
  {
      name: "Pakruojis District Municipality",
      code: "30",
      country_code: "LT",
  },
  {
      name: "Palanga City Municipality",
      code: "31",
      country_code: "LT",
  },
  {
      name: "Panevėžys City Municipality",
      code: "32",
      country_code: "LT",
  },
  {
      name: "Panevėžys County",
      code: "PN",
      country_code: "LT",
  },
  {
      name: "Panevėžys District Municipality",
      code: "33",
      country_code: "LT",
  },
  {
      name: "Pasvalys District Municipality",
      code: "34",
      country_code: "LT",
  },
  {
      name: "Plungė District Municipality",
      code: "35",
      country_code: "LT",
  },
  {
      name: "Prienai District Municipality",
      code: "36",
      country_code: "LT",
  },
  {
      name: "Radviliškis District Municipality",
      code: "37",
      country_code: "LT",
  },
  {
      name: "Raseiniai District Municipality",
      code: "38",
      country_code: "LT",
  },
  {
      name: "Rietavas municipality",
      code: "39",
      country_code: "LT",
  },
  {
      name: "Rokiškis District Municipality",
      code: "40",
      country_code: "LT",
  },
  {
      name: "Šakiai District Municipality",
      code: "41",
      country_code: "LT",
  },
  {
      name: "Šalčininkai District Municipality",
      code: "42",
      country_code: "LT",
  },
  {
      name: "Šiauliai City Municipality",
      code: "43",
      country_code: "LT",
  },
  {
      name: "Šiauliai County",
      code: "SA",
      country_code: "LT",
  },
  {
      name: "Šiauliai District Municipality",
      code: "44",
      country_code: "LT",
  },
  {
      name: "Šilalė District Municipality",
      code: "45",
      country_code: "LT",
  },
  {
      name: "Šilutė District Municipality",
      code: "46",
      country_code: "LT",
  },
  {
      name: "Širvintos District Municipality",
      code: "47",
      country_code: "LT",
  },
  {
      name: "Skuodas District Municipality",
      code: "48",
      country_code: "LT",
  },
  {
      name: "Švenčionys District Municipality",
      code: "49",
      country_code: "LT",
  },
  {
      name: "Tauragė County",
      code: "TA",
      country_code: "LT",
  },
  {
      name: "Tauragė District Municipality",
      code: "50",
      country_code: "LT",
  },
  {
      name: "Telšiai County",
      code: "TE",
      country_code: "LT",
  },
  {
      name: "Telšiai District Municipality",
      code: "51",
      country_code: "LT",
  },
  {
      name: "Trakai District Municipality",
      code: "52",
      country_code: "LT",
  },
  {
      name: "Ukmergė District Municipality",
      code: "53",
      country_code: "LT",
  },
  {
      name: "Utena County",
      code: "UT",
      country_code: "LT",
  },
  {
      name: "Utena District Municipality",
      code: "54",
      country_code: "LT",
  },
  {
      name: "Varėna District Municipality",
      code: "55",
      country_code: "LT",
  },
  {
      name: "Vilkaviškis District Municipality",
      code: "56",
      country_code: "LT",
  },
  {
      name: "Vilnius City Municipality",
      code: "57",
      country_code: "LT",
  },
  {
      name: "Vilnius County",
      code: "VL",
      country_code: "LT",
  },
  {
      name: "Vilnius District Municipality",
      code: "58",
      country_code: "LT",
  },
  {
      name: "Visaginas Municipality",
      code: "59",
      country_code: "LT",
  },
  {
      name: "Zarasai District Municipality",
      code: "60",
      country_code: "LT",
  },
  {
      name: "Canton of Capellen",
      code: "CA",
      country_code: "LU",
  },
  {
      name: "Canton of Clervaux",
      code: "CL",
      country_code: "LU",
  },
  {
      name: "Canton of Diekirch",
      code: "DI",
      country_code: "LU",
  },
  {
      name: "Canton of Echternach",
      code: "EC",
      country_code: "LU",
  },
  {
      name: "Canton of Esch-sur-Alzette",
      code: "ES",
      country_code: "LU",
  },
  {
      name: "Canton of Grevenmacher",
      code: "GR",
      country_code: "LU",
  },
  {
      name: "Canton of Luxembourg",
      code: "LU",
      country_code: "LU",
  },
  {
      name: "Canton of Mersch",
      code: "ME",
      country_code: "LU",
  },
  {
      name: "Canton of Redange",
      code: "RD",
      country_code: "LU",
  },
  {
      name: "Canton of Remich",
      code: "RM",
      country_code: "LU",
  },
  {
      name: "Canton of Vianden",
      code: "VD",
      country_code: "LU",
  },
  {
      name: "Canton of Wiltz",
      code: "WI",
      country_code: "LU",
  },
  {
      name: "Diekirch District",
      code: "D",
      country_code: "LU",
  },
  {
      name: "Grevenmacher District",
      code: "G",
      country_code: "LU",
  },
  {
      name: "Luxembourg District",
      code: "L",
      country_code: "LU",
  },
  {
      name: "Aerodrom Municipality",
      code: "01",
      country_code: "MK",
  },
  {
      name: "Aračinovo Municipality",
      code: "02",
      country_code: "MK",
  },
  {
      name: "Berovo Municipality",
      code: "03",
      country_code: "MK",
  },
  {
      name: "Bitola Municipality",
      code: "04",
      country_code: "MK",
  },
  {
      name: "Bogdanci Municipality",
      code: "05",
      country_code: "MK",
  },
  {
      name: "Bogovinje Municipality",
      code: "06",
      country_code: "MK",
  },
  {
      name: "Bosilovo Municipality",
      code: "07",
      country_code: "MK",
  },
  {
      name: "Brvenica Municipality",
      code: "08",
      country_code: "MK",
  },
  {
      name: "Butel Municipality",
      code: "09",
      country_code: "MK",
  },
  {
      name: "Čair Municipality",
      code: "79",
      country_code: "MK",
  },
  {
      name: "Čaška Municipality",
      code: "80",
      country_code: "MK",
  },
  {
      name: "Centar Municipality",
      code: "77",
      country_code: "MK",
  },
  {
      name: "Centar Župa Municipality",
      code: "78",
      country_code: "MK",
  },
  {
      name: "Češinovo-Obleševo Municipality",
      code: "81",
      country_code: "MK",
  },
  {
      name: "Čučer-Sandevo Municipality",
      code: "82",
      country_code: "MK",
  },
  {
      name: "Debarca Municipality",
      code: "22",
      country_code: "MK",
  },
  {
      name: "Delčevo Municipality",
      code: "23",
      country_code: "MK",
  },
  {
      name: "Demir Hisar Municipality",
      code: "25",
      country_code: "MK",
  },
  {
      name: "Demir Kapija Municipality",
      code: "24",
      country_code: "MK",
  },
  {
      name: "Dojran Municipality",
      code: "26",
      country_code: "MK",
  },
  {
      name: "Dolneni Municipality",
      code: "27",
      country_code: "MK",
  },
  {
      name: "Drugovo Municipality",
      code: "28",
      country_code: "MK",
  },
  {
      name: "Gazi Baba Municipality",
      code: "17",
      country_code: "MK",
  },
  {
      name: "Gevgelija Municipality",
      code: "18",
      country_code: "MK",
  },
  {
      name: "Gjorče Petrov Municipality",
      code: "29",
      country_code: "MK",
  },
  {
      name: "Gostivar Municipality",
      code: "19",
      country_code: "MK",
  },
  {
      name: "Gradsko Municipality",
      code: "20",
      country_code: "MK",
  },
  {
      name: "Greater Skopje",
      code: "85",
      country_code: "MK",
  },
  {
      name: "Ilinden Municipality",
      code: "34",
      country_code: "MK",
  },
  {
      name: "Jegunovce Municipality",
      code: "35",
      country_code: "MK",
  },
  {
      name: "Karbinci",
      code: "37",
      country_code: "MK",
  },
  {
      name: "Karpoš Municipality",
      code: "38",
      country_code: "MK",
  },
  {
      name: "Kavadarci Municipality",
      code: "36",
      country_code: "MK",
  },
  {
      name: "Kičevo Municipality",
      code: "40",
      country_code: "MK",
  },
  {
      name: "Kisela Voda Municipality",
      code: "39",
      country_code: "MK",
  },
  {
      name: "Kočani Municipality",
      code: "42",
      country_code: "MK",
  },
  {
      name: "Konče Municipality",
      code: "41",
      country_code: "MK",
  },
  {
      name: "Kratovo Municipality",
      code: "43",
      country_code: "MK",
  },
  {
      name: "Kriva Palanka Municipality",
      code: "44",
      country_code: "MK",
  },
  {
      name: "Krivogaštani Municipality",
      code: "45",
      country_code: "MK",
  },
  {
      name: "Kruševo Municipality",
      code: "46",
      country_code: "MK",
  },
  {
      name: "Kumanovo Municipality",
      code: "47",
      country_code: "MK",
  },
  {
      name: "Lipkovo Municipality",
      code: "48",
      country_code: "MK",
  },
  {
      name: "Lozovo Municipality",
      code: "49",
      country_code: "MK",
  },
  {
      name: "Makedonska Kamenica Municipality",
      code: "51",
      country_code: "MK",
  },
  {
      name: "Makedonski Brod Municipality",
      code: "52",
      country_code: "MK",
  },
  {
      name: "Mavrovo and Rostuša Municipality",
      code: "50",
      country_code: "MK",
  },
  {
      name: "Mogila Municipality",
      code: "53",
      country_code: "MK",
  },
  {
      name: "Negotino Municipality",
      code: "54",
      country_code: "MK",
  },
  {
      name: "Novaci Municipality",
      code: "55",
      country_code: "MK",
  },
  {
      name: "Novo Selo Municipality",
      code: "56",
      country_code: "MK",
  },
  {
      name: "Ohrid Municipality",
      code: "58",
      country_code: "MK",
  },
  {
      name: "Oslomej Municipality",
      code: "57",
      country_code: "MK",
  },
  {
      name: "Pehčevo Municipality",
      code: "60",
      country_code: "MK",
  },
  {
      name: "Petrovec Municipality",
      code: "59",
      country_code: "MK",
  },
  {
      name: "Plasnica Municipality",
      code: "61",
      country_code: "MK",
  },
  {
      name: "Prilep Municipality",
      code: "62",
      country_code: "MK",
  },
  {
      name: "Probištip Municipality",
      code: "63",
      country_code: "MK",
  },
  {
      name: "Radoviš Municipality",
      code: "64",
      country_code: "MK",
  },
  {
      name: "Rankovce Municipality",
      code: "65",
      country_code: "MK",
  },
  {
      name: "Resen Municipality",
      code: "66",
      country_code: "MK",
  },
  {
      name: "Rosoman Municipality",
      code: "67",
      country_code: "MK",
  },
  {
      name: "Saraj Municipality",
      code: "68",
      country_code: "MK",
  },
  {
      name: "Sopište Municipality",
      code: "70",
      country_code: "MK",
  },
  {
      name: "Staro Nagoričane Municipality",
      code: "71",
      country_code: "MK",
  },
  {
      name: "Štip Municipality",
      code: "83",
      country_code: "MK",
  },
  {
      name: "Struga Municipality",
      code: "72",
      country_code: "MK",
  },
  {
      name: "Strumica Municipality",
      code: "73",
      country_code: "MK",
  },
  {
      name: "Studeničani Municipality",
      code: "74",
      country_code: "MK",
  },
  {
      name: "Šuto Orizari Municipality",
      code: "84",
      country_code: "MK",
  },
  {
      name: "Sveti Nikole Municipality",
      code: "69",
      country_code: "MK",
  },
  {
      name: "Tearce Municipality",
      code: "75",
      country_code: "MK",
  },
  {
      name: "Tetovo Municipality",
      code: "76",
      country_code: "MK",
  },
  {
      name: "Valandovo Municipality",
      code: "10",
      country_code: "MK",
  },
  {
      name: "Vasilevo Municipality",
      code: "11",
      country_code: "MK",
  },
  {
      name: "Veles Municipality",
      code: "13",
      country_code: "MK",
  },
  {
      name: "Vevčani Municipality",
      code: "12",
      country_code: "MK",
  },
  {
      name: "Vinica Municipality",
      code: "14",
      country_code: "MK",
  },
  {
      name: "Vraneštica Municipality",
      code: "15",
      country_code: "MK",
  },
  {
      name: "Vrapčište Municipality",
      code: "16",
      country_code: "MK",
  },
  {
      name: "Zajas Municipality",
      code: "31",
      country_code: "MK",
  },
  {
      name: "Zelenikovo Municipality",
      code: "32",
      country_code: "MK",
  },
  {
      name: "Želino Municipality",
      code: "30",
      country_code: "MK",
  },
  {
      name: "Zrnovci Municipality",
      code: "33",
      country_code: "MK",
  },
  {
      name: "Antananarivo Province",
      code: "T",
      country_code: "MG",
  },
  {
      name: "Antsiranana Province",
      code: "D",
      country_code: "MG",
  },
  {
      name: "Fianarantsoa Province",
      code: "F",
      country_code: "MG",
  },
  {
      name: "Mahajanga Province",
      code: "M",
      country_code: "MG",
  },
  {
      name: "Toamasina Province",
      code: "A",
      country_code: "MG",
  },
  {
      name: "Toliara Province",
      code: "U",
      country_code: "MG",
  },
  {
      name: "Balaka District",
      code: "BA",
      country_code: "MW",
  },
  {
      name: "Blantyre District",
      code: "BL",
      country_code: "MW",
  },
  {
      name: "Central Region",
      code: "C",
      country_code: "MW",
  },
  {
      name: "Chikwawa District",
      code: "CK",
      country_code: "MW",
  },
  {
      name: "Chiradzulu District",
      code: "CR",
      country_code: "MW",
  },
  {
      name: "Chitipa district",
      code: "CT",
      country_code: "MW",
  },
  {
      name: "Dedza District",
      code: "DE",
      country_code: "MW",
  },
  {
      name: "Dowa District",
      code: "DO",
      country_code: "MW",
  },
  {
      name: "Karonga District",
      code: "KR",
      country_code: "MW",
  },
  {
      name: "Kasungu District",
      code: "KS",
      country_code: "MW",
  },
  {
      name: "Likoma District",
      code: "LK",
      country_code: "MW",
  },
  {
      name: "Lilongwe District",
      code: "LI",
      country_code: "MW",
  },
  {
      name: "Machinga District",
      code: "MH",
      country_code: "MW",
  },
  {
      name: "Mangochi District",
      code: "MG",
      country_code: "MW",
  },
  {
      name: "Mchinji District",
      code: "MC",
      country_code: "MW",
  },
  {
      name: "Mulanje District",
      code: "MU",
      country_code: "MW",
  },
  {
      name: "Mwanza District",
      code: "MW",
      country_code: "MW",
  },
  {
      name: "Mzimba District",
      code: "MZ",
      country_code: "MW",
  },
  {
      name: "Nkhata Bay District",
      code: "NB",
      country_code: "MW",
  },
  {
      name: "Nkhotakota District",
      code: "NK",
      country_code: "MW",
  },
  {
      name: "Northern Region",
      code: "N",
      country_code: "MW",
  },
  {
      name: "Nsanje District",
      code: "NS",
      country_code: "MW",
  },
  {
      name: "Ntcheu District",
      code: "NU",
      country_code: "MW",
  },
  {
      name: "Ntchisi District",
      code: "NI",
      country_code: "MW",
  },
  {
      name: "Phalombe District",
      code: "PH",
      country_code: "MW",
  },
  {
      name: "Rumphi District",
      code: "RU",
      country_code: "MW",
  },
  {
      name: "Salima District",
      code: "SA",
      country_code: "MW",
  },
  {
      name: "Southern Region",
      code: "S",
      country_code: "MW",
  },
  {
      name: "Thyolo District",
      code: "TH",
      country_code: "MW",
  },
  {
      name: "Zomba District",
      code: "ZO",
      country_code: "MW",
  },
  {
      name: "Johor",
      code: "01",
      country_code: "MY",
  },
  {
      name: "Kedah",
      code: "02",
      country_code: "MY",
  },
  {
      name: "Kelantan",
      code: "03",
      country_code: "MY",
  },
  {
      name: "Kuala Lumpur",
      code: "14",
      country_code: "MY",
  },
  {
      name: "Labuan",
      code: "15",
      country_code: "MY",
  },
  {
      name: "Malacca",
      code: "04",
      country_code: "MY",
  },
  {
      name: "Negeri Sembilan",
      code: "05",
      country_code: "MY",
  },
  {
      name: "Pahang",
      code: "06",
      country_code: "MY",
  },
  {
      name: "Penang",
      code: "07",
      country_code: "MY",
  },
  {
      name: "Perak",
      code: "08",
      country_code: "MY",
  },
  {
      name: "Perlis",
      code: "09",
      country_code: "MY",
  },
  {
      name: "Putrajaya",
      code: "16",
      country_code: "MY",
  },
  {
      name: "Sabah",
      code: "12",
      country_code: "MY",
  },
  {
      name: "Sarawak",
      code: "13",
      country_code: "MY",
  },
  {
      name: "Selangor",
      code: "10",
      country_code: "MY",
  },
  {
      name: "Terengganu",
      code: "11",
      country_code: "MY",
  },
  {
      name: "Addu Atoll",
      code: "01",
      country_code: "MV",
  },
  {
      name: "Alif Alif Atoll",
      code: "02",
      country_code: "MV",
  },
  {
      name: "Alif Dhaal Atoll",
      code: "00",
      country_code: "MV",
  },
  {
      name: "Central Province",
      code: "CE",
      country_code: "MV",
  },
  {
      name: "Dhaalu Atoll",
      code: "17",
      country_code: "MV",
  },
  {
      name: "Faafu Atoll",
      code: "14",
      country_code: "MV",
  },
  {
      name: "Gaafu Alif Atoll",
      code: "27",
      country_code: "MV",
  },
  {
      name: "Gaafu Dhaalu Atoll",
      code: "28",
      country_code: "MV",
  },
  {
      name: "Gnaviyani Atoll",
      code: "29",
      country_code: "MV",
  },
  {
      name: "Haa Alif Atoll",
      code: "07",
      country_code: "MV",
  },
  {
      name: "Haa Dhaalu Atoll",
      code: "23",
      country_code: "MV",
  },
  {
      name: "Kaafu Atoll",
      code: "26",
      country_code: "MV",
  },
  {
      name: "Laamu Atoll",
      code: "05",
      country_code: "MV",
  },
  {
      name: "Lhaviyani Atoll",
      code: "03",
      country_code: "MV",
  },
  {
      name: "Malé",
      code: "MLE",
      country_code: "MV",
  },
  {
      name: "Meemu Atoll",
      code: "12",
      country_code: "MV",
  },
  {
      name: "Noonu Atoll",
      code: "25",
      country_code: "MV",
  },
  {
      name: "North Central Province",
      code: "NC",
      country_code: "MV",
  },
  {
      name: "North Province",
      code: "NO",
      country_code: "MV",
  },
  {
      name: "Raa Atoll",
      code: "13",
      country_code: "MV",
  },
  {
      name: "Shaviyani Atoll",
      code: "24",
      country_code: "MV",
  },
  {
      name: "South Central Province",
      code: "SC",
      country_code: "MV",
  },
  {
      name: "South Province",
      code: "SU",
      country_code: "MV",
  },
  {
      name: "Thaa Atoll",
      code: "08",
      country_code: "MV",
  },
  {
      name: "Upper South Province",
      code: "US",
      country_code: "MV",
  },
  {
      name: "Vaavu Atoll",
      code: "04",
      country_code: "MV",
  },
  {
      name: "Bamako",
      code: "BKO",
      country_code: "ML",
  },
  {
      name: "Gao Region",
      code: "7",
      country_code: "ML",
  },
  {
      name: "Kayes Region",
      code: "1",
      country_code: "ML",
  },
  {
      name: "Kidal Region",
      code: "8",
      country_code: "ML",
  },
  {
      name: "Koulikoro Region",
      code: "2",
      country_code: "ML",
  },
  {
      name: "Ménaka Region",
      code: "9",
      country_code: "ML",
  },
  {
      name: "Mopti Region",
      code: "5",
      country_code: "ML",
  },
  {
      name: "Ségou Region",
      code: "4",
      country_code: "ML",
  },
  {
      name: "Sikasso Region",
      code: "3",
      country_code: "ML",
  },
  {
      name: "Taoudénit Region",
      code: "10",
      country_code: "ML",
  },
  {
      name: "Tombouctou Region",
      code: "6",
      country_code: "ML",
  },
  {
      name: "Attard",
      code: "01",
      country_code: "MT",
  },
  {
      name: "Balzan",
      code: "02",
      country_code: "MT",
  },
  {
      name: "Birgu",
      code: "03",
      country_code: "MT",
  },
  {
      name: "Birkirkara",
      code: "04",
      country_code: "MT",
  },
  {
      name: "Birżebbuġa",
      code: "05",
      country_code: "MT",
  },
  {
      name: "Cospicua",
      code: "06",
      country_code: "MT",
  },
  {
      name: "Dingli",
      code: "07",
      country_code: "MT",
  },
  {
      name: "Fgura",
      code: "08",
      country_code: "MT",
  },
  {
      name: "Floriana",
      code: "09",
      country_code: "MT",
  },
  {
      name: "Fontana",
      code: "10",
      country_code: "MT",
  },
  {
      name: "Għajnsielem",
      code: "13",
      country_code: "MT",
  },
  {
      name: "Għarb",
      code: "14",
      country_code: "MT",
  },
  {
      name: "Għargħur",
      code: "15",
      country_code: "MT",
  },
  {
      name: "Għasri",
      code: "16",
      country_code: "MT",
  },
  {
      name: "Għaxaq",
      code: "17",
      country_code: "MT",
  },
  {
      name: "Gudja",
      code: "11",
      country_code: "MT",
  },
  {
      name: "Gżira",
      code: "12",
      country_code: "MT",
  },
  {
      name: "Ħamrun",
      code: "18",
      country_code: "MT",
  },
  {
      name: "Iklin",
      code: "19",
      country_code: "MT",
  },
  {
      name: "Kalkara",
      code: "21",
      country_code: "MT",
  },
  {
      name: "Kerċem",
      code: "22",
      country_code: "MT",
  },
  {
      name: "Kirkop",
      code: "23",
      country_code: "MT",
  },
  {
      name: "Lija",
      code: "24",
      country_code: "MT",
  },
  {
      name: "Luqa",
      code: "25",
      country_code: "MT",
  },
  {
      name: "Marsa",
      code: "26",
      country_code: "MT",
  },
  {
      name: "Marsaskala",
      code: "27",
      country_code: "MT",
  },
  {
      name: "Marsaxlokk",
      code: "28",
      country_code: "MT",
  },
  {
      name: "Mdina",
      code: "29",
      country_code: "MT",
  },
  {
      name: "Mellieħa",
      code: "30",
      country_code: "MT",
  },
  {
      name: "Mġarr",
      code: "31",
      country_code: "MT",
  },
  {
      name: "Mosta",
      code: "32",
      country_code: "MT",
  },
  {
      name: "Mqabba",
      code: "33",
      country_code: "MT",
  },
  {
      name: "Msida",
      code: "34",
      country_code: "MT",
  },
  {
      name: "Mtarfa",
      code: "35",
      country_code: "MT",
  },
  {
      name: "Munxar",
      code: "36",
      country_code: "MT",
  },
  {
      name: "Nadur",
      code: "37",
      country_code: "MT",
  },
  {
      name: "Naxxar",
      code: "38",
      country_code: "MT",
  },
  {
      name: "Paola",
      code: "39",
      country_code: "MT",
  },
  {
      name: "Pembroke",
      code: "40",
      country_code: "MT",
  },
  {
      name: "Pietà",
      code: "41",
      country_code: "MT",
  },
  {
      name: "Qala",
      code: "42",
      country_code: "MT",
  },
  {
      name: "Qormi",
      code: "43",
      country_code: "MT",
  },
  {
      name: "Qrendi",
      code: "44",
      country_code: "MT",
  },
  {
      name: "Rabat",
      code: "46",
      country_code: "MT",
  },
  {
      name: "Saint Lawrence",
      code: "50",
      country_code: "MT",
  },
  {
      name: "San Ġwann",
      code: "49",
      country_code: "MT",
  },
  {
      name: "Sannat",
      code: "52",
      country_code: "MT",
  },
  {
      name: "Santa Luċija",
      code: "53",
      country_code: "MT",
  },
  {
      name: "Santa Venera",
      code: "54",
      country_code: "MT",
  },
  {
      name: "Senglea",
      code: "20",
      country_code: "MT",
  },
  {
      name: "Siġġiewi",
      code: "55",
      country_code: "MT",
  },
  {
      name: "Sliema",
      code: "56",
      country_code: "MT",
  },
  {
      name: "St. Julian's",
      code: "48",
      country_code: "MT",
  },
  {
      name: "St. Paul's Bay",
      code: "51",
      country_code: "MT",
  },
  {
      name: "Swieqi",
      code: "57",
      country_code: "MT",
  },
  {
      name: "Ta' Xbiex",
      code: "58",
      country_code: "MT",
  },
  {
      name: "Tarxien",
      code: "59",
      country_code: "MT",
  },
  {
      name: "Valletta",
      code: "60",
      country_code: "MT",
  },
  {
      name: "Victoria",
      code: "45",
      country_code: "MT",
  },
  {
      name: "Xagħra",
      code: "61",
      country_code: "MT",
  },
  {
      name: "Xewkija",
      code: "62",
      country_code: "MT",
  },
  {
      name: "Xgħajra",
      code: "63",
      country_code: "MT",
  },
  {
      name: "Żabbar",
      code: "64",
      country_code: "MT",
  },
  {
      name: "Żebbuġ Gozo",
      code: "65",
      country_code: "MT",
  },
  {
      name: "Żebbuġ Malta",
      code: "66",
      country_code: "MT",
  },
  {
      name: "Żejtun",
      code: "67",
      country_code: "MT",
  },
  {
      name: "Żurrieq",
      code: "68",
      country_code: "MT",
  },
  {
      name: "Ralik Chain",
      code: "L",
      country_code: "MH",
  },
  {
      name: "Ratak Chain",
      code: "T",
      country_code: "MH",
  },
  {
      name: "Adrar Region",
      code: "07",
      country_code: "MR",
  },
  {
      name: "Assaba Region",
      code: "03",
      country_code: "MR",
  },
  {
      name: "Brakna Region",
      code: "05",
      country_code: "MR",
  },
  {
      name: "Dakhlet Nouadhibou",
      code: "08",
      country_code: "MR",
  },
  {
      name: "Gorgol Region",
      code: "04",
      country_code: "MR",
  },
  {
      name: "Guidimaka Region",
      code: "10",
      country_code: "MR",
  },
  {
      name: "Hodh Ech Chargui Region",
      code: "01",
      country_code: "MR",
  },
  {
      name: "Hodh El Gharbi Region",
      code: "02",
      country_code: "MR",
  },
  {
      name: "Inchiri Region",
      code: "12",
      country_code: "MR",
  },
  {
      name: "Nouakchott-Nord Region",
      code: "14",
      country_code: "MR",
  },
  {
      name: "Nouakchott-Ouest Region",
      code: "13",
      country_code: "MR",
  },
  {
      name: "Nouakchott-Sud Region",
      code: "15",
      country_code: "MR",
  },
  {
      name: "Tagant Region",
      code: "09",
      country_code: "MR",
  },
  {
      name: "Tiris Zemmour Region",
      code: "11",
      country_code: "MR",
  },
  {
      name: "Trarza Region",
      code: "06",
      country_code: "MR",
  },
  {
      name: "Agaléga",
      code: "AG",
      country_code: "MU",
  },
  {
      name: "Beau Bassin-Rose Hill",
      code: "BR",
      country_code: "MU",
  },
  {
      name: "Cargados Carajos",
      code: "CC",
      country_code: "MU",
  },
  {
      name: "Curepipe",
      code: "CU",
      country_code: "MU",
  },
  {
      name: "Flacq District",
      code: "FL",
      country_code: "MU",
  },
  {
      name: "Grand Port District",
      code: "GP",
      country_code: "MU",
  },
  {
      name: "Moka District",
      code: "MO",
      country_code: "MU",
  },
  {
      name: "Pamplemousses District",
      code: "PA",
      country_code: "MU",
  },
  {
      name: "Plaines Wilhems District",
      code: "PW",
      country_code: "MU",
  },
  {
      name: "Port Louis",
      code: "PU",
      country_code: "MU",
  },
  {
      name: "Port Louis District",
      code: "PL",
      country_code: "MU",
  },
  {
      name: "Quatre Bornes",
      code: "QB",
      country_code: "MU",
  },
  {
      name: "Rivière du Rempart District",
      code: "RR",
      country_code: "MU",
  },
  {
      name: "Rivière Noire District",
      code: "BL",
      country_code: "MU",
  },
  {
      name: "Rodrigues",
      code: "RO",
      country_code: "MU",
  },
  {
      name: "Savanne District",
      code: "SA",
      country_code: "MU",
  },
  {
      name: "Vacoas-Phoenix",
      code: "VP",
      country_code: "MU",
  },
  {
      name: "Aguascalientes",
      code: "AGU",
      country_code: "MX",
  },
  {
      name: "Baja California",
      code: "BCN",
      country_code: "MX",
  },
  {
      name: "Baja California Sur",
      code: "BCS",
      country_code: "MX",
  },
  {
      name: "Campeche",
      code: "CAM",
      country_code: "MX",
  },
  {
      name: "Chiapas",
      code: "CHP",
      country_code: "MX",
  },
  {
      name: "Chihuahua",
      code: "CHH",
      country_code: "MX",
  },
  {
      name: "Coahuila",
      code: "COA",
      country_code: "MX",
  },
  {
      name: "Colima",
      code: "COL",
      country_code: "MX",
  },
  {
      name: "Durango",
      code: "DUR",
      country_code: "MX",
  },
  {
      name: "Guanajuato",
      code: "GUA",
      country_code: "MX",
  },
  {
      name: "Guerrero",
      code: "GRO",
      country_code: "MX",
  },
  {
      name: "Hidalgo",
      code: "HID",
      country_code: "MX",
  },
  {
      name: "Jalisco",
      code: "JAL",
      country_code: "MX",
  },
  {
      name: "México",
      code: "MEX",
      country_code: "MX",
  },
  {
      name: "Mexico City",
      code: "CMX",
      country_code: "MX",
  },
  {
      name: "Michoacán",
      code: "MIC",
      country_code: "MX",
  },
  {
      name: "Morelos",
      code: "MOR",
      country_code: "MX",
  },
  {
      name: "Nayarit",
      code: "NAY",
      country_code: "MX",
  },
  {
      name: "Nuevo León",
      code: "NLE",
      country_code: "MX",
  },
  {
      name: "Oaxaca",
      code: "OAX",
      country_code: "MX",
  },
  {
      name: "Puebla",
      code: "PUE",
      country_code: "MX",
  },
  {
      name: "Querétaro",
      code: "QUE",
      country_code: "MX",
  },
  {
      name: "Quintana Roo",
      code: "ROO",
      country_code: "MX",
  },
  {
      name: "San Luis Potosí",
      code: "SLP",
      country_code: "MX",
  },
  {
      name: "Sinaloa",
      code: "SIN",
      country_code: "MX",
  },
  {
      name: "Sonora",
      code: "SON",
      country_code: "MX",
  },
  {
      name: "Tabasco",
      code: "TAB",
      country_code: "MX",
  },
  {
      name: "Tamaulipas",
      code: "TAM",
      country_code: "MX",
  },
  {
      name: "Tlaxcala",
      code: "TLA",
      country_code: "MX",
  },
  {
      name: "Veracruz",
      code: "VER",
      country_code: "MX",
  },
  {
      name: "Yucatán",
      code: "YUC",
      country_code: "MX",
  },
  {
      name: "Zacatecas",
      code: "ZAC",
      country_code: "MX",
  },
  {
      name: "Chuuk State",
      code: "TRK",
      country_code: "FM",
  },
  {
      name: "Kosrae State",
      code: "KSA",
      country_code: "FM",
  },
  {
      name: "Pohnpei State",
      code: "PNI",
      country_code: "FM",
  },
  {
      name: "Yap State",
      code: "YAP",
      country_code: "FM",
  },
  {
      name: "Anenii Noi District",
      code: "AN",
      country_code: "MD",
  },
  {
      name: "Bălți Municipality",
      code: "BA",
      country_code: "MD",
  },
  {
      name: "Basarabeasca District",
      code: "BS",
      country_code: "MD",
  },
  {
      name: "Bender Municipality",
      code: "BD",
      country_code: "MD",
  },
  {
      name: "Briceni District",
      code: "BR",
      country_code: "MD",
  },
  {
      name: "Cahul District",
      code: "CA",
      country_code: "MD",
  },
  {
      name: "Călărași District",
      code: "CL",
      country_code: "MD",
  },
  {
      name: "Cantemir District",
      code: "CT",
      country_code: "MD",
  },
  {
      name: "Căușeni District",
      code: "CS",
      country_code: "MD",
  },
  {
      name: "Chișinău Municipality",
      code: "CU",
      country_code: "MD",
  },
  {
      name: "Cimișlia District",
      code: "CM",
      country_code: "MD",
  },
  {
      name: "Criuleni District",
      code: "CR",
      country_code: "MD",
  },
  {
      name: "Dondușeni District",
      code: "DO",
      country_code: "MD",
  },
  {
      name: "Drochia District",
      code: "DR",
      country_code: "MD",
  },
  {
      name: "Dubăsari District",
      code: "DU",
      country_code: "MD",
  },
  {
      name: "Edineț District",
      code: "ED",
      country_code: "MD",
  },
  {
      name: "Fălești District",
      code: "FA",
      country_code: "MD",
  },
  {
      name: "Florești District",
      code: "FL",
      country_code: "MD",
  },
  {
      name: "Gagauzia",
      code: "GA",
      country_code: "MD",
  },
  {
      name: "Glodeni District",
      code: "GL",
      country_code: "MD",
  },
  {
      name: "Hîncești District",
      code: "HI",
      country_code: "MD",
  },
  {
      name: "Ialoveni District",
      code: "IA",
      country_code: "MD",
  },
  {
      name: "Nisporeni District",
      code: "NI",
      country_code: "MD",
  },
  {
      name: "Ocnița District",
      code: "OC",
      country_code: "MD",
  },
  {
      name: "Orhei District",
      code: "OR",
      country_code: "MD",
  },
  {
      name: "Rezina District",
      code: "RE",
      country_code: "MD",
  },
  {
      name: "Rîșcani District",
      code: "RI",
      country_code: "MD",
  },
  {
      name: "Sîngerei District",
      code: "SI",
      country_code: "MD",
  },
  {
      name: "Șoldănești District",
      code: "SD",
      country_code: "MD",
  },
  {
      name: "Soroca District",
      code: "SO",
      country_code: "MD",
  },
  {
      name: "Ștefan Vodă District",
      code: "SV",
      country_code: "MD",
  },
  {
      name: "Strășeni District",
      code: "ST",
      country_code: "MD",
  },
  {
      name: "Taraclia District",
      code: "TA",
      country_code: "MD",
  },
  {
      name: "Telenești District",
      code: "TE",
      country_code: "MD",
  },
  {
      name: "Transnistria autonomous territorial unit",
      code: "SN",
      country_code: "MD",
  },
  {
      name: "Ungheni District",
      code: "UN",
      country_code: "MD",
  },
  {
      name: "La Colle",
      code: "CL",
      country_code: "MC",
  },
  {
      name: "La Condamine",
      code: "CO",
      country_code: "MC",
  },
  {
      name: "Moneghetti",
      code: "MG",
      country_code: "MC",
  },
  {
      name: "Arkhangai Province",
      code: "073",
      country_code: "MN",
  },
  {
      name: "Bayan-Ölgii Province",
      code: "071",
      country_code: "MN",
  },
  {
      name: "Bayankhongor Province",
      code: "069",
      country_code: "MN",
  },
  {
      name: "Bulgan Province",
      code: "067",
      country_code: "MN",
  },
  {
      name: "Darkhan-Uul Province",
      code: "037",
      country_code: "MN",
  },
  {
      name: "Dornod Province",
      code: "061",
      country_code: "MN",
  },
  {
      name: "Dornogovi Province",
      code: "063",
      country_code: "MN",
  },
  {
      name: "Dundgovi Province",
      code: "059",
      country_code: "MN",
  },
  {
      name: "Govi-Altai Province",
      code: "065",
      country_code: "MN",
  },
  {
      name: "Govisümber Province",
      code: "064",
      country_code: "MN",
  },
  {
      name: "Khentii Province",
      code: "039",
      country_code: "MN",
  },
  {
      name: "Khovd Province",
      code: "043",
      country_code: "MN",
  },
  {
      name: "Khövsgöl Province",
      code: "041",
      country_code: "MN",
  },
  {
      name: "Ömnögovi Province",
      code: "053",
      country_code: "MN",
  },
  {
      name: "Orkhon Province",
      code: "035",
      country_code: "MN",
  },
  {
      name: "Övörkhangai Province",
      code: "055",
      country_code: "MN",
  },
  {
      name: "Selenge Province",
      code: "049",
      country_code: "MN",
  },
  {
      name: "Sükhbaatar Province",
      code: "051",
      country_code: "MN",
  },
  {
      name: "Töv Province",
      code: "047",
      country_code: "MN",
  },
  {
      name: "Uvs Province",
      code: "046",
      country_code: "MN",
  },
  {
      name: "Zavkhan Province",
      code: "057",
      country_code: "MN",
  },
  {
      name: "Andrijevica Municipality",
      code: "01",
      country_code: "ME",
  },
  {
      name: "Bar Municipality",
      code: "02",
      country_code: "ME",
  },
  {
      name: "Berane Municipality",
      code: "03",
      country_code: "ME",
  },
  {
      name: "Bijelo Polje Municipality",
      code: "04",
      country_code: "ME",
  },
  {
      name: "Budva Municipality",
      code: "05",
      country_code: "ME",
  },
  {
      name: "Danilovgrad Municipality",
      code: "07",
      country_code: "ME",
  },
  {
      name: "Gusinje Municipality",
      code: "22",
      country_code: "ME",
  },
  {
      name: "Kolašin Municipality",
      code: "09",
      country_code: "ME",
  },
  {
      name: "Kotor Municipality",
      code: "10",
      country_code: "ME",
  },
  {
      name: "Mojkovac Municipality",
      code: "11",
      country_code: "ME",
  },
  {
      name: "Nikšić Municipality",
      code: "12",
      country_code: "ME",
  },
  {
      name: "Old Royal Capital Cetinje",
      code: "06",
      country_code: "ME",
  },
  {
      name: "Petnjica Municipality",
      code: "23",
      country_code: "ME",
  },
  {
      name: "Plav Municipality",
      code: "13",
      country_code: "ME",
  },
  {
      name: "Pljevlja Municipality",
      code: "14",
      country_code: "ME",
  },
  {
      name: "Plužine Municipality",
      code: "15",
      country_code: "ME",
  },
  {
      name: "Podgorica Municipality",
      code: "16",
      country_code: "ME",
  },
  {
      name: "Rožaje Municipality",
      code: "17",
      country_code: "ME",
  },
  {
      name: "Šavnik Municipality",
      code: "18",
      country_code: "ME",
  },
  {
      name: "Tivat Municipality",
      code: "19",
      country_code: "ME",
  },
  {
      name: "Ulcinj Municipality",
      code: "20",
      country_code: "ME",
  },
  {
      name: "Žabljak Municipality",
      code: "21",
      country_code: "ME",
  },
  {
      name: "Agadir-Ida-Ou-Tanane",
      code: "AGD",
      country_code: "MA",
  },
  {
      name: "Al Haouz",
      code: "HAO",
      country_code: "MA",
  },
  {
      name: "Al Hoceïma",
      code: "HOC",
      country_code: "MA",
  },
  {
      name: "Aousserd (EH)",
      code: "AOU",
      country_code: "MA",
  },
  {
      name: "Assa-Zag (EH-partial)",
      code: "ASZ",
      country_code: "MA",
  },
  {
      name: "Azilal",
      code: "AZI",
      country_code: "MA",
  },
  {
      name: "Béni Mellal",
      code: "BEM",
      country_code: "MA",
  },
  {
      name: "Béni Mellal-Khénifra",
      code: "05",
      country_code: "MA",
  },
  {
      name: "Benslimane",
      code: "BES",
      country_code: "MA",
  },
  {
      name: "Berkane",
      code: "BER",
      country_code: "MA",
  },
  {
      name: "Berrechid",
      code: "BRR",
      country_code: "MA",
  },
  {
      name: "Boujdour (EH)",
      code: "BOD",
      country_code: "MA",
  },
  {
      name: "Boulemane",
      code: "BOM",
      country_code: "MA",
  },
  {
      name: "Casablanca",
      code: "CAS",
      country_code: "MA",
  },
  {
      name: "Casablanca-Settat",
      code: "06",
      country_code: "MA",
  },
  {
      name: "Chefchaouen",
      code: "CHE",
      country_code: "MA",
  },
  {
      name: "Chichaoua",
      code: "CHI",
      country_code: "MA",
  },
  {
      name: "Chtouka-Ait Baha",
      code: "CHT",
      country_code: "MA",
  },
  {
      name: "Dakhla-Oued Ed-Dahab (EH)",
      code: "12",
      country_code: "MA",
  },
  {
      name: "Drâa-Tafilalet",
      code: "08",
      country_code: "MA",
  },
  {
      name: "Driouch",
      code: "DRI",
      country_code: "MA",
  },
  {
      name: "El Hajeb",
      code: "HAJ",
      country_code: "MA",
  },
  {
      name: "El Jadida",
      code: "JDI",
      country_code: "MA",
  },
  {
      name: "El Kelâa des Sraghna",
      code: "KES",
      country_code: "MA",
  },
  {
      name: "Errachidia",
      code: "ERR",
      country_code: "MA",
  },
  {
      name: "Es-Semara (EH-partial)",
      code: "ESM",
      country_code: "MA",
  },
  {
      name: "Essaouira",
      code: "ESI",
      country_code: "MA",
  },
  {
      name: "Fahs-Anjra",
      code: "FAH",
      country_code: "MA",
  },
  {
      name: "Fès",
      code: "FES",
      country_code: "MA",
  },
  {
      name: "Fès-Meknès",
      code: "03",
      country_code: "MA",
  },
  {
      name: "Figuig",
      code: "FIG",
      country_code: "MA",
  },
  {
      name: "Fquih Ben Salah",
      code: "FQH",
      country_code: "MA",
  },
  {
      name: "Guelmim",
      code: "GUE",
      country_code: "MA",
  },
  {
      name: "Guelmim-Oued Noun (EH-partial)",
      code: "10",
      country_code: "MA",
  },
  {
      name: "Guercif",
      code: "GUF",
      country_code: "MA",
  },
  {
      name: "Ifrane",
      code: "IFR",
      country_code: "MA",
  },
  {
      name: "Inezgane-Ait Melloul",
      code: "INE",
      country_code: "MA",
  },
  {
      name: "Jerada",
      code: "JRA",
      country_code: "MA",
  },
  {
      name: "Kénitra",
      code: "KEN",
      country_code: "MA",
  },
  {
      name: "Khémisset",
      code: "KHE",
      country_code: "MA",
  },
  {
      name: "Khénifra",
      code: "KHN",
      country_code: "MA",
  },
  {
      name: "Khouribga",
      code: "KHO",
      country_code: "MA",
  },
  {
      name: "L'Oriental",
      code: "02",
      country_code: "MA",
  },
  {
      name: "Laâyoune (EH)",
      code: "LAA",
      country_code: "MA",
  },
  {
      name: "Laâyoune-Sakia El Hamra (EH-partial)",
      code: "11",
      country_code: "MA",
  },
  {
      name: "Larache",
      code: "LAR",
      country_code: "MA",
  },
  {
      name: "M’diq-Fnideq",
      code: "MDF",
      country_code: "MA",
  },
  {
      name: "Marrakech",
      code: "MAR",
      country_code: "MA",
  },
  {
      name: "Marrakesh-Safi",
      code: "07",
      country_code: "MA",
  },
  {
      name: "Médiouna",
      code: "MED",
      country_code: "MA",
  },
  {
      name: "Meknès",
      code: "MEK",
      country_code: "MA",
  },
  {
      name: "Midelt",
      code: "MID",
      country_code: "MA",
  },
  {
      name: "Mohammadia",
      code: "MOH",
      country_code: "MA",
  },
  {
      name: "Moulay Yacoub",
      code: "MOU",
      country_code: "MA",
  },
  {
      name: "Nador",
      code: "NAD",
      country_code: "MA",
  },
  {
      name: "Nouaceur",
      code: "NOU",
      country_code: "MA",
  },
  {
      name: "Ouarzazate",
      code: "OUA",
      country_code: "MA",
  },
  {
      name: "Oued Ed-Dahab (EH)",
      code: "OUD",
      country_code: "MA",
  },
  {
      name: "Ouezzane",
      code: "OUZ",
      country_code: "MA",
  },
  {
      name: "Oujda-Angad",
      code: "OUJ",
      country_code: "MA",
  },
  {
      name: "Rabat",
      code: "RAB",
      country_code: "MA",
  },
  {
      name: "Rabat-Salé-Kénitra",
      code: "04",
      country_code: "MA",
  },
  {
      name: "Rehamna",
      code: "REH",
      country_code: "MA",
  },
  {
      name: "Safi",
      code: "SAF",
      country_code: "MA",
  },
  {
      name: "Salé",
      code: "SAL",
      country_code: "MA",
  },
  {
      name: "Sefrou",
      code: "SEF",
      country_code: "MA",
  },
  {
      name: "Settat",
      code: "SET",
      country_code: "MA",
  },
  {
      name: "Sidi Bennour",
      code: "SIB",
      country_code: "MA",
  },
  {
      name: "Sidi Ifni",
      code: "SIF",
      country_code: "MA",
  },
  {
      name: "Sidi Kacem",
      code: "SIK",
      country_code: "MA",
  },
  {
      name: "Sidi Slimane",
      code: "SIL",
      country_code: "MA",
  },
  {
      name: "Skhirate-Témara",
      code: "SKH",
      country_code: "MA",
  },
  {
      name: "Souss-Massa",
      code: "09",
      country_code: "MA",
  },
  {
      name: "Tan-Tan (EH-partial)",
      code: "TNT",
      country_code: "MA",
  },
  {
      name: "Tanger-Assilah",
      code: "TNG",
      country_code: "MA",
  },
  {
      name: "Tanger-Tétouan-Al Hoceïma",
      code: "01",
      country_code: "MA",
  },
  {
      name: "Taounate",
      code: "TAO",
      country_code: "MA",
  },
  {
      name: "Taourirt",
      code: "TAI",
      country_code: "MA",
  },
  {
      name: "Tarfaya (EH-partial)",
      code: "TAF",
      country_code: "MA",
  },
  {
      name: "Taroudannt",
      code: "TAR",
      country_code: "MA",
  },
  {
      name: "Tata",
      code: "TAT",
      country_code: "MA",
  },
  {
      name: "Taza",
      code: "TAZ",
      country_code: "MA",
  },
  {
      name: "Tétouan",
      code: "TET",
      country_code: "MA",
  },
  {
      name: "Tinghir",
      code: "TIN",
      country_code: "MA",
  },
  {
      name: "Tiznit",
      code: "TIZ",
      country_code: "MA",
  },
  {
      name: "Youssoufia",
      code: "YUS",
      country_code: "MA",
  },
  {
      name: "Zagora",
      code: "ZAG",
      country_code: "MA",
  },
  {
      name: "Cabo Delgado Province",
      code: "P",
      country_code: "MZ",
  },
  {
      name: "Gaza Province",
      code: "G",
      country_code: "MZ",
  },
  {
      name: "Inhambane Province",
      code: "I",
      country_code: "MZ",
  },
  {
      name: "Manica Province",
      code: "B",
      country_code: "MZ",
  },
  {
      name: "Maputo",
      code: "MPM",
      country_code: "MZ",
  },
  {
      name: "Maputo Province",
      code: "L",
      country_code: "MZ",
  },
  {
      name: "Nampula Province",
      code: "N",
      country_code: "MZ",
  },
  {
      name: "Niassa Province",
      code: "A",
      country_code: "MZ",
  },
  {
      name: "Sofala Province",
      code: "S",
      country_code: "MZ",
  },
  {
      name: "Tete Province",
      code: "T",
      country_code: "MZ",
  },
  {
      name: "Zambezia Province",
      code: "Q",
      country_code: "MZ",
  },
  {
      name: "Ayeyarwady Region",
      code: "07",
      country_code: "MM",
  },
  {
      name: "Bago",
      code: "02",
      country_code: "MM",
  },
  {
      name: "Chin State",
      code: "14",
      country_code: "MM",
  },
  {
      name: "Kachin State",
      code: "11",
      country_code: "MM",
  },
  {
      name: "Kayah State",
      code: "12",
      country_code: "MM",
  },
  {
      name: "Kayin State",
      code: "13",
      country_code: "MM",
  },
  {
      name: "Magway Region",
      code: "03",
      country_code: "MM",
  },
  {
      name: "Mandalay Region",
      code: "04",
      country_code: "MM",
  },
  {
      name: "Mon State",
      code: "15",
      country_code: "MM",
  },
  {
      name: "Naypyidaw Union Territory",
      code: "18",
      country_code: "MM",
  },
  {
      name: "Rakhine State",
      code: "16",
      country_code: "MM",
  },
  {
      name: "Sagaing Region",
      code: "01",
      country_code: "MM",
  },
  {
      name: "Shan State",
      code: "17",
      country_code: "MM",
  },
  {
      name: "Tanintharyi Region",
      code: "05",
      country_code: "MM",
  },
  {
      name: "Yangon Region",
      code: "06",
      country_code: "MM",
  },
  {
      name: "Erongo Region",
      code: "ER",
      country_code: "NA",
  },
  {
      name: "Hardap Region",
      code: "HA",
      country_code: "NA",
  },
  {
      name: "Karas Region",
      code: "KA",
      country_code: "NA",
  },
  {
      name: "Kavango East Region",
      code: "KE",
      country_code: "NA",
  },
  {
      name: "Kavango West Region",
      code: "KW",
      country_code: "NA",
  },
  {
      name: "Khomas Region",
      code: "KH",
      country_code: "NA",
  },
  {
      name: "Kunene Region",
      code: "KU",
      country_code: "NA",
  },
  {
      name: "Ohangwena Region",
      code: "OW",
      country_code: "NA",
  },
  {
      name: "Omaheke Region",
      code: "OH",
      country_code: "NA",
  },
  {
      name: "Omusati Region",
      code: "OS",
      country_code: "NA",
  },
  {
      name: "Oshana Region",
      code: "ON",
      country_code: "NA",
  },
  {
      name: "Oshikoto Region",
      code: "OT",
      country_code: "NA",
  },
  {
      name: "Otjozondjupa Region",
      code: "OD",
      country_code: "NA",
  },
  {
      name: "Zambezi Region",
      code: "CA",
      country_code: "NA",
  },
  {
      name: "Aiwo District",
      code: "01",
      country_code: "NR",
  },
  {
      name: "Anabar District",
      code: "02",
      country_code: "NR",
  },
  {
      name: "Anetan District",
      code: "03",
      country_code: "NR",
  },
  {
      name: "Anibare District",
      code: "04",
      country_code: "NR",
  },
  {
      name: "Baiti District",
      code: "05",
      country_code: "NR",
  },
  {
      name: "Boe District",
      code: "06",
      country_code: "NR",
  },
  {
      name: "Buada District",
      code: "07",
      country_code: "NR",
  },
  {
      name: "Denigomodu District",
      code: "08",
      country_code: "NR",
  },
  {
      name: "Ewa District",
      code: "09",
      country_code: "NR",
  },
  {
      name: "Ijuw District",
      code: "10",
      country_code: "NR",
  },
  {
      name: "Meneng District",
      code: "11",
      country_code: "NR",
  },
  {
      name: "Nibok District",
      code: "12",
      country_code: "NR",
  },
  {
      name: "Uaboe District",
      code: "13",
      country_code: "NR",
  },
  {
      name: "Yaren District",
      code: "14",
      country_code: "NR",
  },
  {
      name: "Bagmati Zone",
      code: "BA",
      country_code: "NP",
  },
  {
      name: "Bheri Zone",
      code: "BH",
      country_code: "NP",
  },
  {
      name: "Central Region",
      code: "1",
      country_code: "NP",
  },
  {
      name: "Dhaulagiri Zone",
      code: "DH",
      country_code: "NP",
  },
  {
      name: "Eastern Development Region",
      code: "4",
      country_code: "NP",
  },
  {
      name: "Far-Western Development Region",
      code: "5",
      country_code: "NP",
  },
  {
      name: "Gandaki Zone",
      code: "GA",
      country_code: "NP",
  },
  {
      name: "Janakpur Zone",
      code: "JA",
      country_code: "NP",
  },
  {
      name: "Karnali Zone",
      code: "KA",
      country_code: "NP",
  },
  {
      name: "Kosi Zone",
      code: "KO",
      country_code: "NP",
  },
  {
      name: "Lumbini Zone",
      code: "LU",
      country_code: "NP",
  },
  {
      name: "Mahakali Zone",
      code: "MA",
      country_code: "NP",
  },
  {
      name: "Mechi Zone",
      code: "ME",
      country_code: "NP",
  },
  {
      name: "Mid-Western Region",
      code: "2",
      country_code: "NP",
  },
  {
      name: "Narayani Zone",
      code: "NA",
      country_code: "NP",
  },
  {
      name: "Rapti Zone",
      code: "RA",
      country_code: "NP",
  },
  {
      name: "Sagarmatha Zone",
      code: "SA",
      country_code: "NP",
  },
  {
      name: "Seti Zone",
      code: "SE",
      country_code: "NP",
  },
  {
      name: "Western Region",
      code: "3",
      country_code: "NP",
  },
  {
      name: "Bonaire",
      code: "BQ1",
      country_code: "NL",
  },
  {
      name: "Drenthe",
      code: "DR",
      country_code: "NL",
  },
  {
      name: "Flevoland",
      code: "FL",
      country_code: "NL",
  },
  {
      name: "Friesland",
      code: "FR",
      country_code: "NL",
  },
  {
      name: "Gelderland",
      code: "GE",
      country_code: "NL",
  },
  {
      name: "Groningen",
      code: "GR",
      country_code: "NL",
  },
  {
      name: "Limburg",
      code: "LI",
      country_code: "NL",
  },
  {
      name: "North Brabant",
      code: "NB",
      country_code: "NL",
  },
  {
      name: "North Holland",
      code: "NH",
      country_code: "NL",
  },
  {
      name: "Overijssel",
      code: "OV",
      country_code: "NL",
  },
  {
      name: "Saba",
      code: "BQ2",
      country_code: "NL",
  },
  {
      name: "Sint Eustatius",
      code: "BQ3",
      country_code: "NL",
  },
  {
      name: "South Holland",
      code: "ZH",
      country_code: "NL",
  },
  {
      name: "Utrecht",
      code: "UT",
      country_code: "NL",
  },
  {
      name: "Zeeland",
      code: "ZE",
      country_code: "NL",
  },
  {
      name: "Auckland Region",
      code: "AUK",
      country_code: "NZ",
  },
  {
      name: "Bay of Plenty Region",
      code: "BOP",
      country_code: "NZ",
  },
  {
      name: "Canterbury Region",
      code: "CAN",
      country_code: "NZ",
  },
  {
      name: "Chatham Islands",
      code: "CIT",
      country_code: "NZ",
  },
  {
      name: "Gisborne District",
      code: "GIS",
      country_code: "NZ",
  },
  {
      name: "Hawke's Bay Region",
      code: "HKB",
      country_code: "NZ",
  },
  {
      name: "Manawatu-Wanganui Region",
      code: "MWT",
      country_code: "NZ",
  },
  {
      name: "Marlborough Region",
      code: "MBH",
      country_code: "NZ",
  },
  {
      name: "Nelson Region",
      code: "NSN",
      country_code: "NZ",
  },
  {
      name: "Northland Region",
      code: "NTL",
      country_code: "NZ",
  },
  {
      name: "Otago Region",
      code: "OTA",
      country_code: "NZ",
  },
  {
      name: "Southland Region",
      code: "STL",
      country_code: "NZ",
  },
  {
      name: "Taranaki Region",
      code: "TKI",
      country_code: "NZ",
  },
  {
      name: "Tasman District",
      code: "TAS",
      country_code: "NZ",
  },
  {
      name: "Waikato Region",
      code: "WKO",
      country_code: "NZ",
  },
  {
      name: "Wellington Region",
      code: "WGN",
      country_code: "NZ",
  },
  {
      name: "West Coast Region",
      code: "WTC",
      country_code: "NZ",
  },
  {
      name: "Boaco Department",
      code: "BO",
      country_code: "NI",
  },
  {
      name: "Carazo Department",
      code: "CA",
      country_code: "NI",
  },
  {
      name: "Chinandega Department",
      code: "CI",
      country_code: "NI",
  },
  {
      name: "Chontales Department",
      code: "CO",
      country_code: "NI",
  },
  {
      name: "Estelí Department",
      code: "ES",
      country_code: "NI",
  },
  {
      name: "Granada Department",
      code: "GR",
      country_code: "NI",
  },
  {
      name: "Jinotega Department",
      code: "JI",
      country_code: "NI",
  },
  {
      name: "León Department",
      code: "LE",
      country_code: "NI",
  },
  {
      name: "Madriz Department",
      code: "MD",
      country_code: "NI",
  },
  {
      name: "Managua Department",
      code: "MN",
      country_code: "NI",
  },
  {
      name: "Masaya Department",
      code: "MS",
      country_code: "NI",
  },
  {
      name: "Matagalpa Department",
      code: "MT",
      country_code: "NI",
  },
  {
      name: "North Caribbean Coast Autonomous Region",
      code: "AN",
      country_code: "NI",
  },
  {
      name: "Río San Juan Department",
      code: "SJ",
      country_code: "NI",
  },
  {
      name: "Rivas Department",
      code: "RI",
      country_code: "NI",
  },
  {
      name: "South Caribbean Coast Autonomous Region",
      code: "AS",
      country_code: "NI",
  },
  {
      name: "Agadez Region",
      code: "1",
      country_code: "NE",
  },
  {
      name: "Diffa Region",
      code: "2",
      country_code: "NE",
  },
  {
      name: "Dosso Region",
      code: "3",
      country_code: "NE",
  },
  {
      name: "Maradi Region",
      code: "4",
      country_code: "NE",
  },
  {
      name: "Tahoua Region",
      code: "5",
      country_code: "NE",
  },
  {
      name: "Tillabéri Region",
      code: "6",
      country_code: "NE",
  },
  {
      name: "Zinder Region",
      code: "7",
      country_code: "NE",
  },
  {
      name: "Abia",
      code: "AB",
      country_code: "NG",
  },
  {
      name: "Abuja Federal Capital Territory",
      code: "FC",
      country_code: "NG",
  },
  {
      name: "Adamawa",
      code: "AD",
      country_code: "NG",
  },
  {
      name: "Akwa Ibom",
      code: "AK",
      country_code: "NG",
  },
  {
      name: "Anambra",
      code: "AN",
      country_code: "NG",
  },
  {
      name: "Bauchi",
      code: "BA",
      country_code: "NG",
  },
  {
      name: "Bayelsa",
      code: "BY",
      country_code: "NG",
  },
  {
      name: "Benue",
      code: "BE",
      country_code: "NG",
  },
  {
      name: "Borno",
      code: "BO",
      country_code: "NG",
  },
  {
      name: "Cross River",
      code: "CR",
      country_code: "NG",
  },
  {
      name: "Delta",
      code: "DE",
      country_code: "NG",
  },
  {
      name: "Ebonyi",
      code: "EB",
      country_code: "NG",
  },
  {
      name: "Edo",
      code: "ED",
      country_code: "NG",
  },
  {
      name: "Ekiti",
      code: "EK",
      country_code: "NG",
  },
  {
      name: "Enugu",
      code: "EN",
      country_code: "NG",
  },
  {
      name: "Gombe",
      code: "GO",
      country_code: "NG",
  },
  {
      name: "Imo",
      code: "IM",
      country_code: "NG",
  },
  {
      name: "Jigawa",
      code: "JI",
      country_code: "NG",
  },
  {
      name: "Kaduna",
      code: "KD",
      country_code: "NG",
  },
  {
      name: "Kano",
      code: "KN",
      country_code: "NG",
  },
  {
      name: "Katsina",
      code: "KT",
      country_code: "NG",
  },
  {
      name: "Kebbi",
      code: "KE",
      country_code: "NG",
  },
  {
      name: "Kogi",
      code: "KO",
      country_code: "NG",
  },
  {
      name: "Kwara",
      code: "KW",
      country_code: "NG",
  },
  {
      name: "Lagos",
      code: "LA",
      country_code: "NG",
  },
  {
      name: "Nasarawa",
      code: "NA",
      country_code: "NG",
  },
  {
      name: "Niger",
      code: "NI",
      country_code: "NG",
  },
  {
      name: "Ogun",
      code: "OG",
      country_code: "NG",
  },
  {
      name: "Ondo",
      code: "ON",
      country_code: "NG",
  },
  {
      name: "Osun",
      code: "OS",
      country_code: "NG",
  },
  {
      name: "Oyo",
      code: "OY",
      country_code: "NG",
  },
  {
      name: "Plateau",
      code: "PL",
      country_code: "NG",
  },
  {
      name: "Rivers",
      code: "RI",
      country_code: "NG",
  },
  {
      name: "Sokoto",
      code: "SO",
      country_code: "NG",
  },
  {
      name: "Taraba",
      code: "TA",
      country_code: "NG",
  },
  {
      name: "Yobe",
      code: "YO",
      country_code: "NG",
  },
  {
      name: "Zamfara",
      code: "ZA",
      country_code: "NG",
  },
  {
      name: "Chagang Province",
      code: "04",
      country_code: "KP",
  },
  {
      name: "Kangwon Province",
      code: "07",
      country_code: "KP",
  },
  {
      name: "North Hamgyong Province",
      code: "09",
      country_code: "KP",
  },
  {
      name: "North Hwanghae Province",
      code: "06",
      country_code: "KP",
  },
  {
      name: "North Pyongan Province",
      code: "03",
      country_code: "KP",
  },
  {
      name: "Pyongyang",
      code: "01",
      country_code: "KP",
  },
  {
      name: "Rason",
      code: "13",
      country_code: "KP",
  },
  {
      name: "Ryanggang Province",
      code: "10",
      country_code: "KP",
  },
  {
      name: "South Hamgyong Province",
      code: "08",
      country_code: "KP",
  },
  {
      name: "South Hwanghae Province",
      code: "05",
      country_code: "KP",
  },
  {
      name: "South Pyongan Province",
      code: "02",
      country_code: "KP",
  },
  {
      name: "Akershus",
      code: "02",
      country_code: "NO",
  },
  {
      name: "Buskerud",
      code: "06",
      country_code: "NO",
  },
  {
      name: "Finnmark",
      code: "20",
      country_code: "NO",
  },
  {
      name: "Hedmark",
      code: "04",
      country_code: "NO",
  },
  {
      name: "Hordaland",
      code: "12",
      country_code: "NO",
  },
  {
      name: "Jan Mayen",
      code: "22",
      country_code: "NO",
  },
  {
      name: "Møre og Romsdal",
      code: "15",
      country_code: "NO",
  },
  {
      name: "Nord-Trøndelag",
      code: "17",
      country_code: "NO",
  },
  {
      name: "Nordland",
      code: "18",
      country_code: "NO",
  },
  {
      name: "Oppland",
      code: "05",
      country_code: "NO",
  },
  {
      name: "Oslo",
      code: "03",
      country_code: "NO",
  },
  {
      name: "Østfold",
      code: "01",
      country_code: "NO",
  },
  {
      name: "Rogaland",
      code: "11",
      country_code: "NO",
  },
  {
      name: "Sogn og Fjordane",
      code: "14",
      country_code: "NO",
  },
  {
      name: "Sør-Trøndelag",
      code: "16",
      country_code: "NO",
  },
  {
      name: "Svalbard",
      code: "21",
      country_code: "NO",
  },
  {
      name: "Telemark",
      code: "08",
      country_code: "NO",
  },
  {
      name: "Troms",
      code: "19",
      country_code: "NO",
  },
  {
      name: "Trøndelag",
      code: "50",
      country_code: "NO",
  },
  {
      name: "Vest-Agder",
      code: "10",
      country_code: "NO",
  },
  {
      name: "Vestfold",
      code: "07",
      country_code: "NO",
  },
  {
      name: "Ad Dakhiliyah Governorate",
      code: "DA",
      country_code: "OM",
  },
  {
      name: "Ad Dhahirah Governorate",
      code: "ZA",
      country_code: "OM",
  },
  {
      name: "Al Batinah North Governorate",
      code: "BS",
      country_code: "OM",
  },
  {
      name: "Al Batinah Region",
      code: "BA",
      country_code: "OM",
  },
  {
      name: "Al Batinah South Governorate",
      code: "BJ",
      country_code: "OM",
  },
  {
      name: "Al Buraimi Governorate",
      code: "BU",
      country_code: "OM",
  },
  {
      name: "Al Wusta Governorate",
      code: "WU",
      country_code: "OM",
  },
  {
      name: "Ash Sharqiyah North Governorate",
      code: "SS",
      country_code: "OM",
  },
  {
      name: "Ash Sharqiyah Region",
      code: "SH",
      country_code: "OM",
  },
  {
      name: "Ash Sharqiyah South Governorate",
      code: "SJ",
      country_code: "OM",
  },
  {
      name: "Dhofar Governorate",
      code: "ZU",
      country_code: "OM",
  },
  {
      name: "Musandam Governorate",
      code: "MU",
      country_code: "OM",
  },
  {
      name: "Muscat Governorate",
      code: "MA",
      country_code: "OM",
  },
  {
      name: "Azad Kashmir",
      code: "JK",
      country_code: "PK",
  },
  {
      name: "Balochistan",
      code: "BA",
      country_code: "PK",
  },
  {
      name: "Federally Administered Tribal Areas",
      code: "TA",
      country_code: "PK",
  },
  {
      name: "Gilgit-Baltistan",
      code: "GB",
      country_code: "PK",
  },
  {
      name: "Islamabad Capital Territory",
      code: "IS",
      country_code: "PK",
  },
  {
      name: "Khyber Pakhtunkhwa",
      code: "KP",
      country_code: "PK",
  },
  {
      name: "Punjab",
      code: "PB",
      country_code: "PK",
  },
  {
      name: "Sindh",
      code: "SD",
      country_code: "PK",
  },
  {
      name: "Aimeliik",
      code: "002",
      country_code: "PW",
  },
  {
      name: "Airai",
      code: "004",
      country_code: "PW",
  },
  {
      name: "Angaur",
      code: "010",
      country_code: "PW",
  },
  {
      name: "Hatohobei",
      code: "050",
      country_code: "PW",
  },
  {
      name: "Kayangel",
      code: "100",
      country_code: "PW",
  },
  {
      name: "Koror",
      code: "150",
      country_code: "PW",
  },
  {
      name: "Melekeok",
      code: "212",
      country_code: "PW",
  },
  {
      name: "Ngaraard",
      code: "214",
      country_code: "PW",
  },
  {
      name: "Ngarchelong",
      code: "218",
      country_code: "PW",
  },
  {
      name: "Ngardmau",
      code: "222",
      country_code: "PW",
  },
  {
      name: "Ngatpang",
      code: "224",
      country_code: "PW",
  },
  {
      name: "Ngchesar",
      code: "226",
      country_code: "PW",
  },
  {
      name: "Ngeremlengui",
      code: "227",
      country_code: "PW",
  },
  {
      name: "Ngiwal",
      code: "228",
      country_code: "PW",
  },
  {
      name: "Peleliu",
      code: "350",
      country_code: "PW",
  },
  {
      name: "Sonsorol",
      code: "370",
      country_code: "PW",
  },
  {
      name: "Bocas del Toro Province",
      code: "1",
      country_code: "PA",
  },
  {
      name: "Chiriquí Province",
      code: "4",
      country_code: "PA",
  },
  {
      name: "Coclé Province",
      code: "2",
      country_code: "PA",
  },
  {
      name: "Colón Province",
      code: "3",
      country_code: "PA",
  },
  {
      name: "Darién Province",
      code: "5",
      country_code: "PA",
  },
  {
      name: "Emberá-Wounaan Comarca",
      code: "EM",
      country_code: "PA",
  },
  {
      name: "Guna Yala",
      code: "KY",
      country_code: "PA",
  },
  {
      name: "Herrera Province",
      code: "6",
      country_code: "PA",
  },
  {
      name: "Los Santos Province",
      code: "7",
      country_code: "PA",
  },
  {
      name: "Ngöbe-Buglé Comarca",
      code: "NB",
      country_code: "PA",
  },
  {
      name: "Panamá Oeste Province",
      code: "10",
      country_code: "PA",
  },
  {
      name: "Panamá Province",
      code: "8",
      country_code: "PA",
  },
  {
      name: "Veraguas Province",
      code: "9",
      country_code: "PA",
  },
  {
      name: "Bougainville",
      code: "NSB",
      country_code: "PG",
  },
  {
      name: "Central Province",
      code: "CPM",
      country_code: "PG",
  },
  {
      name: "Chimbu Province",
      code: "CPK",
      country_code: "PG",
  },
  {
      name: "East New Britain",
      code: "EBR",
      country_code: "PG",
  },
  {
      name: "Eastern Highlands Province",
      code: "EHG",
      country_code: "PG",
  },
  {
      name: "Enga Province",
      code: "EPW",
      country_code: "PG",
  },
  {
      name: "Gulf",
      code: "GPK",
      country_code: "PG",
  },
  {
      name: "Hela",
      code: "HLA",
      country_code: "PG",
  },
  {
      name: "Jiwaka Province",
      code: "JWK",
      country_code: "PG",
  },
  {
      name: "Madang Province",
      code: "MPM",
      country_code: "PG",
  },
  {
      name: "Manus Province",
      code: "MRL",
      country_code: "PG",
  },
  {
      name: "Milne Bay Province",
      code: "MBA",
      country_code: "PG",
  },
  {
      name: "Morobe Province",
      code: "MPL",
      country_code: "PG",
  },
  {
      name: "New Ireland Province",
      code: "NIK",
      country_code: "PG",
  },
  {
      name: "Oro Province",
      code: "NPP",
      country_code: "PG",
  },
  {
      name: "Port Moresby",
      code: "NCD",
      country_code: "PG",
  },
  {
      name: "Sandaun Province",
      code: "SAN",
      country_code: "PG",
  },
  {
      name: "Southern Highlands Province",
      code: "SHM",
      country_code: "PG",
  },
  {
      name: "West New Britain Province",
      code: "WBK",
      country_code: "PG",
  },
  {
      name: "Western Highlands Province",
      code: "WHM",
      country_code: "PG",
  },
  {
      name: "Western Province",
      code: "WPD",
      country_code: "PG",
  },
  {
      name: "Alto Paraguay Department",
      code: "16",
      country_code: "PY",
  },
  {
      name: "Alto Paraná Department",
      code: "10",
      country_code: "PY",
  },
  {
      name: "Amambay Department",
      code: "13",
      country_code: "PY",
  },
  {
      name: "Boquerón Department",
      code: "19",
      country_code: "PY",
  },
  {
      name: "Caaguazú",
      code: "5",
      country_code: "PY",
  },
  {
      name: "Caazapá",
      code: "6",
      country_code: "PY",
  },
  {
      name: "Canindeyú",
      code: "14",
      country_code: "PY",
  },
  {
      name: "Central Department",
      code: "11",
      country_code: "PY",
  },
  {
      name: "Concepción Department",
      code: "1",
      country_code: "PY",
  },
  {
      name: "Cordillera Department",
      code: "3",
      country_code: "PY",
  },
  {
      name: "Guairá Department",
      code: "4",
      country_code: "PY",
  },
  {
      name: "Itapúa",
      code: "7",
      country_code: "PY",
  },
  {
      name: "Misiones Department",
      code: "8",
      country_code: "PY",
  },
  {
      name: "Ñeembucú Department",
      code: "12",
      country_code: "PY",
  },
  {
      name: "Paraguarí Department",
      code: "9",
      country_code: "PY",
  },
  {
      name: "Presidente Hayes Department",
      code: "15",
      country_code: "PY",
  },
  {
      name: "San Pedro Department",
      code: "2",
      country_code: "PY",
  },
  {
      name: "Amazonas",
      code: "AMA",
      country_code: "PE",
  },
  {
      name: "Áncash",
      code: "ANC",
      country_code: "PE",
  },
  {
      name: "Apurímac",
      code: "APU",
      country_code: "PE",
  },
  {
      name: "Arequipa",
      code: "ARE",
      country_code: "PE",
  },
  {
      name: "Ayacucho",
      code: "AYA",
      country_code: "PE",
  },
  {
      name: "Cajamarca",
      code: "CAJ",
      country_code: "PE",
  },
  {
      name: "Callao",
      code: "CAL",
      country_code: "PE",
  },
  {
      name: "Cusco",
      code: "CUS",
      country_code: "PE",
  },
  {
      name: "Huancavelica",
      code: "HUV",
      country_code: "PE",
  },
  {
      name: "Huanuco",
      code: "HUC",
      country_code: "PE",
  },
  {
      name: "Ica",
      code: "ICA",
      country_code: "PE",
  },
  {
      name: "Junín",
      code: "JUN",
      country_code: "PE",
  },
  {
      name: "La Libertad",
      code: "LAL",
      country_code: "PE",
  },
  {
      name: "Lambayeque",
      code: "LAM",
      country_code: "PE",
  },
  {
      name: "Lima",
      code: "LIM",
      country_code: "PE",
  },
  {
      name: "Loreto",
      code: "LOR",
      country_code: "PE",
  },
  {
      name: "Madre de Dios",
      code: "MDD",
      country_code: "PE",
  },
  {
      name: "Moquegua",
      code: "MOQ",
      country_code: "PE",
  },
  {
      name: "Pasco",
      code: "PAS",
      country_code: "PE",
  },
  {
      name: "Piura",
      code: "PIU",
      country_code: "PE",
  },
  {
      name: "Puno",
      code: "PUN",
      country_code: "PE",
  },
  {
      name: "San Martín",
      code: "SAM",
      country_code: "PE",
  },
  {
      name: "Tacna",
      code: "TAC",
      country_code: "PE",
  },
  {
      name: "Tumbes",
      code: "TUM",
      country_code: "PE",
  },
  {
      name: "Ucayali",
      code: "UCA",
      country_code: "PE",
  },
  {
      name: "Abra",
      code: "ABR",
      country_code: "PH",
  },
  {
      name: "Agusan del Norte",
      code: "AGN",
      country_code: "PH",
  },
  {
      name: "Agusan del Sur",
      code: "AGS",
      country_code: "PH",
  },
  {
      name: "Aklan",
      code: "AKL",
      country_code: "PH",
  },
  {
      name: "Albay",
      code: "ALB",
      country_code: "PH",
  },
  {
      name: "Antique",
      code: "ANT",
      country_code: "PH",
  },
  {
      name: "Apayao",
      code: "APA",
      country_code: "PH",
  },
  {
      name: "Aurora",
      code: "AUR",
      country_code: "PH",
  },
  {
      name: "Autonomous Region in Muslim Mindanao",
      code: "14",
      country_code: "PH",
  },
  {
      name: "Basilan",
      code: "BAS",
      country_code: "PH",
  },
  {
      name: "Bataan",
      code: "BAN",
      country_code: "PH",
  },
  {
      name: "Batanes",
      code: "BTN",
      country_code: "PH",
  },
  {
      name: "Batangas",
      code: "BTG",
      country_code: "PH",
  },
  {
      name: "Benguet",
      code: "BEN",
      country_code: "PH",
  },
  {
      name: "Bicol Region",
      code: "05",
      country_code: "PH",
  },
  {
      name: "Biliran",
      code: "BIL",
      country_code: "PH",
  },
  {
      name: "Bohol",
      code: "BOH",
      country_code: "PH",
  },
  {
      name: "Bukidnon",
      code: "BUK",
      country_code: "PH",
  },
  {
      name: "Bulacan",
      code: "BUL",
      country_code: "PH",
  },
  {
      name: "Cagayan",
      code: "CAG",
      country_code: "PH",
  },
  {
      name: "Cagayan Valley",
      code: "02",
      country_code: "PH",
  },
  {
      name: "Calabarzon",
      code: "40",
      country_code: "PH",
  },
  {
      name: "Camarines Norte",
      code: "CAN",
      country_code: "PH",
  },
  {
      name: "Camarines Sur",
      code: "CAS",
      country_code: "PH",
  },
  {
      name: "Camiguin",
      code: "CAM",
      country_code: "PH",
  },
  {
      name: "Capiz",
      code: "CAP",
      country_code: "PH",
  },
  {
      name: "Caraga",
      code: "13",
      country_code: "PH",
  },
  {
      name: "Catanduanes",
      code: "CAT",
      country_code: "PH",
  },
  {
      name: "Cavite",
      code: "CAV",
      country_code: "PH",
  },
  {
      name: "Cebu",
      code: "CEB",
      country_code: "PH",
  },
  {
      name: "Central Luzon",
      code: "03",
      country_code: "PH",
  },
  {
      name: "Central Visayas",
      code: "07",
      country_code: "PH",
  },
  {
      name: "Compostela Valley",
      code: "COM",
      country_code: "PH",
  },
  {
      name: "Cordillera Administrative Region",
      code: "15",
      country_code: "PH",
  },
  {
      name: "Cotabato",
      code: "NCO",
      country_code: "PH",
  },
  {
      name: "Davao del Norte",
      code: "DAV",
      country_code: "PH",
  },
  {
      name: "Davao del Sur",
      code: "DAS",
      country_code: "PH",
  },
  {
      name: "Davao Occidental",
      code: "DVO",
      country_code: "PH",
  },
  {
      name: "Davao Oriental",
      code: "DAO",
      country_code: "PH",
  },
  {
      name: "Davao Region",
      code: "11",
      country_code: "PH",
  },
  {
      name: "Dinagat Islands",
      code: "DIN",
      country_code: "PH",
  },
  {
      name: "Eastern Samar",
      code: "EAS",
      country_code: "PH",
  },
  {
      name: "Eastern Visayas",
      code: "08",
      country_code: "PH",
  },
  {
      name: "Guimaras",
      code: "GUI",
      country_code: "PH",
  },
  {
      name: "Ifugao",
      code: "IFU",
      country_code: "PH",
  },
  {
      name: "Ilocos Norte",
      code: "ILN",
      country_code: "PH",
  },
  {
      name: "Ilocos Region",
      code: "01",
      country_code: "PH",
  },
  {
      name: "Ilocos Sur",
      code: "ILS",
      country_code: "PH",
  },
  {
      name: "Iloilo",
      code: "ILI",
      country_code: "PH",
  },
  {
      name: "Isabela",
      code: "ISA",
      country_code: "PH",
  },
  {
      name: "Kalinga",
      code: "KAL",
      country_code: "PH",
  },
  {
      name: "La Union",
      code: "LUN",
      country_code: "PH",
  },
  {
      name: "Laguna",
      code: "LAG",
      country_code: "PH",
  },
  {
      name: "Lanao del Norte",
      code: "LAN",
      country_code: "PH",
  },
  {
      name: "Lanao del Sur",
      code: "LAS",
      country_code: "PH",
  },
  {
      name: "Leyte",
      code: "LEY",
      country_code: "PH",
  },
  {
      name: "Maguindanao",
      code: "MAG",
      country_code: "PH",
  },
  {
      name: "Marinduque",
      code: "MAD",
      country_code: "PH",
  },
  {
      name: "Masbate",
      code: "MAS",
      country_code: "PH",
  },
  {
      name: "Metro Manila",
      code: "NCR",
      country_code: "PH",
  },
  {
      name: "Mimaropa",
      code: "41",
      country_code: "PH",
  },
  {
      name: "Misamis Occidental",
      code: "MSC",
      country_code: "PH",
  },
  {
      name: "Misamis Oriental",
      code: "MSR",
      country_code: "PH",
  },
  {
      name: "Mountain Province",
      code: "MOU",
      country_code: "PH",
  },
  {
      name: "Negros Occidental",
      code: "NEC",
      country_code: "PH",
  },
  {
      name: "Negros Oriental",
      code: "NER",
      country_code: "PH",
  },
  {
      name: "Northern Mindanao",
      code: "10",
      country_code: "PH",
  },
  {
      name: "Northern Samar",
      code: "NSA",
      country_code: "PH",
  },
  {
      name: "Nueva Ecija",
      code: "NUE",
      country_code: "PH",
  },
  {
      name: "Nueva Vizcaya",
      code: "NUV",
      country_code: "PH",
  },
  {
      name: "Occidental Mindoro",
      code: "MDC",
      country_code: "PH",
  },
  {
      name: "Oriental Mindoro",
      code: "MDR",
      country_code: "PH",
  },
  {
      name: "Palawan",
      code: "PLW",
      country_code: "PH",
  },
  {
      name: "Pampanga",
      code: "PAM",
      country_code: "PH",
  },
  {
      name: "Pangasinan",
      code: "PAN",
      country_code: "PH",
  },
  {
      name: "Quezon",
      code: "QUE",
      country_code: "PH",
  },
  {
      name: "Quirino",
      code: "QUI",
      country_code: "PH",
  },
  {
      name: "Rizal",
      code: "RIZ",
      country_code: "PH",
  },
  {
      name: "Romblon",
      code: "ROM",
      country_code: "PH",
  },
  {
      name: "Sarangani",
      code: "SAR",
      country_code: "PH",
  },
  {
      name: "Siquijor",
      code: "SIG",
      country_code: "PH",
  },
  {
      name: "Soccsksargen",
      code: "12",
      country_code: "PH",
  },
  {
      name: "Sorsogon",
      code: "SOR",
      country_code: "PH",
  },
  {
      name: "South Cotabato",
      code: "SCO",
      country_code: "PH",
  },
  {
      name: "Southern Leyte",
      code: "SLE",
      country_code: "PH",
  },
  {
      name: "Sultan Kudarat",
      code: "SUK",
      country_code: "PH",
  },
  {
      name: "Sulu",
      code: "SLU",
      country_code: "PH",
  },
  {
      name: "Surigao del Norte",
      code: "SUN",
      country_code: "PH",
  },
  {
      name: "Surigao del Sur",
      code: "SUR",
      country_code: "PH",
  },
  {
      name: "Tarlac",
      code: "TAR",
      country_code: "PH",
  },
  {
      name: "Tawi-Tawi",
      code: "TAW",
      country_code: "PH",
  },
  {
      name: "Western Visayas",
      code: "06",
      country_code: "PH",
  },
  {
      name: "Zambales",
      code: "ZMB",
      country_code: "PH",
  },
  {
      name: "Zamboanga del Norte",
      code: "ZAN",
      country_code: "PH",
  },
  {
      name: "Zamboanga del Sur",
      code: "ZAS",
      country_code: "PH",
  },
  {
      name: "Zamboanga Peninsula",
      code: "09",
      country_code: "PH",
  },
  {
      name: "Zamboanga Sibugay",
      code: "ZSI",
      country_code: "PH",
  },
  {
      name: "Greater Poland Voivodeship",
      code: "WP",
      country_code: "PL",
  },
  {
      name: "Kuyavian-Pomeranian Voivodeship",
      code: "KP",
      country_code: "PL",
  },
  {
      name: "Lesser Poland Voivodeship",
      code: "MA",
      country_code: "PL",
  },
  {
      name: "Lower Silesian Voivodeship",
      code: "DS",
      country_code: "PL",
  },
  {
      name: "Lublin Voivodeship",
      code: "LU",
      country_code: "PL",
  },
  {
      name: "Lubusz Voivodeship",
      code: "LB",
      country_code: "PL",
  },
  {
      name: "Łódź Voivodeship",
      code: "LD",
      country_code: "PL",
  },
  {
      name: "Masovian Voivodeship",
      code: "MZ",
      country_code: "PL",
  },
  {
      name: "Opole Voivodeship",
      code: "OP",
      country_code: "PL",
  },
  {
      name: "Podkarpackie Voivodeship",
      code: "PK",
      country_code: "PL",
  },
  {
      name: "Podlaskie Voivodeship",
      code: "PD",
      country_code: "PL",
  },
  {
      name: "Pomeranian Voivodeship",
      code: "PM",
      country_code: "PL",
  },
  {
      name: "Silesian Voivodeship",
      code: "SL",
      country_code: "PL",
  },
  {
      name: "Świętokrzyskie Voivodeship",
      code: "SK",
      country_code: "PL",
  },
  {
      name: "Warmian-Masurian Voivodeship",
      code: "WN",
      country_code: "PL",
  },
  {
      name: "West Pomeranian Voivodeship",
      code: "ZP",
      country_code: "PL",
  },
  {
      name: "Açores",
      code: "20",
      country_code: "PT",
  },
  {
      name: "Aveiro",
      code: "01",
      country_code: "PT",
  },
  {
      name: "Beja",
      code: "02",
      country_code: "PT",
  },
  {
      name: "Braga",
      code: "03",
      country_code: "PT",
  },
  {
      name: "Bragança",
      code: "04",
      country_code: "PT",
  },
  {
      name: "Castelo Branco",
      code: "05",
      country_code: "PT",
  },
  {
      name: "Coimbra",
      code: "06",
      country_code: "PT",
  },
  {
      name: "Évora",
      code: "07",
      country_code: "PT",
  },
  {
      name: "Faro",
      code: "08",
      country_code: "PT",
  },
  {
      name: "Guarda",
      code: "09",
      country_code: "PT",
  },
  {
      name: "Leiria",
      code: "10",
      country_code: "PT",
  },
  {
      name: "Lisbon",
      code: "11",
      country_code: "PT",
  },
  {
      name: "Madeira",
      code: "30",
      country_code: "PT",
  },
  {
      name: "Portalegre",
      code: "12",
      country_code: "PT",
  },
  {
      name: "Porto",
      code: "13",
      country_code: "PT",
  },
  {
      name: "Santarém",
      code: "14",
      country_code: "PT",
  },
  {
      name: "Setúbal",
      code: "15",
      country_code: "PT",
  },
  {
      name: "Viana do Castelo",
      code: "16",
      country_code: "PT",
  },
  {
      name: "Vila Real",
      code: "17",
      country_code: "PT",
  },
  {
      name: "Viseu",
      code: "18",
      country_code: "PT",
  },
  {
      name: "Al Daayen",
      code: "ZA",
      country_code: "QA",
  },
  {
      name: "Al Khor",
      code: "KH",
      country_code: "QA",
  },
  {
      name: "Al Rayyan Municipality",
      code: "RA",
      country_code: "QA",
  },
  {
      name: "Al Wakrah",
      code: "WA",
      country_code: "QA",
  },
  {
      name: "Al-Shahaniya",
      code: "SH",
      country_code: "QA",
  },
  {
      name: "Doha",
      code: "DA",
      country_code: "QA",
  },
  {
      name: "Madinat ash Shamal",
      code: "MS",
      country_code: "QA",
  },
  {
      name: "Umm Salal Municipality",
      code: "US",
      country_code: "QA",
  },
  {
      name: "Alba",
      code: "AB",
      country_code: "RO",
  },
  {
      name: "Arad County",
      code: "AR",
      country_code: "RO",
  },
  {
      name: "Arges",
      code: "AG",
      country_code: "RO",
  },
  {
      name: "Bacău County",
      code: "BC",
      country_code: "RO",
  },
  {
      name: "Bihor County",
      code: "BH",
      country_code: "RO",
  },
  {
      name: "Bistrița-Năsăud County",
      code: "BN",
      country_code: "RO",
  },
  {
      name: "Botoșani County",
      code: "BT",
      country_code: "RO",
  },
  {
      name: "Braila",
      code: "BR",
      country_code: "RO",
  },
  {
      name: "Brașov County",
      code: "BV",
      country_code: "RO",
  },
  {
      name: "Bucharest",
      code: "B",
      country_code: "RO",
  },
  {
      name: "Buzău County",
      code: "BZ",
      country_code: "RO",
  },
  {
      name: "Călărași County",
      code: "CL",
      country_code: "RO",
  },
  {
      name: "Caraș-Severin County",
      code: "CS",
      country_code: "RO",
  },
  {
      name: "Cluj County",
      code: "CJ",
      country_code: "RO",
  },
  {
      name: "Constanța County",
      code: "CT",
      country_code: "RO",
  },
  {
      name: "Covasna County",
      code: "CV",
      country_code: "RO",
  },
  {
      name: "Dâmbovița County",
      code: "DB",
      country_code: "RO",
  },
  {
      name: "Dolj County",
      code: "DJ",
      country_code: "RO",
  },
  {
      name: "Galați County",
      code: "GL",
      country_code: "RO",
  },
  {
      name: "Giurgiu County",
      code: "GR",
      country_code: "RO",
  },
  {
      name: "Gorj County",
      code: "GJ",
      country_code: "RO",
  },
  {
      name: "Harghita County",
      code: "HR",
      country_code: "RO",
  },
  {
      name: "Hunedoara County",
      code: "HD",
      country_code: "RO",
  },
  {
      name: "Ialomița County",
      code: "IL",
      country_code: "RO",
  },
  {
      name: "Iași County",
      code: "IS",
      country_code: "RO",
  },
  {
      name: "Ilfov County",
      code: "IF",
      country_code: "RO",
  },
  {
      name: "Maramureș County",
      code: "MM",
      country_code: "RO",
  },
  {
      name: "Mehedinți County",
      code: "MH",
      country_code: "RO",
  },
  {
      name: "Mureș County",
      code: "MS",
      country_code: "RO",
  },
  {
      name: "Neamț County",
      code: "NT",
      country_code: "RO",
  },
  {
      name: "Olt County",
      code: "OT",
      country_code: "RO",
  },
  {
      name: "Prahova County",
      code: "PH",
      country_code: "RO",
  },
  {
      name: "Sălaj County",
      code: "SJ",
      country_code: "RO",
  },
  {
      name: "Satu Mare County",
      code: "SM",
      country_code: "RO",
  },
  {
      name: "Sibiu County",
      code: "SB",
      country_code: "RO",
  },
  {
      name: "Suceava County",
      code: "SV",
      country_code: "RO",
  },
  {
      name: "Teleorman County",
      code: "TR",
      country_code: "RO",
  },
  {
      name: "Timiș County",
      code: "TM",
      country_code: "RO",
  },
  {
      name: "Tulcea County",
      code: "TL",
      country_code: "RO",
  },
  {
      name: "Vâlcea County",
      code: "VL",
      country_code: "RO",
  },
  {
      name: "Vaslui County",
      code: "VS",
      country_code: "RO",
  },
  {
      name: "Vrancea County",
      code: "VN",
      country_code: "RO",
  },
  {
      name: "Altai Krai",
      code: "ALT",
      country_code: "RU",
  },
  {
      name: "Altai Republic",
      code: "AL",
      country_code: "RU",
  },
  {
      name: "Amur Oblast",
      code: "AMU",
      country_code: "RU",
  },
  {
      name: "Arkhangelsk",
      code: "ARK",
      country_code: "RU",
  },
  {
      name: "Astrakhan Oblast",
      code: "AST",
      country_code: "RU",
  },
  {
      name: "Belgorod Oblast",
      code: "BEL",
      country_code: "RU",
  },
  {
      name: "Bryansk Oblast",
      code: "BRY",
      country_code: "RU",
  },
  {
      name: "Chechen Republic",
      code: "CE",
      country_code: "RU",
  },
  {
      name: "Chelyabinsk Oblast",
      code: "CHE",
      country_code: "RU",
  },
  {
      name: "Chukotka Autonomous Okrug",
      code: "CHU",
      country_code: "RU",
  },
  {
      name: "Chuvash Republic",
      code: "CU",
      country_code: "RU",
  },
  {
      name: "Irkutsk",
      code: "IRK",
      country_code: "RU",
  },
  {
      name: "Ivanovo Oblast",
      code: "IVA",
      country_code: "RU",
  },
  {
      name: "Jewish Autonomous Oblast",
      code: "YEV",
      country_code: "RU",
  },
  {
      name: "Kabardino-Balkar Republic",
      code: "KB",
      country_code: "RU",
  },
  {
      name: "Kaliningrad",
      code: "KGD",
      country_code: "RU",
  },
  {
      name: "Kaluga Oblast",
      code: "KLU",
      country_code: "RU",
  },
  {
      name: "Kamchatka Krai",
      code: "KAM",
      country_code: "RU",
  },
  {
      name: "Karachay-Cherkess Republic",
      code: "KC",
      country_code: "RU",
  },
  {
      name: "Kemerovo Oblast",
      code: "KEM",
      country_code: "RU",
  },
  {
      name: "Khabarovsk Krai",
      code: "KHA",
      country_code: "RU",
  },
  {
      name: "Khanty-Mansi Autonomous Okrug",
      code: "KHM",
      country_code: "RU",
  },
  {
      name: "Kirov Oblast",
      code: "KIR",
      country_code: "RU",
  },
  {
      name: "Komi Republic",
      code: "KO",
      country_code: "RU",
  },
  {
      name: "Kostroma Oblast",
      code: "KOS",
      country_code: "RU",
  },
  {
      name: "Krasnodar Krai",
      code: "KDA",
      country_code: "RU",
  },
  {
      name: "Krasnoyarsk Krai",
      code: "KYA",
      country_code: "RU",
  },
  {
      name: "Kurgan Oblast",
      code: "KGN",
      country_code: "RU",
  },
  {
      name: "Kursk Oblast",
      code: "KRS",
      country_code: "RU",
  },
  {
      name: "Leningrad Oblast",
      code: "LEN",
      country_code: "RU",
  },
  {
      name: "Lipetsk Oblast",
      code: "LIP",
      country_code: "RU",
  },
  {
      name: "Magadan Oblast",
      code: "MAG",
      country_code: "RU",
  },
  {
      name: "Mari El Republic",
      code: "ME",
      country_code: "RU",
  },
  {
      name: "Moscow",
      code: "MOW",
      country_code: "RU",
  },
  {
      name: "Moscow Oblast",
      code: "MOS",
      country_code: "RU",
  },
  {
      name: "Murmansk Oblast",
      code: "MUR",
      country_code: "RU",
  },
  {
      name: "Nenets Autonomous Okrug",
      code: "NEN",
      country_code: "RU",
  },
  {
      name: "Nizhny Novgorod Oblast",
      code: "NIZ",
      country_code: "RU",
  },
  {
      name: "Novgorod Oblast",
      code: "NGR",
      country_code: "RU",
  },
  {
      name: "Novosibirsk",
      code: "NVS",
      country_code: "RU",
  },
  {
      name: "Omsk Oblast",
      code: "OMS",
      country_code: "RU",
  },
  {
      name: "Orenburg Oblast",
      code: "ORE",
      country_code: "RU",
  },
  {
      name: "Oryol Oblast",
      code: "ORL",
      country_code: "RU",
  },
  {
      name: "Penza Oblast",
      code: "PNZ",
      country_code: "RU",
  },
  {
      name: "Perm Krai",
      code: "PER",
      country_code: "RU",
  },
  {
      name: "Primorsky Krai",
      code: "PRI",
      country_code: "RU",
  },
  {
      name: "Pskov Oblast",
      code: "PSK",
      country_code: "RU",
  },
  {
      name: "Republic of Adygea",
      code: "AD",
      country_code: "RU",
  },
  {
      name: "Republic of Bashkortostan",
      code: "BA",
      country_code: "RU",
  },
  {
      name: "Republic of Buryatia",
      code: "BU",
      country_code: "RU",
  },
  {
      name: "Republic of Dagestan",
      code: "DA",
      country_code: "RU",
  },
  {
      name: "Republic of Ingushetia",
      code: "IN",
      country_code: "RU",
  },
  {
      name: "Republic of Kalmykia",
      code: "KL",
      country_code: "RU",
  },
  {
      name: "Republic of Karelia",
      code: "KR",
      country_code: "RU",
  },
  {
      name: "Republic of Khakassia",
      code: "KK",
      country_code: "RU",
  },
  {
      name: "Republic of Mordovia",
      code: "MO",
      country_code: "RU",
  },
  {
      name: "Republic of North Ossetia-Alania",
      code: "SE",
      country_code: "RU",
  },
  {
      name: "Republic of Tatarstan",
      code: "TA",
      country_code: "RU",
  },
  {
      name: "Rostov Oblast",
      code: "ROS",
      country_code: "RU",
  },
  {
      name: "Ryazan Oblast",
      code: "RYA",
      country_code: "RU",
  },
  {
      name: "Saint Petersburg",
      code: "SPE",
      country_code: "RU",
  },
  {
      name: "Sakha Republic",
      code: "SA",
      country_code: "RU",
  },
  {
      name: "Sakhalin",
      code: "SAK",
      country_code: "RU",
  },
  {
      name: "Samara Oblast",
      code: "SAM",
      country_code: "RU",
  },
  {
      name: "Saratov Oblast",
      code: "SAR",
      country_code: "RU",
  },
  {
      name: "Sevastopol",
      code: "UA-40",
      country_code: "RU",
  },
  {
      name: "Smolensk Oblast",
      code: "SMO",
      country_code: "RU",
  },
  {
      name: "Stavropol Krai",
      code: "STA",
      country_code: "RU",
  },
  {
      name: "Sverdlovsk",
      code: "SVE",
      country_code: "RU",
  },
  {
      name: "Tambov Oblast",
      code: "TAM",
      country_code: "RU",
  },
  {
      name: "Tomsk Oblast",
      code: "TOM",
      country_code: "RU",
  },
  {
      name: "Tula Oblast",
      code: "TUL",
      country_code: "RU",
  },
  {
      name: "Tuva Republic",
      code: "TY",
      country_code: "RU",
  },
  {
      name: "Tver Oblast",
      code: "TVE",
      country_code: "RU",
  },
  {
      name: "Tyumen Oblast",
      code: "TYU",
      country_code: "RU",
  },
  {
      name: "Udmurt Republic",
      code: "UD",
      country_code: "RU",
  },
  {
      name: "Ulyanovsk Oblast",
      code: "ULY",
      country_code: "RU",
  },
  {
      name: "Vladimir Oblast",
      code: "VLA",
      country_code: "RU",
  },
  {
      name: "Volgograd Oblast",
      code: "VGG",
      country_code: "RU",
  },
  {
      name: "Vologda Oblast",
      code: "VLG",
      country_code: "RU",
  },
  {
      name: "Voronezh Oblast",
      code: "VOR",
      country_code: "RU",
  },
  {
      name: "Yamalo-Nenets Autonomous Okrug",
      code: "YAN",
      country_code: "RU",
  },
  {
      name: "Yaroslavl Oblast",
      code: "YAR",
      country_code: "RU",
  },
  {
      name: "Zabaykalsky Krai",
      code: "ZAB",
      country_code: "RU",
  },
  {
      name: "Eastern Province",
      code: "02",
      country_code: "RW",
  },
  {
      name: "Kigali district",
      code: "01",
      country_code: "RW",
  },
  {
      name: "Northern Province",
      code: "03",
      country_code: "RW",
  },
  {
      name: "Southern Province",
      code: "05",
      country_code: "RW",
  },
  {
      name: "Western Province",
      code: "04",
      country_code: "RW",
  },
  {
      name: "Christ Church Nichola Town Parish",
      code: "01",
      country_code: "KN",
  },
  {
      name: "Nevis",
      code: "N",
      country_code: "KN",
  },
  {
      name: "Saint Anne Sandy Point Parish",
      code: "02",
      country_code: "KN",
  },
  {
      name: "Saint George Gingerland Parish",
      code: "04",
      country_code: "KN",
  },
  {
      name: "Saint James Windward Parish",
      code: "05",
      country_code: "KN",
  },
  {
      name: "Saint John Capisterre Parish",
      code: "06",
      country_code: "KN",
  },
  {
      name: "Saint John Figtree Parish",
      code: "07",
      country_code: "KN",
  },
  {
      name: "Saint Kitts",
      code: "K",
      country_code: "KN",
  },
  {
      name: "Saint Mary Cayon Parish",
      code: "08",
      country_code: "KN",
  },
  {
      name: "Saint Paul Capisterre Parish",
      code: "09",
      country_code: "KN",
  },
  {
      name: "Saint Paul Charlestown Parish",
      code: "10",
      country_code: "KN",
  },
  {
      name: "Saint Peter Basseterre Parish",
      code: "11",
      country_code: "KN",
  },
  {
      name: "Saint Thomas Lowland Parish",
      code: "12",
      country_code: "KN",
  },
  {
      name: "Saint Thomas Middle Island Parish",
      code: "13",
      country_code: "KN",
  },
  {
      name: "Trinity Palmetto Point Parish",
      code: "15",
      country_code: "KN",
  },
  {
      name: "Anse la Raye Quarter",
      code: "01",
      country_code: "LC",
  },
  {
      name: "Canaries",
      code: "12",
      country_code: "LC",
  },
  {
      name: "Castries Quarter",
      code: "02",
      country_code: "LC",
  },
  {
      name: "Choiseul Quarter",
      code: "03",
      country_code: "LC",
  },
  {
      name: "Dauphin Quarter",
      code: "04",
      country_code: "LC",
  },
  {
      name: "Dennery Quarter",
      code: "05",
      country_code: "LC",
  },
  {
      name: "Gros Islet Quarter",
      code: "06",
      country_code: "LC",
  },
  {
      name: "Laborie Quarter",
      code: "07",
      country_code: "LC",
  },
  {
      name: "Micoud Quarter",
      code: "08",
      country_code: "LC",
  },
  {
      name: "Praslin Quarter",
      code: "09",
      country_code: "LC",
  },
  {
      name: "Soufrière Quarter",
      code: "10",
      country_code: "LC",
  },
  {
      name: "Vieux Fort Quarter",
      code: "11",
      country_code: "LC",
  },
  {
      name: "Charlotte Parish",
      code: "01",
      country_code: "VC",
  },
  {
      name: "Grenadines Parish",
      code: "06",
      country_code: "VC",
  },
  {
      name: "Saint Andrew Parish",
      code: "02",
      country_code: "VC",
  },
  {
      name: "Saint David Parish",
      code: "03",
      country_code: "VC",
  },
  {
      name: "Saint George Parish",
      code: "04",
      country_code: "VC",
  },
  {
      name: "Saint Patrick Parish",
      code: "05",
      country_code: "VC",
  },
  {
      name: "A'ana",
      code: "AA",
      country_code: "WS",
  },
  {
      name: "Aiga-i-le-Tai",
      code: "AL",
      country_code: "WS",
  },
  {
      name: "Atua",
      code: "AT",
      country_code: "WS",
  },
  {
      name: "Fa'asaleleaga",
      code: "FA",
      country_code: "WS",
  },
  {
      name: "Gaga'emauga",
      code: "GE",
      country_code: "WS",
  },
  {
      name: "Gaga'ifomauga",
      code: "GI",
      country_code: "WS",
  },
  {
      name: "Palauli",
      code: "PA",
      country_code: "WS",
  },
  {
      name: "Satupa'itea",
      code: "SA",
      country_code: "WS",
  },
  {
      name: "Tuamasaga",
      code: "TU",
      country_code: "WS",
  },
  {
      name: "Va'a-o-Fonoti",
      code: "VF",
      country_code: "WS",
  },
  {
      name: "Vaisigano",
      code: "VS",
      country_code: "WS",
  },
  {
      name: "Acquaviva",
      code: "01",
      country_code: "SM",
  },
  {
      name: "Borgo Maggiore",
      code: "06",
      country_code: "SM",
  },
  {
      name: "Chiesanuova",
      code: "02",
      country_code: "SM",
  },
  {
      name: "Domagnano",
      code: "03",
      country_code: "SM",
  },
  {
      name: "Faetano",
      code: "04",
      country_code: "SM",
  },
  {
      name: "Fiorentino",
      code: "05",
      country_code: "SM",
  },
  {
      name: "Montegiardino",
      code: "08",
      country_code: "SM",
  },
  {
      name: "San Marino",
      code: "07",
      country_code: "SM",
  },
  {
      name: "Serravalle",
      code: "09",
      country_code: "SM",
  },
  {
      name: "Príncipe Province",
      code: "P",
      country_code: "ST",
  },
  {
      name: "São Tomé Province",
      code: "S",
      country_code: "ST",
  },
  {
      name: "'Asir",
      code: "14",
      country_code: "SA",
  },
  {
      name: "Al Bahah",
      code: "11",
      country_code: "SA",
  },
  {
      name: "Al Jawf",
      code: "12",
      country_code: "SA",
  },
  {
      name: "Al Madinah",
      code: "03",
      country_code: "SA",
  },
  {
      name: "Al-Qassim",
      code: "05",
      country_code: "SA",
  },
  {
      name: "Eastern Province",
      code: "04",
      country_code: "SA",
  },
  {
      name: "Ha'il",
      code: "06",
      country_code: "SA",
  },
  {
      name: "Jizan",
      code: "09",
      country_code: "SA",
  },
  {
      name: "Makkah",
      code: "02",
      country_code: "SA",
  },
  {
      name: "Najran",
      code: "10",
      country_code: "SA",
  },
  {
      name: "Northern Borders",
      code: "08",
      country_code: "SA",
  },
  {
      name: "Riyadh",
      code: "01",
      country_code: "SA",
  },
  {
      name: "Tabuk",
      code: "07",
      country_code: "SA",
  },
  {
      name: "Dakar",
      code: "DK",
      country_code: "SN",
  },
  {
      name: "Diourbel Region",
      code: "DB",
      country_code: "SN",
  },
  {
      name: "Fatick",
      code: "FK",
      country_code: "SN",
  },
  {
      name: "Kaffrine",
      code: "KA",
      country_code: "SN",
  },
  {
      name: "Kaolack",
      code: "KL",
      country_code: "SN",
  },
  {
      name: "Kédougou",
      code: "KE",
      country_code: "SN",
  },
  {
      name: "Kolda",
      code: "KD",
      country_code: "SN",
  },
  {
      name: "Louga",
      code: "LG",
      country_code: "SN",
  },
  {
      name: "Matam",
      code: "MT",
      country_code: "SN",
  },
  {
      name: "Saint-Louis",
      code: "SL",
      country_code: "SN",
  },
  {
      name: "Sédhiou",
      code: "SE",
      country_code: "SN",
  },
  {
      name: "Tambacounda Region",
      code: "TC",
      country_code: "SN",
  },
  {
      name: "Thiès Region",
      code: "TH",
      country_code: "SN",
  },
  {
      name: "Ziguinchor",
      code: "ZG",
      country_code: "SN",
  },
  {
      name: "Belgrade",
      code: "00",
      country_code: "RS",
  },
  {
      name: "Bor District",
      code: "14",
      country_code: "RS",
  },
  {
      name: "Braničevo District",
      code: "11",
      country_code: "RS",
  },
  {
      name: "Central Banat District",
      code: "02",
      country_code: "RS",
  },
  {
      name: "Jablanica District",
      code: "23",
      country_code: "RS",
  },
  {
      name: "Kolubara District",
      code: "09",
      country_code: "RS",
  },
  {
      name: "Mačva District",
      code: "08",
      country_code: "RS",
  },
  {
      name: "Moravica District",
      code: "17",
      country_code: "RS",
  },
  {
      name: "Nišava District",
      code: "20",
      country_code: "RS",
  },
  {
      name: "North Bačka District",
      code: "01",
      country_code: "RS",
  },
  {
      name: "North Banat District",
      code: "03",
      country_code: "RS",
  },
  {
      name: "Pčinja District",
      code: "24",
      country_code: "RS",
  },
  {
      name: "Pirot District",
      code: "22",
      country_code: "RS",
  },
  {
      name: "Podunavlje District",
      code: "10",
      country_code: "RS",
  },
  {
      name: "Pomoravlje District",
      code: "13",
      country_code: "RS",
  },
  {
      name: "Rasina District",
      code: "19",
      country_code: "RS",
  },
  {
      name: "Raška District",
      code: "18",
      country_code: "RS",
  },
  {
      name: "South Bačka District",
      code: "06",
      country_code: "RS",
  },
  {
      name: "South Banat District",
      code: "04",
      country_code: "RS",
  },
  {
      name: "Srem District",
      code: "07",
      country_code: "RS",
  },
  {
      name: "Šumadija District",
      code: "12",
      country_code: "RS",
  },
  {
      name: "Toplica District",
      code: "21",
      country_code: "RS",
  },
  {
      name: "Vojvodina",
      code: "VO",
      country_code: "RS",
  },
  {
      name: "West Bačka District",
      code: "05",
      country_code: "RS",
  },
  {
      name: "Zaječar District",
      code: "15",
      country_code: "RS",
  },
  {
      name: "Zlatibor District",
      code: "16",
      country_code: "RS",
  },
  {
      name: "Anse Boileau",
      code: "02",
      country_code: "SC",
  },
  {
      name: "Anse Royale",
      code: "05",
      country_code: "SC",
  },
  {
      name: "Anse-aux-Pins",
      code: "01",
      country_code: "SC",
  },
  {
      name: "Au Cap",
      code: "04",
      country_code: "SC",
  },
  {
      name: "Baie Lazare",
      code: "06",
      country_code: "SC",
  },
  {
      name: "Baie Sainte Anne",
      code: "07",
      country_code: "SC",
  },
  {
      name: "Beau Vallon",
      code: "08",
      country_code: "SC",
  },
  {
      name: "Bel Air",
      code: "09",
      country_code: "SC",
  },
  {
      name: "Bel Ombre",
      code: "10",
      country_code: "SC",
  },
  {
      name: "Cascade",
      code: "11",
      country_code: "SC",
  },
  {
      name: "Glacis",
      code: "12",
      country_code: "SC",
  },
  {
      name: "Grand'Anse Mahé",
      code: "13",
      country_code: "SC",
  },
  {
      name: "Grand'Anse Praslin",
      code: "14",
      country_code: "SC",
  },
  {
      name: "La Digue",
      code: "15",
      country_code: "SC",
  },
  {
      name: "La Rivière Anglaise",
      code: "16",
      country_code: "SC",
  },
  {
      name: "Les Mamelles",
      code: "24",
      country_code: "SC",
  },
  {
      name: "Mont Buxton",
      code: "17",
      country_code: "SC",
  },
  {
      name: "Mont Fleuri",
      code: "18",
      country_code: "SC",
  },
  {
      name: "Plaisance",
      code: "19",
      country_code: "SC",
  },
  {
      name: "Pointe La Rue",
      code: "20",
      country_code: "SC",
  },
  {
      name: "Port Glaud",
      code: "21",
      country_code: "SC",
  },
  {
      name: "Roche Caiman",
      code: "25",
      country_code: "SC",
  },
  {
      name: "Saint Louis",
      code: "22",
      country_code: "SC",
  },
  {
      name: "Takamaka",
      code: "23",
      country_code: "SC",
  },
  {
      name: "Eastern Province",
      code: "E",
      country_code: "SL",
  },
  {
      name: "Northern Province",
      code: "N",
      country_code: "SL",
  },
  {
      name: "Southern Province",
      code: "S",
      country_code: "SL",
  },
  {
      name: "Western Area",
      code: "W",
      country_code: "SL",
  },
  {
      name: "Central Singapore Community Development Council",
      code: "01",
      country_code: "SG",
  },
  {
      name: "North East Community Development Council",
      code: "02",
      country_code: "SG",
  },
  {
      name: "North West Community Development Council",
      code: "03",
      country_code: "SG",
  },
  {
      name: "South East Community Development Council",
      code: "04",
      country_code: "SG",
  },
  {
      name: "South West Community Development Council",
      code: "05",
      country_code: "SG",
  },
  {
      name: "Banská Bystrica Region",
      code: "BC",
      country_code: "SK",
  },
  {
      name: "Bratislava Region",
      code: "BL",
      country_code: "SK",
  },
  {
      name: "Košice Region",
      code: "KI",
      country_code: "SK",
  },
  {
      name: "Nitra Region",
      code: "NI",
      country_code: "SK",
  },
  {
      name: "Prešov Region",
      code: "PV",
      country_code: "SK",
  },
  {
      name: "Trenčín Region",
      code: "TC",
      country_code: "SK",
  },
  {
      name: "Trnava Region",
      code: "TA",
      country_code: "SK",
  },
  {
      name: "Žilina Region",
      code: "ZI",
      country_code: "SK",
  },
  {
      name: "Ajdovščina Municipality",
      code: "001",
      country_code: "SI",
  },
  {
      name: "Ankaran Municipality",
      code: "213",
      country_code: "SI",
  },
  {
      name: "Beltinci Municipality",
      code: "002",
      country_code: "SI",
  },
  {
      name: "Benedikt Municipality",
      code: "148",
      country_code: "SI",
  },
  {
      name: "Bistrica ob Sotli Municipality",
      code: "149",
      country_code: "SI",
  },
  {
      name: "Bled Municipality",
      code: "003",
      country_code: "SI",
  },
  {
      name: "Bloke Municipality",
      code: "150",
      country_code: "SI",
  },
  {
      name: "Bohinj Municipality",
      code: "004",
      country_code: "SI",
  },
  {
      name: "Borovnica Municipality",
      code: "005",
      country_code: "SI",
  },
  {
      name: "Bovec Municipality",
      code: "006",
      country_code: "SI",
  },
  {
      name: "Braslovče Municipality",
      code: "151",
      country_code: "SI",
  },
  {
      name: "Brda Municipality",
      code: "007",
      country_code: "SI",
  },
  {
      name: "Brežice Municipality",
      code: "009",
      country_code: "SI",
  },
  {
      name: "Brezovica Municipality",
      code: "008",
      country_code: "SI",
  },
  {
      name: "Cankova Municipality",
      code: "152",
      country_code: "SI",
  },
  {
      name: "Cerklje na Gorenjskem Municipality",
      code: "012",
      country_code: "SI",
  },
  {
      name: "Cerknica Municipality",
      code: "013",
      country_code: "SI",
  },
  {
      name: "Cerkno Municipality",
      code: "014",
      country_code: "SI",
  },
  {
      name: "Cerkvenjak Municipality",
      code: "153",
      country_code: "SI",
  },
  {
      name: "City Municipality of Celje",
      code: "011",
      country_code: "SI",
  },
  {
      name: "City Municipality of Novo Mesto",
      code: "085",
      country_code: "SI",
  },
  {
      name: "Črenšovci Municipality",
      code: "015",
      country_code: "SI",
  },
  {
      name: "Črna na Koroškem Municipality",
      code: "016",
      country_code: "SI",
  },
  {
      name: "Črnomelj Municipality",
      code: "017",
      country_code: "SI",
  },
  {
      name: "Destrnik Municipality",
      code: "018",
      country_code: "SI",
  },
  {
      name: "Divača Municipality",
      code: "019",
      country_code: "SI",
  },
  {
      name: "Dobje Municipality",
      code: "154",
      country_code: "SI",
  },
  {
      name: "Dobrepolje Municipality",
      code: "020",
      country_code: "SI",
  },
  {
      name: "Dobrna Municipality",
      code: "155",
      country_code: "SI",
  },
  {
      name: "Dobrova–Polhov Gradec Municipality",
      code: "021",
      country_code: "SI",
  },
  {
      name: "Dobrovnik Municipality",
      code: "156",
      country_code: "SI",
  },
  {
      name: "Dol pri Ljubljani Municipality",
      code: "022",
      country_code: "SI",
  },
  {
      name: "Dolenjske Toplice Municipality",
      code: "157",
      country_code: "SI",
  },
  {
      name: "Domžale Municipality",
      code: "023",
      country_code: "SI",
  },
  {
      name: "Dornava Municipality",
      code: "024",
      country_code: "SI",
  },
  {
      name: "Dravograd Municipality",
      code: "025",
      country_code: "SI",
  },
  {
      name: "Duplek Municipality",
      code: "026",
      country_code: "SI",
  },
  {
      name: "Gorenja Vas–Poljane Municipality",
      code: "027",
      country_code: "SI",
  },
  {
      name: "Gorišnica Municipality",
      code: "028",
      country_code: "SI",
  },
  {
      name: "Gorje Municipality",
      code: "207",
      country_code: "SI",
  },
  {
      name: "Gornja Radgona Municipality",
      code: "029",
      country_code: "SI",
  },
  {
      name: "Gornji Grad Municipality",
      code: "030",
      country_code: "SI",
  },
  {
      name: "Gornji Petrovci Municipality",
      code: "031",
      country_code: "SI",
  },
  {
      name: "Grad Municipality",
      code: "158",
      country_code: "SI",
  },
  {
      name: "Grosuplje Municipality",
      code: "032",
      country_code: "SI",
  },
  {
      name: "Hajdina Municipality",
      code: "159",
      country_code: "SI",
  },
  {
      name: "Hoče–Slivnica Municipality",
      code: "160",
      country_code: "SI",
  },
  {
      name: "Hodoš Municipality",
      code: "161",
      country_code: "SI",
  },
  {
      name: "Horjul Municipality",
      code: "162",
      country_code: "SI",
  },
  {
      name: "Hrastnik Municipality",
      code: "034",
      country_code: "SI",
  },
  {
      name: "Hrpelje–Kozina Municipality",
      code: "035",
      country_code: "SI",
  },
  {
      name: "Idrija Municipality",
      code: "036",
      country_code: "SI",
  },
  {
      name: "Ig Municipality",
      code: "037",
      country_code: "SI",
  },
  {
      name: "Ivančna Gorica Municipality",
      code: "039",
      country_code: "SI",
  },
  {
      name: "Izola Municipality",
      code: "040",
      country_code: "SI",
  },
  {
      name: "Jesenice Municipality",
      code: "041",
      country_code: "SI",
  },
  {
      name: "Jezersko Municipality",
      code: "163",
      country_code: "SI",
  },
  {
      name: "Juršinci Municipality",
      code: "042",
      country_code: "SI",
  },
  {
      name: "Kamnik Municipality",
      code: "043",
      country_code: "SI",
  },
  {
      name: "Kanal ob Soči Municipality",
      code: "044",
      country_code: "SI",
  },
  {
      name: "Kidričevo Municipality",
      code: "045",
      country_code: "SI",
  },
  {
      name: "Kobarid Municipality",
      code: "046",
      country_code: "SI",
  },
  {
      name: "Kobilje Municipality",
      code: "047",
      country_code: "SI",
  },
  {
      name: "Kočevje Municipality",
      code: "048",
      country_code: "SI",
  },
  {
      name: "Komen Municipality",
      code: "049",
      country_code: "SI",
  },
  {
      name: "Komenda Municipality",
      code: "164",
      country_code: "SI",
  },
  {
      name: "Koper City Municipality",
      code: "050",
      country_code: "SI",
  },
  {
      name: "Kostanjevica na Krki Municipality",
      code: "197",
      country_code: "SI",
  },
  {
      name: "Kostel Municipality",
      code: "165",
      country_code: "SI",
  },
  {
      name: "Kozje Municipality",
      code: "051",
      country_code: "SI",
  },
  {
      name: "Kranj City Municipality",
      code: "052",
      country_code: "SI",
  },
  {
      name: "Kranjska Gora Municipality",
      code: "053",
      country_code: "SI",
  },
  {
      name: "Križevci Municipality",
      code: "166",
      country_code: "SI",
  },
  {
      name: "Kungota",
      code: "055",
      country_code: "SI",
  },
  {
      name: "Kuzma Municipality",
      code: "056",
      country_code: "SI",
  },
  {
      name: "Laško Municipality",
      code: "057",
      country_code: "SI",
  },
  {
      name: "Lenart Municipality",
      code: "058",
      country_code: "SI",
  },
  {
      name: "Lendava Municipality",
      code: "059",
      country_code: "SI",
  },
  {
      name: "Litija Municipality",
      code: "060",
      country_code: "SI",
  },
  {
      name: "Ljubljana City Municipality",
      code: "061",
      country_code: "SI",
  },
  {
      name: "Ljubno Municipality",
      code: "062",
      country_code: "SI",
  },
  {
      name: "Ljutomer Municipality",
      code: "063",
      country_code: "SI",
  },
  {
      name: "Log–Dragomer Municipality",
      code: "208",
      country_code: "SI",
  },
  {
      name: "Logatec Municipality",
      code: "064",
      country_code: "SI",
  },
  {
      name: "Loška Dolina Municipality",
      code: "065",
      country_code: "SI",
  },
  {
      name: "Loški Potok Municipality",
      code: "066",
      country_code: "SI",
  },
  {
      name: "Lovrenc na Pohorju Municipality",
      code: "167",
      country_code: "SI",
  },
  {
      name: "Luče Municipality",
      code: "067",
      country_code: "SI",
  },
  {
      name: "Lukovica Municipality",
      code: "068",
      country_code: "SI",
  },
  {
      name: "Majšperk Municipality",
      code: "069",
      country_code: "SI",
  },
  {
      name: "Makole Municipality",
      code: "198",
      country_code: "SI",
  },
  {
      name: "Maribor City Municipality",
      code: "070",
      country_code: "SI",
  },
  {
      name: "Markovci Municipality",
      code: "168",
      country_code: "SI",
  },
  {
      name: "Medvode Municipality",
      code: "071",
      country_code: "SI",
  },
  {
      name: "Mengeš Municipality",
      code: "072",
      country_code: "SI",
  },
  {
      name: "Metlika Municipality",
      code: "073",
      country_code: "SI",
  },
  {
      name: "Mežica Municipality",
      code: "074",
      country_code: "SI",
  },
  {
      name: "Miklavž na Dravskem Polju Municipality",
      code: "169",
      country_code: "SI",
  },
  {
      name: "Miren–Kostanjevica Municipality",
      code: "075",
      country_code: "SI",
  },
  {
      name: "Mirna Municipality",
      code: "212",
      country_code: "SI",
  },
  {
      name: "Mirna Peč Municipality",
      code: "170",
      country_code: "SI",
  },
  {
      name: "Mislinja Municipality",
      code: "076",
      country_code: "SI",
  },
  {
      name: "Mokronog–Trebelno Municipality",
      code: "199",
      country_code: "SI",
  },
  {
      name: "Moravče Municipality",
      code: "077",
      country_code: "SI",
  },
  {
      name: "Moravske Toplice Municipality",
      code: "078",
      country_code: "SI",
  },
  {
      name: "Mozirje Municipality",
      code: "079",
      country_code: "SI",
  },
  {
      name: "Municipality of Apače",
      code: "195",
      country_code: "SI",
  },
  {
      name: "Municipality of Cirkulane",
      code: "196",
      country_code: "SI",
  },
  {
      name: "Municipality of Ilirska Bistrica",
      code: "038",
      country_code: "SI",
  },
  {
      name: "Municipality of Krško",
      code: "054",
      country_code: "SI",
  },
  {
      name: "Municipality of Škofljica",
      code: "123",
      country_code: "SI",
  },
  {
      name: "Murska Sobota City Municipality",
      code: "080",
      country_code: "SI",
  },
  {
      name: "Muta Municipality",
      code: "081",
      country_code: "SI",
  },
  {
      name: "Naklo Municipality",
      code: "082",
      country_code: "SI",
  },
  {
      name: "Nazarje Municipality",
      code: "083",
      country_code: "SI",
  },
  {
      name: "Nova Gorica City Municipality",
      code: "084",
      country_code: "SI",
  },
  {
      name: "Odranci Municipality",
      code: "086",
      country_code: "SI",
  },
  {
      name: "Oplotnica",
      code: "171",
      country_code: "SI",
  },
  {
      name: "Ormož Municipality",
      code: "087",
      country_code: "SI",
  },
  {
      name: "Osilnica Municipality",
      code: "088",
      country_code: "SI",
  },
  {
      name: "Pesnica Municipality",
      code: "089",
      country_code: "SI",
  },
  {
      name: "Piran Municipality",
      code: "090",
      country_code: "SI",
  },
  {
      name: "Pivka Municipality",
      code: "091",
      country_code: "SI",
  },
  {
      name: "Podčetrtek Municipality",
      code: "092",
      country_code: "SI",
  },
  {
      name: "Podlehnik Municipality",
      code: "172",
      country_code: "SI",
  },
  {
      name: "Podvelka Municipality",
      code: "093",
      country_code: "SI",
  },
  {
      name: "Poljčane Municipality",
      code: "200",
      country_code: "SI",
  },
  {
      name: "Polzela Municipality",
      code: "173",
      country_code: "SI",
  },
  {
      name: "Postojna Municipality",
      code: "094",
      country_code: "SI",
  },
  {
      name: "Prebold Municipality",
      code: "174",
      country_code: "SI",
  },
  {
      name: "Preddvor Municipality",
      code: "095",
      country_code: "SI",
  },
  {
      name: "Prevalje Municipality",
      code: "175",
      country_code: "SI",
  },
  {
      name: "Ptuj City Municipality",
      code: "096",
      country_code: "SI",
  },
  {
      name: "Puconci Municipality",
      code: "097",
      country_code: "SI",
  },
  {
      name: "Rače–Fram Municipality",
      code: "098",
      country_code: "SI",
  },
  {
      name: "Radeče Municipality",
      code: "099",
      country_code: "SI",
  },
  {
      name: "Radenci Municipality",
      code: "100",
      country_code: "SI",
  },
  {
      name: "Radlje ob Dravi Municipality",
      code: "101",
      country_code: "SI",
  },
  {
      name: "Radovljica Municipality",
      code: "102",
      country_code: "SI",
  },
  {
      name: "Ravne na Koroškem Municipality",
      code: "103",
      country_code: "SI",
  },
  {
      name: "Razkrižje Municipality",
      code: "176",
      country_code: "SI",
  },
  {
      name: "Rečica ob Savinji Municipality",
      code: "209",
      country_code: "SI",
  },
  {
      name: "Renče–Vogrsko Municipality",
      code: "201",
      country_code: "SI",
  },
  {
      name: "Ribnica Municipality",
      code: "104",
      country_code: "SI",
  },
  {
      name: "Ribnica na Pohorju Municipality",
      code: "177",
      country_code: "SI",
  },
  {
      name: "Rogaška Slatina Municipality",
      code: "106",
      country_code: "SI",
  },
  {
      name: "Rogašovci Municipality",
      code: "105",
      country_code: "SI",
  },
  {
      name: "Rogatec Municipality",
      code: "107",
      country_code: "SI",
  },
  {
      name: "Ruše Municipality",
      code: "108",
      country_code: "SI",
  },
  {
      name: "Šalovci Municipality",
      code: "033",
      country_code: "SI",
  },
  {
      name: "Selnica ob Dravi Municipality",
      code: "178",
      country_code: "SI",
  },
  {
      name: "Semič Municipality",
      code: "109",
      country_code: "SI",
  },
  {
      name: "Šempeter–Vrtojba Municipality",
      code: "183",
      country_code: "SI",
  },
  {
      name: "Šenčur Municipality",
      code: "117",
      country_code: "SI",
  },
  {
      name: "Šentilj Municipality",
      code: "118",
      country_code: "SI",
  },
  {
      name: "Šentjernej Municipality",
      code: "119",
      country_code: "SI",
  },
  {
      name: "Šentjur Municipality",
      code: "120",
      country_code: "SI",
  },
  {
      name: "Šentrupert Municipality",
      code: "211",
      country_code: "SI",
  },
  {
      name: "Sevnica Municipality",
      code: "110",
      country_code: "SI",
  },
  {
      name: "Sežana Municipality",
      code: "111",
      country_code: "SI",
  },
  {
      name: "Škocjan Municipality",
      code: "121",
      country_code: "SI",
  },
  {
      name: "Škofja Loka Municipality",
      code: "122",
      country_code: "SI",
  },
  {
      name: "Slovenj Gradec City Municipality",
      code: "112",
      country_code: "SI",
  },
  {
      name: "Slovenska Bistrica Municipality",
      code: "113",
      country_code: "SI",
  },
  {
      name: "Slovenske Konjice Municipality",
      code: "114",
      country_code: "SI",
  },
  {
      name: "Šmarje pri Jelšah Municipality",
      code: "124",
      country_code: "SI",
  },
  {
      name: "Šmarješke Toplice Municipality",
      code: "206",
      country_code: "SI",
  },
  {
      name: "Šmartno ob Paki Municipality",
      code: "125",
      country_code: "SI",
  },
  {
      name: "Šmartno pri Litiji Municipality",
      code: "194",
      country_code: "SI",
  },
  {
      name: "Sodražica Municipality",
      code: "179",
      country_code: "SI",
  },
  {
      name: "Solčava Municipality",
      code: "180",
      country_code: "SI",
  },
  {
      name: "Šoštanj Municipality",
      code: "126",
      country_code: "SI",
  },
  {
      name: "Središče ob Dravi",
      code: "202",
      country_code: "SI",
  },
  {
      name: "Starše Municipality",
      code: "115",
      country_code: "SI",
  },
  {
      name: "Štore Municipality",
      code: "127",
      country_code: "SI",
  },
  {
      name: "Straža Municipality",
      code: "203",
      country_code: "SI",
  },
  {
      name: "Sveta Ana Municipality",
      code: "181",
      country_code: "SI",
  },
  {
      name: "Sveta Trojica v Slovenskih Goricah Municipality",
      code: "204",
      country_code: "SI",
  },
  {
      name: "Sveti Andraž v Slovenskih Goricah Municipality",
      code: "182",
      country_code: "SI",
  },
  {
      name: "Sveti Jurij ob Ščavnici Municipality",
      code: "116",
      country_code: "SI",
  },
  {
      name: "Sveti Jurij v Slovenskih Goricah Municipality",
      code: "210",
      country_code: "SI",
  },
  {
      name: "Sveti Tomaž Municipality",
      code: "205",
      country_code: "SI",
  },
  {
      name: "Tabor Municipality",
      code: "184",
      country_code: "SI",
  },
  {
      name: "Tišina Municipality",
      code: "010",
      country_code: "SI",
  },
  {
      name: "Tolmin Municipality",
      code: "128",
      country_code: "SI",
  },
  {
      name: "Trbovlje Municipality",
      code: "129",
      country_code: "SI",
  },
  {
      name: "Trebnje Municipality",
      code: "130",
      country_code: "SI",
  },
  {
      name: "Trnovska Vas Municipality",
      code: "185",
      country_code: "SI",
  },
  {
      name: "Tržič Municipality",
      code: "131",
      country_code: "SI",
  },
  {
      name: "Trzin Municipality",
      code: "186",
      country_code: "SI",
  },
  {
      name: "Turnišče Municipality",
      code: "132",
      country_code: "SI",
  },
  {
      name: "Velika Polana Municipality",
      code: "187",
      country_code: "SI",
  },
  {
      name: "Velike Lašče Municipality",
      code: "134",
      country_code: "SI",
  },
  {
      name: "Veržej Municipality",
      code: "188",
      country_code: "SI",
  },
  {
      name: "Videm Municipality",
      code: "135",
      country_code: "SI",
  },
  {
      name: "Vipava Municipality",
      code: "136",
      country_code: "SI",
  },
  {
      name: "Vitanje Municipality",
      code: "137",
      country_code: "SI",
  },
  {
      name: "Vodice Municipality",
      code: "138",
      country_code: "SI",
  },
  {
      name: "Vojnik Municipality",
      code: "139",
      country_code: "SI",
  },
  {
      name: "Vransko Municipality",
      code: "189",
      country_code: "SI",
  },
  {
      name: "Vrhnika Municipality",
      code: "140",
      country_code: "SI",
  },
  {
      name: "Vuzenica Municipality",
      code: "141",
      country_code: "SI",
  },
  {
      name: "Zagorje ob Savi Municipality",
      code: "142",
      country_code: "SI",
  },
  {
      name: "Žalec Municipality",
      code: "190",
      country_code: "SI",
  },
  {
      name: "Zavrč Municipality",
      code: "143",
      country_code: "SI",
  },
  {
      name: "Železniki Municipality",
      code: "146",
      country_code: "SI",
  },
  {
      name: "Žetale Municipality",
      code: "191",
      country_code: "SI",
  },
  {
      name: "Žiri Municipality",
      code: "147",
      country_code: "SI",
  },
  {
      name: "Žirovnica Municipality",
      code: "192",
      country_code: "SI",
  },
  {
      name: "Zreče Municipality",
      code: "144",
      country_code: "SI",
  },
  {
      name: "Žužemberk Municipality",
      code: "193",
      country_code: "SI",
  },
  {
      name: "Central Province",
      code: "CE",
      country_code: "SB",
  },
  {
      name: "Choiseul Province",
      code: "CH",
      country_code: "SB",
  },
  {
      name: "Guadalcanal Province",
      code: "GU",
      country_code: "SB",
  },
  {
      name: "Honiara",
      code: "CT",
      country_code: "SB",
  },
  {
      name: "Isabel Province",
      code: "IS",
      country_code: "SB",
  },
  {
      name: "Makira-Ulawa Province",
      code: "MK",
      country_code: "SB",
  },
  {
      name: "Malaita Province",
      code: "ML",
      country_code: "SB",
  },
  {
      name: "Rennell and Bellona Province",
      code: "RB",
      country_code: "SB",
  },
  {
      name: "Temotu Province",
      code: "TE",
      country_code: "SB",
  },
  {
      name: "Western Province",
      code: "WE",
      country_code: "SB",
  },
  {
      name: "Awdal Region",
      code: "AW",
      country_code: "SO",
  },
  {
      name: "Bakool",
      code: "BK",
      country_code: "SO",
  },
  {
      name: "Banaadir",
      code: "BN",
      country_code: "SO",
  },
  {
      name: "Bari",
      code: "BR",
      country_code: "SO",
  },
  {
      name: "Bay",
      code: "BY",
      country_code: "SO",
  },
  {
      name: "Galguduud",
      code: "GA",
      country_code: "SO",
  },
  {
      name: "Gedo",
      code: "GE",
      country_code: "SO",
  },
  {
      name: "Hiran",
      code: "HI",
      country_code: "SO",
  },
  {
      name: "Lower Juba",
      code: "JH",
      country_code: "SO",
  },
  {
      name: "Lower Shebelle",
      code: "SH",
      country_code: "SO",
  },
  {
      name: "Middle Juba",
      code: "JD",
      country_code: "SO",
  },
  {
      name: "Middle Shebelle",
      code: "SD",
      country_code: "SO",
  },
  {
      name: "Mudug",
      code: "MU",
      country_code: "SO",
  },
  {
      name: "Nugal",
      code: "NU",
      country_code: "SO",
  },
  {
      name: "Sanaag Region",
      code: "SA",
      country_code: "SO",
  },
  {
      name: "Togdheer Region",
      code: "TO",
      country_code: "SO",
  },
  {
      name: "Eastern Cape",
      code: "EC",
      country_code: "ZA",
  },
  {
      name: "Free State",
      code: "FS",
      country_code: "ZA",
  },
  {
      name: "Gauteng",
      code: "GP",
      country_code: "ZA",
  },
  {
      name: "KwaZulu-Natal",
      code: "KZN",
      country_code: "ZA",
  },
  {
      name: "Limpopo",
      code: "LP",
      country_code: "ZA",
  },
  {
      name: "Mpumalanga",
      code: "MP",
      country_code: "ZA",
  },
  {
      name: "North West",
      code: "NW",
      country_code: "ZA",
  },
  {
      name: "Northern Cape",
      code: "NC",
      country_code: "ZA",
  },
  {
      name: "Western Cape",
      code: "WC",
      country_code: "ZA",
  },
  {
      name: "Busan",
      code: "26",
      country_code: "KR",
  },
  {
      name: "Daegu",
      code: "27",
      country_code: "KR",
  },
  {
      name: "Daejeon",
      code: "30",
      country_code: "KR",
  },
  {
      name: "Gangwon Province",
      code: "42",
      country_code: "KR",
  },
  {
      name: "Gwangju",
      code: "29",
      country_code: "KR",
  },
  {
      name: "Gyeonggi Province",
      code: "41",
      country_code: "KR",
  },
  {
      name: "Incheon",
      code: "28",
      country_code: "KR",
  },
  {
      name: "Jeju",
      code: "49",
      country_code: "KR",
  },
  {
      name: "North Chungcheong Province",
      code: "43",
      country_code: "KR",
  },
  {
      name: "North Gyeongsang Province",
      code: "47",
      country_code: "KR",
  },
  {
      name: "North Jeolla Province",
      code: "45",
      country_code: "KR",
  },
  {
      name: "Sejong City",
      code: "50",
      country_code: "KR",
  },
  {
      name: "Seoul",
      code: "11",
      country_code: "KR",
  },
  {
      name: "South Chungcheong Province",
      code: "44",
      country_code: "KR",
  },
  {
      name: "South Gyeongsang Province",
      code: "48",
      country_code: "KR",
  },
  {
      name: "South Jeolla Province",
      code: "46",
      country_code: "KR",
  },
  {
      name: "Ulsan",
      code: "31",
      country_code: "KR",
  },
  {
      name: "Central Equatoria",
      code: "EC",
      country_code: "SS",
  },
  {
      name: "Eastern Equatoria",
      code: "EE",
      country_code: "SS",
  },
  {
      name: "Jonglei State",
      code: "JG",
      country_code: "SS",
  },
  {
      name: "Lakes",
      code: "LK",
      country_code: "SS",
  },
  {
      name: "Northern Bahr el Ghazal",
      code: "BN",
      country_code: "SS",
  },
  {
      name: "Unity",
      code: "UY",
      country_code: "SS",
  },
  {
      name: "Upper Nile",
      code: "NU",
      country_code: "SS",
  },
  {
      name: "Warrap",
      code: "WR",
      country_code: "SS",
  },
  {
      name: "Western Bahr el Ghazal",
      code: "BW",
      country_code: "SS",
  },
  {
      name: "Western Equatoria",
      code: "EW",
      country_code: "SS",
  },
  {
      name: "Andalusia",
      code: "AN",
      country_code: "ES",
  },
  {
      name: "Aragon",
      code: "AR",
      country_code: "ES",
  },
  {
      name: "Asturias",
      code: "AS",
      country_code: "ES",
  },
  {
      name: "Ávila",
      code: "AV",
      country_code: "ES",
  },
  {
      name: "Balearic Islands",
      code: "PM",
      country_code: "ES",
  },
  {
      name: "Basque Country",
      code: "PV",
      country_code: "ES",
  },
  {
      name: "Burgos Province",
      code: "BU",
      country_code: "ES",
  },
  {
      name: "Canary Islands",
      code: "CN",
      country_code: "ES",
  },
  {
      name: "Cantabria",
      code: "CB",
      country_code: "ES",
  },
  {
      name: "Castile and León",
      code: "CL",
      country_code: "ES",
  },
  {
      name: "Castilla La Mancha",
      code: "CM",
      country_code: "ES",
  },
  {
      name: "Catalonia",
      code: "CT",
      country_code: "ES",
  },
  {
      name: "Ceuta",
      code: "CE",
      country_code: "ES",
  },
  {
      name: "Extremadura",
      code: "EX",
      country_code: "ES",
  },
  {
      name: "Galicia",
      code: "GA",
      country_code: "ES",
  },
  {
      name: "La Rioja",
      code: "RI",
      country_code: "ES",
  },
  {
      name: "Léon",
      code: "LE",
      country_code: "ES",
  },
  {
      name: "Madrid",
      code: "MD",
      country_code: "ES",
  },
  {
      name: "Melilla",
      code: "ML",
      country_code: "ES",
  },
  {
      name: "Murcia",
      code: "MC",
      country_code: "ES",
  },
  {
      name: "Navarra",
      code: "NC",
      country_code: "ES",
  },
  {
      name: "Palencia Province",
      code: "P",
      country_code: "ES",
  },
  {
      name: "Salamanca Province",
      code: "SA",
      country_code: "ES",
  },
  {
      name: "Segovia Province",
      code: "SG",
      country_code: "ES",
  },
  {
      name: "Soria Province",
      code: "SO",
      country_code: "ES",
  },
  {
      name: "Valencia",
      code: "VC",
      country_code: "ES",
  },
  {
      name: "Valladolid Province",
      code: "VA",
      country_code: "ES",
  },
  {
      name: "Zamora Province",
      code: "ZA",
      country_code: "ES",
  },
  {
      name: "Ampara District",
      code: "52",
      country_code: "LK",
  },
  {
      name: "Anuradhapura District",
      code: "71",
      country_code: "LK",
  },
  {
      name: "Badulla District",
      code: "81",
      country_code: "LK",
  },
  {
      name: "Batticaloa District",
      code: "51",
      country_code: "LK",
  },
  {
      name: "Central Province",
      code: "2",
      country_code: "LK",
  },
  {
      name: "Colombo District",
      code: "11",
      country_code: "LK",
  },
  {
      name: "Eastern Province",
      code: "5",
      country_code: "LK",
  },
  {
      name: "Galle District",
      code: "31",
      country_code: "LK",
  },
  {
      name: "Gampaha District",
      code: "12",
      country_code: "LK",
  },
  {
      name: "Hambantota District",
      code: "33",
      country_code: "LK",
  },
  {
      name: "Jaffna District",
      code: "41",
      country_code: "LK",
  },
  {
      name: "Kalutara District",
      code: "13",
      country_code: "LK",
  },
  {
      name: "Kandy District",
      code: "21",
      country_code: "LK",
  },
  {
      name: "Kegalle District",
      code: "92",
      country_code: "LK",
  },
  {
      name: "Kilinochchi District",
      code: "42",
      country_code: "LK",
  },
  {
      name: "Mannar District",
      code: "43",
      country_code: "LK",
  },
  {
      name: "Matale District",
      code: "22",
      country_code: "LK",
  },
  {
      name: "Matara District",
      code: "32",
      country_code: "LK",
  },
  {
      name: "Monaragala District",
      code: "82",
      country_code: "LK",
  },
  {
      name: "Mullaitivu District",
      code: "45",
      country_code: "LK",
  },
  {
      name: "North Central Province",
      code: "7",
      country_code: "LK",
  },
  {
      name: "North Western Province",
      code: "6",
      country_code: "LK",
  },
  {
      name: "Northern Province",
      code: "4",
      country_code: "LK",
  },
  {
      name: "Nuwara Eliya District",
      code: "23",
      country_code: "LK",
  },
  {
      name: "Polonnaruwa District",
      code: "72",
      country_code: "LK",
  },
  {
      name: "Puttalam District",
      code: "62",
      country_code: "LK",
  },
  {
      name: "Ratnapura district",
      code: "91",
      country_code: "LK",
  },
  {
      name: "Sabaragamuwa Province",
      code: "9",
      country_code: "LK",
  },
  {
      name: "Southern Province",
      code: "3",
      country_code: "LK",
  },
  {
      name: "Trincomalee District",
      code: "53",
      country_code: "LK",
  },
  {
      name: "Uva Province",
      code: "8",
      country_code: "LK",
  },
  {
      name: "Vavuniya District",
      code: "44",
      country_code: "LK",
  },
  {
      name: "Western Province",
      code: "1",
      country_code: "LK",
  },
  {
      name: "Al Jazirah",
      code: "GZ",
      country_code: "SD",
  },
  {
      name: "Al Qadarif",
      code: "GD",
      country_code: "SD",
  },
  {
      name: "Blue Nile",
      code: "NB",
      country_code: "SD",
  },
  {
      name: "Central Darfur",
      code: "DC",
      country_code: "SD",
  },
  {
      name: "East Darfur",
      code: "DE",
      country_code: "SD",
  },
  {
      name: "Kassala",
      code: "KA",
      country_code: "SD",
  },
  {
      name: "Khartoum",
      code: "KH",
      country_code: "SD",
  },
  {
      name: "North Darfur",
      code: "DN",
      country_code: "SD",
  },
  {
      name: "North Kordofan",
      code: "KN",
      country_code: "SD",
  },
  {
      name: "Northern",
      code: "NO",
      country_code: "SD",
  },
  {
      name: "Red Sea",
      code: "RS",
      country_code: "SD",
  },
  {
      name: "River Nile",
      code: "NR",
      country_code: "SD",
  },
  {
      name: "Sennar",
      code: "SI",
      country_code: "SD",
  },
  {
      name: "South Darfur",
      code: "DS",
      country_code: "SD",
  },
  {
      name: "South Kordofan",
      code: "KS",
      country_code: "SD",
  },
  {
      name: "West Darfur",
      code: "DW",
      country_code: "SD",
  },
  {
      name: "West Kordofan",
      code: "GK",
      country_code: "SD",
  },
  {
      name: "White Nile",
      code: "NW",
      country_code: "SD",
  },
  {
      name: "Brokopondo District",
      code: "BR",
      country_code: "SR",
  },
  {
      name: "Commewijne District",
      code: "CM",
      country_code: "SR",
  },
  {
      name: "Coronie District",
      code: "CR",
      country_code: "SR",
  },
  {
      name: "Marowijne District",
      code: "MA",
      country_code: "SR",
  },
  {
      name: "Nickerie District",
      code: "NI",
      country_code: "SR",
  },
  {
      name: "Para District",
      code: "PR",
      country_code: "SR",
  },
  {
      name: "Paramaribo District",
      code: "PM",
      country_code: "SR",
  },
  {
      name: "Saramacca District",
      code: "SA",
      country_code: "SR",
  },
  {
      name: "Sipaliwini District",
      code: "SI",
      country_code: "SR",
  },
  {
      name: "Wanica District",
      code: "WA",
      country_code: "SR",
  },
  {
      name: "Hhohho District",
      code: "HH",
      country_code: "SZ",
  },
  {
      name: "Lubombo District",
      code: "LU",
      country_code: "SZ",
  },
  {
      name: "Manzini District",
      code: "MA",
      country_code: "SZ",
  },
  {
      name: "Shiselweni District",
      code: "SH",
      country_code: "SZ",
  },
  {
      name: "Blekinge",
      code: "K",
      country_code: "SE",
  },
  {
      name: "Dalarna County",
      code: "W",
      country_code: "SE",
  },
  {
      name: "Gävleborg County",
      code: "X",
      country_code: "SE",
  },
  {
      name: "Gotland County",
      code: "I",
      country_code: "SE",
  },
  {
      name: "Halland County",
      code: "N",
      country_code: "SE",
  },
  {
      name: "Jönköping County",
      code: "F",
      country_code: "SE",
  },
  {
      name: "Kalmar County",
      code: "H",
      country_code: "SE",
  },
  {
      name: "Kronoberg County",
      code: "G",
      country_code: "SE",
  },
  {
      name: "Norrbotten County",
      code: "BD",
      country_code: "SE",
  },
  {
      name: "Örebro County",
      code: "T",
      country_code: "SE",
  },
  {
      name: "Östergötland County",
      code: "E",
      country_code: "SE",
  },
  {
      name: "Skåne County",
      code: "M",
      country_code: "SE",
  },
  {
      name: "Södermanland County",
      code: "D",
      country_code: "SE",
  },
  {
      name: "Stockholm County",
      code: "AB",
      country_code: "SE",
  },
  {
      name: "Uppsala County",
      code: "C",
      country_code: "SE",
  },
  {
      name: "Värmland County",
      code: "S",
      country_code: "SE",
  },
  {
      name: "Västerbotten County",
      code: "AC",
      country_code: "SE",
  },
  {
      name: "Västernorrland County",
      code: "Y",
      country_code: "SE",
  },
  {
      name: "Västmanland County",
      code: "U",
      country_code: "SE",
  },
  {
      name: "Västra Götaland County",
      code: "O",
      country_code: "SE",
  },
  {
      name: "Aargau",
      code: "AG",
      country_code: "CH",
  },
  {
      name: "Appenzell Ausserrhoden",
      code: "AR",
      country_code: "CH",
  },
  {
      name: "Appenzell Innerrhoden",
      code: "AI",
      country_code: "CH",
  },
  {
      name: "Basel-Landschaft",
      code: "BL",
      country_code: "CH",
  },
  {
      name: "canton of Bern",
      code: "BE",
      country_code: "CH",
  },
  {
      name: "Canton of Fribourg",
      code: "FR",
      country_code: "CH",
  },
  {
      name: "Canton of Geneva",
      code: "GE",
      country_code: "CH",
  },
  {
      name: "Canton of Jura",
      code: "JU",
      country_code: "CH",
  },
  {
      name: "Canton of Lucerne",
      code: "LU",
      country_code: "CH",
  },
  {
      name: "Canton of Neuchâtel",
      code: "NE",
      country_code: "CH",
  },
  {
      name: "Canton of Schaffhausen",
      code: "SH",
      country_code: "CH",
  },
  {
      name: "Canton of Solothurn",
      code: "SO",
      country_code: "CH",
  },
  {
      name: "Canton of St. Gallen",
      code: "SG",
      country_code: "CH",
  },
  {
      name: "Canton of Valais",
      code: "VS",
      country_code: "CH",
  },
  {
      name: "Canton of Vaud",
      code: "VD",
      country_code: "CH",
  },
  {
      name: "Canton of Zug",
      code: "ZG",
      country_code: "CH",
  },
  {
      name: "canton of Zürich",
      code: "ZH",
      country_code: "CH",
  },
  {
      name: "Glarus",
      code: "GL",
      country_code: "CH",
  },
  {
      name: "Graubünden",
      code: "GR",
      country_code: "CH",
  },
  {
      name: "Nidwalden",
      code: "NW",
      country_code: "CH",
  },
  {
      name: "Obwalden",
      code: "OW",
      country_code: "CH",
  },
  {
      name: "Schwyz",
      code: "SZ",
      country_code: "CH",
  },
  {
      name: "Thurgau",
      code: "TG",
      country_code: "CH",
  },
  {
      name: "Ticino",
      code: "TI",
      country_code: "CH",
  },
  {
      name: "Uri",
      code: "UR",
      country_code: "CH",
  },
  {
      name: "Al-Hasakah Governorate",
      code: "HA",
      country_code: "SY",
  },
  {
      name: "Al-Raqqah Governorate",
      code: "RA",
      country_code: "SY",
  },
  {
      name: "Aleppo Governorate",
      code: "HL",
      country_code: "SY",
  },
  {
      name: "As-Suwayda Governorate",
      code: "SU",
      country_code: "SY",
  },
  {
      name: "Damascus Governorate",
      code: "DI",
      country_code: "SY",
  },
  {
      name: "Daraa Governorate",
      code: "DR",
      country_code: "SY",
  },
  {
      name: "Deir ez-Zor Governorate",
      code: "DY",
      country_code: "SY",
  },
  {
      name: "Hama Governorate",
      code: "HM",
      country_code: "SY",
  },
  {
      name: "Homs Governorate",
      code: "HI",
      country_code: "SY",
  },
  {
      name: "Idlib Governorate",
      code: "ID",
      country_code: "SY",
  },
  {
      name: "Latakia Governorate",
      code: "LA",
      country_code: "SY",
  },
  {
      name: "Quneitra Governorate",
      code: "QU",
      country_code: "SY",
  },
  {
      name: "Rif Dimashq Governorate",
      code: "RD",
      country_code: "SY",
  },
  {
      name: "Tartus Governorate",
      code: "TA",
      country_code: "SY",
  },
  {
      name: "Changhua County",
      code: "CHA",
      country_code: "TW",
  },
  {
      name: "Chiayi City",
      code: "CYQ",
      country_code: "TW",
  },
  {
      name: "Chiayi County",
      code: "CYI",
      country_code: "TW",
  },
  {
      name: "Hsinchu",
      code: "HSZ",
      country_code: "TW",
  },
  {
      name: "Hsinchu County",
      code: "HSQ",
      country_code: "TW",
  },
  {
      name: "Hualien County",
      code: "HUA",
      country_code: "TW",
  },
  {
      name: "Kaohsiung",
      code: "KHH",
      country_code: "TW",
  },
  {
      name: "Kaohsiung County",
      code: "KHQ",
      country_code: "TW",
  },
  {
      name: "Kinmen",
      code: "KIN",
      country_code: "TW",
  },
  {
      name: "Lienchiang County",
      code: "LIE",
      country_code: "TW",
  },
  {
      name: "Miaoli County",
      code: "MIA",
      country_code: "TW",
  },
  {
      name: "Nantou County",
      code: "NAN",
      country_code: "TW",
  },
  {
      name: "Penghu County",
      code: "PEN",
      country_code: "TW",
  },
  {
      name: "Pingtung County",
      code: "PIF",
      country_code: "TW",
  },
  {
      name: "Taichung",
      code: "TXG",
      country_code: "TW",
  },
  {
      name: "Taichung County",
      code: "TXQ",
      country_code: "TW",
  },
  {
      name: "Tainan",
      code: "TNN",
      country_code: "TW",
  },
  {
      name: "Tainan County",
      code: "TNQ",
      country_code: "TW",
  },
  {
      name: "Taipei",
      code: "TPE",
      country_code: "TW",
  },
  {
      name: "Taitung County",
      code: "TTT",
      country_code: "TW",
  },
  {
      name: "Taoyuan City",
      code: "TAO",
      country_code: "TW",
  },
  {
      name: "Yilan County",
      code: "ILA",
      country_code: "TW",
  },
  {
      name: "Yunlin County",
      code: "YUN",
      country_code: "TW",
  },
  {
      name: "districts of Republican Subordination",
      code: "RA",
      country_code: "TJ",
  },
  {
      name: "Gorno-Badakhshan Autonomous Province",
      code: "GB",
      country_code: "TJ",
  },
  {
      name: "Khatlon Province",
      code: "KT",
      country_code: "TJ",
  },
  {
      name: "Sughd Province",
      code: "SU",
      country_code: "TJ",
  },
  {
      name: "Arusha",
      code: "01",
      country_code: "TZ",
  },
  {
      name: "Dar es Salaam",
      code: "02",
      country_code: "TZ",
  },
  {
      name: "Dodoma",
      code: "03",
      country_code: "TZ",
  },
  {
      name: "Geita",
      code: "27",
      country_code: "TZ",
  },
  {
      name: "Iringa",
      code: "04",
      country_code: "TZ",
  },
  {
      name: "Kagera",
      code: "05",
      country_code: "TZ",
  },
  {
      name: "Katavi",
      code: "28",
      country_code: "TZ",
  },
  {
      name: "Kigoma",
      code: "08",
      country_code: "TZ",
  },
  {
      name: "Kilimanjaro",
      code: "09",
      country_code: "TZ",
  },
  {
      name: "Lindi",
      code: "12",
      country_code: "TZ",
  },
  {
      name: "Manyara",
      code: "26",
      country_code: "TZ",
  },
  {
      name: "Mara",
      code: "13",
      country_code: "TZ",
  },
  {
      name: "Mbeya",
      code: "14",
      country_code: "TZ",
  },
  {
      name: "Morogoro",
      code: "16",
      country_code: "TZ",
  },
  {
      name: "Mtwara",
      code: "17",
      country_code: "TZ",
  },
  {
      name: "Mwanza",
      code: "18",
      country_code: "TZ",
  },
  {
      name: "Njombe",
      code: "29",
      country_code: "TZ",
  },
  {
      name: "Pemba North",
      code: "06",
      country_code: "TZ",
  },
  {
      name: "Pemba South",
      code: "10",
      country_code: "TZ",
  },
  {
      name: "Pwani",
      code: "19",
      country_code: "TZ",
  },
  {
      name: "Rukwa",
      code: "20",
      country_code: "TZ",
  },
  {
      name: "Ruvuma",
      code: "21",
      country_code: "TZ",
  },
  {
      name: "Shinyanga",
      code: "22",
      country_code: "TZ",
  },
  {
      name: "Simiyu",
      code: "30",
      country_code: "TZ",
  },
  {
      name: "Singida",
      code: "23",
      country_code: "TZ",
  },
  {
      name: "Songwe",
      code: "31",
      country_code: "TZ",
  },
  {
      name: "Tabora",
      code: "24",
      country_code: "TZ",
  },
  {
      name: "Tanga",
      code: "25",
      country_code: "TZ",
  },
  {
      name: "Zanzibar North",
      code: "07",
      country_code: "TZ",
  },
  {
      name: "Zanzibar South",
      code: "11",
      country_code: "TZ",
  },
  {
      name: "Zanzibar West",
      code: "15",
      country_code: "TZ",
  },
  {
      name: "Amnat Charoen",
      code: "37",
      country_code: "TH",
  },
  {
      name: "Ang Thong",
      code: "15",
      country_code: "TH",
  },
  {
      name: "Bangkok",
      code: "10",
      country_code: "TH",
  },
  {
      name: "Bueng Kan",
      code: "38",
      country_code: "TH",
  },
  {
      name: "Buri Ram",
      code: "31",
      country_code: "TH",
  },
  {
      name: "Chachoengsao",
      code: "24",
      country_code: "TH",
  },
  {
      name: "Chai Nat",
      code: "18",
      country_code: "TH",
  },
  {
      name: "Chaiyaphum",
      code: "36",
      country_code: "TH",
  },
  {
      name: "Chanthaburi",
      code: "22",
      country_code: "TH",
  },
  {
      name: "Chiang Mai",
      code: "50",
      country_code: "TH",
  },
  {
      name: "Chiang Rai",
      code: "57",
      country_code: "TH",
  },
  {
      name: "Chon Buri",
      code: "20",
      country_code: "TH",
  },
  {
      name: "Chumphon",
      code: "86",
      country_code: "TH",
  },
  {
      name: "Kalasin",
      code: "46",
      country_code: "TH",
  },
  {
      name: "Kamphaeng Phet",
      code: "62",
      country_code: "TH",
  },
  {
      name: "Kanchanaburi",
      code: "71",
      country_code: "TH",
  },
  {
      name: "Khon Kaen",
      code: "40",
      country_code: "TH",
  },
  {
      name: "Krabi",
      code: "81",
      country_code: "TH",
  },
  {
      name: "Lampang",
      code: "52",
      country_code: "TH",
  },
  {
      name: "Lamphun",
      code: "51",
      country_code: "TH",
  },
  {
      name: "Loei",
      code: "42",
      country_code: "TH",
  },
  {
      name: "Lop Buri",
      code: "16",
      country_code: "TH",
  },
  {
      name: "Mae Hong Son",
      code: "58",
      country_code: "TH",
  },
  {
      name: "Maha Sarakham",
      code: "44",
      country_code: "TH",
  },
  {
      name: "Mukdahan",
      code: "49",
      country_code: "TH",
  },
  {
      name: "Nakhon Nayok",
      code: "26",
      country_code: "TH",
  },
  {
      name: "Nakhon Pathom",
      code: "73",
      country_code: "TH",
  },
  {
      name: "Nakhon Phanom",
      code: "48",
      country_code: "TH",
  },
  {
      name: "Nakhon Ratchasima",
      code: "30",
      country_code: "TH",
  },
  {
      name: "Nakhon Sawan",
      code: "60",
      country_code: "TH",
  },
  {
      name: "Nakhon Si Thammarat",
      code: "80",
      country_code: "TH",
  },
  {
      name: "Nan",
      code: "55",
      country_code: "TH",
  },
  {
      name: "Narathiwat",
      code: "96",
      country_code: "TH",
  },
  {
      name: "Nong Bua Lam Phu",
      code: "39",
      country_code: "TH",
  },
  {
      name: "Nong Khai",
      code: "43",
      country_code: "TH",
  },
  {
      name: "Nonthaburi",
      code: "12",
      country_code: "TH",
  },
  {
      name: "Pathum Thani",
      code: "13",
      country_code: "TH",
  },
  {
      name: "Pattani",
      code: "94",
      country_code: "TH",
  },
  {
      name: "Pattaya",
      code: "S",
      country_code: "TH",
  },
  {
      name: "Phangnga",
      code: "82",
      country_code: "TH",
  },
  {
      name: "Phatthalung",
      code: "93",
      country_code: "TH",
  },
  {
      name: "Phayao",
      code: "56",
      country_code: "TH",
  },
  {
      name: "Phetchabun",
      code: "67",
      country_code: "TH",
  },
  {
      name: "Phetchaburi",
      code: "76",
      country_code: "TH",
  },
  {
      name: "Phichit",
      code: "66",
      country_code: "TH",
  },
  {
      name: "Phitsanulok",
      code: "65",
      country_code: "TH",
  },
  {
      name: "Phra Nakhon Si Ayutthaya",
      code: "14",
      country_code: "TH",
  },
  {
      name: "Phrae",
      code: "54",
      country_code: "TH",
  },
  {
      name: "Phuket",
      code: "83",
      country_code: "TH",
  },
  {
      name: "Prachin Buri",
      code: "25",
      country_code: "TH",
  },
  {
      name: "Prachuap Khiri Khan",
      code: "77",
      country_code: "TH",
  },
  {
      name: "Ranong",
      code: "85",
      country_code: "TH",
  },
  {
      name: "Ratchaburi",
      code: "70",
      country_code: "TH",
  },
  {
      name: "Rayong",
      code: "21",
      country_code: "TH",
  },
  {
      name: "Roi Et",
      code: "45",
      country_code: "TH",
  },
  {
      name: "Sa Kaeo",
      code: "27",
      country_code: "TH",
  },
  {
      name: "Sakon Nakhon",
      code: "47",
      country_code: "TH",
  },
  {
      name: "Samut Prakan",
      code: "11",
      country_code: "TH",
  },
  {
      name: "Samut Sakhon",
      code: "74",
      country_code: "TH",
  },
  {
      name: "Samut Songkhram",
      code: "75",
      country_code: "TH",
  },
  {
      name: "Saraburi",
      code: "19",
      country_code: "TH",
  },
  {
      name: "Satun",
      code: "91",
      country_code: "TH",
  },
  {
      name: "Si Sa Ket",
      code: "33",
      country_code: "TH",
  },
  {
      name: "Sing Buri",
      code: "17",
      country_code: "TH",
  },
  {
      name: "Songkhla",
      code: "90",
      country_code: "TH",
  },
  {
      name: "Sukhothai",
      code: "64",
      country_code: "TH",
  },
  {
      name: "Suphan Buri",
      code: "72",
      country_code: "TH",
  },
  {
      name: "Surat Thani",
      code: "84",
      country_code: "TH",
  },
  {
      name: "Surin",
      code: "32",
      country_code: "TH",
  },
  {
      name: "Tak",
      code: "63",
      country_code: "TH",
  },
  {
      name: "Trang",
      code: "92",
      country_code: "TH",
  },
  {
      name: "Trat",
      code: "23",
      country_code: "TH",
  },
  {
      name: "Ubon Ratchathani",
      code: "34",
      country_code: "TH",
  },
  {
      name: "Udon Thani",
      code: "41",
      country_code: "TH",
  },
  {
      name: "Uthai Thani",
      code: "61",
      country_code: "TH",
  },
  {
      name: "Uttaradit",
      code: "53",
      country_code: "TH",
  },
  {
      name: "Yala",
      code: "95",
      country_code: "TH",
  },
  {
      name: "Yasothon",
      code: "35",
      country_code: "TH",
  },
  {
      name: "Centrale Region",
      code: "C",
      country_code: "TG",
  },
  {
      name: "Kara Region",
      code: "K",
      country_code: "TG",
  },
  {
      name: "Maritime",
      code: "M",
      country_code: "TG",
  },
  {
      name: "Plateaux Region",
      code: "P",
      country_code: "TG",
  },
  {
      name: "Savanes Region",
      code: "S",
      country_code: "TG",
  },
  {
      name: "Haʻapai",
      code: "02",
      country_code: "TO",
  },
  {
      name: "ʻEua",
      code: "01",
      country_code: "TO",
  },
  {
      name: "Niuas",
      code: "03",
      country_code: "TO",
  },
  {
      name: "Tongatapu",
      code: "04",
      country_code: "TO",
  },
  {
      name: "Vavaʻu",
      code: "05",
      country_code: "TO",
  },
  {
      name: "Arima",
      code: "ARI",
      country_code: "TT",
  },
  {
      name: "Chaguanas",
      code: "CHA",
      country_code: "TT",
  },
  {
      name: "Couva-Tabaquite-Talparo Regional Corporation",
      code: "CTT",
      country_code: "TT",
  },
  {
      name: "Diego Martin Regional Corporation",
      code: "DMN",
      country_code: "TT",
  },
  {
      name: "Eastern Tobago",
      code: "ETO",
      country_code: "TT",
  },
  {
      name: "Penal-Debe Regional Corporation",
      code: "PED",
      country_code: "TT",
  },
  {
      name: "Point Fortin",
      code: "PTF",
      country_code: "TT",
  },
  {
      name: "Port of Spain",
      code: "POS",
      country_code: "TT",
  },
  {
      name: "Princes Town Regional Corporation",
      code: "PRT",
      country_code: "TT",
  },
  {
      name: "Rio Claro-Mayaro Regional Corporation",
      code: "MRC",
      country_code: "TT",
  },
  {
      name: "San Fernando",
      code: "SFO",
      country_code: "TT",
  },
  {
      name: "San Juan-Laventille Regional Corporation",
      code: "SJL",
      country_code: "TT",
  },
  {
      name: "Sangre Grande Regional Corporation",
      code: "SGE",
      country_code: "TT",
  },
  {
      name: "Siparia Regional Corporation",
      code: "SIP",
      country_code: "TT",
  },
  {
      name: "Tunapuna-Piarco Regional Corporation",
      code: "TUP",
      country_code: "TT",
  },
  {
      name: "Western Tobago",
      code: "WTO",
      country_code: "TT",
  },
  {
      name: "Ariana Governorate",
      code: "12",
      country_code: "TN",
  },
  {
      name: "Ben Arous Governorate",
      code: "13",
      country_code: "TN",
  },
  {
      name: "Bizerte Governorate",
      code: "23",
      country_code: "TN",
  },
  {
      name: "Gabès Governorate",
      code: "81",
      country_code: "TN",
  },
  {
      name: "Gafsa Governorate",
      code: "71",
      country_code: "TN",
  },
  {
      name: "Jendouba Governorate",
      code: "32",
      country_code: "TN",
  },
  {
      name: "Kairouan Governorate",
      code: "41",
      country_code: "TN",
  },
  {
      name: "Kasserine Governorate",
      code: "42",
      country_code: "TN",
  },
  {
      name: "Kassrine",
      code: "31",
      country_code: "TN",
  },
  {
      name: "Kebili Governorate",
      code: "73",
      country_code: "TN",
  },
  {
      name: "Kef Governorate",
      code: "33",
      country_code: "TN",
  },
  {
      name: "Mahdia Governorate",
      code: "53",
      country_code: "TN",
  },
  {
      name: "Manouba Governorate",
      code: "14",
      country_code: "TN",
  },
  {
      name: "Medenine Governorate",
      code: "82",
      country_code: "TN",
  },
  {
      name: "Monastir Governorate",
      code: "52",
      country_code: "TN",
  },
  {
      name: "Sfax Governorate",
      code: "61",
      country_code: "TN",
  },
  {
      name: "Sidi Bouzid Governorate",
      code: "43",
      country_code: "TN",
  },
  {
      name: "Siliana Governorate",
      code: "34",
      country_code: "TN",
  },
  {
      name: "Sousse Governorate",
      code: "51",
      country_code: "TN",
  },
  {
      name: "Tataouine Governorate",
      code: "83",
      country_code: "TN",
  },
  {
      name: "Tozeur Governorate",
      code: "72",
      country_code: "TN",
  },
  {
      name: "Tunis Governorate",
      code: "11",
      country_code: "TN",
  },
  {
      name: "Zaghouan Governorate",
      code: "22",
      country_code: "TN",
  },
  {
      name: "Adana Province",
      code: "01",
      country_code: "TR",
  },
  {
      name: "Adıyaman Province",
      code: "02",
      country_code: "TR",
  },
  {
      name: "Afyonkarahisar Province",
      code: "03",
      country_code: "TR",
  },
  {
      name: "Ağrı Province",
      code: "04",
      country_code: "TR",
  },
  {
      name: "Aksaray Province",
      code: "68",
      country_code: "TR",
  },
  {
      name: "Amasya Province",
      code: "05",
      country_code: "TR",
  },
  {
      name: "Ankara Province",
      code: "06",
      country_code: "TR",
  },
  {
      name: "Antalya Province",
      code: "07",
      country_code: "TR",
  },
  {
      name: "Ardahan Province",
      code: "75",
      country_code: "TR",
  },
  {
      name: "Artvin Province",
      code: "08",
      country_code: "TR",
  },
  {
      name: "Aydın Province",
      code: "09",
      country_code: "TR",
  },
  {
      name: "Balıkesir Province",
      code: "10",
      country_code: "TR",
  },
  {
      name: "Bartın Province",
      code: "74",
      country_code: "TR",
  },
  {
      name: "Batman Province",
      code: "72",
      country_code: "TR",
  },
  {
      name: "Bayburt Province",
      code: "69",
      country_code: "TR",
  },
  {
      name: "Bilecik Province",
      code: "11",
      country_code: "TR",
  },
  {
      name: "Bingöl Province",
      code: "12",
      country_code: "TR",
  },
  {
      name: "Bitlis Province",
      code: "13",
      country_code: "TR",
  },
  {
      name: "Bolu Province",
      code: "14",
      country_code: "TR",
  },
  {
      name: "Burdur Province",
      code: "15",
      country_code: "TR",
  },
  {
      name: "Bursa Province",
      code: "16",
      country_code: "TR",
  },
  {
      name: "Çanakkale Province",
      code: "17",
      country_code: "TR",
  },
  {
      name: "Çankırı Province",
      code: "18",
      country_code: "TR",
  },
  {
      name: "Çorum Province",
      code: "19",
      country_code: "TR",
  },
  {
      name: "Denizli Province",
      code: "20",
      country_code: "TR",
  },
  {
      name: "Diyarbakır Province",
      code: "21",
      country_code: "TR",
  },
  {
      name: "Düzce Province",
      code: "81",
      country_code: "TR",
  },
  {
      name: "Edirne Province",
      code: "22",
      country_code: "TR",
  },
  {
      name: "Elazığ Province",
      code: "23",
      country_code: "TR",
  },
  {
      name: "Erzincan Province",
      code: "24",
      country_code: "TR",
  },
  {
      name: "Erzurum Province",
      code: "25",
      country_code: "TR",
  },
  {
      name: "Eskişehir Province",
      code: "26",
      country_code: "TR",
  },
  {
      name: "Gaziantep Province",
      code: "27",
      country_code: "TR",
  },
  {
      name: "Giresun Province",
      code: "28",
      country_code: "TR",
  },
  {
      name: "Gümüşhane Province",
      code: "29",
      country_code: "TR",
  },
  {
      name: "Hakkâri Province",
      code: "30",
      country_code: "TR",
  },
  {
      name: "Hatay Province",
      code: "31",
      country_code: "TR",
  },
  {
      name: "Iğdır Province",
      code: "76",
      country_code: "TR",
  },
  {
      name: "Isparta Province",
      code: "32",
      country_code: "TR",
  },
  {
      name: "Istanbul Province",
      code: "34",
      country_code: "TR",
  },
  {
      name: "İzmir Province",
      code: "35",
      country_code: "TR",
  },
  {
      name: "Kahramanmaraş Province",
      code: "46",
      country_code: "TR",
  },
  {
      name: "Karabük Province",
      code: "78",
      country_code: "TR",
  },
  {
      name: "Karaman Province",
      code: "70",
      country_code: "TR",
  },
  {
      name: "Kars Province",
      code: "36",
      country_code: "TR",
  },
  {
      name: "Kastamonu Province",
      code: "37",
      country_code: "TR",
  },
  {
      name: "Kayseri Province",
      code: "38",
      country_code: "TR",
  },
  {
      name: "Kilis Province",
      code: "79",
      country_code: "TR",
  },
  {
      name: "Kırıkkale Province",
      code: "71",
      country_code: "TR",
  },
  {
      name: "Kırklareli Province",
      code: "39",
      country_code: "TR",
  },
  {
      name: "Kırşehir Province",
      code: "40",
      country_code: "TR",
  },
  {
      name: "Kocaeli Province",
      code: "41",
      country_code: "TR",
  },
  {
      name: "Konya Province",
      code: "42",
      country_code: "TR",
  },
  {
      name: "Kütahya Province",
      code: "43",
      country_code: "TR",
  },
  {
      name: "Malatya Province",
      code: "44",
      country_code: "TR",
  },
  {
      name: "Manisa Province",
      code: "45",
      country_code: "TR",
  },
  {
      name: "Mardin Province",
      code: "47",
      country_code: "TR",
  },
  {
      name: "Mersin Province",
      code: "33",
      country_code: "TR",
  },
  {
      name: "Muğla Province",
      code: "48",
      country_code: "TR",
  },
  {
      name: "Muş Province",
      code: "49",
      country_code: "TR",
  },
  {
      name: "Nevşehir Province",
      code: "50",
      country_code: "TR",
  },
  {
      name: "Niğde Province",
      code: "51",
      country_code: "TR",
  },
  {
      name: "Ordu Province",
      code: "52",
      country_code: "TR",
  },
  {
      name: "Osmaniye Province",
      code: "80",
      country_code: "TR",
  },
  {
      name: "Rize Province",
      code: "53",
      country_code: "TR",
  },
  {
      name: "Sakarya Province",
      code: "54",
      country_code: "TR",
  },
  {
      name: "Samsun Province",
      code: "55",
      country_code: "TR",
  },
  {
      name: "Şanlıurfa Province",
      code: "63",
      country_code: "TR",
  },
  {
      name: "Siirt Province",
      code: "56",
      country_code: "TR",
  },
  {
      name: "Sinop Province",
      code: "57",
      country_code: "TR",
  },
  {
      name: "Sivas Province",
      code: "58",
      country_code: "TR",
  },
  {
      name: "Şırnak Province",
      code: "73",
      country_code: "TR",
  },
  {
      name: "Tekirdağ Province",
      code: "59",
      country_code: "TR",
  },
  {
      name: "Tokat Province",
      code: "60",
      country_code: "TR",
  },
  {
      name: "Trabzon Province",
      code: "61",
      country_code: "TR",
  },
  {
      name: "Tunceli Province",
      code: "62",
      country_code: "TR",
  },
  {
      name: "Uşak Province",
      code: "64",
      country_code: "TR",
  },
  {
      name: "Van Province",
      code: "65",
      country_code: "TR",
  },
  {
      name: "Yalova Province",
      code: "77",
      country_code: "TR",
  },
  {
      name: "Yozgat Province",
      code: "66",
      country_code: "TR",
  },
  {
      name: "Zonguldak Province",
      code: "67",
      country_code: "TR",
  },
  {
      name: "Ahal Region",
      code: "A",
      country_code: "TM",
  },
  {
      name: "Ashgabat",
      code: "S",
      country_code: "TM",
  },
  {
      name: "Balkan Region",
      code: "B",
      country_code: "TM",
  },
  {
      name: "Daşoguz Region",
      code: "D",
      country_code: "TM",
  },
  {
      name: "Lebap Region",
      code: "L",
      country_code: "TM",
  },
  {
      name: "Mary Region",
      code: "M",
      country_code: "TM",
  },
  {
      name: "Funafuti",
      code: "FUN",
      country_code: "TV",
  },
  {
      name: "Nanumanga",
      code: "NMG",
      country_code: "TV",
  },
  {
      name: "Nanumea",
      code: "NMA",
      country_code: "TV",
  },
  {
      name: "Niutao Island Council",
      code: "NIT",
      country_code: "TV",
  },
  {
      name: "Nui",
      code: "NUI",
      country_code: "TV",
  },
  {
      name: "Nukufetau",
      code: "NKF",
      country_code: "TV",
  },
  {
      name: "Nukulaelae",
      code: "NKL",
      country_code: "TV",
  },
  {
      name: "Vaitupu",
      code: "VAI",
      country_code: "TV",
  },
  {
      name: "Abim District",
      code: "314",
      country_code: "UG",
  },
  {
      name: "Adjumani District",
      code: "301",
      country_code: "UG",
  },
  {
      name: "Agago District",
      code: "322",
      country_code: "UG",
  },
  {
      name: "Alebtong District",
      code: "323",
      country_code: "UG",
  },
  {
      name: "Amolatar District",
      code: "315",
      country_code: "UG",
  },
  {
      name: "Amudat District",
      code: "324",
      country_code: "UG",
  },
  {
      name: "Amuria District",
      code: "216",
      country_code: "UG",
  },
  {
      name: "Amuru District",
      code: "316",
      country_code: "UG",
  },
  {
      name: "Apac District",
      code: "302",
      country_code: "UG",
  },
  {
      name: "Arua District",
      code: "303",
      country_code: "UG",
  },
  {
      name: "Budaka District",
      code: "217",
      country_code: "UG",
  },
  {
      name: "Bududa District",
      code: "218",
      country_code: "UG",
  },
  {
      name: "Bugiri District",
      code: "201",
      country_code: "UG",
  },
  {
      name: "Buhweju District",
      code: "420",
      country_code: "UG",
  },
  {
      name: "Buikwe District",
      code: "117",
      country_code: "UG",
  },
  {
      name: "Bukedea District",
      code: "219",
      country_code: "UG",
  },
  {
      name: "Bukomansimbi District",
      code: "118",
      country_code: "UG",
  },
  {
      name: "Bukwo District",
      code: "220",
      country_code: "UG",
  },
  {
      name: "Bulambuli District",
      code: "225",
      country_code: "UG",
  },
  {
      name: "Buliisa District",
      code: "416",
      country_code: "UG",
  },
  {
      name: "Bundibugyo District",
      code: "401",
      country_code: "UG",
  },
  {
      name: "Bunyangabu District",
      code: "430",
      country_code: "UG",
  },
  {
      name: "Bushenyi District",
      code: "402",
      country_code: "UG",
  },
  {
      name: "Busia District",
      code: "202",
      country_code: "UG",
  },
  {
      name: "Butaleja District",
      code: "221",
      country_code: "UG",
  },
  {
      name: "Butambala District",
      code: "119",
      country_code: "UG",
  },
  {
      name: "Butebo District",
      code: "233",
      country_code: "UG",
  },
  {
      name: "Buvuma District",
      code: "120",
      country_code: "UG",
  },
  {
      name: "Buyende District",
      code: "226",
      country_code: "UG",
  },
  {
      name: "Central Region",
      code: "C",
      country_code: "UG",
  },
  {
      name: "Dokolo District",
      code: "317",
      country_code: "UG",
  },
  {
      name: "Eastern Region",
      code: "E",
      country_code: "UG",
  },
  {
      name: "Gomba District",
      code: "121",
      country_code: "UG",
  },
  {
      name: "Gulu District",
      code: "304",
      country_code: "UG",
  },
  {
      name: "Ibanda District",
      code: "417",
      country_code: "UG",
  },
  {
      name: "Iganga District",
      code: "203",
      country_code: "UG",
  },
  {
      name: "Isingiro District",
      code: "418",
      country_code: "UG",
  },
  {
      name: "Jinja District",
      code: "204",
      country_code: "UG",
  },
  {
      name: "Kaabong District",
      code: "318",
      country_code: "UG",
  },
  {
      name: "Kabale District",
      code: "404",
      country_code: "UG",
  },
  {
      name: "Kabarole District",
      code: "405",
      country_code: "UG",
  },
  {
      name: "Kaberamaido District",
      code: "213",
      country_code: "UG",
  },
  {
      name: "Kagadi District",
      code: "427",
      country_code: "UG",
  },
  {
      name: "Kakumiro District",
      code: "428",
      country_code: "UG",
  },
  {
      name: "Kalangala District",
      code: "101",
      country_code: "UG",
  },
  {
      name: "Kaliro District",
      code: "222",
      country_code: "UG",
  },
  {
      name: "Kalungu District",
      code: "122",
      country_code: "UG",
  },
  {
      name: "Kampala District",
      code: "102",
      country_code: "UG",
  },
  {
      name: "Kamuli District",
      code: "205",
      country_code: "UG",
  },
  {
      name: "Kamwenge District",
      code: "413",
      country_code: "UG",
  },
  {
      name: "Kanungu District",
      code: "414",
      country_code: "UG",
  },
  {
      name: "Kapchorwa District",
      code: "206",
      country_code: "UG",
  },
  {
      name: "Kasese District",
      code: "406",
      country_code: "UG",
  },
  {
      name: "Katakwi District",
      code: "207",
      country_code: "UG",
  },
  {
      name: "Kayunga District",
      code: "112",
      country_code: "UG",
  },
  {
      name: "Kibaale District",
      code: "407",
      country_code: "UG",
  },
  {
      name: "Kiboga District",
      code: "103",
      country_code: "UG",
  },
  {
      name: "Kibuku District",
      code: "227",
      country_code: "UG",
  },
  {
      name: "Kiruhura District",
      code: "419",
      country_code: "UG",
  },
  {
      name: "Kiryandongo District",
      code: "421",
      country_code: "UG",
  },
  {
      name: "Kisoro District",
      code: "408",
      country_code: "UG",
  },
  {
      name: "Kitgum District",
      code: "305",
      country_code: "UG",
  },
  {
      name: "Koboko District",
      code: "319",
      country_code: "UG",
  },
  {
      name: "Kole District",
      code: "325",
      country_code: "UG",
  },
  {
      name: "Kotido District",
      code: "306",
      country_code: "UG",
  },
  {
      name: "Kumi District",
      code: "208",
      country_code: "UG",
  },
  {
      name: "Kween District",
      code: "228",
      country_code: "UG",
  },
  {
      name: "Kyankwanzi District",
      code: "123",
      country_code: "UG",
  },
  {
      name: "Kyegegwa District",
      code: "422",
      country_code: "UG",
  },
  {
      name: "Kyenjojo District",
      code: "415",
      country_code: "UG",
  },
  {
      name: "Kyotera District",
      code: "125",
      country_code: "UG",
  },
  {
      name: "Lamwo District",
      code: "326",
      country_code: "UG",
  },
  {
      name: "Lira District",
      code: "307",
      country_code: "UG",
  },
  {
      name: "Luuka District",
      code: "229",
      country_code: "UG",
  },
  {
      name: "Luwero District",
      code: "104",
      country_code: "UG",
  },
  {
      name: "Lwengo District",
      code: "124",
      country_code: "UG",
  },
  {
      name: "Lyantonde District",
      code: "114",
      country_code: "UG",
  },
  {
      name: "Manafwa District",
      code: "223",
      country_code: "UG",
  },
  {
      name: "Maracha District",
      code: "320",
      country_code: "UG",
  },
  {
      name: "Masaka District",
      code: "105",
      country_code: "UG",
  },
  {
      name: "Masindi District",
      code: "409",
      country_code: "UG",
  },
  {
      name: "Mayuge District",
      code: "214",
      country_code: "UG",
  },
  {
      name: "Mbale District",
      code: "209",
      country_code: "UG",
  },
  {
      name: "Mbarara District",
      code: "410",
      country_code: "UG",
  },
  {
      name: "Mitooma District",
      code: "423",
      country_code: "UG",
  },
  {
      name: "Mityana District",
      code: "115",
      country_code: "UG",
  },
  {
      name: "Moroto District",
      code: "308",
      country_code: "UG",
  },
  {
      name: "Moyo District",
      code: "309",
      country_code: "UG",
  },
  {
      name: "Mpigi District",
      code: "106",
      country_code: "UG",
  },
  {
      name: "Mubende District",
      code: "107",
      country_code: "UG",
  },
  {
      name: "Mukono District",
      code: "108",
      country_code: "UG",
  },
  {
      name: "Nakapiripirit District",
      code: "311",
      country_code: "UG",
  },
  {
      name: "Nakaseke District",
      code: "116",
      country_code: "UG",
  },
  {
      name: "Nakasongola District",
      code: "109",
      country_code: "UG",
  },
  {
      name: "Namayingo District",
      code: "230",
      country_code: "UG",
  },
  {
      name: "Namisindwa District",
      code: "234",
      country_code: "UG",
  },
  {
      name: "Namutumba District",
      code: "224",
      country_code: "UG",
  },
  {
      name: "Napak District",
      code: "327",
      country_code: "UG",
  },
  {
      name: "Nebbi District",
      code: "310",
      country_code: "UG",
  },
  {
      name: "Ngora District",
      code: "231",
      country_code: "UG",
  },
  {
      name: "Northern Region",
      code: "N",
      country_code: "UG",
  },
  {
      name: "Ntoroko District",
      code: "424",
      country_code: "UG",
  },
  {
      name: "Ntungamo District",
      code: "411",
      country_code: "UG",
  },
  {
      name: "Nwoya District",
      code: "328",
      country_code: "UG",
  },
  {
      name: "Omoro District",
      code: "331",
      country_code: "UG",
  },
  {
      name: "Otuke District",
      code: "329",
      country_code: "UG",
  },
  {
      name: "Oyam District",
      code: "321",
      country_code: "UG",
  },
  {
      name: "Pader District",
      code: "312",
      country_code: "UG",
  },
  {
      name: "Pakwach District",
      code: "332",
      country_code: "UG",
  },
  {
      name: "Pallisa District",
      code: "210",
      country_code: "UG",
  },
  {
      name: "Rakai District",
      code: "110",
      country_code: "UG",
  },
  {
      name: "Rubanda District",
      code: "429",
      country_code: "UG",
  },
  {
      name: "Rubirizi District",
      code: "425",
      country_code: "UG",
  },
  {
      name: "Rukiga District",
      code: "431",
      country_code: "UG",
  },
  {
      name: "Rukungiri District",
      code: "412",
      country_code: "UG",
  },
  {
      name: "Sembabule District",
      code: "111",
      country_code: "UG",
  },
  {
      name: "Serere District",
      code: "232",
      country_code: "UG",
  },
  {
      name: "Sheema District",
      code: "426",
      country_code: "UG",
  },
  {
      name: "Sironko District",
      code: "215",
      country_code: "UG",
  },
  {
      name: "Soroti District",
      code: "211",
      country_code: "UG",
  },
  {
      name: "Tororo District",
      code: "212",
      country_code: "UG",
  },
  {
      name: "Wakiso District",
      code: "113",
      country_code: "UG",
  },
  {
      name: "Western Region",
      code: "W",
      country_code: "UG",
  },
  {
      name: "Yumbe District",
      code: "313",
      country_code: "UG",
  },
  {
      name: "Zombo District",
      code: "330",
      country_code: "UG",
  },
  {
      name: "Autonomous Republic of Crimea",
      code: "43",
      country_code: "UA",
  },
  {
      name: "Cherkasy Oblast",
      code: "71",
      country_code: "UA",
  },
  {
      name: "Chernihiv Oblast",
      code: "74",
      country_code: "UA",
  },
  {
      name: "Chernivtsi Oblast",
      code: "77",
      country_code: "UA",
  },
  {
      name: "Dnipropetrovsk Oblast",
      code: "12",
      country_code: "UA",
  },
  {
      name: "Donetsk Oblast",
      code: "14",
      country_code: "UA",
  },
  {
      name: "Ivano-Frankivsk Oblast",
      code: "26",
      country_code: "UA",
  },
  {
      name: "Kharkiv Oblast",
      code: "63",
      country_code: "UA",
  },
  {
      name: "Kherson Oblast",
      code: "65",
      country_code: "UA",
  },
  {
      name: "Khmelnytsky Oblast",
      code: "68",
      country_code: "UA",
  },
  {
      name: "Kiev",
      code: "30",
      country_code: "UA",
  },
  {
      name: "Kirovohrad Oblast",
      code: "35",
      country_code: "UA",
  },
  {
      name: "Kyiv Oblast",
      code: "32",
      country_code: "UA",
  },
  {
      name: "Luhansk Oblast",
      code: "09",
      country_code: "UA",
  },
  {
      name: "Lviv Oblast",
      code: "46",
      country_code: "UA",
  },
  {
      name: "Mykolaiv Oblast",
      code: "48",
      country_code: "UA",
  },
  {
      name: "Odessa Oblast",
      code: "51",
      country_code: "UA",
  },
  {
      name: "Rivne Oblast",
      code: "56",
      country_code: "UA",
  },
  {
      name: "Sumy Oblast",
      code: "59",
      country_code: "UA",
  },
  {
      name: "Ternopil Oblast",
      code: "61",
      country_code: "UA",
  },
  {
      name: "Vinnytsia Oblast",
      code: "05",
      country_code: "UA",
  },
  {
      name: "Volyn Oblast",
      code: "07",
      country_code: "UA",
  },
  {
      name: "Zakarpattia Oblast",
      code: "21",
      country_code: "UA",
  },
  {
      name: "Zaporizhzhya Oblast",
      code: "23",
      country_code: "UA",
  },
  {
      name: "Zhytomyr Oblast",
      code: "18",
      country_code: "UA",
  },
  {
      name: "Abu Dhabi Emirate",
      code: "AZ",
      country_code: "AE",
  },
  {
      name: "Ajman Emirate",
      code: "AJ",
      country_code: "AE",
  },
  {
      name: "Dubai",
      code: "DU",
      country_code: "AE",
  },
  {
      name: "Fujairah",
      code: "FU",
      country_code: "AE",
  },
  {
      name: "Ras al-Khaimah",
      code: "RK",
      country_code: "AE",
  },
  {
      name: "Sharjah Emirate",
      code: "SH",
      country_code: "AE",
  },
  {
      name: "Umm al-Quwain",
      code: "UQ",
      country_code: "AE",
  },
  {
      name: "Aberdeen",
      code: "ABE",
      country_code: "GB",
  },
  {
      name: "Aberdeenshire",
      code: "ABD",
      country_code: "GB",
  },
  {
      name: "Angus",
      code: "ANS",
      country_code: "GB",
  },
  {
      name: "Antrim",
      code: "ANT",
      country_code: "GB",
  },
  {
      name: "Antrim and Newtownabbey",
      code: "ANN",
      country_code: "GB",
  },
  {
      name: "Ards",
      code: "ARD",
      country_code: "GB",
  },
  {
      name: "Ards and North Down",
      code: "AND",
      country_code: "GB",
  },
  {
      name: "Argyll and Bute",
      code: "AGB",
      country_code: "GB",
  },
  {
      name: "Armagh City and District Council",
      code: "ARM",
      country_code: "GB",
  },
  {
      name: "Armagh, Banbridge and Craigavon",
      code: "ABC",
      country_code: "GB",
  },
  {
      name: "Ascension Island",
      code: "SH-AC",
      country_code: "GB",
  },
  {
      name: "Ballymena Borough",
      code: "BLA",
      country_code: "GB",
  },
  {
      name: "Ballymoney",
      code: "BLY",
      country_code: "GB",
  },
  {
      name: "Banbridge",
      code: "BNB",
      country_code: "GB",
  },
  {
      name: "Barnsley",
      code: "BNS",
      country_code: "GB",
  },
  {
      name: "Bath and North East Somerset",
      code: "BAS",
      country_code: "GB",
  },
  {
      name: "Bedford",
      code: "BDF",
      country_code: "GB",
  },
  {
      name: "Belfast district",
      code: "BFS",
      country_code: "GB",
  },
  {
      name: "Birmingham",
      code: "BIR",
      country_code: "GB",
  },
  {
      name: "Blackburn with Darwen",
      code: "BBD",
      country_code: "GB",
  },
  {
      name: "Blackpool",
      code: "BPL",
      country_code: "GB",
  },
  {
      name: "Blaenau Gwent County Borough",
      code: "BGW",
      country_code: "GB",
  },
  {
      name: "Bolton",
      code: "BOL",
      country_code: "GB",
  },
  {
      name: "Bournemouth",
      code: "BMH",
      country_code: "GB",
  },
  {
      name: "Bracknell Forest",
      code: "BRC",
      country_code: "GB",
  },
  {
      name: "Bradford",
      code: "BRD",
      country_code: "GB",
  },
  {
      name: "Bridgend County Borough",
      code: "BGE",
      country_code: "GB",
  },
  {
      name: "Brighton and Hove",
      code: "BNH",
      country_code: "GB",
  },
  {
      name: "Buckinghamshire",
      code: "BKM",
      country_code: "GB",
  },
  {
      name: "Bury",
      code: "BUR",
      country_code: "GB",
  },
  {
      name: "Caerphilly County Borough",
      code: "CAY",
      country_code: "GB",
  },
  {
      name: "Calderdale",
      code: "CLD",
      country_code: "GB",
  },
  {
      name: "Cambridgeshire",
      code: "CAM",
      country_code: "GB",
  },
  {
      name: "Carmarthenshire",
      code: "CMN",
      country_code: "GB",
  },
  {
      name: "Carrickfergus Borough Council",
      code: "CKF",
      country_code: "GB",
  },
  {
      name: "Castlereagh",
      code: "CSR",
      country_code: "GB",
  },
  {
      name: "Causeway Coast and Glens",
      code: "CCG",
      country_code: "GB",
  },
  {
      name: "Central Bedfordshire",
      code: "CBF",
      country_code: "GB",
  },
  {
      name: "Ceredigion",
      code: "CGN",
      country_code: "GB",
  },
  {
      name: "Cheshire East",
      code: "CHE",
      country_code: "GB",
  },
  {
      name: "Cheshire West and Chester",
      code: "CHW",
      country_code: "GB",
  },
  {
      name: "City and County of Cardiff",
      code: "CRF",
      country_code: "GB",
  },
  {
      name: "City and County of Swansea",
      code: "SWA",
      country_code: "GB",
  },
  {
      name: "City of Bristol",
      code: "BST",
      country_code: "GB",
  },
  {
      name: "City of Derby",
      code: "DER",
      country_code: "GB",
  },
  {
      name: "City of Kingston upon Hull",
      code: "KHL",
      country_code: "GB",
  },
  {
      name: "City of Leicester",
      code: "LCE",
      country_code: "GB",
  },
  {
      name: "City of London",
      code: "LND",
      country_code: "GB",
  },
  {
      name: "City of Nottingham",
      code: "NGM",
      country_code: "GB",
  },
  {
      name: "City of Peterborough",
      code: "PTE",
      country_code: "GB",
  },
  {
      name: "City of Plymouth",
      code: "PLY",
      country_code: "GB",
  },
  {
      name: "City of Portsmouth",
      code: "POR",
      country_code: "GB",
  },
  {
      name: "City of Southampton",
      code: "STH",
      country_code: "GB",
  },
  {
      name: "City of Stoke-on-Trent",
      code: "STE",
      country_code: "GB",
  },
  {
      name: "City of Sunderland",
      code: "SND",
      country_code: "GB",
  },
  {
      name: "City of Westminster",
      code: "WSM",
      country_code: "GB",
  },
  {
      name: "City of Wolverhampton",
      code: "WLV",
      country_code: "GB",
  },
  {
      name: "City of York",
      code: "YOR",
      country_code: "GB",
  },
  {
      name: "Clackmannanshire",
      code: "CLK",
      country_code: "GB",
  },
  {
      name: "Coleraine Borough Council",
      code: "CLR",
      country_code: "GB",
  },
  {
      name: "Conwy County Borough",
      code: "CWY",
      country_code: "GB",
  },
  {
      name: "Cookstown District Council",
      code: "CKT",
      country_code: "GB",
  },
  {
      name: "Cornwall",
      code: "CON",
      country_code: "GB",
  },
  {
      name: "County Durham",
      code: "DUR",
      country_code: "GB",
  },
  {
      name: "Coventry",
      code: "COV",
      country_code: "GB",
  },
  {
      name: "Craigavon Borough Council",
      code: "CGV",
      country_code: "GB",
  },
  {
      name: "Cumbria",
      code: "CMA",
      country_code: "GB",
  },
  {
      name: "Darlington",
      code: "DAL",
      country_code: "GB",
  },
  {
      name: "Denbighshire",
      code: "DEN",
      country_code: "GB",
  },
  {
      name: "Derbyshire",
      code: "DBY",
      country_code: "GB",
  },
  {
      name: "Derry City and Strabane",
      code: "DRS",
      country_code: "GB",
  },
  {
      name: "Derry City Council",
      code: "DRY",
      country_code: "GB",
  },
  {
      name: "Devon",
      code: "DEV",
      country_code: "GB",
  },
  {
      name: "Doncaster",
      code: "DNC",
      country_code: "GB",
  },
  {
      name: "Dorset",
      code: "DOR",
      country_code: "GB",
  },
  {
      name: "Down District Council",
      code: "DOW",
      country_code: "GB",
  },
  {
      name: "Dudley",
      code: "DUD",
      country_code: "GB",
  },
  {
      name: "Dumfries and Galloway",
      code: "DGY",
      country_code: "GB",
  },
  {
      name: "Dundee",
      code: "DND",
      country_code: "GB",
  },
  {
      name: "Dungannon and South Tyrone Borough Council",
      code: "DGN",
      country_code: "GB",
  },
  {
      name: "East Ayrshire",
      code: "EAY",
      country_code: "GB",
  },
  {
      name: "East Dunbartonshire",
      code: "EDU",
      country_code: "GB",
  },
  {
      name: "East Lothian",
      code: "ELN",
      country_code: "GB",
  },
  {
      name: "East Renfrewshire",
      code: "ERW",
      country_code: "GB",
  },
  {
      name: "East Riding of Yorkshire",
      code: "ERY",
      country_code: "GB",
  },
  {
      name: "East Sussex",
      code: "ESX",
      country_code: "GB",
  },
  {
      name: "Edinburgh",
      code: "EDH",
      country_code: "GB",
  },
  {
      name: "England",
      code: "ENG",
      country_code: "GB",
  },
  {
      name: "Essex",
      code: "ESS",
      country_code: "GB",
  },
  {
      name: "Falkirk",
      code: "FAL",
      country_code: "GB",
  },
  {
      name: "Fermanagh and Omagh",
      code: "FMO",
      country_code: "GB",
  },
  {
      name: "Fermanagh District Council",
      code: "FER",
      country_code: "GB",
  },
  {
      name: "Fife",
      code: "FIF",
      country_code: "GB",
  },
  {
      name: "Flintshire",
      code: "FLN",
      country_code: "GB",
  },
  {
      name: "Gateshead",
      code: "GAT",
      country_code: "GB",
  },
  {
      name: "Glasgow",
      code: "GLG",
      country_code: "GB",
  },
  {
      name: "Gloucestershire",
      code: "GLS",
      country_code: "GB",
  },
  {
      name: "Gwynedd",
      code: "GWN",
      country_code: "GB",
  },
  {
      name: "Halton",
      code: "HAL",
      country_code: "GB",
  },
  {
      name: "Hampshire",
      code: "HAM",
      country_code: "GB",
  },
  {
      name: "Hartlepool",
      code: "HPL",
      country_code: "GB",
  },
  {
      name: "Herefordshire",
      code: "HEF",
      country_code: "GB",
  },
  {
      name: "Hertfordshire",
      code: "HRT",
      country_code: "GB",
  },
  {
      name: "Highland",
      code: "HLD",
      country_code: "GB",
  },
  {
      name: "Inverclyde",
      code: "IVC",
      country_code: "GB",
  },
  {
      name: "Isle of Wight",
      code: "IOW",
      country_code: "GB",
  },
  {
      name: "Isles of Scilly",
      code: "IOS",
      country_code: "GB",
  },
  {
      name: "Kent",
      code: "KEN",
      country_code: "GB",
  },
  {
      name: "Kirklees",
      code: "KIR",
      country_code: "GB",
  },
  {
      name: "Knowsley",
      code: "KWL",
      country_code: "GB",
  },
  {
      name: "Lancashire",
      code: "LAN",
      country_code: "GB",
  },
  {
      name: "Larne Borough Council",
      code: "LRN",
      country_code: "GB",
  },
  {
      name: "Leeds",
      code: "LDS",
      country_code: "GB",
  },
  {
      name: "Leicestershire",
      code: "LEC",
      country_code: "GB",
  },
  {
      name: "Limavady Borough Council",
      code: "LMV",
      country_code: "GB",
  },
  {
      name: "Lincolnshire",
      code: "LIN",
      country_code: "GB",
  },
  {
      name: "Lisburn and Castlereagh",
      code: "LBC",
      country_code: "GB",
  },
  {
      name: "Lisburn City Council",
      code: "LSB",
      country_code: "GB",
  },
  {
      name: "Liverpool",
      code: "LIV",
      country_code: "GB",
  },
  {
      name: "London Borough of Barking and Dagenham",
      code: "BDG",
      country_code: "GB",
  },
  {
      name: "London Borough of Barnet",
      code: "BNE",
      country_code: "GB",
  },
  {
      name: "London Borough of Bexley",
      code: "BEX",
      country_code: "GB",
  },
  {
      name: "London Borough of Brent",
      code: "BEN",
      country_code: "GB",
  },
  {
      name: "London Borough of Bromley",
      code: "BRY",
      country_code: "GB",
  },
  {
      name: "London Borough of Camden",
      code: "CMD",
      country_code: "GB",
  },
  {
      name: "London Borough of Croydon",
      code: "CRY",
      country_code: "GB",
  },
  {
      name: "London Borough of Ealing",
      code: "EAL",
      country_code: "GB",
  },
  {
      name: "London Borough of Enfield",
      code: "ENF",
      country_code: "GB",
  },
  {
      name: "London Borough of Hackney",
      code: "HCK",
      country_code: "GB",
  },
  {
      name: "London Borough of Hammersmith and Fulham",
      code: "HMF",
      country_code: "GB",
  },
  {
      name: "London Borough of Haringey",
      code: "HRY",
      country_code: "GB",
  },
  {
      name: "London Borough of Harrow",
      code: "HRW",
      country_code: "GB",
  },
  {
      name: "London Borough of Havering",
      code: "HAV",
      country_code: "GB",
  },
  {
      name: "London Borough of Hillingdon",
      code: "HIL",
      country_code: "GB",
  },
  {
      name: "London Borough of Hounslow",
      code: "HNS",
      country_code: "GB",
  },
  {
      name: "London Borough of Islington",
      code: "ISL",
      country_code: "GB",
  },
  {
      name: "London Borough of Lambeth",
      code: "LBH",
      country_code: "GB",
  },
  {
      name: "London Borough of Lewisham",
      code: "LEW",
      country_code: "GB",
  },
  {
      name: "London Borough of Merton",
      code: "MRT",
      country_code: "GB",
  },
  {
      name: "London Borough of Newham",
      code: "NWM",
      country_code: "GB",
  },
  {
      name: "London Borough of Redbridge",
      code: "RDB",
      country_code: "GB",
  },
  {
      name: "London Borough of Richmond upon Thames",
      code: "RIC",
      country_code: "GB",
  },
  {
      name: "London Borough of Southwark",
      code: "SWK",
      country_code: "GB",
  },
  {
      name: "London Borough of Sutton",
      code: "STN",
      country_code: "GB",
  },
  {
      name: "London Borough of Tower Hamlets",
      code: "TWH",
      country_code: "GB",
  },
  {
      name: "London Borough of Waltham Forest",
      code: "WFT",
      country_code: "GB",
  },
  {
      name: "London Borough of Wandsworth",
      code: "WND",
      country_code: "GB",
  },
  {
      name: "Magherafelt District Council",
      code: "MFT",
      country_code: "GB",
  },
  {
      name: "Manchester",
      code: "MAN",
      country_code: "GB",
  },
  {
      name: "Medway",
      code: "MDW",
      country_code: "GB",
  },
  {
      name: "Merthyr Tydfil County Borough",
      code: "MTY",
      country_code: "GB",
  },
  {
      name: "Metropolitan Borough of Wigan",
      code: "WGN",
      country_code: "GB",
  },
  {
      name: "Mid and East Antrim",
      code: "MEA",
      country_code: "GB",
  },
  {
      name: "Mid Ulster",
      code: "MUL",
      country_code: "GB",
  },
  {
      name: "Middlesbrough",
      code: "MDB",
      country_code: "GB",
  },
  {
      name: "Midlothian",
      code: "MLN",
      country_code: "GB",
  },
  {
      name: "Milton Keynes",
      code: "MIK",
      country_code: "GB",
  },
  {
      name: "Monmouthshire",
      code: "MON",
      country_code: "GB",
  },
  {
      name: "Moray",
      code: "MRY",
      country_code: "GB",
  },
  {
      name: "Moyle District Council",
      code: "MYL",
      country_code: "GB",
  },
  {
      name: "Neath Port Talbot County Borough",
      code: "NTL",
      country_code: "GB",
  },
  {
      name: "Newcastle upon Tyne",
      code: "NET",
      country_code: "GB",
  },
  {
      name: "Newport",
      code: "NWP",
      country_code: "GB",
  },
  {
      name: "Newry and Mourne District Council",
      code: "NYM",
      country_code: "GB",
  },
  {
      name: "Newry, Mourne and Down",
      code: "NMD",
      country_code: "GB",
  },
  {
      name: "Newtownabbey Borough Council",
      code: "NTA",
      country_code: "GB",
  },
  {
      name: "Norfolk",
      code: "NFK",
      country_code: "GB",
  },
  {
      name: "North Ayrshire",
      code: "NAY",
      country_code: "GB",
  },
  {
      name: "North Down Borough Council",
      code: "NDN",
      country_code: "GB",
  },
  {
      name: "North East Lincolnshire",
      code: "NEL",
      country_code: "GB",
  },
  {
      name: "North Lanarkshire",
      code: "NLK",
      country_code: "GB",
  },
  {
      name: "North Lincolnshire",
      code: "NLN",
      country_code: "GB",
  },
  {
      name: "North Somerset",
      code: "NSM",
      country_code: "GB",
  },
  {
      name: "North Tyneside",
      code: "NTY",
      country_code: "GB",
  },
  {
      name: "North Yorkshire",
      code: "NYK",
      country_code: "GB",
  },
  {
      name: "Northamptonshire",
      code: "NTH",
      country_code: "GB",
  },
  {
      name: "Northern Ireland",
      code: "NIR",
      country_code: "GB",
  },
  {
      name: "Northumberland",
      code: "NBL",
      country_code: "GB",
  },
  {
      name: "Nottinghamshire",
      code: "NTT",
      country_code: "GB",
  },
  {
      name: "Oldham",
      code: "OLD",
      country_code: "GB",
  },
  {
      name: "Omagh District Council",
      code: "OMH",
      country_code: "GB",
  },
  {
      name: "Orkney Islands",
      code: "ORK",
      country_code: "GB",
  },
  {
      name: "Outer Hebrides",
      code: "ELS",
      country_code: "GB",
  },
  {
      name: "Oxfordshire",
      code: "OXF",
      country_code: "GB",
  },
  {
      name: "Pembrokeshire",
      code: "PEM",
      country_code: "GB",
  },
  {
      name: "Perth and Kinross",
      code: "PKN",
      country_code: "GB",
  },
  {
      name: "Poole",
      code: "POL",
      country_code: "GB",
  },
  {
      name: "Powys",
      code: "POW",
      country_code: "GB",
  },
  {
      name: "Reading",
      code: "RDG",
      country_code: "GB",
  },
  {
      name: "Redcar and Cleveland",
      code: "RCC",
      country_code: "GB",
  },
  {
      name: "Renfrewshire",
      code: "RFW",
      country_code: "GB",
  },
  {
      name: "Rhondda Cynon Taf",
      code: "RCT",
      country_code: "GB",
  },
  {
      name: "Rochdale",
      code: "RCH",
      country_code: "GB",
  },
  {
      name: "Rotherham",
      code: "ROT",
      country_code: "GB",
  },
  {
      name: "Royal Borough of Greenwich",
      code: "GRE",
      country_code: "GB",
  },
  {
      name: "Royal Borough of Kensington and Chelsea",
      code: "KEC",
      country_code: "GB",
  },
  {
      name: "Royal Borough of Kingston upon Thames",
      code: "KTT",
      country_code: "GB",
  },
  {
      name: "Rutland",
      code: "RUT",
      country_code: "GB",
  },
  {
      name: "Saint Helena",
      code: "SH-HL",
      country_code: "GB",
  },
  {
      name: "Salford",
      code: "SLF",
      country_code: "GB",
  },
  {
      name: "Sandwell",
      code: "SAW",
      country_code: "GB",
  },
  {
      name: "Scotland",
      code: "SCT",
      country_code: "GB",
  },
  {
      name: "Scottish Borders",
      code: "SCB",
      country_code: "GB",
  },
  {
      name: "Sefton",
      code: "SFT",
      country_code: "GB",
  },
  {
      name: "Sheffield",
      code: "SHF",
      country_code: "GB",
  },
  {
      name: "Shetland Islands",
      code: "ZET",
      country_code: "GB",
  },
  {
      name: "Shropshire",
      code: "SHR",
      country_code: "GB",
  },
  {
      name: "Slough",
      code: "SLG",
      country_code: "GB",
  },
  {
      name: "Solihull",
      code: "SOL",
      country_code: "GB",
  },
  {
      name: "Somerset",
      code: "SOM",
      country_code: "GB",
  },
  {
      name: "South Ayrshire",
      code: "SAY",
      country_code: "GB",
  },
  {
      name: "South Gloucestershire",
      code: "SGC",
      country_code: "GB",
  },
  {
      name: "South Lanarkshire",
      code: "SLK",
      country_code: "GB",
  },
  {
      name: "South Tyneside",
      code: "STY",
      country_code: "GB",
  },
  {
      name: "Southend-on-Sea",
      code: "SOS",
      country_code: "GB",
  },
  {
      name: "St Helens",
      code: "SHN",
      country_code: "GB",
  },
  {
      name: "Staffordshire",
      code: "STS",
      country_code: "GB",
  },
  {
      name: "Stirling",
      code: "STG",
      country_code: "GB",
  },
  {
      name: "Stockport",
      code: "SKP",
      country_code: "GB",
  },
  {
      name: "Stockton-on-Tees",
      code: "STT",
      country_code: "GB",
  },
  {
      name: "Strabane District Council",
      code: "STB",
      country_code: "GB",
  },
  {
      name: "Suffolk",
      code: "SFK",
      country_code: "GB",
  },
  {
      name: "Surrey",
      code: "SRY",
      country_code: "GB",
  },
  {
      name: "Swindon",
      code: "SWD",
      country_code: "GB",
  },
  {
      name: "Tameside",
      code: "TAM",
      country_code: "GB",
  },
  {
      name: "Telford and Wrekin",
      code: "TFW",
      country_code: "GB",
  },
  {
      name: "Thurrock",
      code: "THR",
      country_code: "GB",
  },
  {
      name: "Torbay",
      code: "TOB",
      country_code: "GB",
  },
  {
      name: "Torfaen",
      code: "TOF",
      country_code: "GB",
  },
  {
      name: "Trafford",
      code: "TRF",
      country_code: "GB",
  },
  {
      name: "United Kingdom",
      code: "UKM",
      country_code: "GB",
  },
  {
      name: "Vale of Glamorgan",
      code: "VGL",
      country_code: "GB",
  },
  {
      name: "Wakefield",
      code: "WKF",
      country_code: "GB",
  },
  {
      name: "Wales",
      code: "WLS",
      country_code: "GB",
  },
  {
      name: "Walsall",
      code: "WLL",
      country_code: "GB",
  },
  {
      name: "Warrington",
      code: "WRT",
      country_code: "GB",
  },
  {
      name: "Warwickshire",
      code: "WAR",
      country_code: "GB",
  },
  {
      name: "West Berkshire",
      code: "WBK",
      country_code: "GB",
  },
  {
      name: "West Dunbartonshire",
      code: "WDU",
      country_code: "GB",
  },
  {
      name: "West Lothian",
      code: "WLN",
      country_code: "GB",
  },
  {
      name: "West Sussex",
      code: "WSX",
      country_code: "GB",
  },
  {
      name: "Wiltshire",
      code: "WIL",
      country_code: "GB",
  },
  {
      name: "Windsor and Maidenhead",
      code: "WNM",
      country_code: "GB",
  },
  {
      name: "Wirral",
      code: "WRL",
      country_code: "GB",
  },
  {
      name: "Wokingham",
      code: "WOK",
      country_code: "GB",
  },
  {
      name: "Worcestershire",
      code: "WOR",
      country_code: "GB",
  },
  {
      name: "Wrexham County Borough",
      code: "WRX",
      country_code: "GB",
  },
  {
      name: "Alabama",
      code: "AL",
      country_code: "US",
  },
  {
      name: "Alaska",
      code: "AK",
      country_code: "US",
  },
  {
      name: "American Samoa",
      code: "AS",
      country_code: "US",
  },
  {
      name: "Arizona",
      code: "AZ",
      country_code: "US",
  },
  {
      name: "Arkansas",
      code: "AR",
      country_code: "US",
  },
  {
      name: "Baker Island",
      code: "UM-81",
      country_code: "US",
  },
  {
      name: "California",
      code: "CA",
      country_code: "US",
  },
  {
      name: "Colorado",
      code: "CO",
      country_code: "US",
  },
  {
      name: "Connecticut",
      code: "CT",
      country_code: "US",
  },
  {
      name: "Delaware",
      code: "DE",
      country_code: "US",
  },
  {
      name: "District of Columbia",
      code: "DC",
      country_code: "US",
  },
  {
      name: "Florida",
      code: "FL",
      country_code: "US",
  },
  {
      name: "Georgia",
      code: "GA",
      country_code: "US",
  },
  {
      name: "Guam",
      code: "GU",
      country_code: "US",
  },
  {
      name: "Hawaii",
      code: "HI",
      country_code: "US",
  },
  {
      name: "Howland Island",
      code: "UM-84",
      country_code: "US",
  },
  {
      name: "Idaho",
      code: "ID",
      country_code: "US",
  },
  {
      name: "Illinois",
      code: "IL",
      country_code: "US",
  },
  {
      name: "Indiana",
      code: "IN",
      country_code: "US",
  },
  {
      name: "Iowa",
      code: "IA",
      country_code: "US",
  },
  {
      name: "Jarvis Island",
      code: "UM-86",
      country_code: "US",
  },
  {
      name: "Johnston Atoll",
      code: "UM-67",
      country_code: "US",
  },
  {
      name: "Kansas",
      code: "KS",
      country_code: "US",
  },
  {
      name: "Kentucky",
      code: "KY",
      country_code: "US",
  },
  {
      name: "Kingman Reef",
      code: "UM-89",
      country_code: "US",
  },
  {
      name: "Louisiana",
      code: "LA",
      country_code: "US",
  },
  {
      name: "Maine",
      code: "ME",
      country_code: "US",
  },
  {
      name: "Maryland",
      code: "MD",
      country_code: "US",
  },
  {
      name: "Massachusetts",
      code: "MA",
      country_code: "US",
  },
  {
      name: "Michigan",
      code: "MI",
      country_code: "US",
  },
  {
      name: "Midway Atoll",
      code: "UM-71",
      country_code: "US",
  },
  {
      name: "Minnesota",
      code: "MN",
      country_code: "US",
  },
  {
      name: "Mississippi",
      code: "MS",
      country_code: "US",
  },
  {
      name: "Missouri",
      code: "MO",
      country_code: "US",
  },
  {
      name: "Montana",
      code: "MT",
      country_code: "US",
  },
  {
      name: "Navassa Island",
      code: "UM-76",
      country_code: "US",
  },
  {
      name: "Nebraska",
      code: "NE",
      country_code: "US",
  },
  {
      name: "Nevada",
      code: "NV",
      country_code: "US",
  },
  {
      name: "New Hampshire",
      code: "NH",
      country_code: "US",
  },
  {
      name: "New Jersey",
      code: "NJ",
      country_code: "US",
  },
  {
      name: "New Mexico",
      code: "NM",
      country_code: "US",
  },
  {
      name: "New York",
      code: "NY",
      country_code: "US",
  },
  {
      name: "North Carolina",
      code: "NC",
      country_code: "US",
  },
  {
      name: "North Dakota",
      code: "ND",
      country_code: "US",
  },
  {
      name: "Northern Mariana Islands",
      code: "MP",
      country_code: "US",
  },
  {
      name: "Ohio",
      code: "OH",
      country_code: "US",
  },
  {
      name: "Oklahoma",
      code: "OK",
      country_code: "US",
  },
  {
      name: "Oregon",
      code: "OR",
      country_code: "US",
  },
  {
      name: "Palmyra Atoll",
      code: "UM-95",
      country_code: "US",
  },
  {
      name: "Pennsylvania",
      code: "PA",
      country_code: "US",
  },
  {
      name: "Puerto Rico",
      code: "PR",
      country_code: "US",
  },
  {
      name: "Rhode Island",
      code: "RI",
      country_code: "US",
  },
  {
      name: "South Carolina",
      code: "SC",
      country_code: "US",
  },
  {
      name: "South Dakota",
      code: "SD",
      country_code: "US",
  },
  {
      name: "Tennessee",
      code: "TN",
      country_code: "US",
  },
  {
      name: "Texas",
      code: "TX",
      country_code: "US",
  },
  {
      name: "United States Minor Outlying Islands",
      code: "UM",
      country_code: "US",
  },
  {
      name: "United States Virgin Islands",
      code: "VI",
      country_code: "US",
  },
  {
      name: "Utah",
      code: "UT",
      country_code: "US",
  },
  {
      name: "Vermont",
      code: "VT",
      country_code: "US",
  },
  {
      name: "Virginia",
      code: "VA",
      country_code: "US",
  },
  {
      name: "Wake Island",
      code: "UM-79",
      country_code: "US",
  },
  {
      name: "Washington",
      code: "WA",
      country_code: "US",
  },
  {
      name: "West Virginia",
      code: "WV",
      country_code: "US",
  },
  {
      name: "Wisconsin",
      code: "WI",
      country_code: "US",
  },
  {
      name: "Wyoming",
      code: "WY",
      country_code: "US",
  },
  {
      name: "Artigas Department",
      code: "AR",
      country_code: "UY",
  },
  {
      name: "Canelones Department",
      code: "CA",
      country_code: "UY",
  },
  {
      name: "Cerro Largo Department",
      code: "CL",
      country_code: "UY",
  },
  {
      name: "Colonia Department",
      code: "CO",
      country_code: "UY",
  },
  {
      name: "Durazno Department",
      code: "DU",
      country_code: "UY",
  },
  {
      name: "Flores Department",
      code: "FS",
      country_code: "UY",
  },
  {
      name: "Florida Department",
      code: "FD",
      country_code: "UY",
  },
  {
      name: "Lavalleja Department",
      code: "LA",
      country_code: "UY",
  },
  {
      name: "Maldonado Department",
      code: "MA",
      country_code: "UY",
  },
  {
      name: "Montevideo Department",
      code: "MO",
      country_code: "UY",
  },
  {
      name: "Paysandú Department",
      code: "PA",
      country_code: "UY",
  },
  {
      name: "Río Negro Department",
      code: "RN",
      country_code: "UY",
  },
  {
      name: "Rivera Department",
      code: "RV",
      country_code: "UY",
  },
  {
      name: "Rocha Department",
      code: "RO",
      country_code: "UY",
  },
  {
      name: "Salto Department",
      code: "SA",
      country_code: "UY",
  },
  {
      name: "San José Department",
      code: "SJ",
      country_code: "UY",
  },
  {
      name: "Soriano Department",
      code: "SO",
      country_code: "UY",
  },
  {
      name: "Tacuarembó Department",
      code: "TA",
      country_code: "UY",
  },
  {
      name: "Treinta y Tres Department",
      code: "TT",
      country_code: "UY",
  },
  {
      name: "Andijan Region",
      code: "AN",
      country_code: "UZ",
  },
  {
      name: "Bukhara Region",
      code: "BU",
      country_code: "UZ",
  },
  {
      name: "Fergana Region",
      code: "FA",
      country_code: "UZ",
  },
  {
      name: "Jizzakh Region",
      code: "JI",
      country_code: "UZ",
  },
  {
      name: "Karakalpakstan",
      code: "QR",
      country_code: "UZ",
  },
  {
      name: "Namangan Region",
      code: "NG",
      country_code: "UZ",
  },
  {
      name: "Navoiy Region",
      code: "NW",
      country_code: "UZ",
  },
  {
      name: "Qashqadaryo Region",
      code: "QA",
      country_code: "UZ",
  },
  {
      name: "Samarqand Region",
      code: "SA",
      country_code: "UZ",
  },
  {
      name: "Sirdaryo Region",
      code: "SI",
      country_code: "UZ",
  },
  {
      name: "Surxondaryo Region",
      code: "SU",
      country_code: "UZ",
  },
  {
      name: "Tashkent",
      code: "TK",
      country_code: "UZ",
  },
  {
      name: "Tashkent Region",
      code: "TO",
      country_code: "UZ",
  },
  {
      name: "Xorazm Region",
      code: "XO",
      country_code: "UZ",
  },
  {
      name: "Malampa",
      code: "MAP",
      country_code: "VU",
  },
  {
      name: "Penama",
      code: "PAM",
      country_code: "VU",
  },
  {
      name: "Sanma",
      code: "SAM",
      country_code: "VU",
  },
  {
      name: "Shefa",
      code: "SEE",
      country_code: "VU",
  },
  {
      name: "Tafea",
      code: "TAE",
      country_code: "VU",
  },
  {
      name: "Torba",
      code: "TOB",
      country_code: "VU",
  },
  {
      name: "Amazonas",
      code: "Z",
      country_code: "VE",
  },
  {
      name: "Anzoátegui",
      code: "B",
      country_code: "VE",
  },
  {
      name: "Apure",
      code: "C",
      country_code: "VE",
  },
  {
      name: "Aragua",
      code: "D",
      country_code: "VE",
  },
  {
      name: "Barinas",
      code: "E",
      country_code: "VE",
  },
  {
      name: "Bolívar",
      code: "F",
      country_code: "VE",
  },
  {
      name: "Capital District",
      code: "A",
      country_code: "VE",
  },
  {
      name: "Carabobo",
      code: "G",
      country_code: "VE",
  },
  {
      name: "Cojedes",
      code: "H",
      country_code: "VE",
  },
  {
      name: "Delta Amacuro",
      code: "Y",
      country_code: "VE",
  },
  {
      name: "Falcón",
      code: "I",
      country_code: "VE",
  },
  {
      name: "Federal Dependencies of Venezuela",
      code: "W",
      country_code: "VE",
  },
  {
      name: "Guárico",
      code: "J",
      country_code: "VE",
  },
  {
      name: "Lara",
      code: "K",
      country_code: "VE",
  },
  {
      name: "Mérida",
      code: "L",
      country_code: "VE",
  },
  {
      name: "Miranda",
      code: "M",
      country_code: "VE",
  },
  {
      name: "Monagas",
      code: "N",
      country_code: "VE",
  },
  {
      name: "Nueva Esparta",
      code: "O",
      country_code: "VE",
  },
  {
      name: "Portuguesa",
      code: "P",
      country_code: "VE",
  },
  {
      name: "Sucre",
      code: "R",
      country_code: "VE",
  },
  {
      name: "Táchira",
      code: "S",
      country_code: "VE",
  },
  {
      name: "Trujillo",
      code: "T",
      country_code: "VE",
  },
  {
      name: "Vargas",
      code: "X",
      country_code: "VE",
  },
  {
      name: "Yaracuy",
      code: "U",
      country_code: "VE",
  },
  {
      name: "Zulia",
      code: "V",
      country_code: "VE",
  },
  {
      name: "An Giang",
      code: "44",
      country_code: "VN",
  },
  {
      name: "Bà Rịa-Vũng Tàu",
      code: "43",
      country_code: "VN",
  },
  {
      name: "Bắc Giang",
      code: "54",
      country_code: "VN",
  },
  {
      name: "Bắc Kạn",
      code: "53",
      country_code: "VN",
  },
  {
      name: "Bạc Liêu",
      code: "55",
      country_code: "VN",
  },
  {
      name: "Bắc Ninh",
      code: "56",
      country_code: "VN",
  },
  {
      name: "Bến Tre",
      code: "50",
      country_code: "VN",
  },
  {
      name: "Bình Dương",
      code: "57",
      country_code: "VN",
  },
  {
      name: "Bình Định",
      code: "31",
      country_code: "VN",
  },
  {
      name: "Bình Phước",
      code: "58",
      country_code: "VN",
  },
  {
      name: "Bình Thuận",
      code: "40",
      country_code: "VN",
  },
  {
      name: "Cà Mau",
      code: "59",
      country_code: "VN",
  },
  {
      name: "Cần Thơ",
      code: "CT",
      country_code: "VN",
  },
  {
      name: "Cao Bằng",
      code: "04",
      country_code: "VN",
  },
  {
      name: "Đà Nẵng",
      code: "DN",
      country_code: "VN",
  },
  {
      name: "Đắk Lắk",
      code: "33",
      country_code: "VN",
  },
  {
      name: "Đắk Nông",
      code: "72",
      country_code: "VN",
  },
  {
      name: "Điện Biên",
      code: "71",
      country_code: "VN",
  },
  {
      name: "Đồng Nai",
      code: "39",
      country_code: "VN",
  },
  {
      name: "Đồng Tháp",
      code: "45",
      country_code: "VN",
  },
  {
      name: "Gia Lai",
      code: "30",
      country_code: "VN",
  },
  {
      name: "Hà Giang",
      code: "03",
      country_code: "VN",
  },
  {
      name: "Hà Nam",
      code: "63",
      country_code: "VN",
  },
  {
      name: "Hà Nội",
      code: "HN",
      country_code: "VN",
  },
  {
      name: "Hà Tĩnh",
      code: "23",
      country_code: "VN",
  },
  {
      name: "Hải Dương",
      code: "61",
      country_code: "VN",
  },
  {
      name: "Hải Phòng",
      code: "HP",
      country_code: "VN",
  },
  {
      name: "Hậu Giang",
      code: "73",
      country_code: "VN",
  },
  {
      name: "Hồ Chí Minh",
      code: "SG",
      country_code: "VN",
  },
  {
      name: "Hòa Bình",
      code: "14",
      country_code: "VN",
  },
  {
      name: "Hưng Yên",
      code: "66",
      country_code: "VN",
  },
  {
      name: "Khánh Hòa",
      code: "34",
      country_code: "VN",
  },
  {
      name: "Kiên Giang",
      code: "47",
      country_code: "VN",
  },
  {
      name: "Kon Tum",
      code: "28",
      country_code: "VN",
  },
  {
      name: "Lai Châu",
      code: "01",
      country_code: "VN",
  },
  {
      name: "Lâm Đồng",
      code: "35",
      country_code: "VN",
  },
  {
      name: "Lạng Sơn",
      code: "09",
      country_code: "VN",
  },
  {
      name: "Lào Cai",
      code: "02",
      country_code: "VN",
  },
  {
      name: "Long An",
      code: "41",
      country_code: "VN",
  },
  {
      name: "Nam Định",
      code: "67",
      country_code: "VN",
  },
  {
      name: "Nghệ An",
      code: "22",
      country_code: "VN",
  },
  {
      name: "Ninh Bình",
      code: "18",
      country_code: "VN",
  },
  {
      name: "Ninh Thuận",
      code: "36",
      country_code: "VN",
  },
  {
      name: "Phú Thọ",
      code: "68",
      country_code: "VN",
  },
  {
      name: "Phú Yên",
      code: "32",
      country_code: "VN",
  },
  {
      name: "Quảng Bình",
      code: "24",
      country_code: "VN",
  },
  {
      name: "Quảng Nam",
      code: "27",
      country_code: "VN",
  },
  {
      name: "Quảng Ngãi",
      code: "29",
      country_code: "VN",
  },
  {
      name: "Quảng Ninh",
      code: "13",
      country_code: "VN",
  },
  {
      name: "Quảng Trị",
      code: "25",
      country_code: "VN",
  },
  {
      name: "Sóc Trăng",
      code: "52",
      country_code: "VN",
  },
  {
      name: "Sơn La",
      code: "05",
      country_code: "VN",
  },
  {
      name: "Tây Ninh",
      code: "37",
      country_code: "VN",
  },
  {
      name: "Thái Bình",
      code: "20",
      country_code: "VN",
  },
  {
      name: "Thái Nguyên",
      code: "69",
      country_code: "VN",
  },
  {
      name: "Thanh Hóa",
      code: "21",
      country_code: "VN",
  },
  {
      name: "Thừa Thiên-Huế",
      code: "26",
      country_code: "VN",
  },
  {
      name: "Tiền Giang",
      code: "46",
      country_code: "VN",
  },
  {
      name: "Trà Vinh",
      code: "51",
      country_code: "VN",
  },
  {
      name: "Tuyên Quang",
      code: "07",
      country_code: "VN",
  },
  {
      name: "Vĩnh Long",
      code: "49",
      country_code: "VN",
  },
  {
      name: "Vĩnh Phúc",
      code: "70",
      country_code: "VN",
  },
  {
      name: "Yên Bái",
      code: "06",
      country_code: "VN",
  },
  {
      name: "'Adan Governorate",
      code: "AD",
      country_code: "YE",
  },
  {
      name: "'Amran Governorate",
      code: "AM",
      country_code: "YE",
  },
  {
      name: "Abyan Governorate",
      code: "AB",
      country_code: "YE",
  },
  {
      name: "Al Bayda' Governorate",
      code: "BA",
      country_code: "YE",
  },
  {
      name: "Al Hudaydah Governorate",
      code: "HU",
      country_code: "YE",
  },
  {
      name: "Al Jawf Governorate",
      code: "JA",
      country_code: "YE",
  },
  {
      name: "Al Mahrah Governorate",
      code: "MR",
      country_code: "YE",
  },
  {
      name: "Al Mahwit Governorate",
      code: "MW",
      country_code: "YE",
  },
  {
      name: "Dhamar Governorate",
      code: "DH",
      country_code: "YE",
  },
  {
      name: "Hadhramaut Governorate",
      code: "HD",
      country_code: "YE",
  },
  {
      name: "Hajjah Governorate",
      code: "HJ",
      country_code: "YE",
  },
  {
      name: "Ibb Governorate",
      code: "IB",
      country_code: "YE",
  },
  {
      name: "Lahij Governorate",
      code: "LA",
      country_code: "YE",
  },
  {
      name: "Ma'rib Governorate",
      code: "MA",
      country_code: "YE",
  },
  {
      name: "Raymah Governorate",
      code: "RA",
      country_code: "YE",
  },
  {
      name: "Saada Governorate",
      code: "SD",
      country_code: "YE",
  },
  {
      name: "Sana'a",
      code: "SA",
      country_code: "YE",
  },
  {
      name: "Sana'a Governorate",
      code: "SN",
      country_code: "YE",
  },
  {
      name: "Shabwah Governorate",
      code: "SH",
      country_code: "YE",
  },
  {
      name: "Socotra Governorate",
      code: "SU",
      country_code: "YE",
  },
  {
      name: "Ta'izz Governorate",
      code: "TA",
      country_code: "YE",
  },
  {
      name: "Central Province",
      code: "02",
      country_code: "ZM",
  },
  {
      name: "Copperbelt Province",
      code: "08",
      country_code: "ZM",
  },
  {
      name: "Eastern Province",
      code: "03",
      country_code: "ZM",
  },
  {
      name: "Luapula Province",
      code: "04",
      country_code: "ZM",
  },
  {
      name: "Lusaka Province",
      code: "09",
      country_code: "ZM",
  },
  {
      name: "Muchinga Province",
      code: "10",
      country_code: "ZM",
  },
  {
      name: "Northern Province",
      code: "05",
      country_code: "ZM",
  },
  {
      name: "Northwestern Province",
      code: "06",
      country_code: "ZM",
  },
  {
      name: "Southern Province",
      code: "07",
      country_code: "ZM",
  },
  {
      name: "Western Province",
      code: "01",
      country_code: "ZM",
  },
  {
      name: "Bulawayo Province",
      code: "BU",
      country_code: "ZW",
  },
  {
      name: "Harare Province",
      code: "HA",
      country_code: "ZW",
  },
  {
      name: "Manicaland",
      code: "MA",
      country_code: "ZW",
  },
  {
      name: "Mashonaland Central Province",
      code: "MC",
      country_code: "ZW",
  },
  {
      name: "Mashonaland East Province",
      code: "ME",
      country_code: "ZW",
  },
  {
      name: "Mashonaland West Province",
      code: "MW",
      country_code: "ZW",
  },
  {
      name: "Masvingo Province",
      code: "MV",
      country_code: "ZW",
  },
  {
      name: "Matabeleland North Province",
      code: "MN",
      country_code: "ZW",
  },
  {
      name: "Matabeleland South Province",
      code: "MS",
      country_code: "ZW",
  },
  {
      name: "Midlands Province",
      code: "MI",
      country_code: "ZW",
  }
];

export default STATES;
