import React from 'react'
import { Button } from 'antd';

const FiltersModalFooter = (props) => {
  const { loading, onClear, onCancel, onSuccess } = props;

  return (
    <>
      <Button
        disabled={loading}
        onClick={onCancel}
      >
        Cancel
      </Button>

      <Button
        type="primary"
        disabled={loading}
        onClick={onClear}
        ghost
      >
        Clear
      </Button>

      <Button
        type="primary"
        loading={loading}
        onClick={onSuccess}
      >
        Apply
      </Button>
    </>
  );
}

export default FiltersModalFooter;
