import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import RowsSelectAlert from '../RowsSelectAlert';

const SORT_ORDERS = {
  ascend: 'ASC',
  descend: 'DESC',
}

const getParsedFilters = (filters) => {
  return Object
    .entries(filters)
    .reduce((acc, [key, value]) => {
      return value ? {
        ...acc,
        [key]: {
          $in: value
        }
      } : acc;
  }, {});
}

const TableBody = (props) => {
  const {
    prevPage,
    page,
    setPage,
    pageSize,
    setPageSize,
    setFilters,
    defaultFilters,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    total,
    results,
    getResults,
    loading,
    automaticRequest,
    tableRowKey,
    tableColumns,
    tableProps,
    tableBulkActionsModal,
    modalFilters,
    setTableFilters,
    selectedRowKeys,
    setSelectedRowKeys,
  } = props;

  const [loadingAllRows, setLoadingAllRows] = useState(false);

  const rowSelectionEnabled = useMemo(() => (
    Boolean(tableBulkActionsModal)
  ), [tableBulkActionsModal]);

  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    rowSelection: {
      type: 'checkbox',
    },
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  }), [selectedRowKeys, setSelectedRowKeys]);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
    showSizeChanger: true,
  }), [page, pageSize, total]);

  const handleChange = useCallback((value, filters, sorter) => {
    const newTableFilters = getParsedFilters(filters);

    setPage(value.current);
    setPageSize(value.pageSize);
    setFilters({ ...defaultFilters, ...modalFilters, ...newTableFilters });
    setSortField(SORT_ORDERS[sorter.order] ? sorter.field : '');
    setSortOrder(SORT_ORDERS[sorter.order]);
    setTableFilters(newTableFilters);
  }, [
    setPage,
    setPageSize,
    setFilters,
    defaultFilters,
    modalFilters,
    setSortField,
    setSortOrder,
    setTableFilters,
  ]);

  useEffect(() => {
    if (!automaticRequest && prevPage !== undefined) {
      getResults();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortField, sortOrder]);

  return (
    <>
      {rowSelectionEnabled &&
      <RowsSelectAlert
        {...props}
        loading={loading || loadingAllRows}
        setLoading={setLoadingAllRows}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />}

      <Table
        {...tableProps}
        rowKey={tableRowKey}
        rowSelection={rowSelectionEnabled ? rowSelection : undefined}
        loading={loading || loadingAllRows}
        dataSource={results}
        columns={tableColumns}
        pagination={pagination}
        onChange={handleChange}
        scroll={{ x: 'auto' }}
      />
    </>
  );
}

export default TableBody;
