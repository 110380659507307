import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Typography, Button, Form, Input, Divider, Alert, message, Select } from "antd";
import { requiredField } from "../../../utils/validations";
import MerchantSelectInput from "../../Global/MerchantSelectInput";
import PrimaryLayout from "../../Layout/PrimaryLayout";
import LocationSearchInput from "../../Global/LocationSearchInput";
import LocationSelectInput from "../../Global/LocationSelectInput";
import TimezoneSelectInput from "../../Global/TimezoneSelectInput";
import PhoneInput2 from "../../Global/PhoneInput2";
import ProviderNumbersSearchInput from "../../Global/ProviderNumbersSearchInput";
import ProviderBuyNumberInput from "../../Global/ProviderBuyNumberInput";
import request from "../../../core/request";

const PROVIDER_NO_AVAILABLE_NUMBERS_ERROR =
  "No available numbers in this area code. Please enter a different area code.";
const PROVIDER_SUCCESS_PURCHASE_MESSAGE = "Number has been purchased successfully.";

const PROVIDERS = [
  { value: "twilio", label: "Twilio" },
  { value: "bandwidth", label: "Bandwidth" },
];

const CreateStore = () => {
  const [form] = Form.useForm();

  const history = useHistory();

  const merchant = Form.useWatch("merchant", form);
  const storeName = Form.useWatch("store_name", form);
  const phoneNumber = Form.useWatch("phone_number", form);
  const provider = Form.useWatch("provider", form);

  const [providerCountryCode, setProviderCountryCode] = useState("US");
  const [providerCountryCodeStatus, setProviderCountryCodeStatus] = useState(null);
  const [providerCountryCodeStatusMessage, setProviderCountryCodeStatusMessage] = useState(null);
  const [providerPhoneNumberStatus, setProviderPhoneNumberStatus] = useState(null);
  const [providerPhoneNumberStatusMessage, setProviderPhoneNumberStatusMessage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [merchantsList, setMerchantsList] = useState([]);

  const disableChanges = useMemo(
    () => providerCountryCodeStatus === "success" && providerPhoneNumberStatus === "success",
    [providerCountryCodeStatus, providerPhoneNumberStatus]
  );

  const disableProviderInputs = useMemo(
    () => !merchant || !storeName || !phoneNumber || disableChanges,
    [disableChanges, merchant, phoneNumber, storeName]
  );

  const selectedMerchant = useMemo(() => {
    return merchantsList.find((m) => m.id === merchant?.value);
  }, [merchantsList, merchant]);

  const initialProviderValue = useMemo(() => {
    return selectedMerchant?.twilio_auth_token && selectedMerchant?.twilio_account_sid
      ? "twilio"
      : "bandwidth";
  }, [selectedMerchant]);

  const accountAlias = useMemo(() => {
    if (!selectedMerchant || !storeName) {
      return "";
    }

    if (provider === "bandwidth") {
      return selectedMerchant.license_key;
    }

    let friendlyName = `${selectedMerchant.license_key}${storeName}`
      .replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase();

    return friendlyName;
  }, [selectedMerchant, storeName, provider]);

  const handleSuccessSearchProviderNumbers = (values) => {
    if (values.length) {
      setProviderCountryCodeStatus("success");
      setProviderCountryCodeStatusMessage(null);

      form.setFieldValue("provider_phone_number", values[0]);
    } else {
      setProviderCountryCodeStatus("error");
      setProviderCountryCodeStatusMessage(PROVIDER_NO_AVAILABLE_NUMBERS_ERROR);

      form.setFieldValue("provider_phone_number", "");
    }
  };

  const handleErrorSearchProviderNumbers = (errorMessage) => {
    setProviderPhoneNumberStatus("error");
    setProviderPhoneNumberStatusMessage(errorMessage);
  };

  const handleSuccessBuyProviderPhoneNumber = () => {
    setProviderPhoneNumberStatus("success");
    setProviderPhoneNumberStatusMessage(PROVIDER_SUCCESS_PURCHASE_MESSAGE);
  };

  const handleErrorBuyProviderPhoneNumber = (errorMessage) => {
    setProviderPhoneNumberStatus("error");
    setProviderPhoneNumberStatusMessage(errorMessage);
  };

  const handleSelectLocation = (location) => {
    if (location) {
      let street1 = "";

      if (location.streetNumber) {
        street1 += `${location.streetNumber}`;
      }

      if (location.street) {
        street1 += ` ${location.street}`;
      }

      form.setFieldsValue({
        street_1: street1,
        country: location.country,
        state: location.state,
        city: location.city,
        zip_code: location.zipCode,
        timezone: location.timezone,
        lat: location.lat,
        lng: location.lng,
        review_link: `https://search.google.com/local/writereview?placeid=`,
        street1,
      });
    }
  };

  const handleSubmit = async (formValues) => {
    setLoading(true);

    try {
      if (!formValues.review_link.startsWith("https://")) {
        return message.error("Review link must start with https://");
      }
      await request.basic.post("/stores/createStore", {
        city: formValues.city,
        country: formValues.country,
        lat: formValues.lat,
        lng: formValues.lng,
        merchant_id: formValues.merchant.value,
        phone_number: formValues.phone_number,
        state: formValues.state,
        store_name: formValues.store_name,
        street_1: formValues.street_1,
        street_2: formValues.street_2,
        timezone: formValues.timezone,
        provider: formValues.provider,
        twilio_phone_number: formValues.provider_phone_number,
        zip_code: formValues.zip_code,
        review_link: formValues.review_link,
      });

      message.success("Store created successfully");
      history.push("/stores/list");
    } catch (error) {
      message.error("Error creating store");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("initialProviderValue", initialProviderValue);
    form.setFieldValue("provider", initialProviderValue);
  }, [form, initialProviderValue]);

  useEffect(() => {
    form.setFieldValue("phone_number", "");
    form.setFieldValue("provider_area_code", "");
    form.setFieldValue("provider_phone_number", "");

    setProviderCountryCodeStatus(null);
    setProviderCountryCodeStatusMessage(null);

    setProviderPhoneNumberStatus(null);
    setProviderPhoneNumberStatusMessage(null);
  }, [form, providerCountryCode]);

  return (
    <PrimaryLayout>
      <div style={{ maxWidth: "650px", paddingBottom: "20px" }}>
        <Typography.Title level={3}>Create Store</Typography.Title>

        <Alert
          message="Before buying phone numbers"
          description="Merchant, Store name, Phone number, Provider area code, and Provider phone number fields cannot be modified after purchasing Provider phone number."
          type="info"
          showIcon
        />

        <Divider />

        <Form
          form={form}
          onFinish={handleSubmit}
          autoComplete="off"
          labelAlign="left"
          disabled={loading}
          labelCol={{
            sm: { span: 24 },
            md: { span: 6 },
          }}
          wrapperCol={{
            sm: { span: 24 },
            md: { span: 18 },
          }}
        >
          <Form.Item name="merchant" label="Merchant" rules={[requiredField("Merchant")]}>
            <MerchantSelectInput
              valueKey="id"
              labelKey="name"
              onListChange={setMerchantsList}
              disabled={disableChanges}
            />
          </Form.Item>

          <Form.Item name="store_name" label="Store name" rules={[requiredField("Store name")]}>
            <Input disabled={disableChanges} />
          </Form.Item>

          <Form.Item
            name="street_1"
            label="Street 1"
            initialValue=""
            rules={[requiredField("Street 1")]}
          >
            <LocationSearchInput onSelect={handleSelectLocation} />
          </Form.Item>

          <Form.Item name="street_2" label="Street 2">
            <Input />
          </Form.Item>

          <Form.Item name="city" label="City" rules={[requiredField("City")]}>
            <Input />
          </Form.Item>

          <Form.Item name="state" label="State" rules={[requiredField("State")]}>
            <Input />
          </Form.Item>

          <Form.Item name="zip_code" label="Zip code" rules={[requiredField("Zip code")]}>
            <Input />
          </Form.Item>

          <Form.Item name="country" label="Country" rules={[requiredField("Country")]}>
            <LocationSelectInput locationType="country" showSearch />
          </Form.Item>

          <Form.Item name="lng" label="Longitude" rules={[requiredField("Longitude")]}>
            <Input />
          </Form.Item>

          <Form.Item name="lat" label="Latitude" rules={[requiredField("Latitude")]}>
            <Input />
          </Form.Item>

          <Form.Item name="timezone" label="Timezone" rules={[requiredField("Timezone")]}>
            <TimezoneSelectInput showSearch />
          </Form.Item>

          <Form.Item name="review_link" label="Review link">
            <Input />
          </Form.Item>

          <Form.Item
            name="phone_number"
            label="Phone number"
            initialValue=""
            rules={[requiredField("Phone number")]}
          >
            <PhoneInput2
              countryValue={providerCountryCode}
              onCountryChange={setProviderCountryCode}
              disabled={disableChanges}
            />
          </Form.Item>

          <Form.Item
            name="provider"
            label="Provider"
            initialValue={initialProviderValue}
            rules={[requiredField("Provider")]}
          >
            <Select placeholder="Enter provider" style={{ width: "100%" }} disabled>
              {PROVIDERS.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="provider_area_code"
            label="Provider area code"
            initialValue=""
            rules={[requiredField("Provider area code")]}
            hasFeedback={providerCountryCodeStatus}
            validateStatus={providerCountryCodeStatus}
            help={providerCountryCodeStatusMessage}
          >
            <ProviderNumbersSearchInput
              provider={provider}
              countryValue={providerCountryCode}
              onCountryChange={setProviderCountryCode}
              onSuccess={handleSuccessSearchProviderNumbers}
              onError={handleErrorSearchProviderNumbers}
              disabled={disableProviderInputs}
            />
          </Form.Item>

          <Form.Item
            name="provider_phone_number"
            label="Provider phone number"
            initialValue=""
            rules={[requiredField("Provider phone number")]}
            hasFeedback={providerPhoneNumberStatus}
            validateStatus={providerPhoneNumberStatus}
            help={providerPhoneNumberStatusMessage}
          >
            <ProviderBuyNumberInput
              provider={provider}
              countryValue={providerCountryCode}
              onCountryChange={setProviderCountryCode}
              accountAlias={accountAlias}
              authToken={selectedMerchant?.twilio_auth_token}
              sid={selectedMerchant?.twilio_account_sid}
              onSuccess={handleSuccessBuyProviderPhoneNumber}
              onError={handleErrorBuyProviderPhoneNumber}
              disabled={disableProviderInputs}
            />
          </Form.Item>

          <Form.Item style={{ display: "flex", justifyContent: "right" }}>
            <Button type="primary" htmlType="submit" loading={loading} disabled={!disableChanges}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default CreateStore;
