export const INPUT_FILTERS = [
  {
    value: '$eq',
    label: 'Equal',
    tooltip: 'Only available for not NULL fields',
  },
  {
    value: '$ne',
    label: 'Not Equal',
    tooltip: 'Only available for not NULL fields',
  },
  {
    value: '$like',
    label: 'Text pattern',
    tooltip: 'Only available for not NULL fields. Use the percent sign (%) at the beginning and/or end of your text to find records that start and/or end with some value. For example, "John%" pattern will find all records that starts with John like "John Smith".',
  },
  {
    type: 'number',
    value: '$lt',
    label: 'Less than',
    tooltip: 'Only available for not NULL and numeric fields',
  },
  {
    type: 'number',
    value: '$lte',
    label: 'Less than or equal',
    tooltip: 'Only available for not NULL and numeric fields',
  },
  {
    type: 'number',
    value: '$gt',
    label: 'Greater than',
    tooltip: 'Only available for not NULL and numeric fields',
  },
  {
    type: 'number',
    value: '$gte',
    label: 'Greater than or equal',
    tooltip: 'Only available for not NULL and numeric fields',
  },
];

export const CHECKBOX_FILTERS = [
  {
    value: '$null',
    label: 'Is NULL',
    tooltip: 'Only available for not NULL fields',
  },
  {
    value: '$notNull',
    label: 'Is not NULL',
    tooltip: 'Only available for not NULL fields',
  },
];
