import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, notification, Spin, Checkbox } from 'antd';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import cbfeather from '../../../images/cbfeatherz.svg'
import request from '../../../core/request';

const Login = (props) => {
    const [showModal] = useState(true);
    const [pwText, setPwText] = useState('');
    const [userText, setUserText] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // if(props.jwtToken) {
        //     history.push('/');
        // }
    }, [props.jwtToken])

    const cancelHandler = () => {
        setPwText('');
        setUserText('');
    }

    const signInHandler =  async () => {
        if(pwText && userText){
            setIsLoading(true);
            await request.basic.post(`/auth/login`, {}, {
                headers: {
                    "x-clientbook-user": userText,
                    "x-clientbook-pw": pwText
                }
            })
            .then(response => {
                const { apiKey, apiToken } = response?.data;
                setIsLoading(false);

                if (rememberMe) {
                    localStorage.setItem("jwtToken", apiKey);
                    localStorage.setItem("apiToken", apiToken);
                } else {
                    localStorage.setItem('jwtToken', '');
                    localStorage.setItem("apiToken", '');
                }

                props.setAuth({
                    apiKey: apiKey,
                    apiToken,
                });
                // Axios.defaults.headers.common['Authorization'] = token;
                request.basic.defaults.headers.common['x-api-key'] = apiKey;
                request.twilio.defaults.headers.common['x-api-key'] = apiKey;
                request.login.defaults.headers.common['x-api-key'] = apiKey;

                setIsLoading(false);
                setTimeout(() => {
                    history.push('/');
                },100)

            })
            .catch(err => {
                setIsLoading(false);
            })
        } else {
            notification['warning']({
                message: 'Login Failed',
                description: 'Please enter a username and password'
            })
        }

    }

    return (
        <div className="colorChangingBG w-100-vw h-100-vh">
            <Modal
                // title="Please Log In..."
                open={showModal}
                onOk={() => {
                    //do something
                }}
                onCancel={(() =>  {
                    cancelHandler();
                    //do something
                })}
                cancelText={"CLEAR"}
                okText={"SIGN IN"}
                closable={false}
                footer={
                    <div className="w-100-P align-center">
                        <Button
                            onClick={signInHandler}
                            type="primary"
                            size="large"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            SIGN IN
                        </Button>
                    </div>
                }
            >
                <Spin
                    tip="Logging in..."
                    spinning={isLoading}
                >

                    <div className="w-100-P align-center m-btm-50">
                        <span className="fs-30 cb-blue align-center w-100-P">
                            <img src={cbfeather} alt="#" style={{width: '50px', height: '50px'}}/>
                            Clientbook Console
                        </span>
                    </div>
                    <Input className="m-btm-25" size="large" prefix={<UserOutlined />} name="username" placeholder="Username" onChange={e => setUserText(e.target.value)} value={userText}/>

                    <Input type="password" className="m-btm-25" size="large" prefix={<LockOutlined />} name="password" placeholder="Password" onChange={e => setPwText(e.target.value)} value={pwText}/>
                    <Checkbox
                      checked={rememberMe}
                    //   disabled={}
                      onChange={() => setRememberMe(!rememberMe)}
                    >
                      {"Remember Me?"}
                    </Checkbox>
                </Spin>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => state.auth;

const mapDispatchToProps = dispatch => ({
    setAuth(val) {
        dispatch({
            type: "SET_JWT_TOKEN",
            payload: val.apiKey,
        });

        dispatch({
            type: "SET_API_TOKEN",
            payload: val.apiToken,
        });
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
