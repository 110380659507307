import React from 'react'
import { Typography, Divider, Alert } from 'antd';

const PhoneLookupDetails = (props) => {
  const { information } = props;

  return (
    information?.status
    ? <Alert
      type="error"
      message={`Error ${information.status}`}
      description={information.moreInfo}
      showIcon
    />
    : <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Divider />

        <Typography.Title level={4}>General information</Typography.Title>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              Phone number
            </Typography.Text>

            <Typography.Text type="secondary">
              { information?.phoneNumber }
            </Typography.Text>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              National format
            </Typography.Text>

            <Typography.Text type="secondary">
              { information?.nationalFormat }
            </Typography.Text>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              Country code
            </Typography.Text>

            <Typography.Text type="secondary">
              { information?.countryCode }
            </Typography.Text>
          </div>
        </div>

        <Divider />

        <Typography.Title level={4}>Carrier information</Typography.Title>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              Carrier name
            </Typography.Text>

            <Typography.Text type="secondary">
              { information?.carrier?.name }
            </Typography.Text>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              Carrier type
            </Typography.Text>

            <Typography.Text type="secondary">
              { information?.carrier?.type }
            </Typography.Text>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              Mobile country code
            </Typography.Text>

            <Typography.Text type="secondary">
              { information?.carrier?.mobile_country_code }
            </Typography.Text>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              Mobile network code
            </Typography.Text>

            <Typography.Text type="secondary">
              { information?.carrier?.mobile_network_code }
            </Typography.Text>
          </div>
        </div>

        <Divider />
      </div>
  );
}

export default PhoneLookupDetails;
