import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router';
import request from '../../../core/request';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import Table from '../../Global/Table';
import usePaginatedRequest from '../../../hooks/usePaginatedRequest';
import MerchantClientsBulkActionsModal from './components/BulkActionsModal';
import { CheckOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { format } from 'date-fns';

const OPT_IN_SRC_FILTERS = [
  { value: 'CSV', text: 'CSV' },
  { value: 'IMPOR', text: 'IMPOR' },
  { value: 'KIOSK', text: 'KIOSK' },
  { value: 'MAN', text: 'MAN' },
  { value: 'PAY', text: 'PAY' },
  { value: 'SMS', text: 'SMS' },
  { value: 'WEB', text: 'WEB' },
]

const MerchantClients = () => {
  const { uuid } = useParams();
  const [tags, setTags] = useState([]);

  const tagsMap = useMemo(() => {
    return tags.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: curr.name,
    }), {});
  }, [tags]);

  const requestMethod = useCallback(({ params }) => {
    return request.basic.get('/clients/getClients', {
      params: { ...params, merchantUuid: uuid },
    });
  }, [uuid]);

  const paginatedRequest = usePaginatedRequest({
    requestMethod,
  });

  const columns = useMemo(() => ([
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Archived',
      dataIndex: 'archive_date',
      sorter: true,
      ellipsis: true,
      render: value => (Number(value) ? <CheckOutlined /> : null),
    },
    {
      title: 'Opt in date',
      dataIndex: 'opt_in_date',
      sorter: true,
      ellipsis: true,
      render: value => (value ? format(new Date(value), 'yyyy-mm-dd') : null),
    },
    {
      title: 'Opt in req date',
      dataIndex: 'opt_in_req_date',
      sorter: true,
      ellipsis: true,
      render: value => (value ? format(new Date(value), 'yyyy-mm-dd') : null),
    },
    {
      title: 'Opt in source',
      dataIndex: 'opt_in_src',
      sorter: true,
      ellipsis: true,
      filters: OPT_IN_SRC_FILTERS,
      render: (value) => value ? <Tag color="blue">{value}</Tag> : null,
    },
    {
      title: 'tags',
      dataIndex: 'tags',
		  filters: tags.map((tag) => ({
        text: tag.name,
        value: tag.id,
      })),
      render: value => (
        value.map(item => (
          <Tag
            color="blue"
            key={item}
          >
            {tagsMap[item]}
          </Tag>
        ))
      ),
    },
    {
      title: 'pos_ref_num',
      dataIndex: 'pos_ref_num',
      sorter: true,
      ellipsis: true,
    },
  ]), [tags, tagsMap]);

  useEffect(() => {
    const getMerchantTags = async () => {
      const response = await request.basic.get('/merchants/getMerchantTags', {
        params: { merchantUuid: uuid },
      });

      setTags(response.data);
    }

    getMerchantTags();
  }, [uuid]);

  return (
    <PrimaryLayout header="Merchant clients">
      <Table
        {...paginatedRequest}
        tableRowKey="uuid"
        tableColumns={columns}
        tableBulkActionsModal={(props) => (
          <MerchantClientsBulkActionsModal {...props} />
        )}
      />
    </PrimaryLayout>
  );
}

export default MerchantClients;
