import React from 'react';
import { Select } from 'antd';
import STATES from './states';

const StateSelect = (props) => {
    const {
        countryCode,
        handleFilter,
        ...inputProps
    } = props;

    return (
        <Select
            {...inputProps}
            filterOption={handleFilter}
            style={{ width: '100%' }}
        >
            { STATES
                .filter(e => e.country_code === countryCode)
                .map(item => (
                    <Select.Option
                        key={item.code}
                        value={item.code}
                    >
                        { item.name }
                    </Select.Option>
                ))
            }
        </Select>
    );
}

export default StateSelect;
