import { useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import Axios from 'axios';

const API_KEY = 'AIzaSyC4kOtn4ZNP-Q5r2y3btAwqjQXeOOYjuHs';

const useGoogleMapsService = (props) => {
  const [loaded, setLoaded] = useState(Boolean(window.google));
  const { libraries } = props;

  const loadLibraries = async () => {
    try {
      const loader = new Loader({ apiKey: API_KEY, libraries });

      await loader.load();

      setLoaded(true);
    } catch (error) {
      // ERROR HANDLER
    }
  }

  const getPredictions = (input) => {
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    let intervalCounter = 5;

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (autocompleteService) {
          autocompleteService.getQueryPredictions({ input }, (suggestions) => resolve(suggestions));
        } else if (intervalCounter > 0) {
          intervalCounter--;
        } else {
          clearInterval(interval);
          reject('AutocompleteService not found');
        }
      }, 1000);
    });
  }

  const getGeocodeInformation = async (address) => {
    const endpoint = '/maps/api/geocode/json';

    const response = await Axios.get(endpoint, {
      baseURL: 'https://maps.googleapis.com',
      params: {
        key: API_KEY,
        address,
      },
      transformRequest: [(data, headers) => {
        delete headers.common['x-api-key'];
        delete headers.common['Authorization'];

        return data;
      }],
    });

    return response?.data;
  }

  const getLocationTimezone = async (lat, lng) => {
    const endpoint = '/maps/api/timezone/json';
    const location = `${lat},${lng}`;
    const targetDate = new Date();
    const timestamp = targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60;

    const response = await Axios.get(endpoint, {
      baseURL: 'https://maps.googleapis.com',
      params: {
        key: API_KEY,
        location,
        timestamp,
      },
      transformRequest: [(data, headers) => {
        delete headers.common['x-api-key'];
        delete headers.common['Authorization'];

        return data;
      }],
    });

    return response?.data?.timeZoneId || '';
  }

  return {
    loaded,
    loadLibraries,
    getPredictions,
    getGeocodeInformation,
    getLocationTimezone,
  };
}

export default useGoogleMapsService;
