import { Button, Table } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { REPORTS } from '../../shared/constants';

const sortAlphabetically = (a, b) => {
  if (a.name < b.name) {
    return - 1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
}

const ReportsList = () => {
  const history = useHistory();

  const columns = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      sorter: sortAlphabetically,
    },
    {
      key: 'options',
      title: 'Options',
      fixed: 'right',
      width: 150,
      ellipsis: true,
      render: (_, item) => (
        <Button
          type="primary"
          onClick={() => history.push(item.link)}
        >
          Create Report
        </Button>
      )
    },
  ], [history]);

  return (
    <Table
      dataSource={Object.values(REPORTS)}
      columns={columns}
      scroll={{ x: 'auto' }}
    />
  );
}

export default ReportsList;
