import moment from 'moment';
import { csv } from "csvtojson";

export const timeFormat14 = (tempClientTime, clientObjTime) => {
    let returnTime = '';
    if(tempClientTime || clientObjTime) {
      returnTime = moment(tempClientTime || clientObjTime, 'YYYYMMDDHHmmss').format('MM/DD/YYYY');
      if (returnTime === "Invalid date") {
        returnTime = '';
      }
    }
    return returnTime;
  }

export const timeFormat8 = (tempClientTime, clientObjTime) => {
    let returnTime = '';
    if(tempClientTime || clientObjTime) {
      returnTime = moment(tempClientTime || clientObjTime, 'YYYYMMDDHHmmss').format('MM/DD/YYYY');
      if (returnTime === "Invalid date") {
        returnTime = '';
      }
    }
    return returnTime;
  }

export const truthTextHandler = (val) => {
    return Number(val) === 1 ? 'True' : 'False';
}

export const urlDictionary = {
  login: {
    local: 'http://localhost:3358',
    stage: "https://tytilwklxtxiou4y53rf7xg33u0lrzag.lambda-url.us-east-1.on.aws",
    test: "https://mmdd3ai6hkkyzdxxzrvr5x62vy0hexxb.lambda-url.us-east-1.on.aws",
    production: "https://zcpsw6nqkt5ynorsg65aug3vze0iglov.lambda-url.us-east-1.on.aws",
    development: "https://mmdd3ai6hkkyzdxxzrvr5x62vy0hexxb.lambda-url.us-east-1.on.aws",
  },
  basic: {
    local: 'http://localhost:3358',
    stage: "https://tytilwklxtxiou4y53rf7xg33u0lrzag.lambda-url.us-east-1.on.aws",
    test: "https://mmdd3ai6hkkyzdxxzrvr5x62vy0hexxb.lambda-url.us-east-1.on.aws",
    production: "https://zcpsw6nqkt5ynorsg65aug3vze0iglov.lambda-url.us-east-1.on.aws",
    development: "https://mmdd3ai6hkkyzdxxzrvr5x62vy0hexxb.lambda-url.us-east-1.on.aws"
  },
  twilio: {
    local: "https://n7xgld4v22cwwys4c6mr2zhi6a0nwtrc.lambda-url.us-east-1.on.aws",
    stage: "https://n7xgld4v22cwwys4c6mr2zhi6a0nwtrc.lambda-url.us-east-1.on.aws",
    test: "https://n7xgld4v22cwwys4c6mr2zhi6a0nwtrc.lambda-url.us-east-1.on.aws",
    production: "https://n7xgld4v22cwwys4c6mr2zhi6a0nwtrc.lambda-url.us-east-1.on.aws",
    development: "https://n7xgld4v22cwwys4c6mr2zhi6a0nwtrc.lambda-url.us-east-1.on.aws"
  },
  admin: {
    local: 'https://devadmin.clbk.app',
    development: 'https://devadmin.clbk.app',
    test: 'https://devadmin.clbk.app',
    stage: 'https://stageadmin.clbk.app',
    production: 'https://admin.clbk.app',
  }
}

export const getBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const csvFileToArray = (file, options = {}) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const results = csv({ ...options }).fromString(reader.result);
      resolve(results);
    };

    reader.onerror = error => reject(error);
    reader.readAsBinaryString(file);
  });
}
