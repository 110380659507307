import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Skeleton, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import DeleteClients from "./DeleteClients";
import DataAssignmentListItems from "./DataAssignmentListItems";
import PrimaryClientListItems from "./PrimaryClientListItems";
import "./merge.css";
import request from "../../../../../../core/request";

const MergeAssignment = (props) => {
  const [usedParams, setUsedParams] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [fullClientDetails, setFullClientDetails] = useState({
    salesTransactions: [],
    salesOpps: [],
    relatives: [],
    lifeEvents: [],
    tags: [],
    collections: [],
    associates: [],
    history: [],
    address: [],
    preferences: [],
    chatMessages: [],
    client: {},
  });

  const paramsKeyLegend = {
    lifeEventCnt: "Life Events",
    address: "Address",
    tags: "Tags",
    historyCnt: "History Events",
    relationshipCnt: "Relationships",
    salesCnt: "Sales",
  };

  useEffect(() => {
    let detectedParams = {};
    Object.values(props.cliAttrCnts).forEach((obj) => {
      Object.keys(obj).forEach((word) => (detectedParams[word] = true));
    });
    setUsedParams(Object.keys(detectedParams));
  }, [props.cliAttrCnts]);

  const checkOtherData = (cliId) => {
    return usedParams.map((param) => {
      const paramsKey = paramsKeyLegend[param] || param;
      if (props.cliAttrCnts.hasOwnProperty(cliId)) {
        if (props.cliAttrCnts[cliId].hasOwnProperty(param)) {
          return { key: paramsKey, value: props.cliAttrCnts[cliId][param] };
        } else {
          return { key: paramsKey, value: 0 };
        }
      } else {
        return { key: paramsKey, value: 0 };
      }
    });
  };

  const getSingleClientDetails = (id) => {
    if(id) {
      // setShowDrawer(true);
      props.setLoading(true);
      request.basic.get(`/clients/getSingleClientDetails?clientId=${id}`)
      .then(resp => {

        props.setLoading(false);
        let newDetailsObj = {
          ...resp.data
        }
        if(newDetailsObj.hasOwnProperty('client') && Array.isArray(newDetailsObj.client) && newDetailsObj.client.length > 0) {
          newDetailsObj.client = newDetailsObj.client[0]
          setFullClientDetails(newDetailsObj);
        } else {
          // notify client not found or something?

        }
      })
      .catch(err => {
        props.setLoading(false);
      })
    }
  }

  const primaryClientHandler = (clientObj) => {
    const {id} = clientObj;
    if(id) {
      props.setLoading(true);
      // props.setPrimaryClient({client: {}});
      props.resetToDefault()

      request.basic.get(`/clients/getSingleClientDetails?clientId=${id}`)
      .then(resp => {
        props.setLoading(false);
        let newDetailsObj = {
          ...resp.data
        }
        if(newDetailsObj.hasOwnProperty('client') && Array.isArray(newDetailsObj.client) && newDetailsObj.client.length > 0) {
          newDetailsObj.client = newDetailsObj.client[0]
          props.setPrimaryClient(newDetailsObj);
        }
      })
      .catch(err => {
        props.setLoading(false);
      })
    }
  }

  const dataSorter = useMemo(() => {
    return props.selCliList
      .filter((item) => {
        return (
          Number(item.id) !== Number(props.primaryClient.client.id) &&
          !Object.keys(props.mergingClients).includes(`${item.id}`)
        );
      })
      .sort((a, b) => {
        let fa = (a.pos_ref_num || "").toLowerCase(),
          fb = (b.pos_ref_num || "").toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
  }, [props.mergingClients, props.primaryClient.client.id, props.selCliList]);

  const mergeClientHandler = (obj) => {
    props.setMergingClients({ ...props.mergingClients, [obj.id]: obj });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {/* Outermost */}
      <div
        style={{ maxHeight: "48%", overflowY: "scroll"}}
        className="mergeToplist"
      >
        {/* Top inner */}
        <div
          style={{
            width: "50%",
            border: "solid 2px #1890ff",
            minHeight: "30%",
            overflowY: "scroll",
          }}
        >
          <span style={{ fontWeight: 400, fontSize: "22px", marginLeft: '24px' }}>
            Primary Client
          </span>

          {props.loading ? <div><Spin style={{marginLeft: '24px'}} /></div> : null}
          {props.primaryClient.client.id ? (
            <Card
              extra={
                <Button
                  type="danger"
                  onClick={() => props.setPrimaryClient({client: {}})}
                >
                  Remove
                </Button>
              }
              style={{ width: "100%" }}
              title={`${props.primaryClient.client.name || ""}: ${
                props.primaryClient.client.id
              }`.trim()}
            >
              {props.loading ? <Spin /> : null}
              <PrimaryClientListItems
                primaryClient={props.primaryClient}
                tempClientDetails={props.tempClientDetails}
                setTempClientDetails={props.setTempClientDetails}
              />
            </Card>
          ) : (
            <div className="centerCenterChildren" style={{ height: "240px" }}>
              <span>Select Primary Client to merge others into</span>
            </div>
          )}
        </div>

        <div
          style={{
            width: "50%",
            marginLeft: '15px',
            border: "dashed 2px #1890ff",
            overflowY: "scroll",
          }}
        >
          <span style={{ fontWeight: 400, fontSize: "22px", marginLeft: '24px' }}>
            Inspect Client
          </span>

          {props.loading ? (
            <div style={{marginLeft: '24px'}}>
              <Spin />
              <Skeleton />
            </div>
          ) : (
            <div>
              { fullClientDetails.client.id ?
                <Card
                  extra={
                    <Button
                      type="danger"
                      onClick={() => setFullClientDetails({client: {}})}
                    >
                      Remove
                    </Button>
                  }
                  style={{ width: "100%" }}
                  title={`${fullClientDetails.client.name || ""}: ${
                    fullClientDetails.client.id
                  }`.trim()}
                >
                  <DataAssignmentListItems
                    fullClientDetails={fullClientDetails}
                    setTempClientDetails={props.setTempClientDetails}
                    tempClientDetails={props.tempClientDetails}
                    primaryClient={props.primaryClient}
                  />
                </Card>
                :
                <div className="centerCenterChildren" style={{ height: "240px" }}>
                  <span>Select client to Inspect and Merge</span>
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <div className="centerFlex">
        <Button type="primary" onClick={() => setShowDrawer(true)}>
          Show Delete Clients List ({Object.keys(props.mergingClients).length})
        </Button>
      </div>
      <div
        style={{
          overflowY: "scroll",
          maxHeight: "48%",
          backgroundColor: "WhiteSmoke",
        }}
        className="mergeBottomList"
      >
        {/* Bottom inner */}
        {dataSorter.map((cli) => {
          return (
            <Card
              key={cli.id}
              style={{ width: "30%", marginTop: "15px" }}
              title={`${cli.name || ""}: ${cli.id}`.trim()}
              extra={[
                <Button
                  size="small"
                  type="primary"
                  ghost
                  style={{ marginRight: "10px" }}
                  key="list-set-primary"
                  onClick={() => primaryClientHandler(cli)}
                  disabled={props.loading}
                >
                  Set as Primary
                </Button>,
                <Button
                  disabled={props.loading}
                  size="small"
                  style={{ borderColor: "#1890ff", color: "#1890ff", marginRight: "10px" }}
                  type="dashed"
                  key="list-set-merge"
                  onClick={() => getSingleClientDetails(cli.id)}
                >
                  Set to Inspect
                </Button>,
                <DeleteOutlined
                  onClick={() => mergeClientHandler(cli)}
                  style={{color: 'red'}}
                />,

              ]}
            >
              <div >
                <div key={"ID"}>
                  SQL ID: <b>{`${cli.id || "_"}`.trim()}</b>
                </div>
                <div key={"PosRefNum"}>
                  PosRefNum: <b>{`${cli.pos_ref_num || "_"}`.trim()}</b>
                </div>
                <div key={"Mobile"}>
                  Mobile: <b>{`${cli.mobile || "_"}`.trim()}</b>
                </div>
                <div key={"Email"}>
                  Email: <b>{`${cli.email || "_"}`.trim()}</b>
                </div>
                <div key={"Archived"}>
                  Archived: <b>{`${cli.archive_date ? "YES" : "no"}`}</b>
                </div>
                {checkOtherData(cli.id).map((attr, i) => {
                  return (
                    <div key={attr.key + i}>
                      {attr.key}: <b>{attr.value}</b>
                    </div>
                  );
                })}
              </div>
            </Card>
          );
        })}
      </div>
      <DeleteClients
        open={showDrawer}
        closable={true}
        setOpen={setShowDrawer}
        loading={props.loading}
        primaryClient={props.primaryClient}
        fullClientDetails={fullClientDetails}
        setTempClientDetails={props.setTempClientDetails}
        tempClientDetails={props.tempClientDetails}
        mergingClients={props.mergingClients}
        setMergingClients={props.setMergingClients}
      />
    </div>
  );
};
export default MergeAssignment;
