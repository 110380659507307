import React, { useEffect, useState } from 'react';
import { EditOutlined, ExportOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import request from '../../../../../core/request';
import fetchFileFromUrl from '../../../../../utils/fetchFileFromUrl';
import './styles.css';

const TableHeader = (props) => {
  const {
    setPage,
    search,
    setSearch,
    filters,
    sortField,
    sortOrder,
    exportUri,
    loading,
    prevPage,
    getResults,
    setLoading,
    disabled,
    showSearch,
    showAdvancedFilters,
    automaticRequest,
    headerChildren,
    showBulkActionsButton,
    bulkActionsButtonDisabled,
    onClickFiltersButton,
    onClickBulkActionsButton,
  } = props;

  const [searchValue, setTextSearch] = useState('');

  const handleClickExport = async () => {
    setLoading(true);

    try {
      const config = {
        params: {
          search,
          filters,
          sortField,
          sortOrder,
        },
      };

      const response = await request.basic.get(exportUri, config);

      await fetchFileFromUrl(response.data.url);
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setTextSearch(search);
  }, [search]);

  useEffect(() => {
    if (!automaticRequest && prevPage !== undefined) {
      getResults();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="cb-filtered-table__header">
      {showSearch && <Input.Search
        size="large"
        loading={loading}
        readOnly={loading}
        value={searchValue}
        placeholder="Type to search"
        onChange={(e) => setTextSearch(e.target.value)}
        onSearch={setSearch}
        onPressEnter={(e) => {
          if (!automaticRequest && prevPage !== undefined) {
            setPage(1);
          }

          setSearch(e.target.value)
        }}
        className="cb-filtered-table__header-search-input"
        disabled={disabled}
        enterButton
      />}

      <Space>
        {showAdvancedFilters && <Button
          size="large"
          type="primary"
          loading={loading}
          icon={<FilterOutlined />}
          onClick={onClickFiltersButton}
          disabled={disabled}
        >
          Filters
        </Button>}

        {exportUri && <Button
          size="large"
          type="primary"
          loading={loading}
          icon={<ExportOutlined />}
          onClick={handleClickExport}
          disabled={disabled}
        >
          Export
        </Button>}

        {headerChildren}

        {showBulkActionsButton &&
        <Button
          size="large"
          type="primary"
          loading={loading}
          icon={<EditOutlined />}
          disabled={bulkActionsButtonDisabled}
          onClick={onClickBulkActionsButton}
        >
          Update selected
        </Button>}
      </Space>
    </div>
  );
}

export default TableHeader;
