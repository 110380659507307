import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, CalendarOutlined } from '@ant-design/icons';
import DuplicatedClientsLifeEventsModal from '../LifeEventsModal';
import request from '../../../../../core/request';
import usePaginatedRequest from '../../../../../hooks/usePaginatedRequest';

const DuplicatedClientsInnerTable = (props) => {
  const { clients, selectedMerchant, expanded } = props;

  const [selectedClient, setSelectedClient] = useState(null);
  const [showLifeEventsModal, setShowLifeEventsModal] = useState(false);

  const requestMethod = useCallback(({ params }) => {
    return request.basic.get('/clients/getClients', {
      params: { ...params, merchantUuid: selectedMerchant },
    });
  }, [selectedMerchant]);

  const defaultFilters = useMemo(() => ({
    id: {
      $in: clients,
    }
  }), [clients]);

  const {
    loading,
    results,
    setFilters,
    getResults,
  } = usePaginatedRequest({
    defaultFilters,
    defaultPageSize: 100,
    requestMethod,
    automaticRequest: false,
  });

  const handleOpenLifeEventsModal = useCallback((client) => {
    setSelectedClient(client);
    setShowLifeEventsModal(true);
  }, []);

  const handleCloseLifeEventsModal = useCallback(() => {
    setShowLifeEventsModal(false);

    setTimeout(() => {
      setSelectedClient(null);
    }, 500);
  }, []);

  const columns = useMemo(() => ([
    {
      title: 'UUID',
      dataIndex: 'uuid',
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: true,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      ellipsis: true,
    },
    {
      title: 'pos_ref_num',
      dataIndex: 'pos_ref_num',
      ellipsis: true,
    },
    {
      title: 'Archived',
      dataIndex: 'archive_date',
      ellipsis: true,
      render: value => (Number(value) ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      key: 'options',
      title: 'Options',
      fixed: 'right',
      ellipsis: true,
      render: (_, item) => (
        <Tooltip title="Show life events">
          <Button
            type="primary"
            icon={<CalendarOutlined />}
            onClick={() => handleOpenLifeEventsModal(item)}
            ghost
          />
        </Tooltip>
      ),
    },
  ]), [handleOpenLifeEventsModal]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [setFilters, defaultFilters]);

  useEffect(() => {
    if (expanded && clients.length) {
      getResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, clients]);

  return (
    <>
      <Table
        rowKey="uuid"
        loading={loading}
        dataSource={results}
        columns={columns}
        pagination={false}
        scroll={{ x: 'auto' }}
      />

      {
        selectedClient &&
        <DuplicatedClientsLifeEventsModal
          open={showLifeEventsModal}
          clientUuid={selectedClient.uuid}
          onCancel={handleCloseLifeEventsModal}
        />
      }
    </>
  );
};

export default DuplicatedClientsInnerTable;
