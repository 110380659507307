import React from 'react';
import { Form, Input, InputNumber } from 'antd';

const FiltersModalFormField = (props) => {
  const { value, type } = props;

  return (
    <Form.Item
      name={[value, 'value']}
      noStyle
    >
      {type === 'number' ?
      <InputNumber
        type='number'
        placeholder="Enter value"
        style={{ width: '70%' }}
      /> :
      <Input
        placeholder="Enter value"
        style={{ width: '70%' }}
      />}
    </Form.Item>
  );
}

export default FiltersModalFormField;
