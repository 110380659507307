import React, { useCallback, useEffect, useState } from 'react';
import { Steps } from 'antd';
import { connect } from 'react-redux';
import { STEPS } from '../shared/constants';

const MerchantSetupHeader = props => {
  const [steps, setSteps] = useState([]);
  const { currentStep } = props.stores;

  const updateStepsStatus = useCallback(() => {
    const updatedSteps = [...STEPS].map((item, index) => {
      let status = '';

      if (index > currentStep) {
        status = 'wait';
      } else if (index < currentStep) {
        status = 'finish';
      } else {
        status = 'process';
      }

      return { ...item, status };
    });

    setSteps(updatedSteps);
  }, [currentStep]);

  useEffect(() => {
    updateStepsStatus();
  }, [updateStepsStatus]);

  return (
    <Steps
      type="navigation"
      current={currentStep}
    >
      { steps.map((item, index) => (
        <Steps.Step
          key={index}
          status={item.status}
          title={item.title}
        />
      ))}
    </Steps>
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSetupHeader);
