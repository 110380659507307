const POS_TYPES = [
  { value: 'ASC', label: 'ASC' },
  { value: 'BIG', label: 'BIG' },
  { value: 'DCIT', label: 'DCIT' },
  { value: 'EDGE', label: 'EDGE' },
  { value: 'FURNITUREWIZARD', label: 'Furniture Wizard' },
  { value: 'LIGHTSPEED', label: 'Lightspeed' },
  { value: 'LOGICMATE', label: 'DO NOT USE WITHOUT PERMISSION FROM DEV Logicmate' },
  { value: 'RAIN', label: 'Rain' },
  { value: 'SHOPIFY', label: 'Shopify' },
  { value: 'SHOPKEEPER', text: 'Shopkeeper' },
];

export default POS_TYPES;
