import React, { useEffect, useState } from 'react';
import { Upload, message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { getBase64FromFile } from '../../../core/utils';
import request from '../../../core/request';

const LoadingButton = () => (
    <>
        <LoadingOutlined />
        <div style={{ marginTop: 8 }}>Loading...</div>
    </>
);

const UploadButton = () => (
    <>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </>
);

const UpdateButton = () => (
    <>
        <RedoOutlined />
        <div style={{ marginTop: 8 }}>Update</div>
    </>
);

const ImageUploadInput = (props) => {
    const { value, folder, onChange } = props;
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState(value);
    const [base64Image, setBase64Image] = useState('');
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const handleValidate = (file) => {
        const isJpgOrPng = (
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png'
        );

        if (!isJpgOrPng) {
            message.error('You can only upload JPEG/JPG/PNG files.');
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error('Image must smaller than 2MB.');
        }

        return isJpgOrPng && isLt2M;
    }

    const handleClear = () => {
        setBase64Image('');

        onChange({
            base64: '',
            fileUrl: '',
            fileList: [],
        });
    }

    const handleUpload = async (info) => {
        if (info.fileList.length) {
            try {
                setLoading(true);

                const base64 = await getBase64FromFile(info.file);
                let fileUrl = base64;

                const response = await request.basic.post(`/images/saveImage`, {
                    image: base64,
                    folder,
                });

                fileUrl = response.data.url;

                setBase64Image(base64);

                onChange({
                    base64,
                    fileUrl,
                    fileList: [info.fileList.pop()],
                });

                message.success('Image uploaded successfully.');
            } catch (error) {
                message.error('Error uploading image.');
            } finally {
                setLoading(false);
            }
        } else {
            handleClear();
        }
    }

    const handleChange = (info) => {
        const valid = handleValidate(info.file);

        if (valid) {
            handleUpload(info);
        }
    }

    useEffect(() => {
        setFileList(value);
    }, [value]);

    return (
        <>
            <Upload
                fileList={fileList}
                listType="picture-card"
                onChange={handleChange}
                onPreview={() => setShowPreviewModal(true)}
                beforeUpload={() => false}
                disabled={loading}
            >
                { loading
                    ? <LoadingButton />
                    : fileList.length
                        ? <UpdateButton />
                        : <UploadButton />
                }
            </Upload>

            <Modal
                open={showPreviewModal}
                footer={null}
                onCancel={() => setShowPreviewModal(false)}
            >
                <img
                    src={base64Image}
                    alt=""
                    style={{ width: "100%" }}
                />
            </Modal>
        </>
    );
}

export default ImageUploadInput;
