const REDUCER_PREFIX = 'table';

const DEFAULT_LOADING = false;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 1;
const DEFAULT_PAGES = 0;
const DEFAULT_TOTAL = 0;
const DEFAULT_RESULTS = [];
const DEFAULT_SEARCH = '';
const DEFAULT_FILTERS = {};
const DEFAULT_SORT_FIELD = '';
const DEFAULT_SORT_ORDER = '';

const getDefaultState = () => ({
  loading: DEFAULT_LOADING,
  pageSize: DEFAULT_PAGE_SIZE,
  page: DEFAULT_PAGE,
  pages: DEFAULT_PAGES,
  total: DEFAULT_TOTAL,
  results: [ ...DEFAULT_RESULTS ],
  search: DEFAULT_SEARCH,
  filters: { ...DEFAULT_FILTERS },
  sortField: DEFAULT_SORT_FIELD,
  sortOrder: DEFAULT_SORT_ORDER,
});

const INITIAL_STATE = { ...getDefaultState() }

const setLoading = (payload) => ({ loading: payload });
const setPageSize = (payload) => ({ pageSize: payload });
const setPage = (payload) => ({ page: payload });
const setPages = (payload) => ({ pages: payload });
const setTotal = (payload) => ({ total: payload });
const setResults = (payload) => ({ results: payload });
const setSearch = (payload) => ({ search: payload });
const setFilters = (payload) => ({ filters: payload });
const setSortField = (payload) => ({ sortField: payload });
const setSortOrder = (payload) => ({ sortOrder: payload });
const setDefaultState = () => ({ ...getDefaultState() });

const ACTIONS = {
  [`${REDUCER_PREFIX}/setLoading`]: setLoading,
  [`${REDUCER_PREFIX}/setPageSize`]: setPageSize,
  [`${REDUCER_PREFIX}/setPage`]: setPage,
  [`${REDUCER_PREFIX}/setPages`]: setPages,
  [`${REDUCER_PREFIX}/setTotal`]: setTotal,
  [`${REDUCER_PREFIX}/setResults`]: setResults,
  [`${REDUCER_PREFIX}/setSearch`]: setSearch,
  [`${REDUCER_PREFIX}/setFilters`]: setFilters,
  [`${REDUCER_PREFIX}/setSortField`]: setSortField,
  [`${REDUCER_PREFIX}/setSortOrder`]: setSortOrder,
  [`${REDUCER_PREFIX}/setDefaultState`]: setDefaultState,
}

const tableReducer = (state, { type, payload }) => {
  const newState = state || INITIAL_STATE;
  const action = ACTIONS[type];

  if (!action) return newState;

  return {
    ...newState,
    ...action(payload)
  }
}

export default tableReducer;
