import React from 'react';
import { Form, Select } from 'antd';

const FiltersModalFormFieldsMultiSelect = (props) => {
  const { value, fields, style } = props;

  return (
    <Form.Item
      name={[value, 'fields']}
      noStyle
    >
      <Select
        mode="multiple"
        placeholder="Select fields"
        style={style}
        allowClear
      >
        {fields.map(innerItem => (
          <Select.Option
            key={innerItem.value}
            value={innerItem.value}
          >
            {innerItem.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default FiltersModalFormFieldsMultiSelect;
