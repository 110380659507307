export const ASSOCIATE_HEADERS = [
  { value: 'associate_id', label: 'Associate ID' },
  { value: 'associate_uuid', label: 'Associate UUID' },
  { value: 'associate_name', label: 'Associate Name' },
  { value: 'associate_email', label: 'Associate Email' },
  { value: 'associate_mobile', label: 'Associate Mobile' },
  { value: 'associate_pos_ref_num',  label: 'Associate pos_ref_num' },
];

export const CLIENT_HEADERS = [
  { value: 'client_id',  label: 'Client ID' },
  { value: 'client_uuid',  label: 'Client UUID' },
  { value: 'client_name',  label: 'Client Name' },
  { value: 'client_email',  label: 'Client Email' },
  { value: 'client_mobile',  label: 'Client Mobile' },
  { value: 'client_pos_ref_num',  label: 'Client pos_ref_num' },
];

export const HEADERS = [
  ...ASSOCIATE_HEADERS,
  ...CLIENT_HEADERS,
];
