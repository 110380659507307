import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { notification } from 'antd';
import { version as pckJsonVersion } from '../../../../package.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VersionRefreshNotification from '../RefreshNotification';

const VersionCheck = () => {
  const [api, contextHolder] = notification.useNotification({
    maxCount: 1,
  });
  const HOUR_MS = 60000 * 60;

  useEffect(() => {
    checkVersion();
    const interval = setInterval(() => {
      notification.destroy();
      checkVersion();
    }, HOUR_MS);

    return () => clearInterval(interval);
  }, []);

  const checkVersion = () => {
      Axios.get('/version.txt')
        .then(resp => {
          if (resp.data && `${resp.data}`.includes('.')) {
            const newVersion = resp.data.trim();
            const oldVersion = pckJsonVersion.trim();
            if (isNewerVersion(oldVersion, newVersion)) {
              api.warning({
                message: <span className="fs-14">New version of Clientbook Admin Console is available!</span>,
                duration: (HOUR_MS / 1000) -1,
                description: <VersionRefreshNotification />,
                placement: 'bottomRight',
                closeIcon: (
                  <FontAwesomeIcon
                    className="fs-18"
                    icon={['fa', 'times']}
                    onClick={() => {}}
                  />
                ),
                maxCount: 1,
              });
            }
          }
        })
        .catch(err => {});
  };

  const isNewerVersion = (oldVer, newVer) => {
    const oldParts = oldVer.split('.');
    const newParts = newVer.split('.');
    for (var i = 0; i < newParts.length; i++) {
      const a = ~~newParts[i];
      const b = ~~oldParts[i];
      if (a > b) return true;
      if (a < b) return false;
    }
    return false;
  };

  return contextHolder;
};
export default VersionCheck;
