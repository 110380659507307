import { notification } from 'antd';
import Axios from 'axios';
import { urlDictionary } from '../utils';
import { getHeaders } from './getHeaders';


const request = (type) => {
   const req = Axios.create({
        baseURL: urlDictionary[type][[process.env.REACT_APP_API_ENV || 'development']],
        timeout: 900000,
   });

   req.interceptors.request.use(
    config => ({
      ...config,
      headers: {
        ...config.headers,
        ...getHeaders(),
      },
    }),
    response => response,
    error => Promise.reject(error),
  );

   req.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response) {
        if(err.response.status !== 401) {
          notification.error({
            message: 'Error: please contact Admin Console Support'
          })
          if(err.response.data) {
            notification.error({
              message: JSON.stringify(err.response.data)
            })
          }
        } else {
          notification.error({
            message: 'Unauthorized'
          })
        }
      }
      return Promise.reject(err);
    },
  );

  return req;
}


export default {
    admin: request('admin'),
    basic: request('basic'),
    login: request('login'),
    twilio: request('twilio'),
};


