import React from 'react';
import { Button, Popconfirm, Space } from 'antd';

const BulkActionsModalFooter = (props) => {
  const {
    title,
    loading,
    disabled,
    onConfirm,
    onCancel,
  } = props;

  return (
    <Space>
      <Button onClick={onCancel}>
        Cancel
      </Button>

      <Popconfirm
        title={title}
        onConfirm={onConfirm}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button
          type="primary"
          loading={loading}
          disabled={disabled}
        >
          Apply
        </Button>
      </Popconfirm>
    </Space>
  );
}

export default BulkActionsModalFooter;
