export const DEFAULT_BANDWIDTH_INFORMATION = {
  peerId: '',
  peerName: '',
}

export const DEFAULT_TWILIO_INFORMATION = {
  friendlyName: '',
  authToken: '',
  sid: '',
}

export const DEFAULT_ACCOUNT_INFORMATION = {
  brandImage: '',
  fileList: [],
  accountName: '',
  websiteUrl: '',
  packageBillingCode: 'basic',
  licenseSeats: 10,
  massMessageLimit: 2000,
  posType: null,
  salesforceBillingAccountId: '',
  zohoCustomerId: '',
}

export const DEFAULT_OWNER_INFORMATION = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

export const DEFAULT_STORE_LOCATION = {
  searchStatus: '',
  paymentStatus: '',
  locationName: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zipCode: '',
  country: 'US',
  reviewLink: 'https://search.google.com/local/writereview?placeid=',
  salesforceAccountId: '',
  phoneNumber: '',
  phoneNumberCountryCode: 'US',
  provider: 'bandwidth',
  providerAreaCode: '',
  providerAreaCodeCountryCode: 'US',
  providerNumber: '',
  timezone: '',
  lat: '',
  lng: '',
}

const DEFAULT_VALUES = {
  loading: false,
  currentStep: 0,
  storeType: null,
  bandwidthInformation: {...DEFAULT_BANDWIDTH_INFORMATION},
  twilioInformation: {...DEFAULT_TWILIO_INFORMATION},
  accountInformation: {...DEFAULT_ACCOUNT_INFORMATION},
  ownerInformation: {...DEFAULT_OWNER_INFORMATION},
  storeLocations: {
    0: {...DEFAULT_STORE_LOCATION}
  },
}

const initialState = { ...DEFAULT_VALUES }

export default function reducer(state = initialState, action) {
  let state2 = state;

  switch (action.type) {
    case 'SET_STORE_LOADING':
      return {
        ...state2,
        loading: action.payload,
      };
    case 'SET_CURRENT_STEP':
      return {
        ...state2,
        currentStep: action.payload,
      };
    case 'SET_STORE_TYPE':
      return {
        ...state2,
        storeType: action.payload,
      };
    case 'SET_BANDWIDTH_INFORMATION':
      return {
        ...state2,
        bandwidthInformation: {
          ...DEFAULT_BANDWIDTH_INFORMATION,
          ...action.payload
        },
      };
    case 'SET_TWILIO_INFORMATION':
      return {
        ...state2,
        twilioInformation: {
          ...DEFAULT_TWILIO_INFORMATION,
          ...action.payload
        },
      };
    case 'SET_ACCOUNT_INFORMATION':
      return {
        ...state2,
        accountInformation: {
          ...DEFAULT_ACCOUNT_INFORMATION,
          ...action.payload,
        },
      };
    case 'SET_OWNER_INFORMATION':
      return {
        ...state2,
        ownerInformation: {
          ...DEFAULT_OWNER_INFORMATION,
          ...action.payload,
        },
      };
    case 'SET_STORE_LOCATIONS':
      return {
        ...state2,
        storeLocations: action.payload,
      };
    case 'SET_DEFAULT_VALUES':
      return {
        ...state2,
        ...DEFAULT_VALUES,
      }
    default:
      return state2;
  }
}
