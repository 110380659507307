import React, { useCallback, useEffect, useMemo } from 'react'
import { Modal, Table } from 'antd';
import request from '../../../../../core/request';
import usePaginatedRequest from '../../../../../hooks/usePaginatedRequest';
import usePreviousValue from '../../../../../hooks/usePreviousValue';

const SORT_ORDERS = {
  ascend: 'ASC',
  descend: 'DESC',
}

const COLUMNS = [
  {
    title: 'Type',
    dataIndex: 'type',
    ellipsis: true,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    ellipsis: true,
  },
];

const DuplicatedClientsLifeEventsModal = ({ open, onCancel, clientUuid }) => {
  const requestMethod = useCallback(({ params }) => {
    return request.basic.get('/clients/getClientLifeEvents', {
      params: { ...params, clientUuid },
    });
  }, [clientUuid]);

  const {
    loading,
    page,
    setPage,
    total,
    pageSize,
    setPageSize,
    results,
    getResults,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaginatedRequest({
    requestMethod,
    automaticRequest: false,
  });

  const prevPage = usePreviousValue(page);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
    showSizeChanger: true,
  }), [page, pageSize, total]);

  const handleChange = (value, filters, sorter) => {
    setPage(value.pageSize === pageSize ? value.current : 1);
    setPageSize(value.pageSize);
    setSortField(SORT_ORDERS[sorter.order] ? sorter.field : '');
    setSortOrder(SORT_ORDERS[sorter.order] || '');
  }

  useEffect(() => {
    if (clientUuid) {
      getResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUuid]);

  useEffect(() => {
    if (prevPage) {
      getResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sortField, sortOrder]);

  return (
    <Modal
      title="Life events"
      open={open}
      footer={false}
      onCancel={onCancel}
    >
      <Table
        rowKey="uuid"
        loading={loading}
        dataSource={results}
        columns={COLUMNS}
        pagination={pagination}
        onChange={handleChange}
        scroll={{ x: 'auto' }}
      />
    </Modal>
  );
}

export default DuplicatedClientsLifeEventsModal;
