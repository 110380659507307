import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Steps } from 'antd';

const STEPS = [
  { title: 'Upload', status: 'progress' },
  { title: 'Edit', status: 'wait' },
  { title: 'Confirm', status: 'wait' },
  { title: 'Status', status: 'wait' },
];

const UploadAssociateAssignmentsHeader = ({ currentStep }) => {
  const [steps, setSteps] = useState([...STEPS]);

  const handleChangeStep = useCallback(() => {
    const updatedSteps = [...STEPS].map((item, index) => {
        if (index > currentStep) {
            return {
              ...item,
              status: 'wait',
            }
        } else if (index < currentStep) {
            return {
              ...item,
              status: 'finish',
            }
        } else {
            return {
              ...item,
              status: 'process',
            }
        }
    });

    setSteps(updatedSteps);
  }, [currentStep]);

  useEffect(() => {
    handleChangeStep();
  }, [handleChangeStep]);

  return (
    <Steps
      type="navigation"
      current={currentStep}
      style={{ marginBottom: '20px' }}
    >
      {steps.map((item, index) => (
        <Steps.Step
          key={index}
          status={item.status}
          title={item.title}
        />
      ))}
    </Steps>
  );
}


const mapStateToProps = state => state.uploadAssociateAssignments;

export default connect(mapStateToProps, {})(UploadAssociateAssignmentsHeader);
