import React, { useState } from 'react'
import { Typography, Divider, Form } from 'antd';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import PhoneLookupForm from './components/Form';
import PhoneLookupDetails from './components/Details';

const PhoneLookup = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [information, setInformation] = useState(null);

  return (
    <PrimaryLayout>
      <div style={{ maxWidth: '650px', paddingBottom: '20px' }}>
        <Typography.Title level={3}>Phone Lookup</Typography.Title>

        <Divider />

        <PhoneLookupForm
          form={form}
          loading={loading}
          setLoading={setLoading}
          setInformation={setInformation}
        />

        { information && <PhoneLookupDetails information={information} /> }
      </div>
    </PrimaryLayout>
  );
}

export default PhoneLookup;
