import React from 'react'
import { message, Modal } from 'antd'
import { useState } from 'react'
import request from '../../../../core/request';

const DELETE_SUCCESS_MESSAGE = 'Store deleted successfully.';

const DeleteStoreModal = ({ open, onCancel, onSuccess, store }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    try {
      const params = { params: { uuid: store.uuid }};

      await request.basic.delete(`/stores/deleteStore`, params);

      message.success(DELETE_SUCCESS_MESSAGE);

      if (onSuccess) onSuccess()
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
      onCancel();
    }
  }

  return (
    <Modal
      title="Delete store"
      open={open}
      okText="Delete"
      onOk={handleDelete}
      confirmLoading={loading}
      onCancel={onCancel}
    >
      Are you sure you want to delete <strong>{store?.name}</strong> store?
    </Modal>
  );
}

export default DeleteStoreModal;
