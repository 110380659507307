import { DeleteOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";
import moment from "moment";
import React from "react";
import { timeFormat14, timeFormat8, truthTextHandler } from "../../../../../../../core/utils";

const PrimaryClientListItems = (props) => {
  const {
    salesTransactions,
    salesOpps,
    relatives,
    lifeEvents,
    tags,
    collections,
    associates,
    history,
    address,
    client,
    preferences,
    chatMessages,
  } = props.primaryClient;

  const {
    salesOpps: temp_salesOpps,
    relatives: temp_relatives,
    lifeEvents: temp_lifeEvents,
    tags: temp_tags,
    collections: temp_collections,
    associates: temp_associates,
    history: temp_history,
    address: temp_address,
    client: temp_client,
    preferences: temp_preferences,
    salesTransactions: temp_salesTransactions,
    chatMessages: temp_chatMessages,
  } = props.tempClientDetails;

  const clientObj = client || {};
  const tempClient = temp_client || {};

  const DelAttrIcon = (val, clientProp) => {
    if(val.newAttribute) {
      return <DeleteOutlined style={{marginLeft: '10px', color: 'red'}} onClick={() => DelAttrHandler(val, clientProp)}/>
    }
  }

  const DelAttrHandler = (val, clientProp) => {
    let filterArr = props.tempClientDetails[clientProp].filter(obj => {
      return !Object.is(obj, val)
    })
    props.setTempClientDetails({...props.tempClientDetails, [clientProp]: filterArr})
  }

  const DelCliAttrIcon = (clientProp) => {
    if(props.tempClientDetails.client[clientProp]) {
      let newClientObj = { ...props.tempClientDetails }
      delete newClientObj[clientProp];
      return <DeleteOutlined style={{marginLeft: '10px', color: 'red'}} onClick={() =>{
        props.setTempClientDetails({...props.tempClientDetails, client: newClientObj});
      }}/>
    }
  }



  return (
    <div>
      <Descriptions key={"clientObj"} title="Client" column={1}>
        <Descriptions.Item label="Name">
          {tempClient.name || clientObj.name} {DelCliAttrIcon("name")}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile">
          {tempClient.mobile || clientObj.mobile} {DelCliAttrIcon("mobile")}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {tempClient.email || clientObj.email} {DelCliAttrIcon("email")}
        </Descriptions.Item>
        <Descriptions.Item label="PosRefNum">
          {tempClient.pos_ref_num || clientObj.pos_ref_num} {DelCliAttrIcon("pos_ref_num")}
        </Descriptions.Item>
        <Descriptions.Item label="Preferred Name">
          {tempClient.preferred_name || clientObj.preferred_name} {DelCliAttrIcon("preferred_name")}
        </Descriptions.Item>
        <Descriptions.Item label="Home Phone">
          {tempClient.home_phone || clientObj.home_phone} {DelCliAttrIcon("home_phone")}
        </Descriptions.Item>
        <Descriptions.Item label="Pref Contact Type">
          {tempClient.pref_contact_type || clientObj.pref_contact_type} {DelCliAttrIcon("pref_contact_type")}
        </Descriptions.Item>
        <Descriptions.Item label="Dont Contact SMS">
          {truthTextHandler(tempClient.dont_contact_sms || clientObj.dont_contact_sms)} {DelCliAttrIcon("dont_contact_sms")}
        </Descriptions.Item>
        <Descriptions.Item label="Dont Contact Email">
          {truthTextHandler(tempClient.dont_contact_email || clientObj.dont_contact_email)} {DelCliAttrIcon("dont_contact_email")}
        </Descriptions.Item>
        <Descriptions.Item label="Image">
          {tempClient.image || clientObj.image} {DelCliAttrIcon("image")}
        </Descriptions.Item>
        <Descriptions.Item label="Opt In Date">
          {timeFormat14(tempClient.opt_in_date, clientObj.opt_in_date)} {DelCliAttrIcon("opt_in_date")}
        </Descriptions.Item>
        <Descriptions.Item label="Opt In Req Date">
          {timeFormat14(tempClient.opt_in_req_date, clientObj.opt_in_req_date)} {DelCliAttrIcon("opt_in_req_date")}
        </Descriptions.Item>
        <Descriptions.Item label="Opt Out Date">
          {timeFormat14(tempClient.opt_out_date, clientObj.opt_out_date)} {DelCliAttrIcon("opt_out_date")}
        </Descriptions.Item>
        <Descriptions.Item label="Opt In Src">
          {tempClient.opt_in_src || clientObj.opt_in_src} {DelCliAttrIcon("opt_in_src")}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile Validated">
          {tempClient.mobile_validated || clientObj.mobile_validated} {DelCliAttrIcon("mobile_validated")}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile Validation Date">
          {timeFormat8(tempClient.mobile_validation_date, clientObj.mobile_validation_date)} {DelCliAttrIcon("mobile_validation_date")}
        </Descriptions.Item>
        <Descriptions.Item label="Auto Review Timestamp">
          {tempClient.auto_review_ts || clientObj.auto_review_ts} {DelCliAttrIcon("auto_review_ts")}
        </Descriptions.Item>
        <Descriptions.Item label="Last Visit Date">
          {timeFormat8(tempClient.last_visit_date, clientObj.last_visit_date)} {DelCliAttrIcon("last_visit_date")}
        </Descriptions.Item>
        <Descriptions.Item label="Last Contact Date">
          {timeFormat8(tempClient.last_contact_date, clientObj.last_contact_date)} {DelCliAttrIcon("last_contact_date")}
        </Descriptions.Item>
      </Descriptions>

      {[...temp_address, ...address].map((addr) => {
        return (
          <Descriptions column={1} title="Address" key={`Addr${addr.id}`}>
            <Descriptions.Item label="Address 1">
              {addr.address_1} {DelAttrIcon(addr, 'address')}
            </Descriptions.Item>
            <Descriptions.Item label="Address 2">
              {addr.address_2}
            </Descriptions.Item>
            <Descriptions.Item label="City">{addr.city}</Descriptions.Item>
            <Descriptions.Item label="State">{addr.state}</Descriptions.Item>
            <Descriptions.Item label="ZIP">
              {addr.postal_code}
            </Descriptions.Item>
            <Descriptions.Item label="Type">{addr.type}</Descriptions.Item>
          </Descriptions>
        );
      })}

      {[...temp_lifeEvents, ...lifeEvents].length > 0 ?
        <Descriptions
          key={`LifeEvents`}
          column={1}
          title="Life Event"
          >
          {[...temp_lifeEvents, ...lifeEvents].map((lifeE) => {
            return (<Descriptions.Item key={lifeE.id} label={lifeE.type}>{lifeE.date} {DelAttrIcon(lifeE, 'lifeEvents')} </Descriptions.Item>)
          })}
        </Descriptions>
      : null}

      {[...temp_relatives, ...relatives].length > 0 ? (
        <Descriptions column={1} key={`Relative`} title="Relative">
          {[...temp_relatives, ...relatives].map((relat) => {
            return (
              <Descriptions.Item
                key={`${relat.client_id}${relat.related_client_id}${relat.relationship}`}
                label={relat.relationship}
              >
                {relat.client_name} and {relat.related_client_name} {DelAttrIcon(relat, 'relatives')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      {[...temp_tags, ...tags].length > 0 ? (
        <Descriptions column={1} key={"tags"} title="Tags">
          {[...temp_tags, ...tags].map((tag) => (
            <Descriptions.Item key={tag.id}>
              <span style={{ color: tag.color || "black" }}>{tag.name} {DelAttrIcon(tag, 'tags')}</span>
            </Descriptions.Item>
          ))}
        </Descriptions>
      ) : null}
      {[...temp_preferences, ...preferences].length > 0 ? (
        <Descriptions column={1} key={"Personalizations"} title="Preferences">
          {[...temp_preferences, ...preferences].map((pref) => {
            return (
              <Descriptions.Item key={pref.id} label={pref.name}>
                {pref.value} {DelAttrIcon(pref, 'preferences')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      {[...temp_associates, ...associates].length > 0 ? (
        <Descriptions
          column={1}
          key={`AssignedAscts`}
          title="Assigned Associates"
        >
          {[...temp_associates, ...associates].map((asct) => {
            return (
              <Descriptions.Item key={asct.id}>
                {asct.first_name} {asct.last_name} {DelAttrIcon(asct, 'associates')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      {[...temp_salesTransactions, ...salesTransactions].length > 0 ? (
        <Descriptions column={1} key={"ThemSales"} title="Sales History">
          {salesTransactions.map((sale) => {
            return (
              <Descriptions.Item
                key={sale.id}
                label={`Sale on ${moment(
                  sale.transaction_date_time,
                  "YYYYMMDDHHmmss"
                ).format("MM/DD/YYYY")}`}
              >
                {sale.unit_quantity} {sale.category} for ${sale.unit_price} {DelAttrIcon(sale, 'salesTransactions')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      {[...temp_salesOpps, ...salesOpps].length > 0 ? (
        <Descriptions column={1} key={"SalesOpz"} title="Sales Opportunities">
          {[...temp_salesOpps, ...salesOpps].map((sOpp) => {
            return (
              <Descriptions.Item key={sOpp.id}>
                {sOpp.complete_date ? "COMPLETE" : "INCOMPLETE"}:<br />
                {`\n ${sOpp.name} - ${sOpp.stage_name}`} {DelAttrIcon(sOpp, 'salesOpps')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      {[...temp_history, ...history].length > 0 ? (
        <Descriptions column={1} key={"ClientHistory"} title="Client History">
          {[...temp_history, ...history].map((hist) => {
            return (
              <Descriptions.Item
                key={hist.id}
                label={moment(hist.created_date, "YYYYMMDDHHmmss").format(
                  "MM/DD/YYYY"
                )}
              >
                {hist.type} {DelAttrIcon(hist, 'history')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      {[...temp_collections, ...collections].length > 0 ? (
        <Descriptions column={1} key={"TheCollectionz"} title="Collections">
          {[...temp_collections, ...collections].map((coll) => {
            return (
              <Descriptions.Item key={coll.id} label={`"${coll.name}"`}>
                Item Count: {coll.item_count} {DelAttrIcon(coll, 'collections')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : null}
      <Descriptions column={1} key={"ChatMSgs"} title="Chat Messages">
        <Descriptions.Item key={"primarychat"} label={'Primary Client'}>
          {chatMessages.length > 0 ?
            `${chatMessages[0].msgs ||0}`
            : '0'}
          {/* {"msgs":17} */}

        </Descriptions.Item>
        {
            Object.values(temp_chatMessages).map(clientChat => {
              return (
                <Descriptions.Item key={clientChat.clientId} label={`Client ${clientChat.clientId}`}>
                  {clientChat.msgs} Messages <DeleteOutlined style={{marginLeft: '10px', color: 'red'}} onClick={() =>{
                                      let tempChatObj = props.tempClientDetails.chatMessages;
                                      delete tempChatObj[clientChat.clientId];
                                      props.setTempClientDetails({...props.tempClientDetails, chatMessages: tempChatObj});
                                    }}/>
                </Descriptions.Item>
              )
            })
          }
      </Descriptions>
    </div>
  );
};
export default PrimaryClientListItems;
