export const ACTIVE_STYLES = {
  backgroundColor: "#fafafa",
  cursor: "pointer",
  padding: 25,
}

export const INACTIVE_STYLES = {
  backgroundColor: "#ffffff",
  cursor: "pointer",
}
