import { getLatLng } from 'react-places-autocomplete';
import { ACTIVE_STYLES, INACTIVE_STYLES } from './constants';

export const getInputPropsWithId = (id, props, getInputProps) => {
  return id ? getInputProps({ props }) : getInputProps();
}

export const getSuggestionItemClassName = (active) => {
  return active
    ? 'suggestion-item--active'
    : 'suggestion-item';
}

export const getSuggestionItemStyles = (active) => {
  return active
    ? ACTIVE_STYLES
    : INACTIVE_STYLES;
}

export const getLocationDetails = async (geocodeInstance, getLocationTimezone, placeId) => {
  const details = {
    country: '',
    state: '',
    city: '',
    zipCode: '',
    neighborhood: '',
    street: '',
    streetNumber: '',
    timezone: '',
    lat: '',
    lng: '',
    placeId: placeId,
  };

  geocodeInstance.address_components.forEach(addressComponent => {
    if (addressComponent.types.includes('country')) {
      details['country'] = addressComponent.short_name;
    } else if (addressComponent.types.includes('administrative_area_level_1')) {
      details['state'] = addressComponent.short_name;
    } else if (addressComponent.types.includes('locality')) {
      details['city'] = addressComponent.long_name;
    } else if (addressComponent.types.includes('postal_code')) {
      details['zipCode'] = addressComponent.long_name;
    } else if (addressComponent.types.includes('route')) {
      details['street'] = addressComponent.long_name;
    } else if (addressComponent.types.includes('street_number')) {
      details['streetNumber'] = addressComponent.long_name;
    }
  });

  const { lat, lng } = await getLatLng(geocodeInstance);

  details['lat'] = lat;
  details['lng'] = lng;
  details['timezone'] = await getLocationTimezone(lat, lng);

  return details;
}
