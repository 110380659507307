import React, { useEffect, useState } from "react";
import PrimaryLayout from "../../../../Layout/PrimaryLayout";
import { Table } from "antd";
import moment from 'moment';
import { connect } from "react-redux";
import SmileyFace from "../../HomeImages/SmileyFace";
import NeutralFace from "../../HomeImages/NeutralFace";
import SadFace from "../../HomeImages/SadFace";
import SatisfactionCard from "../../../../Layout/SatisfactionCard";
import request from "../../../../../core/request";


const AssociateRating = (props) => {
  const [merchantList, setMerchantList] = useState([]);
  const [selectedTimeFrame] = useState("30 days");
  const {associateId} = props.match.params;

  useEffect(() => {
      if(props.auth.jwtToken) {
          request.basic.get('/merchants/getMerchants')
          .then(resp => {
              setMerchantList([...resp.data]);
            })
            .catch(err => {});
        }
    }, [props.auth.jwtToken]);

    useEffect(() => {
        if(merchantList.length > 0) {
            request.basic.get(`/accounts/getFeedback?timeFrame=${selectedTimeFrame}`)
            .then(resp => {
                props.setRatingState({allRatings: resp.data});
            })
            .catch(err => {});
        }
    }, [merchantList, selectedTimeFrame]);

    const columns = [
        {
            title: 'Account',
            dataIndex: 'merchant',
            key: 'merchant',
        },
        {
            title: 'Name',
            dataIndex: 'associate_name',
            key: 'associate_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Platform',
            dataIndex: 'device',
            key: 'device',
        },
        {
            title: 'Screen',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Date / Time',
            dataIndex: 'date',
            key: 'date',
            render: (t,o) => {
                return  moment(t, 'YYYYMMDDHHmmss').format("MM/DD/YYYY")
            }
        },
        {
            title: 'Response',
            dataIndex: 'answer',
            key: 'answer',
            render: (t,o ) => {
                if(t < 0) {
                    return <SadFace height="16" width="16" />
                } else if (t > 0) {
                    return <SmileyFace height="16" width="16" />

                } else {
                    return <NeutralFace height="16" width="16" />
                }
            }
        },
    ];

    const specificAssociateList = () => {
      return props.ratings.allRatings.filter(row => row.associate_id == associateId)
    }

  return (
    <PrimaryLayout header="Home">
      <div>
        <div className="flex-row-wrap" style={{justifyContent: "space-between"}}>
            <SatisfactionCard
                image={SmileyFace}
                type={"Satisfied"}
                calcValue={1}
                allFeedback={specificAssociateList()}
            />
            <SatisfactionCard
                image={NeutralFace}
                type={"Neutral"}
                calcValue={0}
                allFeedback={specificAssociateList()}
            />
            <SatisfactionCard
                image={SadFace}
                type={"Unsatisfied"}
                calcValue={-1}
                allFeedback={specificAssociateList()}
            />
        </div>
        <div className="m-top-15">
            <Table dataSource={specificAssociateList()} rowKey="date" columns={columns} pagination={false}/>
        </div>
      </div>
    </PrimaryLayout>
  );
};

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setRatingState(val){
        dispatch({
            type: "SET_RATING_STATE",
            payload: val
        })
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(AssociateRating);
