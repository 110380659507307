import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { getCountryCallingCode } from 'libphonenumber-js';
import CountrySelect from '../CountrySelect';
import './styles.css';

const DEFAULT_COUNTRY = 'US';

const PhoneInput2 = props  => {
  const {
    value,
    onChange,
    countryValue,
    onCountryChange,
    disabled,
    loading,
    children,
    ...rest
  } = props;

  const [countryCode, setCountryCode] = useState(countryValue || DEFAULT_COUNTRY);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChangeValue = () => {
    if (value) {
      const callingCode = `+${getCountryCallingCode(countryCode)}`;
      const fixedValue = value.replace(callingCode, '');

      if (Number(fixedValue) !== Number(phoneNumber)) {
        setPhoneNumber(fixedValue);
      }
    } else {
      setPhoneNumber('');
    }
  }

  const handleChangePhoneNumber = () => {
    if (phoneNumber) {
      const callingCode = `+${getCountryCallingCode(countryCode)}`;
      const fixedValue = `${callingCode}${phoneNumber}`;

      if (fixedValue !== value) {
        onChange(fixedValue);
      }
    } else if (phoneNumber !== value) {
      onChange(phoneNumber || '');
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleChangeValue(), [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleChangePhoneNumber(), [phoneNumber]);

  useEffect(() => {
    setCountryCode(countryValue || DEFAULT_COUNTRY)
  }, [countryValue]);

  useEffect(() => {
    if (onCountryChange) {
      onCountryChange(countryCode)
    }
  }, [onCountryChange, countryCode]);

  return (
    <Input.Group className="cb-phone-input-2" compact>
      <CountrySelect
        value={countryCode}
        onChange={setCountryCode}
        disabled={disabled}
        showSearch
      />

      <Input
        {...rest}
        value={phoneNumber}
        loading={loading}
        disabled={disabled}
        onChange={(e) => {
          const value = e.target.value || ''
          const fixedValue = value.replace(/[^0-9]/g, '');

          setPhoneNumber(fixedValue);
        }}
      />

      {children}
    </Input.Group>
  );
}

export default PhoneInput2;
