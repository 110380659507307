import React, { useEffect, useState } from "react";
import PrimaryLayout from "../../Layout/PrimaryLayout";
import { Link, useHistory } from "react-router-dom";
import { Select, Spin, Table } from "antd";
import { connect } from "react-redux";
import SmileyFace from "./HomeImages/SmileyFace";
import NeutralFace from "./HomeImages/NeutralFace";
import SadFace from "./HomeImages/SadFace";
import SatisfactionCard from "../../Layout/SatisfactionCard";
import downArrow from '../../../images/arrow-square-down-solid.svg';
import upArrow from '../../../images/arrow-square-up-solid.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MerchantRatingLineGraph from "./children/MerchantRatingLineGraph";
import request from "../../../core/request";

const Option = Select.Option;


const Home = (props) => {
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedDeviceType, setSelectedDeviceType] = useState("All Device Types");
  const deviceTypes = ["All Device Types ", "Web", "iOS", "Android"];
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("30 days");
  const timeFrameOptions = ["30 days", "60 days", "90 days", "All Time"];
  const [loading, setLoading] = useState(false);
    const history = useHistory();
//   const [merchantFeedback, setMerchantFeedback] = useState([]);

    useEffect(() => {
        if(props.auth.jwtToken) {
          setLoading(true);
            request.basic.get(`/accounts/getFeedback?timeFrame=${selectedTimeFrame}&deviceType=${selectedDeviceType}`)
            .then(resp => {
              setLoading(false);
                props.setRatingState({allRatings: resp.data});
                buildMerchantData(resp);
            })
            .catch(err => {
              setLoading(false);
            });
        }
    }, [props.auth.jwtToken, selectedTimeFrame, selectedDeviceType]);

    const buildMerchantData = (resp) => {
        const merchObj = {};
        let earliestDate;
        let latestDate;
        resp.data.forEach(row => {
            if(!earliestDate || row.date < earliestDate) {
              earliestDate = row.date;
            }
            if(!latestDate || row.date > latestDate) {
              latestDate = row.date;
            }

            if(!merchObj.hasOwnProperty(row.merchant)) {
                merchObj[row.merchant] = {
                    status: row.answer,
                    firstHalf: null,
                    secondHalf: null,
                    ...row
                };
            } else {
                merchObj[row.merchant].status += row.answer;
            }
        })
        const midDate = Math.floor((earliestDate + latestDate) / 2);

        resp.data.forEach(row =>{
          if(row.date < midDate) {
            if(merchObj[row.merchant].firstHalf === null) {
              merchObj[row.merchant].firstHalf = row.answer;
            } else {
              merchObj[row.merchant].firstHalf += row.answer;
            }
          } else {
            if(merchObj[row.merchant].secondHalf === null) {
              merchObj[row.merchant].secondHalf = row.answer;
            } else {
              merchObj[row.merchant].secondHalf += row.answer;
            }
          }
        });


        props.setRatingState({merchantRatings: Object.values(merchObj), allRatings: resp.data});
    }

    const columns = [
        {
            title: 'Account',
            dataIndex: 'merchant',
            key: 'merchant',
        },
        {
            title: 'Response',
            dataIndex: 'status',
            key: 'status',
            render: (t,o ) => {
                if(t < 0) {
                    return <SadFace height="16" width="16" />
                } else if (t > 0) {
                    return <SmileyFace height="16" width="16" />

                } else {
                    return <NeutralFace height="16" width="16" />
                }
            }
        },
        {
            title: 'Progress',
            dataIndex: 'status',
            key: 'progress',
            render: (t,o ) => {
                if(o.firstHalf !== null && o.secondHalf !== null) {
                  if(o.firstHalf < o.secondHalf) {
                    return <img style={{width: '13px' }} src={upArrow} />
                  } else if (o.firstHalf > o.secondHalf) {
                    return <img style={{width: '13px' }} src={downArrow} />
                  } else {
                    return <FontAwesomeIcon
                      icon={['fas', 'minus-square']}
                      color="#FFCA00"
                    />
                  }
                } else {
                  return <FontAwesomeIcon
                    icon={['fas', 'minus-square']}
                    color="#FFCA00"
                  />
                }
            }
        },
    ];

    const dataSort = () => {
      if(selectedMerchant) {
        return props.ratings.merchantRatings.filter(row => row.merchant === selectedMerchant)
      } else return props.ratings.merchantRatings
    }

  return (
    <PrimaryLayout header="Home">
      <Spin spinning={loading}>
      <div>
        <div
          className="flex-row-wrap"
          style={{ justifyContent: "space-between" }}
        >
          <div className="flex-row-wrap">
            <span className="fs-28 m-right-30">All Merchants</span>
            <Select
              size="large"
              allowClear
              style={{ width: "350px", height: "50px" }}
              placeholder="Search For Dashboard Account"
              value={selectedMerchant}
              onChange={val => {
                  setSelectedMerchant(val);
              }}
            >
              {props.ratings.merchantRatings.map(mer => (
                <Option key={mer.merchant}>{mer.merchant}</Option>
              ))}
            </Select>
          </div>
          <Select
            style={{ height: '32px'}}
            placeholder="Device Type"
            value={selectedDeviceType}
            onChange={val => setSelectedDeviceType(val)}
          >
            {deviceTypes.map(type => <Option key={type}>{type}</Option>)}
          </Select>
          <Select
            style={{width: '144px', height: '32px'}}
            placeholder="Time Frame"
            value={selectedTimeFrame}
            onChange={val => setSelectedTimeFrame(val)}
          >
            {timeFrameOptions.map(tf => <Option key={tf}>{tf}</Option>)}
          </Select>
        </div>
        <div style={{height: '20vh'}} className="bg-white rem-pad-width p-left-15 p-right-15 p-top-15 p-btm-15">
          <MerchantRatingLineGraph
            configObj={{}}
            allRatings={props.ratings.allRatings}
            selectedTimeFrame={selectedTimeFrame}
          />
        </div>
        <div className="flex-row-wrap m-top-15" style={{justifyContent: "space-between"}}>
            <SatisfactionCard
                image={SmileyFace}
                type={"Satisfied"}
                calcValue={1}
                allFeedback={props.ratings.allRatings}
            />
            <SatisfactionCard
                image={NeutralFace}
                type={"Neutral"}
                calcValue={0}
                allFeedback={props.ratings.allRatings}
            />
            <SatisfactionCard
                image={SadFace}
                type={"Unsatisfied"}
                calcValue={-1}
                allFeedback={props.ratings.allRatings}
            />
        </div>
        <div className="m-top-15">
            <Table
                dataSource={dataSort()}
                rowKey="date"
                columns={columns}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                          history.push(`/merchants/${record.merchant}/ratings`);
                      },
                    };
                  }}
            />
        </div>
        <div className="m-btm-50 align-center m-top-24 ">
          <Link className="fs-25" to="/associates/ratings">View All Associate Ratings</Link>
        </div>

      </div>
      </Spin>
    </PrimaryLayout>
  );
};

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setRatingState(val){
        dispatch({
            type: "SET_RATING_STATE",
            payload: val
        })
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(Home);
