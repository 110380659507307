import React from 'react';
import { Select } from 'antd';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import countries from './constants/countries.json'

const CountrySelect = props => {
  return (
    <Select {...props} style={{ width: '95px !important' }}>
      {getCountries().map(code => (
        <Select.Option key={code} value={code}>
          {countries[code].emoji} +{getCountryCallingCode(code)}
        </Select.Option>
      ))}
    </Select>
  );
}

export default CountrySelect;
