import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PrimaryLayout from '../../Layout/PrimaryLayout';
import UploadAssociateAssignmentsHeader from './Layout/Header';
import UploadAssociateAssignmentsBody from './Layout/Body';

const UploadAssociateAssignments = ({ setDefaultValues }) => {
  useEffect(() => {
    return () => setDefaultValues()
  }, [setDefaultValues]);

  return (
    <PrimaryLayout header="Upload Associate Assignments">
      <div style={{ padding: '10px 15px' }}>
        <UploadAssociateAssignmentsHeader />
        <UploadAssociateAssignmentsBody />
      </div>
    </PrimaryLayout>
  );
}

const mapStateToProps = state => state.uploadAssociateAssignments;

const mapDispatchToProps = dispatch => ({
  setDefaultValues() {
    dispatch({
      type: 'uploadAssociateAssignments/SET_DEFAULT_VALUES',
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadAssociateAssignments);
