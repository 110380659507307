import React from 'react';
import PhoneInputCountry from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './styles.css';

/*
** DESCRIPTION:
** react-phone-number-input npm component with fixed styles.
*/

const PhoneInput = props => {
  return (
    <PhoneInputCountry {...props} countrySelectProps={{ unicodeFlags: true }} />
  );
}

export default PhoneInput;