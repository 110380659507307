import React, { useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import request from '../../../../core/request';
import { requiredField } from '../../../../utils/validations';

const DELETE_SUCCESS_MESSAGE = 'Merchant deleted successfully.';

const DeleteMerchantModal = ({ open, onCancel, onSuccess, merchant }) => {
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const [form] = Form.useForm();

  const password = Form.useWatch('password', form);

  const handleDelete = async () => {
    setLoading(true);

    try {
      const params = {
        params: {
          merchantUuid: merchant.uuid,
          password: encodeURIComponent(btoa(password)),
        }
      };

      await request.basic.delete(`/merchants/deleteMerchant`, params);

      message.success(DELETE_SUCCESS_MESSAGE);

      if (onSuccess) onSuccess()
    } catch (error) {
      if (error.response.status === 401) {
        setPasswordError('Incorrect password');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title="Delete merchant"
      open={open}
      okText="Delete"
      onOk={handleDelete}
      confirmLoading={loading}
      okButtonProps={{ disabled: !password }}
      onCancel={onCancel}
    >
      <p><strong>{merchant?.name}</strong> account will be deleted, enter password to continue:</p>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleDelete}
      >
        <Form.Item
          name="password"
          rules={[requiredField('Password')]}
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError}
        >
          <Input
            type="password"
            placeholder="Enter password"
            onChange={() => setPasswordError('')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DeleteMerchantModal;
