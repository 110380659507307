import React from 'react'
import { notification, message, Modal } from 'antd'
import { useState } from 'react'
import request from '../../../../../../core/request';

const DELETE_SUCCESS_MESSAGE = 'Sales Action deleted successfully.';

const DELETE_ERROR_MESSAGE = 'This configuration is currently being used.  Please remove the usage and try again.';

const DeleteSalesActionConfigModal = ({ open, onCancel, onSuccess, salesActionConfig }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    try {
      const params = { params: { id: salesActionConfig.id }};

      await request.basic.delete(`/merchants/deleteSalesActionConfig`, params);

      message.success(DELETE_SUCCESS_MESSAGE);

      if (onSuccess) onSuccess()
    } catch (error) {
      notification.error({
        message: 'Error deleting configuration',
        description: DELETE_ERROR_MESSAGE
      });
    } finally {
      setLoading(false);
      onCancel();
    }
  }

  return (
    <Modal
      title="Delete sales action"
      open={open}
      okText="Delete"
      onOk={handleDelete}
      confirmLoading={loading}
      onCancel={onCancel}
    >
      Are you sure you want to delete <strong>{salesActionConfig?.title}</strong> sales action?
    </Modal>
  );
}

export default DeleteSalesActionConfigModal;
